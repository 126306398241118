.visiting--table--booking--content--p1 {
    font-family: Helvetica;
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
    margin-top: 30px;
    margin-bottom: 10px;

}
.visiting--table--booking--content--p2 {
    font-family: Helvetica;
    font-size: 12px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
    margin-top: 10px;
    margin-bottom: 30px;
}
.visiting--table--booking--content--tabs::-webkit-scrollbar {
    width: 1px;
}
.visiting--table--booking--content--tabs::-webkit-scrollbar-track {
    background-color: transparent;
}
.visiting--table--booking--content--tabs::-webkit-scrollbar-thumb {
    border-radius: 10px;
}
.visiting--table--booking--content--tabs {
    display: flex;
    overflow-x: auto;
    gap: 10px;
    padding-bottom: 5px;
}
.visiting--table--booking--content--tabs1 {
    width: 80%;
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    padding-bottom: 5px;
    margin: auto;
}
.visiting--table--booking--content--tabs--card {
    width: 150px;
    height: 80px;
    border-radius: 4px;
    border: 1px;
    font-family: Helvetica;
    font-size: 12px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    border: 1px solid #b3b3b3;
    background-color: #fff;
}
.visiting--table--booking--content--tabs--card {
    flex: 0 0 120px;
    height: 80px;
    border-radius: 5px;
    overflow: hidden;
    transition: transform 0.2s;
}
.Plan--your--event--popup--1--p--visit--button button {
    width: 150px;
    height: 40px;
    flex-shrink: 0;
    border: none;
    outline: none;
    border-radius: 5px;
    background: #1EBE92;
    color: #FFF;
    text-align: center;
    font-family: Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.28px;
}
.Plan--your--event--popup--1--p--visit--button {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.Plan--your--event--popup--1--img--h2 {
    color: #FFF;
    text-align: center;
    font-family: Helvetica;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
}
.visiting--table--booking-second--page--1 {
    display: flex;
    flex-direction: row;
    column-gap: 30px;
    justify-content: center;
    margin-bottom: 30px;
}
.visiting--table--booking-second--page--1--1 label {
    color: #000;
    font-family: poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.56px;
    margin: 0;
    padding: 0;
}
.visiting--table--booking-second--page--1--1--amenities label {
    color: #000;
    font-family: poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.56px;
    margin: 0;
    padding: 0;
}
.visiting--table--booking-second--page--1--1 {
    display: flex;
    flex-direction: column;
    row-gap: 3px;
}
.visiting--table--booking-second--page--1--1 input {
    width: 165px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 0.7px solid rgba(0, 0, 0, 0.30);
    background: #FFF;
    color: #000;
    font-family: poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.64px;
    padding-left: 10px;
}
.visiting--table--booking-second--page--1--1 select {
    width: 165px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 0.7px solid rgba(0, 0, 0, 0.30);
    background: #FFF;
    color: #000;
    font-family: poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.64px;
    padding-left: 10px;
}
.visiting--table--booking-second--page--1--1--amenities {
    display: flex;
    flex-direction: column;
    row-gap: 3px;
}
.visiting--table--booking-second--page--1--1--amenities select {
    width: 350px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 0.7px solid rgba(0, 0, 0, 0.30);
    background: #FFF;
    color: #000;
    font-family: poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.64px;
    opacity: 0.3;
}
.visiting--table--booking--content {
    padding-left: 30px;
    padding-right: 30px;
}
.visiting--table--booking--content--tabs--card.selected {
    width: 150px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: none;
    outline: none;
    border: 1px solid #1976D2;
    background: #FFF;
    color: #1976D2;
    text-align: center;
    font-family: Helvetica;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
}
.responsive-modal-1--popup--1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    height: 450px;
    background-color: white;
    border: 2px solid;
    border-radius: 20px;
    box-shadow: 24px;
    transition: all 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.9);
    outline: none;
    overflow: hidden;
}
.Plan--your--event--popup--1--p--visit--button .selectedwidgetedbutton {
    background-color: rgba(128, 128, 128, 0.701);
}
.Plan--your--event--popup--1--p--visit--button--1 .selectedwidgetedbutton {
    background-color: rgba(128, 128, 128, 0.701);
}
.step-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
}
.step-dots li {
    margin: 0 5px;
}
.step-dots span {
    cursor: pointer;
    font-size: 10px;
}
.step-dots .active {
    font-size: 10px;
    font-weight: 700;
    color: #007bff !important;
}
.load--more--item {
    margin-top: 20px;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.load--more--item button {
    width: 100px;
    border: none;
    background-color: #1976D2;
    cursor: pointer;
    color: white;
    margin: auto;
    border-radius: 3px;
}
.Artist-viewall-banner--11 {
    height: auto;
    width: 100%;
    margin-top: 20px;
    border-radius: 5px;
    overflow: hidden;
}
.Artist-viewall-banner--11 .footer--banner {
    width: 100%;
    height: auto;
    border-radius: 5px;
    overflow: hidden;
    margin: auto;
}
.Artist-viewall-banner--img--1-1 {
    width: 100%;
    height: auto;
}
.Artist-viewall-banner--img--1-1 img {
    width: 100%;
    height: auto;
}
.step-dots span {
    cursor: pointer;
    font-size: 10px;
}
.step-dots .active {
    font-size: 10px;
    font-weight: 600;
    color: #007bff !important;
}
.Plan--your--event--popup--1--p--visit--button--1 .selectedwidgetedbutton {
    background-color: rgba(128, 128, 128, 0.701);
}
.visiting--table--booking--content--p1 {
    font-family: Helvetica;
    font-size: 14px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
    margin-top: 10px;
    margin-bottom: 10px;
}

.visiting--table--booking--content--p2 {
    font-family: Helvetica;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
    /* margin-top: -10px; */
    margin-bottom: 10px;

}

.visiting--table--booking--content--tabs::-webkit-scrollbar {
    width: 1px;
}

.visiting--table--booking--content--tabs::-webkit-scrollbar-track {
    background-color: transparent;
}

.visiting--table--booking--content--tabs::-webkit-scrollbar-thumb {
    border-radius: 10px;
}

.visiting--table--booking--content--tabs {
    display: flex;
    overflow-x: auto;
    gap: 10px;
    padding-bottom: 5px;
}

.visiting--table--booking--content--tabs1 {
    width: 85%;
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    justify-content: center;
    align-items: center;
    padding-bottom: 5px;
     margin-top: 10px;
}

.visiting--table--booking--content--tabs--card {
    background-color: #fff;
    border: 1px solid #b3b3b3;
    /* border-radius: 4px; */
    border-radius: 5px;
    flex: 0 0 74px;
    font-family: Helvetica;
    font-size: 10px;
    font-weight: 400;
    /* height: 50px; */
    height: 56px;
    letter-spacing: 0;
    line-height: 12px;
    overflow: hidden;
    text-align: center;
    transition: transform .2s;
    width: 150px;
}
.visiting--table--booking--content--tabs--card--1 {
    background-color: #fff;
    border: 1px solid #b3b3b3;
    border-radius: 5px;
    flex: 0 0 116px;
    font-family: Helvetica;
    font-size: 12px;
    font-weight: 400;
    height: 116px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.visiting--table--booking--content--tabs--card--1.selected {
    background-color: #fff;
    border: 1px solid #b3b3b3;
    border-radius: 5px;
    flex: 0 0 116px;
    font-family: Helvetica;
    font-size: 12px;
    font-weight: 400;
    height: 116px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Plan--your--event--popup--1--p--visit--button button {
    width: 75px;
    height: 30px;
    flex-shrink: 0;
    border: none;
    outline: none;
    border-radius: 5px;
    background: #1EBE92;
    color: #FFF;
    text-align: center;
    font-family: Helvetica;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.28px;
}

.Plan--your--event--popup--1--p--visit--button {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.Plan--your--event--popup--1--p--visit--button--1 button {
    width: 75px;
    height: 30px;
    flex-shrink: 0;
    border: none;
    outline: none;
    border-radius: 5px;
    background: #1EBE92;
    color: #FFF;
    text-align: center;
    font-family: Helvetica;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.28px;
}

.Plan--your--event--popup--1--p--visit--button--1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 50px;
}

.Plan--your--event--popup--1--img--h2 {
    color: #FFF;
    text-align: center;
    font-family: Helvetica;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
}

.visiting--table--booking-second--page--1 {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    justify-content: center;
    margin-bottom: 15px;
}

.visiting--table--booking-second--page--11 {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
  
    margin-top: -11px;
    margin-bottom: 10px;
    margin-left: 55px;
    /* margin: auto; */
}

.visiting--table--booking-second--page--1--1 label {
    color: #000;
    font-family: poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.56px;
    margin: 0;
    padding: 0;
}


.visiting--table--booking-second--page--1--1--1 label {
    color: #000;
    font-family: poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.56px;
    margin: 0;
    padding: 0;
    margin-left: -19px;
}

.visiting--table--booking-second--page--1--1--amenities label {
    color: #000;
    font-family: poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.56px;
    margin: 0;
    padding: 0;
}
.multiSelectContainer ul {
   max-height: 120px !important;
}
.visiting--table--booking-second--page--1--1 {
    display: flex;
    flex-direction: column;
    row-gap: 3px;
    z-index: 100;
}

.visiting--table--booking-second--page--1--1--1 {
    width: 85%;
    display: flex;
    flex-direction: column;
    row-gap: 3px;
    /* margin-left: 22px; */
}

.visiting--table--booking-second--page--1--1 input {
    width: 125px;
    height: 30px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 0.7px solid rgba(0, 0, 0, 0.30);
    background: #FFF;
    color: #000;
    font-family: poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.64px;
    padding-left: 10px;
}

.visiting--table--booking-second--page--1--1--1 input {
    width: 125px;
    height: 30px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 0.7px solid rgba(0, 0, 0, 0.30);
    background: #FFF;
    color: #000;
    font-family: poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.64px;
    padding-left: 10px;
}

.visiting--table--booking-second--page--1--1 select {
    width: 125px;
    height: 30px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 0.7px solid rgba(0, 0, 0, 0.30);
    background: #FFF;
    color: #000;
    font-family: poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.64px;
    padding-left: 10px;
}

.visiting--table--booking-second--page--1--1--1 select {
    width: 261px;
    height: 30px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 0.7px solid rgba(0, 0, 0, 0.30);
    background: #FFF;
    color: #000;
    font-family: poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.64px;
    padding-left: 10px;
    margin-left:-18px;
}

.visiting--table--booking-second--page--1--1--amenities {
    display: flex;
    flex-direction: column;
    row-gap: 3px;
}

.visiting--table--booking-second--page--1--1--amenities select {
    width: 200px;
    height: 30px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 0.7px solid rgba(0, 0, 0, 0.30);
    background: #FFF;
    color: #000;
    font-family: poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.64px;
    opacity: 0.3;
}

.visiting--table--booking--content {
    padding-left: 30px;
    padding-right: 30px;
}

.visiting--table--booking--content--tabs--card.selected {
    background: #fff;
    border: 1px solid #1976d2;
    border-radius: 4px;
    color: #1976d2;
    display: flex;
    font-family: Helvetica;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    height: 56px;
    justify-content: center;
    line-height: normal;
    /* outline: none; */
    text-align: center;
    width: 74px;
}
.Plan--your--event--popup--1--img--group--banner{
    height: 180px;
    width: 100%;
    background:
      url('./image/group--booking--img.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 10px 10px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .about-us-playstore-image_widget{
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    margin-top: 30px;
  }
  .about-us-playstore-image_widget img{
    height: 30px;
    width: auto;
  }
  .Plan--your--event--popup-page2 p{
    margin: 0;padding: 0;
    text-align: center;
    font-size: 14px;
    font-family: "open sans",sans-serif;
    
  }
  .Plan--your--event--popup-page2 p b{
   margin-bottom: 5px;
}
@media (max-width: 640px) {
    .responsive-modal-1--popup--1 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        height: 400px;
        background-color: white;
        border: 2px solid;
        border-radius: 20px;
        box-shadow: 24px;
        transition: all 0.3s ease;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.9);
        outline: none;
        overflow: hidden;
    }
    .about-us-playstore-image_widget{
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        margin-top: 20px;
      }
    .responsive-modal-1--popup--1--carnival {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 85%;
        height: 430px;
        background-color: white;
        border: 2px solid;
        border-radius: 20px;
        box-shadow: 24px;
        transition: all 0.3s ease;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.9);
        outline: none;
        overflow: hidden;
    }
    .Plan--your--event--popup--1--img--group--banner{
        height: 145px;
        width: 100%;
        background:
          url('./image/group--booking--img.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 10px 10px 0px 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    .step-dots span {
        cursor: pointer;
        font-size: 10px;

    }

   

    .step-dots .active {
        font-size: 10px;
        font-weight: 600;
        color: #007bff !important;
      
    }
    .Plan--your--event--popup--1--p--visit--button--1 .selectedwidgetedbutton {
        background-color: rgba(128, 128, 128, 0.701);
    }
    .visiting--table--booking--content--p1 {
        font-family: Helvetica;
        font-size: 14px;
        font-weight: 400;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: center;
        color: #000000;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .visiting--table--booking--content--p2 {
        font-family: Helvetica;
        font-size: 10px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: center;
        color: #000000;
        /* margin-top: -10px; */
        margin-bottom: 10px;

    }

    .visiting--table--booking--content--tabs::-webkit-scrollbar {
        width: 1px;
    }

    .visiting--table--booking--content--tabs::-webkit-scrollbar-track {
        background-color: transparent;
    }

    .visiting--table--booking--content--tabs::-webkit-scrollbar-thumb {
        border-radius: 10px;
    }

    .visiting--table--booking--content--tabs {
        display: flex;
        overflow-x: auto;
        gap: 10px;
        padding-bottom: 5px;
    }

    .visiting--table--booking--content--tabs1 {
        width: 85%;
        display: flex;
        flex-direction: row;
        column-gap: 20px;
        justify-content: center;
        align-items: center;
        padding-bottom: 5px;
         margin-top: 10px;
    }
   
    .visiting--table--booking--content--tabs--card {
        background-color: #fff;
        border: 1px solid #b3b3b3;
        /* border-radius: 4px; */
        border-radius: 5px;
        flex: 0 0 74px;
        font-family: Helvetica;
        font-size: 10px;
        font-weight: 400;
        /* height: 50px; */
        height: 56px;
        letter-spacing: 0;
        line-height: 12px;
        overflow: hidden;
        text-align: center;
        transition: transform .2s;
        width: 150px;
    }
    .visiting--table--booking--content--tabs--card--1 {
        background-color: #fff;
        border: 1px solid #b3b3b3;
        border-radius: 5px;
        flex: 0 0 116px;
        font-family: Helvetica;
        font-size: 12px;
        font-weight: 400;
        height: 116px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .visiting--table--booking--content--tabs--card--1.selected {
        background-color: #fff;
        border: 1px solid #b3b3b3;
        border-radius: 5px;
        flex: 0 0 116px;
        font-family: Helvetica;
        font-size: 12px;
        font-weight: 400;
        height: 116px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }
  
    .Plan--your--event--popup--1--p--visit--button button {
        width: 75px;
        height: 30px;
        flex-shrink: 0;
        border: none;
        outline: none;
        border-radius: 5px;
        background: #1EBE92;
        color: #FFF;
        text-align: center;
        font-family: Helvetica;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.28px;
    }

    .Plan--your--event--popup--1--p--visit--button {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .Plan--your--event--popup--1--p--visit--button--1 button {
        width: 75px;
        height: 30px;
        flex-shrink: 0;
        border: none;
        outline: none;
        border-radius: 5px;
        background: #1EBE92;
        color: #FFF;
        text-align: center;
        font-family: Helvetica;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.28px;
    }

    .Plan--your--event--popup--1--p--visit--button--1 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 50px;
    }

    .Plan--your--event--popup--1--img--h2 {
        color: #FFF;
        text-align: center;
        font-family: Helvetica;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 35px;
    }

    .visiting--table--booking-second--page--1 {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        justify-content: center;
        margin-bottom: 15px;
    }

    .visiting--table--booking-second--page--11 {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
      
        margin-top: -11px;
        margin-bottom: 10px;
        margin-left: 0px;
    }

    .visiting--table--booking-second--page--1--1 label {
        color: #000;
        font-family: poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.56px;
        margin: 0;
        padding: 0;
    }
    .visiting--table--booking-second--page--1--1-h label {
        color: #000;
        font-family: poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.56px;
        margin: 0;
        padding: 0;
    }
    
    .visiting--table--booking-second--page--1--1--1 label {
        color: #000;
        font-family: poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.56px;
        margin: 0;
        padding: 0;
        margin-left: -19px;
    }

    .visiting--table--booking-second--page--1--1--amenities label {
        color: #000;
        font-family: poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.56px;
        margin: 0;
        padding: 0;
    }

    .visiting--table--booking-second--page--1--1 {
        display: flex;
        flex-direction: column;
        row-gap: 3px;
    }
    .visiting--table--booking-second--page--1--1-h {
        display: flex;
        flex-direction: column;
        row-gap: 3px;
    }
    .visiting--table--booking-second--page--1--1--1 {
        width: 85%;
        display: flex;
        flex-direction: column;
        row-gap: 3px;
        margin-left: 22px;
    }

    .visiting--table--booking-second--page--1--1 input {
        width: 125px;
        height: 30px;
        flex-shrink: 0;
        border-radius: 5px;
        border: 0.7px solid rgba(0, 0, 0, 0.30);
        background: #FFF;
        color: #000;
        font-family: poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.64px;
        padding-left: 10px;
    }
    .visiting--table--booking-second--page--1--1-h input {
        width: 90px;
        height: 30px;
        flex-shrink: 0;
        border-radius: 5px;
        border: 0.7px solid rgba(0, 0, 0, 0.30);
        background: #FFF;
        color: #000;
        font-family: poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.64px;
        padding-left: 10px;
    }

    .visiting--table--booking-second--page--1--1--1 input {
        width: 125px;
        height: 30px;
        flex-shrink: 0;
        border-radius: 5px;
        border: 0.7px solid rgba(0, 0, 0, 0.30);
        background: #FFF;
        color: #000;
        font-family: poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.64px;
        padding-left: 10px;
    }

    .visiting--table--booking-second--page--1--1 select {
        width: 125px;
        height: 30px;
        flex-shrink: 0;
        border-radius: 5px;
        border: 0.7px solid rgba(0, 0, 0, 0.30);
        background: #FFF;
        color: #000;
        font-family: poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.64px;
        padding-left: 10px;
    }

    .visiting--table--booking-second--page--1--1--1 select {
        width: 261px;
        height: 30px;
        flex-shrink: 0;
        border-radius: 5px;
        border: 0.7px solid rgba(0, 0, 0, 0.30);
        background: #FFF;
        color: #000;
        font-family: poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.64px;
        padding-left: 10px;
        margin-left:-18px;
    }

    .visiting--table--booking-second--page--1--1--amenities {
        display: flex;
        flex-direction: column;
        row-gap: 3px;
    }

    .visiting--table--booking-second--page--1--1--amenities select {
        width: 200px;
        height: 30px;
        flex-shrink: 0;
        border-radius: 5px;
        border: 0.7px solid rgba(0, 0, 0, 0.30);
        background: #FFF;
        color: #000;
        font-family: poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.64px;
        opacity: 0.3;
    }

    .visiting--table--booking--content {
        padding-left: 30px;
        padding-right: 30px;
    }

    .visiting--table--booking--content--tabs--card.selected {
        background: #fff;
        border: 1px solid #1976d2;
        border-radius: 4px;
        color: #1976d2;
        display: flex;
        font-family: Helvetica;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        height: 56px;
        justify-content: center;
        line-height: normal;
        /* outline: none; */
        text-align: center;
        width: 74px;
    }

    .club--about--us--Gallery--section--buttons11 .button--tab--history {
        width: 75px;
        height: 35px;
        border-radius: 20px;
        background-color: #1B183B;

    }

    .club--about--us--Gallery--section--buttons11 .selectedtab {
        background-color: #1976D2;
    }

    .bookatable-right button {
        border: none;
        background-color: #1976D2;
        cursor: pointer;
        color: white;
    }

    .Artist-viewall-banner--11 {

        height: auto;
        width: 97%;
        margin-top: 20px;
        border-radius: 5px;
        overflow: hidden;
    
    }

    .Artist-viewall-banner--11 .footer--banner {
        width: 100%;
        height: auto;
        padding-left: 15px;
        border-radius: 5px;
        overflow: hidden;
        margin: auto;
    }
.Artist-viewall-banner--img--1-1{
    width: 100%;
    height: auto;
}
    .Artist-viewall-banner--img--1-1 img {
        width: 100%;
        height: auto;
    }
    @media(max-width:540px){
        .visiting--table--booking--content--p1 {
            font-family: "Open sans",sans-serif;
            font-size: 12px;
            font-weight: 400;
            text-align: center;
            color: #000000;
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
    

