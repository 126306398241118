.Container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    margin-left: 55px;
}

.content_card {
    padding: 1rem;
    color: #fff;
}
.checkbox-wrapper {
    position: relative;
    display: inline-block;
  }
  
  
  .checkbox-wrapper input[type="checkbox"]  {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30px;
    height: 20px;
    border: 1px solid #ccc;
    
}

/* .checkbox-wrapper input[type="checkbox"] + label::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
    background-color: transparent;
    cursor: pointer;
} */

.checkbox-wrapper input[type="checkbox"]:checked {
    background-color: #d71362; /* Change this color to your desired color */
}

  
  .checkbox-wrapper input[type="checkbox"]:checked + .checkbox-custom::before {
    background-color: #d71362; /* Change this color to match your checked state */
  }
 
  

.ImageContainer {
    position: relative;
}

.discount_ELE {
    position: absolute;
    top: 0;
    right: 0;
    width: fit-content;
    height: fit-content;
    background: #141420;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #fff;
    padding: .2rem .6rem;
    border-radius: 0 0 0 5px;
}

.Prd_name {
    text-transform: capitalize;
}

.Offer_price {
    color: #CCF326;
    font-size: 20px;
    display: flex;
    gap: .2rem;
}

.originalPrice {
    color: #8B94B2;
    text-decoration: line-through;
    font-size: 18px;
}
/* .reserve--table--booking--card{
    width: 400px;
} */

/* Modal container */
.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Modal content */
.modal-content {
    background-color: #fff;
    padding: 25px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    text-align: center; /* Center align text and elements */
}
.modal-content_package {
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    text-align: center;
}
/* Lottie animation container */
.lottie-container {
    width: 300px; /* Adjust width as needed */
    height: 300px; /* Adjust height as needed */
    margin: 0 auto; /* Center the animation horizontally */
}

/* Modal message */
.modal-message {
    font-size: 18px;
    margin-bottom: 10px;
}

/* Close button */
.modal-button {
    position: absolute;
    top: 5%;
    left: 92%;
    color: black;
    font-size: 25px;
    border: none;
    opacity: 0.5;
    background-color: transparent;
    
}
.about-us-playstore-image{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
}
.about-us-playstore-image img{
    height: 35px;
    width: auto;
}



/* Responsive styles */

@media screen and (max-width: 930px){
    /* .reserve--table--booking--card{
        width: 340px;
    } */
}
@media screen and (max-width: 768px) {
    .Container {
        flex-direction: column;
        align-items: flex-start;
        margin-left: 0;
    }
    
    .content_card {
        margin-bottom: 20px;
    }
    /* .reserve--table--booking--card{
        width: 340px;
    } */
    .sticky-container{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }
    
}
