.carnival-view-all-img {
    width: 100%;
    height: 300px;
}

.carnival-view-all-elements {
    margin: 30px;
}

.Artist-viewall--some--filter11 {
    display: flex;
    flex-direction: row;
    column-gap: 40px;
    justify-content: center;
    align-items: center;
}

.Artist-viewall--some--filter12 {
    position: relative;
    top: 20px;
}

.carnival-view-searchtag {
    background-color: whitesmoke;
    color: black;
    border: 2px solid grey;
    border: none;
    height: 35px;
    width: 180px;
    padding: 10px;
}

.carnival-view-searchbutton {
    background-color: #1f1E2E;
    border: none;
    border: 2px solid grey;
    height: 35px;
    width: 35px;
    margin-right: 5px;
    margin-top: 0.5px;
}

.carnival-view-card1 img,
.carnival-view-card1 video {
    height: 450px;
    width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    object-fit: contain;
}

.carnival-view-card1 {
    flex: 0 0 280px;
    width: 280px;
    height: 450px;
    background-color: #1c0e33;
    border-radius: 15px;
    overflow: hidden;
    transition: transform 0.2s ease;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1),
        rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
        rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    position: relative;
}

.carnival-view-card1-content {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, #000 100%);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 6px;
    color: white;
    padding: 10px;
    width: 100%;
    height: 135px;
    position: absolute;
    bottom: -135px;
    /* Initially hidden */
    transition: bottom 0.3s ease;
}

.carnival-view-card1:hover .carnival-view-card1-content {
    bottom: 0;
}

.carnival-view-card1:hover img,
.carnival-view-card1:hover video {
    transform: scale(1.05);
}

.carnival-view-card1-content--7 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 6px;
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
}

.carnival-view-card1-content1 h4 {
    font-size: 16px;
    font-weight: 600;
    font-family: "open sans", sans-serif;
    letter-spacing: 1px;
}

.carnival-view-card1-content1-p {
    font-size: 14px;
    font-weight: 500;
    font-family: "open sans", sans-serif;
    margin: 0;
    padding: 0;
}

.carnival-view-card1-content1-p1 {
    font-size: 12px;
    font-weight: 500;
    font-family: "open sans", sans-serif;
    margin: 0;
    padding: 0;
    opacity: 0.5;
}

.carnival-view-card1-content1--p {
    padding: 0;
    margin: 0;
    font-size: 20px;
}

.carnival-view-card1-content1 {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.date {
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    align-items: center;
}

.date p {
    color: #d71362;
    font-size: 12px;
    padding: 0;
    margin: 0;
    font-family: 500;
    font-family: "open sans", sans-serif;
}

.Artist-viewall--some--filter11--dropdown select {
    height: 40px;
    width: 190px;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 10px;
    background-color: gainsboro;
    color: #757575;
    outline: none;
}

.Artist-viewall-banner-sort-icon {
    margin-left: 40px;
}

.Artist-viewall-banner-sort-icon span {
    font-size: 40px;
    color: white;
}

.Artist-viewall--some--filter11--dropdown button {
    border: none;
    background-color: #d71362;
    height: 40px;
    width: auto;
    color: white;
    border-radius: 5px;
    font-size: 14px;
    outline: none;
}

.Artist-viewall--some--filter12--border {
    border-left: 1px solid gray;
    height: 50px;
    margin-top: 15px;
}

.carnival-collapse h4 {
    color: white;
}

.carnival-view-searchbutton span {
    color: wheat;
}

.carnival-view-tags-card-event {
    height: 130px;
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.ant-carousel .slick-slide h3 {
    color: #fff;
}

.carnival-view-tags-allcards::-webkit-scrollbar {
    width: 1px;
}

.carnival-view-tags-allcards::-webkit-scrollbar-track {
    background-color: transparent;
}

.carnival-view-tags-allcards::-webkit-scrollbar-thumb {
    border-radius: 10px;
}

.carnival-view-tags-allcards {
    display: flex;
    overflow-x: auto;
    margin-top: 30px;
    padding-left: 5px;
    width: 100%;
    height: 200px;
    flex-direction: row;
    column-gap: 20px;
    cursor: pointer;
}

.carnival-view-tags-card {
    height: 155px;
    width: 165px;
    background-color: transparent;
}

.carnival-view-tags-card p {
    color: white;
    font-size: 15px;
    text-align: center;
    background-color: #d71362;
    padding: 10px;
    position: relative;
    top: -20px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.carnival-view-tags-card-live-music {
    height: 110px;
    width: 150px;
    padding-left: 20px;
    margin-top: 15px;
}

.carnival-view-tags-card-dancefloor {
    height: 100px;
    width: 140px;
    padding-left: 30px;
    margin-bottom: 10px;
    margin-top: 15px;
}

.allcarnival-card {
    width: 100%;
    height: auto;
}

.carnival-view-allclubs {
    display: flex;
    flex-direction: row;
    column-gap: 15px;
    position: relative;
    top: -15px;
}

.allcarnival-view-card {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    position: relative;
    top: -15px;
}

.allcarnival-view-card::-webkit-scrollbar {
    width: 1px;
}

.allcarnival-view-card::-webkit-scrollbar-track {
    background-color: transparent;
}

.allcarnival-view-card::-webkit-scrollbar-thumb {
    border-radius: 10px;
}

.allcarnival-view-card {
    display: flex;
    overflow-x: auto;
    gap: 20px;
    padding: 10px;
    width: 100%;
    transition: transform 0.3s ease;
    margin-top: 40px;
}

.allcarnival-view-card--8 {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
}

.allcarnival-view-card--8::-webkit-scrollbar {
    width: 1px;
}

.allcarnival-view-card--8::-webkit-scrollbar-track {
    background-color: transparent;
}

.allcarnival-view-card--8::-webkit-scrollbar-thumb {
    border-radius: 10px;
}

.allcarnival-view-card--8 {
    display: flex;
    overflow-x: auto;
    gap: 20px;
    padding: 10px;
    width: 100%;
    transition: transform 0.3s ease;
    margin-top: 40px;
}

.carnival-view-btn {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
}

.carnival-viewall-button {
    background-color: #0F0F3B;
    color: white;
    border: 2px solid #d71362;
    height: 30px;
    width: 100px;
    border-radius: 14px;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.carnival-view-location p {
    font-size: 15px;
}

.carnival-view-location p span {
    font-size: 20px;
}

.carnival-view-all-button {
    background-color: #d71362;
    color: white;
    height: 40px;
    width: 90px;
    border: 2px solid;
    border: none;
    border-radius: 10px;
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin-top: 10px;
}

.carnival-view-filter {
    margin-top: 43px;
    width: 170px;
    height: 40px;
    background-color: #d71362;
    color: white;
    border: none;
    font-size: 14px;
    text-align: left;
}

.Artist-viewall--some--filter11--dropdown--heading {
    background-color: #D71362;
    color: white;
}

.carnival-view-filter-text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.carnival-collapse p button span {
    margin-right: 30px;
    font-size: 25px;
    margin-bottom: 10px;
}

.card-body {
    color: white;
}

.carnival-collapse p {
    margin: 0;
    padding: 0;
    margin-top: -30px;
}

.card-body form input {
    height: 30px;
    width: 130px;
}

.card-body form button {
    height: 30px;
    width: 30px;
    border: none;
    margin-right: 5px;
}

.carnival-view-filter {
    background-color: #d71362;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    display: block;
    transition: background-color 0.3s, color 0.3s, transform 0.2s ease;
}

.card-body button {
    height: 30px;
    width: auto;
    border: none;
    margin-bottom: 8px;
    margin-left: 8px;
    background-color: #0C0522;
    border: 2px solid #5E5C64;
    border-radius: 5px;
    color: white;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.carnival-view-filter-date {
    width: 100%;
    padding: 5px;
    border: 1px solid #5E5C64;
    border-radius: 5px;
    font-size: 15px;
    box-sizing: border-box;
    background-color: #0C0522;
    color: white;
}

.carnival-view-filter-date::-webkit-calendar-picker-indicator {
    filter: invert(50%);
    margin-right: -5px;
}

.ourartist-second-secondpart-card {
    height: 380px;
    width: 340px;
    background-color: #0F0F3B;
    border-radius: 15px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
}

.ourartist-content1-button {
    background-color: #0F0F3B;
    color: white;
    border: 1px solid #D71362;
    border-radius: 12px;
    margin-top: 5px;
    margin-right: 5px;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.ourartist-image1 {
    width: 100%;
    height: 230px;
}

.ourartist-content1-p1 {
    color: white;
    margin: 0;
    padding: 0;
}

.ourartist-content {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.ourartist-content2-button {
    color: white;
    height: 35px;
    width: 100px;
    background-color: #D71362;
    border: 1px solid #D71362;
    font-size: 15px;
    border-radius: 3px;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.ourartist-content1-p2 {
    color: white;
    font-size: 19px;
    margin: 0;
    padding: 0;
}

.artist-viewall {
    margin-top: 30px;
    width: 85%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 10px;
    row-gap: 20px;
}

.Artist-viewall-banner img {
    height: 350px;
    width: 100%;
    object-fit: cover;
}

.artist-view-all-filter {
    margin-top: 17px;
    margin-left: 30px;
    width: 15%;
    height: auto;
}

.artist-view-allclubs {
    display: flex;
    flex-direction: row;
    column-gap: 30px;
}

.artist-view-all-filter p {
    margin: 0;
    padding: 0;
    margin-top: -30px;
}

.artist-view-all-filter p button span {
    margin-right: 30px;
    font-size: 25px;
    margin-bottom: 10px;
}

.artist-view-all-filter h4 {
    color: white;
}

.Artist-viewall-search-sort-content {
    margin-top: 24px;
    margin-left: 20px;
    margin-right: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Artist-viewall-search-sort-content-sorticon {
    margin-top: -30px;
    font-size: 50px;
    color: wheat;
}

.carnival--about--us--details2 {
    width: 100%;
}

.carnival--about--us--details2 button {
    height: 50px;
    width: 100%;
    border: none;
    background-color: #D71362;
    color: white;
    border-radius: 5px;
    margin-top: 10px;
    word-wrap: normal;
}

.carnival--about--us--banner-content {
    width: 29%;
}

.carnival--about--us--carnival-time-detail--time-and-detail {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: white;
}

.carnival--about--us--carnival-time-detail--time-and-detail p {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-family: "open sans", sans-serif;
}

.carnival--about--us--banner-content h1 {
    padding-top: 50px;
    padding-left: 120px;
    color: white;
    font-size: 65px;
    font-weight: 600;
}

.carnival--about--us--banner-content-button {
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-left: 350px;
}

.carnival--about--us--banner-content-button button {
    height: 43px;
    width: 85px;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.6);
    border: none;
    border: 2px solid #D71362;
    color: #D71362;
    font-size: 22px;
}

.carnival--about--us--details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 30px;
    margin-top: 25px;
}

.carnival--about--us--details-8 {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 25px;
}

.carnival--about--us h1 {
    color: white;
}

.carnival--about--us--details1 p {
    color: white;
    margin: 0;
    padding: 0;
    padding-bottom: 3px;
    font-family: "Open Sans", sans-serif;
    letter-spacing: 1px;
}

.carnival--about--us--details1 h2 {
    padding-top: 20px;
    color: #fff;
    font-family: "Open Sans", sans-serif;
    font-size: 25px;
    letter-spacing: 0.5px;
}

.carnival--about--us--details2 p {
    color: #246edb;
    margin: 0;
    padding: 0;
}

.carnival--about--us--border-line1 {
    height: 0.5px;
    width: 100%;
    opacity: 0.5;
    background: #FFF;
    margin-top: 16px;
    margin-bottom: 15px;
}

.carnival--about--us--carnival-time-detail {
    padding-top: 5px;
}

.carnival--about--us--carnival-time-detail--11-p {
    font-size: 18px;
    margin: 0;
    padding: 0;
    font-family: "Open sans", sans-serif;
    color: #fff;
    letter-spacing: 0.5px;
}

.carnival--about--us--carnival-time-detail--1 p {
    color: white;
}

.carnival--about--us--carnival-time-detail--1 {
    display: flex;
    flex-direction: row;
    column-gap: 40px;
    justify-content: space-between;
}

.carnival--about--us--carnival-time-detail--11 {
    display: flex;
    flex-direction: row;
    column-gap: 15px;
}

.carnival--about--us--carnival-time-detail--11 span {
    font-size: 30px;
    color: #fd2f70;
    padding-left: 3px;
}

.carnival--about--us--carnival-time-detail--11--p span {
    font-size: 30px;
    color: #fd2f70;
    margin: 0;
    padding: 0;
    margin-right: 13px;
}

.carnival--about--us--carnival-time-detail--11--p {
    font-size: 16px;
    color: #fff;
    margin: 0;
    padding: 0;
    letter-spacing: 0.5px;
}

.carnival--about--us--About {
    padding-left: 0px;
    flex: 1;
}

.carnival--about--us--About p {
    font-size: 14px;
    font-weight: 400;
    color: white;
    font-family: "Open Sans", sans-serif;
    letter-spacing: 1px;
    margin-bottom: 20px;
}

.carnival--about--us--About img {
    width: 30px;
    height: auto;
    margin-right: 10px;
}

.carnival--about--us--location {
    padding-left: 30px;
    padding-right: 30px;
}

.carnival--about--us--location img {
    padding-top: 10px;
    width: 100%;
    height: 300px;
}

.carnival--about--us--location1 {
    display: flex;
    flex-direction: row;
    column-gap: 50px;
    color: white;
    background-color: #0C0522;
    margin-bottom: 30px;
    padding-left: 70px;
}

.carnival--about--us--location1-direction-content {
    margin-top: 80px;
    text-align: left;
}

.carnival--about--us--location1-direction-content p {
    margin: 0;
    padding: 0;
}

.carnival--about--us--location1-club-img img {
    height: 180px;
    margin-top: 50px;
}

.carnival--about--us--location1-direction {
    color: #246edb;
}

.carnival--about--us--location1-map {
    position: relative;
    height: 270px;
    width: 700px;
    border-radius: 5px;
    text-align: center;
    padding-top: 120px;
}

.carnival--about--us--location1-map::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background-image: url("./image/map1.jpg");*/
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
    opacity: 0.1;
}

.carnival--about--us--location1-map button {
    position: relative;
    z-index: 1;
    border: none;
    border: 2px solid #d71362;
    border-radius: 5px;
    background-color: transparent;
    color: white;
}

.carnival--about--us--reserve-now button {
    height: 60px;
    width: 250px;
    border: none;
    background-color: #d71362;
    color: white;
    border-radius: 5px;
    font-size: 24px;
    margin-left: 30px;
    margin-bottom: 15px;
}

.carnival--about--us--partywitty--pay {
    padding-left: 30px;
}

.carnival--about--us--partywitty--pay p {
    color: white;
    font-size: 19px;
}

.carnival--about--us--partywitty--pay-wallet {
    height: 60px;
    width: 550px;
    background-image: linear-gradient(to bottom, #3b3b3c, #434445);
    border-radius: 3px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    margin-bottom: 30px;
}

.carnival--about--us--partywitty--pay-wallet span {
    font-size: 32px;
    color: #EAC8C9;
    margin-top: -18px;
}

.carnival--about--us--partywitty--pay--button button {
    height: 60px;
    width: 250px;
    border: none;
    background-color: #d71362;
    color: white;
    border-radius: 5px;
    font-size: 24px;
}

.carnival--about--us--partywitty--pay--button--1 {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.Artist-viewall--some--filter1 {
    height: 80px;
    width: 100%;
    border: 1px solid #d71362;
    background-color: #1f1E2E;
    border-radius: 5px;
    border-radius: 5px;
    position: relative;
    top: -20px;
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    justify-content: space-evenly;
    padding-left: 20px;
    padding-right: 20px;
}

.Artist-viewall--some--filter {
    padding-left: 50px;
    padding-right: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.carnival--about--us--partywitty--pay--button--11 {
    height: 40px;
    width: 120px;
    border: none;
    background-color: #d71362;
    color: white;
    border-radius: 5px;
    font-size: 18px;
}

.carnival--about--us--partywitty--pay--button--12 {
    height: 40px;
    width: 120px;
    border: none;
    background-color: #434445;
    color: white;
    border-radius: 5px;
    font-size: 18px;
}

.carnival-view-search--1 {
    display: none;
}

.artist-about-us--book-now {
    height: auto;
    width: 100%;
    text-align: center;
    margin-top: 55px;
    color: white;
}

.artist-about-us--book-now--address--all-card {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.artist-about-us--book-now--address {
    display: flex;
    flex-direction: row;
    column-gap: 40px;
    justify-content: center;
}

.artist-about-us--book-now--address-card {
    background-color: #1f1E2E;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.9);
    height: auto;
    width: 700px;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.artist-about-us--book-now--address-content-card {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.artist-about-us-ordernow-tab {
    height: auto;
    width: 400px;
    padding: 20px;
    background-color: #1f1E2E;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.9);
    border-radius: 5px;
}

.artist-about-us--book-now--address--all-card-and-total {
    display: flex;
    flex-direction: row;
    column-gap: 30px;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

.artist-about-us--book-now--address-card1--pass-detail {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.artist-about-us--book-now--address-card1--pass input {
    height: 25px;
    width: 100px;
}

.artist-about-us--book-now--address-card1 {
    width: 50%;
    height: 100%;
    text-align: left;
}

.artist-about-us--book-now h2 {
    font-family: "open sans", sans-serif;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 2px;
}

.artist-about-us--book-now h4 {
    font-family: "open sans", sans-serif;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 2px;
    opacity: 0.5;
    margin: 10px 0px;
}

.artist-about-us--book-now--address-card2 {
    width: 30%;
    align-items: end;
}

.artist-about-us--book-now--address-card2 button {
    border: none;
    background-color: #D71362;
    color: white;
    height: 30px;
    width: 60px;
    border-radius: 5px;
    margin-left: 55px;
}

.artist-about-us--book-now--address-card2--ticket-price {
    display: flex;
    flex-direction: row;
    column-gap: 6px;
    margin-left: 200px;
}

.artist-about-us--book-now--address-card2--ticket-buy-total {
    margin-left: 200px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.Artist-viewall--some--filter12 form {
    display: flex;
    flex-direction: row;
}

.carnival-view-tags.selected .carnival-view-tags-card {
    border: 2px solid #d71362;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.carnival--about--us--About h2 {
    font-size: 23px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    letter-spacing: 2px;
    color: #fff;
    margin-bottom: 30px;
    margin-top: 30px;
    font-family: "open sans", sans-serif;

    opacity: 0.7;
}

.carnival--about--us--About-recommended {
    padding-left: 80px;
    padding-right: 80px;
    margin-top: 50px;
}

.carnival--about--us--About-recommended h2 {
    font-size: 23px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    letter-spacing: 2px;
    color: #fff;
    margin-bottom: 30px;
    margin-top: 30px;
    font-family: "open sans", sans-serif;
    opacity: 0.7;
}

.carnival--about--us--About span {
    color: #ee0763;
    font-size: 40px;
    font-weight: 800;
    margin: 0;
    padding: 0;
    cursor: pointer;
    position: relative;
    top: 6px;
}

.add_quantity_inc_dec {
    height: 40px;
    width: 100px;
    border: 1px solid #fff;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    justify-content: center;
    align-items: center;
    margin-left: 75px;
}

.add_quantity_inc_dec p {
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.your-order3 {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    position: sticky;
    bottom: 0;
    z-index: 1000;
    background-color: #1c0e33;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px 0;
}

.your-order3 button {
    height: 40px;
    width: 100px;
    background-color: #d71362;
    color: #fff;
    border-radius: 5px;
    border: none;
}

.your-order3 p {
    font-size: 18px;
    font-family: "open sans", sans-serif;
    font-weight: 500px;
    margin: 0;
    padding: 0;
    letter-spacing: 1px;
}

.artist-about-us--book-now--address p {
    font-size: 14px;
    font-family: "open sans", sans-serif;
    color: #fff;
    letter-spacing: 1px;
}

.event_ticket_name {
    font-size: 17px;
    font-family: "open sans", sans-serif;
    color: #fff;
    letter-spacing: 1px;
    font-weight: 600;
    opacity: 0.5;
    margin: 0;
    padding: 0;
}

.event_ticket_price {
    font-size: 14px;
    font-family: "open sans", sans-serif;
    color: #fff;
    letter-spacing: 1px;
    font-weight: 500;
    margin: 0;
    padding: 0;
}

.event_ticket_contain {
    font-size: 12px;
    font-family: "open sans", sans-serif;
    color: #fff;
    letter-spacing: 1px;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

.carnival-about-us-left {
    width: 60%;
    height: 450px;
}

.Artist-viewall-banner--img-8 {
    width: 100%;
    height: 450px;
}

.Artist-viewall-banner--img-8 img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.Artist-viewall-banner--img-8 video {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.carnival-about-us-right {
    width: 35%;
    height: auto;
    border: 1px solid rgba(255, 255, 255, 0.20);
    border-radius: 10px;
}

.carnival-about-us-page {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    padding-left: 0px;
}

.carnival-about-us-page-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    gap: 30px;
    justify-content: center;
}

.date span {
    color: #d71362;
    font-size: 18px;
    position: relative;
    top: -3px;
}

@media (min-width:600px) and (max-width:1140px) {
    .artist-about-us--book-now {
        text-align: center;
        margin-top: 25px;
        color: white;
        margin-top: 105px;
    }

    .carnival-about-us-right {
        width: 45%;
        height: auto;
    }

    .carnival--about--us--carnival-time-detail--1 {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        padding-bottom: 20px;
    }

    .carnival-about-us-page {
        margin-top: 90px;
        display: flex;
        flex-direction: row;
        column-gap: 20px;
        padding-left: 40px;
        padding-right: 40px;
    }

    .carnival-about-us-page-content {
        width: 90%;
        padding-left: 40px;
        padding-right: 40px;
        display: flex;
        flex-direction: column;
        row-gap: 40px;
        margin-top: 30px;
    }
}

@media(max-width:599px) {
    .carnival-view-all {
        overflow: hidden;
    }

    .carnival-about-us-left {
        width: 100%;
        height: auto
    }

    .Artist-viewall-banner--img-8 {
        width: 100%;
        height: 200px;
    }

    .Artist-viewall-banner--img-8 img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .carnival-about-us-right {
        width: 100%;
        height: auto;
        border: 1px solid transparent;

    }

    .carnival-about-us-page {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        margin-top: 60px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .carnival-about-us-page-content {
        width: 90%;
        padding-left: 10px;
        padding-right: 10px;
        display: flex;
        flex-direction: column;
        row-gap: 40px;
        margin-top: 30px;
    }

    .artist-about-us--book-now h2 {
        font-family: "open sans", sans-serif;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 2px;
    }

    .artist-about-us--book-now--address-card2 button {
        border: none;
        background-color: #D71362;
        color: white;
        height: 30px;
        width: 60px;
        border-radius: 5px;
        margin-left: 40px;
    }

    .add_quantity_inc_dec {
        height: 35px;
        width: 70px;
        border: 1px solid #fff;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        column-gap: 15px;
        justify-content: center;
        align-items: center;
        margin-left: 32px;
    }

    .artist-about-us--book-now h4 {
        font-family: "open sans", sans-serif;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 2px;
        opacity: 0.5;
        margin: 10px 0px;
    }

    .carnival--about--us {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .carnival-view-img {
        width: 100%;
        height: 250px;
    }

    .carnival-view-all-img {
        width: 100%;
        height: 200px;
    }

    .carnival-view-all-elements {
        margin: 5px;
    }

    .carnival-view-search {
        display: flex;
        flex-direction: row;
    }

    .carnival-view-tags-allcards {
        display: flex;
        overflow-x: auto;
        margin-top: 30px;
        width: 100%;
        height: 150px;
        column-gap: 15px;
    }

    .Artist-viewall-banner img {
        height: 200px;
        width: 100%;
        object-fit: contain;
    }

    .carnival-view-searchtag {
        height: 30px;
        width: 120px;
        padding: 10px;
    }

    .carnival-view-searchbutton {
        height: 30px;
        width: 35px;
        margin-right: 5px;
        margin-top: 0.5px;
    }

    .carnival--about--us--details-8 {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 0px;
    }

    .carnival-view-tags-card p {
        color: white;
        font-size: 12px;
        text-align: center;
        background-color: #d71362;
        padding: 7px;
        position: relative;
        top: -7px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .carnival-view-tags-card--button {
        border: none;
        outline: none;
        height: 30px;
        width: 100%;
        border-radius: 6px 6px 0px 0px;
        background: #F6F6F6;
        box-shadow: 0px 4px 4px 0px rgba(14, 53, 191, 0.25);
        position: relative;
        top: -4px;
    }

    .selected .carnival-view-tags-card--button {
        border: none;
        outline: none;
        height: 30px;
        width: 100%;
        border-radius: 6px 6px 0px 0px;
        background: #D71462;
        position: relative;
        top: -4px;
    }

    .carnival-view-all--banner--1 {
        margin-top: 30px;
    }

    .Artist-viewall-search-sort-content-sorticon {
        margin-top: -5px;
        font-size: 30px;
        color: wheat;
    }

    .Artist-viewall-search-sort-content {
        margin-top: -10px;
        margin-left: 5px;
        margin-right: 5px;
    }

    .artist-view-all-filter {
        margin-top: 5px;
        margin-left: 10px;
        width: 0%;
        display: none;
    }

    .artist-viewall {
        margin-top: 10px;
        width: 100%;
        height: auto;
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 20px;
        margin-left: 5px;
        margin-right: 5px;
    }

    .ourartist-second-secondpart-card {
        height: auto;
        width: 302px;
    }

    .ourartist-image1 {
        width: 100%;
        height: 180px;
    }

    .ourartist-content1-p2 {
        color: white;
        font-size: 17px;
        margin: 0;
        padding: 0;
    }

    .carnival--about--us--partywitty--pay--button button {
        height: 40px;
        width: 120px;
        font-size: 15px;
    }

    .ourartist-content2-button {
        color: white;
        height: 35px;
        width: 80px;
    }

    .carnival-view-tags-card {
        height: 98px;
        width: 130px;
        background-color: transparent;
        display: flex;
        flex-direction: column;
        cursor: pointer;
    }

    .carnival-view-tags-card-event {
        height: 70px;
        width: 100%;
        object-fit: cover;
    }

    .ant-carousel .slick-slide {
        height: 200px;
        width: 100%;
    }

    .allcarnival-view-card--8 {
        display: flex;
        overflow-x: auto;
        gap: 20px;
        padding: 10px;
        width: 100%;
        transition: transform 0.3s ease;
        margin-top: 40px;
    }

    .allcarnival-view-card {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
    }

    .carnival-view-card1 {
        margin: 0;
        width: 260px;
        height: 400px;
        border-radius: 5px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
        transition: transform 0.2s ease;
    }

    .carnival-view-card1 img {
        height: 400px;
        width: 100%;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        object-fit: contain;
    }

    .carnival-view-card1 video {
        height: 400px;
        width: 100%;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        object-fit: contain;
    }

    .carnival-view-card1-content {
        padding-top: 10px;
        height: 105px;
        position: absolute;
        bottom: -105px;
    }

    .carnival-view-card1:hover .carnival-view-card1-content {
        bottom: 0;
    }

    .carnival-view-card1:hover img,
    .carnival-view-card1:hover video {
        transform: scale(1.05);
    }

    .carnival-view-location h5 {
        font-size: 18px;
        margin-top: 3px;
    }

    .carnival-view-location h5 span {
        font-size: 15px;
        margin: 0;
        padding: 0;
    }

    .date {
        display: flex;
        flex-direction: row;
        column-gap: 5px;
    }

    .date span {
        color: #fff;
        font-size: 12px;
        margin: 0;
        padding: 0;
        position: relative;
        top: -3px;
    }

    .carnival-view-card1-content1--2--p {
        color: #FFF;
        text-align: right;
        font-family: Helvetica;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .carnival-view-card1-content1--2--p1 {
        color: #CC0C39;
        text-align: right;
        font-family: Helvetica;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .carnival-view-card1-content1--2 p {
        margin: 0;
        padding: 0;
    }

    .carnival-view-card1-content1--2--p span {
        color: #FFF;
        font-family: Helvetica;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .carnival-view-card1-content1--2 {
        margin-top: 19px;
        display: flex;
        flex-direction: column;
        row-gap: 6px;
    }

    .date p {
        color: #fff;
        font-size: 12px;
        padding: 0;
        margin: 0;
    }

    .carnival--view--all--page--start--price {
        color: #FFF;
        font-family: Helvetica;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: right;
        margin: 0;
        padding: 0;
    }

    .carnival--view--all--page--start--price span {
        color: #FFF;
        text-align: right;
        font-family: Helvetica;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        position: relative;
        top: -2px;
    }

    .carnival--view--all--seats--left {
        color: #CC0C39;
        text-align: right;
        font-family: Helvetica;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 0;
        padding: 0;
    }

    .carnival--view--all--seats--left--remove {
        color: #CC0C39;
        text-align: right;
        font-family: Helvetica;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0;
        padding: 0;
    }

    .carnival-view-card1-content1 {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        width: 60%;
    }

    .carnival-view-card1-content2 {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        width: 40%;
        justify-content: right;
        align-items: end;
    }

    .carnival-view-btn {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
    }

    .carnival-viewall-button {
        background-color: #0F0F3B;
        color: white;
        width: 70px;
        height: 25px;
        flex-shrink: 0;
        font-size: 10px;
        border-radius: 3px;
        border: 0.5px solid #FFF;
    }

    .carnival-view-location p {
        font-size: 12px;
    }

    .carnival-view-location p span {
        font-size: 14px;
    }

    .carnival-view-card1-content1--p {
        color: #FFF;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        opacity: 0.5;
        position: relative;
        top: -2px;
    }

    .carnival--about--us--card--complete {
        width: 350px;
        height: auto;
        background: #1c0e33;
        border-radius: 3px;
        padding-bottom: 12px;
    }

    .carnival--about--us--card--complete--1 {
        width: 350px;
        height: auto;
        margin-top: 15px;
        padding-left: 17px;
        padding-right: 10px;
    }

    .carnival-view-all-button {
        width: 70px;
        height: 28px;
        flex-shrink: 0;
        border-radius: 4px;
        background-color: #d71362;
        color: var(--grey-grey-1, #F6F6F6);
        text-align: center;
        font-family: Helvetica;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        box-shadow: 0px 4px 4px 0px rgba(14, 53, 191, 0.25);
        margin: 0;
        padding: 0;

    }

    .carnival--about--recommended--events--see-all {
        color: #FFF;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: left;
    }

    .carnival--about--recommended--events--see-all span {
        color: #E11D37;
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }

    .carnival--checkout--page--drawer--card {
        width: 330px;
        height: 80px;
        flex-shrink: 0;
        border-radius: 4px;
        padding: 20px;
        border-radius: 3px;
        background: rgba(255, 255, 255, 0.10);
        display: flex;
        flex-direction: row;
        column-gap: 15px;

        margin-top: 30px;

    }

    .carnival--checkout--page--drawer--card input[type="checkbox"] {
        width: 16px;
        height: 16px;
        flex-shrink: 0;
        border-radius: 2px;
        border: 0.7px solid rgba(255, 255, 255, 0.30);
        background: #2B2B36 !important;
        position: relative;
        top: 3px;

    }

    .carnival--about--us--cart--border--line {
        width: 330px;
        height: 1px;
        background: rgba(255, 255, 255, 0.10);
    }

    .my--bidding--checkout--page--drawer--card--p2--1 {
        margin: 0;
        padding: 0;
        color: #fff;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .carnival--advance--booking--8--p--grey {
        color: rgba(255, 255, 255, 0.40);
        text-align: justify;
        font-family: Karla;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .carnival--advance--booking--8 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .carnival--advance--booking--8--p--8 {
        position: relative;
        top: 8px;
    }

    .my--bidding--checkout--page--drawer--card--p1--1 {
        color: #fff;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0;
        padding: 0;
    }

    .carnival--about--us--detail-cart-cheackout {
        height: auto;
        width: 330px;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        row-gap: 20px;

    }

    .carnival--advance--booking--8--p {
        color: #FFF;
        text-align: right;
        font-family: Karla;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 0;
        padding: 0;
    }

    .carnival--advance--booking--8--p--blue {
        color: #008AD8;
        text-align: right;
        font-family: Karla;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .carnival--about--us--details {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        padding-left: 5px;
        padding-right: 5px;
    }

    .carnival-view-card1-content1 h4 {
        color: #FFF;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0;
        padding: 0;
    }

    .carnival-view-card1-content1-p {
        margin: 0;
        padding: 0;
        color: #FFF;
        font-family: Poppins;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

    }

    .carnival-view-card1-content1-p1 {
        margin: 0;
        padding: 0;
        color: #FFF;
        font-family: Poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        opacity: 0.5;
    }

    .carnival--view--card--button--bowd {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
    }

    .carnival--about--us--details1 p {
        color: white;
        margin: 0;
        padding: 0;
        padding-bottom: 3px;
        font-size: 14px;
        font-family: "Open sans", sans-serif;
        letter-spacing: 1px;
    }

    .carnival--view--card--button--bowd button {
        width: 70px;
        height: 25px;
        flex-shrink: 0;
        background-color: transparent;
        border-radius: 3px;
        border: 0.5px solid #FFF;
        color: #FFF;
        font-family: Poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .carnival--about--us--carnival-time-detail {
        padding-left: 10px;
        padding-top: 5px;
    }

    .carnival--about--us--carnival-time-detail--7 {

        padding-top: 5px;
    }

    .carnival--about--us--carnival-time-detail--time-and-detail {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        color: white;
    }

    .carnival--about--us--carnival-time-detail--time-and-detail p {
        margin: 0;
        padding: 0;
        font-size: 12px;
        font-family: "open sans", sans-serif;
    }

    .carnival--about--us--carnival-time-detail--1 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 0px;
        align-items: center;
    }

    .carnival--about--us--carnival-time-detail--11-p {
        font-size: 13px;
        margin: 0;
        padding: 0;
        letter-spacing: 0.8px;
    }

    .Artist-viewall--some--filter {
        display: none;
    }

    .carnival--about--us--carnival-time-detail--11 span {
        font-size: 18px;
    }

    .carnival--about--us--carnival-time-detail--11-p--duration {
        font-size: 12px;
        margin: 0;
        padding: 0;
    }

    .carnival--about--us--carnival-time-detail--11 {
        display: flex;
        flex-direction: row;
        column-gap: 13px;
        margin-top: 0px;
        margin-bottom: 0px;
        padding-left: 2px;
    }

    .carnival--about--us h1 {
        font-size: 22px;
    }

    .carnival--about--us--About {
        padding-left: 20px;
        padding-right: 8px;
    }

    .carnival--about--us--About-recommended {
        padding-left: 30px;
        padding-right: 10px;
    }

    .carnival--about--us--About h2 {
        font-size: 16px;
        font-weight: 600;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        margin-top: 15px;
        color: #fff;
    }

    .carnival--about--us--About-recommended h2 {
        font-size: 16px;
        font-weight: 600;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
        margin-top: 15px;
        color: #fff;
    }

    .carnival--about--us--About p {
        margin: 0;
        padding: 0;
        color: #FFF;
        font-family: "open sans", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.5;
        margin-bottom: 16px;
    }

    .carnival--about--us--About img {
        width: 20px;
        height: auto;
        margin-right: 10px;
    }

    .carnival--about--us--location {
        padding-left: 10px;
        padding-right: 5px;
    }

    .carnival--about--us--location1 {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        color: white;
        background-color: #0C0522;
        margin-bottom: 30px;
        padding-left: 10px;
    }

    .carnival--about--us--location1-club-img {
        display: none;
    }

    .carnival--about--us--location1-direction-content {
        display: none;
    }

    .carnival--about--us--location1-map {
        position: relative;
        height: 200px;
        width: 100%;
        padding-top: 80px;
    }

    .carnival-view-allclubs {
        display: flex;
        flex-direction: row;
        column-gap: 15px;
        position: relative;
        top: -15px;

    }

    .carnival--about--us--reserve-now button {
        height: 40px;
        width: 100px;
        font-size: 15px;
        margin-left: 115px;
        margin-bottom: 15px;
    }

    .carnival--about--us--partywitty--pay {
        padding-left: 10px;
    }

    .carnival--about--us--partywitty--pay p {
        color: white;
        font-size: 15px;
    }

    .carnival--about--us--partywitty--pay-wallet {
        height: 60px;
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 15px;
        margin-bottom: 30px;
    }

    .carnival--about--us--carnival-time-detail--11--p {
        font-size: 12px;
        color: #fff;
        margin: 0;
        padding: 0;
        letter-spacing: 0.5px;
    }

    .carnival--about--us--carnival-time-detail--11--p span {
        font-size: 20px;
        color: #fd2f70;
        margin: 0;
        padding: 0;
        margin-right: 13px;
    }

    .artist-about-us--book-now {
        text-align: center;
        margin-top: 25px;
        color: white;
        margin-top: 85px;
    }

    .artist-about-us--book-now--total {
        position: relative;
        top: -1px;
    }

    .carnival--view-cart--page--design--card {
        height: auto;
        width: 330px;
        background: rgba(255, 255, 255, 0.10);
        border-radius: 4px;
        margin-top: 20px;
        padding-bottom: 30px;
    }

    .carnival--view-cart--page--design--card img {
        height: 250px;
        width: 330px;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
    }

    .new--year--add--number--page1--event {
        height: 30px;
        width: 90px;
        border-radius: 3px;
        display: flex;
        flex-direction: row;
        background: #FFF;
        justify-content: center;
        justify-content: space-around;
        align-items: center;
        border: 1px solid gray;
        padding-top: 17px;
        margin-top: 5px;
    }

    .carnival--about--us--detail-cart-cheackout--detail {
        color: #FFF;
        text-align: justify;
        font-family: Karla;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0;
        padding: 0;
    }

    .carnival--cart--about--coupon {
        height: 30px;
        width: 100px;
        background-color: transparent;
        border: 1px dashed #b1b1b1;
        border-radius: 4px;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .carnival--cart--about--coupon p {
        color: #008AD8;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0;
        padding: 0;
    }

    .carnival--about--us--details1 h2 {
        padding-top: 20px;
        color: #fff;
        font-family: "Open Sans", sans-serif;
        font-size: 20px;
        letter-spacing: 1px;
    }

    .carnival--advance--booking--8--p--total {
        color: #FFF;
        text-align: justify;
        font-family: Karla;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-decoration-line: underline;
    }

    .carnival--advance--booking--8--p--total-grand {
        color: #FFF;
        text-align: justify;
        font-family: Karla;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .carnival--advance--booking--8--coupon--blue {
        color: #008AD8;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0;
        padding: 0;
    }

    .carnival--view-cart--page {
        margin-top: 25px;
    }

    .carnival--view-cart--page--design--card1 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .carnival--view-cart--page--design {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .artist-about-us--book-now--address {
        display: flex;
        flex-direction: column;
        column-gap: 10px;
    }

    .artist-about-us--book-now--address--all-card-and-total {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        margin-bottom: 20px;
    }

    .artist-about-us--book-now--address-card1--pass input {
        height: 25px;
        width: 25px;
        margin-top: 5px;
    }

    .artist-about-us--book-now--address p {
        font-size: 12px;
    }

    .artist-about-us--book-now--address-card {
        height: auto;
        width: 350px;
        padding: 3px 22px;
        display: flex;
        flex-direction: row;
    }

    .artist-about-us--book-now--address-card1--pass-detail--1 p {
        font-size: 14px;
    }

    .artist-about-us--book-now--address-card2--ticket-price {
        display: flex;
        flex-direction: row;
        column-gap: 6px;
        margin-left: 40px;
    }

    .artist-about-us--book-now--address-card2--ticket-buy-total {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
    }

    .artist-about-us-ordernow-tab {
        height: auto;
        width: 330px;
        background-color: #1f1E2E;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.9);
        border-radius: 5px;
    }

    .selected .carnival--view--tag--title--p {
        color: #fff;
    }
}

.artist-about-us--book-now--address-card1--pass input {
    height: 25px;
    width: 25px;
}

.ticketbutton .incrementbtn {

    border: none;
    background-color: #D71362;
    padding: 5px 10px;
    border-radius: 2px;
    color: white;
    margin-right: 10px;

}

.ticketbutton .decrementbtn {

    border: none;
    background-color: #D71362;
    padding: 5px 10px;
    border-radius: 2px;
    color: white;


}

.event_filter {
    width: 95%;
    height: 70px;
    display: grid;

    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;
    margin: auto;

    border: 1px solid #D71362;
    border-radius: 5px;
    margin-top: 10px;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
}

.filterby {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;


}

.filterby p {
    color: white;
    font-size: 22px;
    font-weight: 400;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    padding: 0px;
    margin: 0px;
}