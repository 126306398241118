.club-detail-package {
  padding-top: 20px;
  padding-left: 130px;
  padding-right: 130px;
  display: flex;
  flex-direction: column;
}
.club-detail-banner {
  width: 100%;
  height: 600px;
  position: relative;
}
.club-detail-bg-tansparent{
    width: 100%;
    height: 600px;
    position: absolute;
    top: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, #000 100%);
}
.club-detail-banner img {
  width: 100%;
  height: 600px;
  object-fit: cover;
}
.club-details {
  padding: 0px 35px;
  position: absolute;
  bottom: 7%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.club-detail-direction {
  position: absolute;
  top: 5%;
  right: 2%;
}
.club-detail-direction{
    display: flex;
    flex-direction: row;
    column-gap: 20px;
}
.club-detail-direction button{
    height: 32px;
    padding: 0px 10px;
    padding-bottom: 5px;
    width: auto;
    border: 0.5px solid #fff;
    background-color: transparent;
    color: #fff;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 1px;
 
    border-radius: 2px;
}
.club-detail-direction button span{
    font-size: 18px;
    color: #d71362;
    margin-right: 5px;
}
.club-detail-top-banner{
    height: 120px;
    width: 100%;
    background-color: #fff;
    margin-bottom: 40px;
    opacity: 80%;
}
.club-rating-card{
    height: 35px;
    width: 75px;
    background: #339F00;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    column-gap: 7px;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
}
.club-rating-card p{
 color: #fff;
 font-size: 20px;
 font-weight: 600;
 margin: 0;padding: 0;
 letter-spacing: 1px;

}
.club-rating-card span{
    color: #fff;
    font-size: 20px;
    margin: 0;padding: 0;
    position: relative;
    top: -3px;
}
.club-rating-card-recommended p{
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  margin: 0;padding: 0;
  letter-spacing: 1px;
 
 }
 .club-rating-card-recommended span{
     color: #fff;
     font-size: 10px;
     margin: 0;padding: 0;
    }
.club-rating-card-recommended{
  height: 27px;
  width: 60px;
  background: #339F00;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  column-gap: 7px;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}
.club-name{
    font-size: 35px;
    font-weight: 600;
    color: #fff;
    letter-spacing: 2px;
    margin: 0;padding: 0;
    
}
.club-address{
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    letter-spacing: 0.5px;
    opacity: 0.6;
    margin: 0;padding: 0;
}
.club-time span{
    font-size: 14px;
    font-weight: 600;
    color: #FF0000;
    letter-spacing: 0.5px;
    margin: 0;padding: 0;
}
.club-time{
    font-size: 14px;
    font-weight: 300;
    color: #fff;
    letter-spacing: 0.5px;
    margin: 0;padding: 0;
}
.club-details-field{
    display: flex;
    flex-direction: column;
    row-gap: 12px;
}
.club-details-popup{
    display:flex ;
    flex-direction: column;
    row-gap: 20px;
}
.club-book-a-table{
    height: 42px;
    width: 170px;
    background-color: #d71362;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    letter-spacing: 1px;
}
.club-party-package{
    height: 45px;
    width: 190px;
    background-color: transparent;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 600;
    border: 1px solid #d71362;
    color: #fff;
    letter-spacing: 1px;
    margin: 0;padding: 0;
}
.club-book-a-table span{
    font-size: 20px;
    color: #fff;
}
.club-party-package span{
    font-size: 20px;
    color: #fff;
    margin: 0;padding: 0;
}
.club-detail-promotional-line {
    margin-top: 20px;
    height: 50px;
    width: 100%;
    background: #FFE500;
    overflow: hidden;
    position: relative;
}
  .club-detail-promotional-line-content {
    display: flex;
    flex-direction: row;
    column-gap: 30px;
    position: absolute;
    animation: moveLeft 40s linear infinite;
  }
  .club-detail-promotional-line-content li {
    white-space: nowrap;
    color: #000;
    font-size: 14px;
    font-weight: 600;
    margin: 0;padding: 0;
}

  @keyframes moveLeft {
    0% { left: 100%; }
    100% { left: -100%; }
  }
  .about-club-detail{
    padding-left: 25px;
    padding-right: 25px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 25px;
  }
  .about-club-details{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }
  .about-club-detail-1{
    display: flex;
    flex-direction: column;
    row-gap: 45px;
  }
  .about-club-detail-collapse{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .about-club-detail-collapse p{
    color: #fff;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 2.5px;
    margin: 0;padding: 0;
  }
  .about-club-detail-collapse span{
    color: #fff;
    font-size: 45px;
    position: relative;
    top: -10px;
  }
  .club-menu-p{
    color: #fff;
    font-size: 26px;
    font-weight: 600;
    letter-spacing: 2px;
    margin: 0;padding: 0;
  }
  .club-menu-card{
    height: 375px;
    width: 245px ;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
  .club-menu-card-img{
    height: 320px;
    width: 245px;
    border-radius: 2px;
  }
  .club-menu-card-img img{
    height: 320px;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
  .club-menu-card-p{
     font-size: 20px;
     color: #fff;
     font-weight: 500;
     letter-spacing: 1px;
     margin: 0;padding: 0;
  }
  .club-menu-card-no{
     font-size: 11px;
     color: #fff;
     font-weight: 400;
     letter-spacing: 1px;
     margin: 0;padding: 0;
  }

  .club-teaser{
    height: 400px;
    width: 250px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
  .club-teaser img{
    height: 360px;
    width: 250px;
    object-fit: cover;
    border-radius: 5px;
  }
  .club-teaser p{
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    letter-spacing: 1px;
    margin: 0;padding: 0;
  }
  .about-club-Gallery1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .about-club-Gallery-button{
    display: flex;
    flex-direction: row;
    column-gap: 15px;
  }
  .about-club-Gallery-button button{
    height: 40px;
    width: auto;
    border: none;
    background-color: transparent;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
  }
  .about-club-Gallery-view button{
    height: 40px;
    width: 120px;
    border: none;
    background-color: #d71362;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
  }
  .club-gallery-img1{
    height: 200px;
    width: 100%;
  }
  .club-gallery-img1 img{
    height: 200px;
    width: 100%;
    object-fit: cover;
  }
  .club-gallery-img{
    margin-top: 20px;
   display: grid;
   grid-template-columns: 1fr 1fr 1fr 1fr;
   gap: 20px;
  }
  .club-review-card-number2{
    height: 120px;
    width: 120px;
    background:#140041;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    justify-content: center;
    align-items: center;
  }
  .club-review-card-number1{
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    align-items: center;
  }
  .club-review-card-number2 h3{
    color: #fff;
    font-size: 35px;
    font-weight: 600;
    letter-spacing: 2px;
    margin: 0;padding: 0;
  }
  .club-review-card-number2 p{
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.5px;
    margin: 0;padding: 0;
  }
  .club-review-card-number3 p{
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.5px;
    margin: 0;padding: 0;
  }
  .club-review-card-number3{
    display: flex;
    flex-direction: column;
    row-gap: 5px;
  }
  .club-review-card-number{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .club-rating-parameter-card{
    display: flex;
    flex-direction: row;
    column-gap: 13px;
    align-items: center;
  }
  .rating-parameter-white{
    height: 5px;
    width: 120px;
    border-radius: 12px;
    background: #fff;
  }
  .rating-parameter-pink{
    height: 5px;
    width: 60px;
    border-radius: 12px;
    background: #d71362;
  }
  .club-rating-parameter-card p{
  
   margin: 0;padding: 0;
   color: #fff;
   font-size: 14px;
   letter-spacing: 0.5px;
  }
  .club-rating-parameter{
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 20px;
    column-gap: 60px;
  }
  .club-rating-parameter-card-name{
    width: 30%;
  }
  .club-rating-parameter-card-no{
    width: 20%;
  }
  .club-write-review-img{
    height: 90px;
    width: 90px;
    border-radius: 5px;
  }
  .club-write-review-img img{
    height: 90px;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
  .club-write-review-name p{
    margin: 0;padding: 0;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
  }
  .club-write-review-card1{
    width: 40%;
    display: flex;
    flex-direction: row;
    column-gap: 20px;
  }
  .club-write-review-name span{
    font-size: 12px;
    color: #FFCF41;
  }
  .club-review-multi-image{
    display: flex;
    flex-direction: row;
    column-gap: 10px;
  }
  .club-review-multi-image img{
    height: 50px;
    width: 50px;
    object-fit: cover;
  }
  .club-write-review-card{
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    border-right: 1px solid rgba(255, 255, 255, 0.3);
    height: auto;
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    padding: 12px;
  }
  .club-write-review-card2-p{
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 2px;
    margin: 0;padding: 0;
    padding-bottom:7px ;
  }
  .club-write-review-card2-p1{
    color: #fff;
    font-size: 10px;
    font-weight: 300;
    letter-spacing: 1px;
    opacity: 0.5;
    margin: 0;padding: 0;
  }
  .club-write-review-card2{
    width: 60%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
  .all-write-review-card{
    margin-top: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .other-amenties-card img{
    height: 23px;
    width: auto;
    object-fit: cover;
  }
  .other-amenties-card{
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;
  }
  .other-amenties-card p{
    margin: 0;padding: 0;
    font-size: 13px;
    color: #fff;
    font-weight: 400;
  }
  .all-other-amenties{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 8px;
    column-gap: 60px;
  }
  .club-detail-recommened-card{
    height: auto;
    flex: 0 0 250px;
    background-color: #1E1434;
    border-radius: 5px;
  }
  .club-detail-recommened-card-img img{
    height: 200px;
    width: 100%;
    object-fit: cover;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .club-detail-recommened-card-content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    padding-left: 12px;
  }
  .recommmened-club-name{
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    margin: 0;padding: 0;
  }
  .recommmened-club-category{
    font-size: 10.3px;
    font-weight: 400;
    color: #fff;
    letter-spacing: 0.5px;
    opacity: 0.8;
    margin: 0;padding: 0;
  }
  .recommmened-club-address{
    font-size: 9px;
    font-weight: 300;
    color: #fff;
    opacity: 0.5;
    letter-spacing: 1px;
    margin: 0;padding: 0;
  }
  .all-club-detail-recomeneded-club{
    display: flex;
    flex-direction: row;
    column-gap: 20px;
  }
  .club-detail-recommened-card-img{
    position: relative;
  }
  .club-detail-favorite-recommened{
    position: absolute;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    border-radius: 50%;
    background-color: #fff;
    top: 3%;
    right: 4%;
  }
  .club-detail-favorite-recommened span{
    color: #FF0000;
    font-size: 22px;
    position: relative;
    top: -2px;
  }

  /* club-package-caraousal */
  .club-detail-package-card{
    height: 400px;
    width: 100%;
    background-color: #1e1434;
    display: flex;
    flex-direction: row;
    column-gap: 30px;
  }
  .club-detail-package-card-name{
    height: 400px;
    width: 50px;
    background: #4D00FF;
    display: flex;
    flex-direction: column;
   row-gap: 4px;
    justify-content: center;
    align-items: center;
  }
  .club-detail-package-card-name p{
    font-size: 28px;
    font-weight: 600;
    color: #fff;
    margin: 0;padding: 0;
  }
  .club-detail-package-caraousal{
    width: 90%;
    padding-top: 10px;
    padding-bottom: 20px;
    color: #fff;
  }
  .club-detail-package-caraousal-1{
    height: 325px;
    width: 210px;
    margin-top: 40px;
    border-radius: 5px;
    background: #fff;
  }
  .club-detail-package-caraousal-1 img{
    height: 265px;
    width: 100%;
  }
  .club-detail-package-caraousal-1 p{
    font-size: 16px;
    color: #000;
    font-weight: 600;
    margin: 0;padding: 0;
    padding-top: 5px;
    padding-left: 10px;
    letter-spacing: 1px;
  }
  .club-detail-event-card{
    height: 320px;
    flex: 0 0 230px;
    background-color: #fff;
    border-radius: 5px;
  }
  .event-card-img{
    height: 240px;
    width: 100%;
    position: relative;
  }
  .event-card-img img{
    height: 240px;
    width: 100%;
    object-fit: cover;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .event-card-content{
    padding: 7px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .event-date-card{
    height: 60px;
    width: 50px;
    background-color: #eff1e5;
    border-radius: 7px;
  }
  .event-card-name{
    font-size: 18px;
    color: #000;
    margin: 0;padding: 0;
    font-weight: 600;
    letter-spacing: 1px;
  }
  .event-card-location{
    font-size: 12px;
    color: #000;
    font-weight: 500;
    margin: 0;padding: 0;
    letter-spacing: 0.5px;
  }
  .event-date-card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .event-date-card p{
  margin: 0;padding: 0;
  }
  .event-date-card-p{
    font-size: 18px;
    color: #000;
    font-weight: 600;
  }
  .event-date-card-p1{
    font-size: 14px;
    color: #000;
    font-weight: 600;
    letter-spacing: 1px;
  }
  /* custom Slider  */
  
  .all-club-menu{
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    position: relative;
    overflow: hidden;
    overflow-x: scroll;
  }
  .all-club-menu::-webkit-scrollbar {
    display: none;
  }
  .all-club-menu::-webkit-scrollbar {
    width: 100%;
    height: 10px;
  }
  .all-club-menu::-webkit-scrollbar-track {
    background-color: transparent;
  }

  @media (max-width:640px) {
    .club-detail-top-banner{
      height: 80px;
      width: 100%;
      background-color: #fff;
      margin-bottom: 40px;
      opacity: 80%;
  }
  .club-detail-banner {
    width: 100%;
    height: 250px;
    position: relative;
  }
  .club-detail-package {
    padding-top: 120px;
    padding-left: 0px;
    padding-right: 0px;
    display: flex;
    flex-direction: column;
  }
  }