* {
    margin: 0;
}
.main {
    width: 100%;
    background-color: #120829;
}
.All--page{
    width: 100%;
    height: auto;
    background-color: #120829;
}
.logo {
    margin-left: 5%;
}
.form_navbar,
.login_navbar {
    margin-left: auto;
}
.login_navbar {
    margin-right: 5%;
}
.nav_item {
    margin-right: auto;
}
.form_input {
    width: 400px;
    height: 40px;
    border-radius: 6px;
    border: 1px solid white;
    background: #FFF;
    font-family: 'Prompt', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}
.search_icon {
    margin-top: 10px;
    height: 22px;
    width: 22px;
    background: none;
    border: none;
    margin-left: -40px;
    background-color: white;
    cursor: pointer;
}
.login_section img {
    width: 35px;
    height: 35px;
}
#login {
    color: #FFF;
    font-family: 'Prompt', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    margin-top: 5px;
    margin-left: 10px;
}
.second_div {
    width: 100%;
    display: grid;
    place-items: center;
}
.second_div .box3 {
    width: 90%;
    height: 90px;
    border-radius: 6px;
    border: 0.7px dashed #7E7E7E;
    background: #1F1F21;
    margin: 50px 0px;
}
.banner_img {
    width: 100%;
    height: 500px;
    position: relative;
}
.banner_img img {
    width: 100%;
    height: 500px;
    object-fit: cover;
}
.banner_img_overlay {
    width: 100%;
    position: relative;
  }
  .package_card_right1{
    position: sticky;
    top: 80px;
   }
  .third3{
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, #000 100%);
    padding-left: 20px;
  }

.third3 h2 {
    color: #FFF;
    font-family: Helvetica, Sans-Serif;
    text-align: left;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin: 0;padding: 0;
    margin-bottom: 28px;
    margin-top: 28px;
    letter-spacing: 2px;
}
.third3 h3 {
    color: #E4E4E4;
    text-align: center;
    font-family: Helvetica, Sans-Serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;padding: 0;
}
.third3 p {
    color: #fff;
    text-align: left;
    font-family: Helvetica, Sans-Serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 30%;
    margin: 0;padding: 0;
}
.third3 h3 span {
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
}
.third_2 {
    width: 100%;
    display: grid;
    place-items: center;
}
.third_2 .buttons {
    width: 40%;
    display: flex;
    justify-content:center ;
    align-items: center;
    margin-top: 30px;
}
.third_2 .buttons button {
    border-radius: 6px;
    border: 1px solid #FFF;
    background: #1F1F21;
    width: auto;
    height: 45px;
    padding: 15px;
    color: #FFF;
    font-family: Helvetica, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 8px;
}

.Timing_container{
    gap: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.shareBTN{
    display: flex;
    border: none;
    background: transparent;
    outline: none;
    color: #fff;
}

.SocialShareIcon{
    display: flex;
    gap: 20px;
    position: absolute;
    top: 10px;
    right: 15px; 
    transform: translate(0, 0);
}
.third_2 .buttons .btn2,
.third_2 .buttons .btn3 {
    margin-left: 8%
}
.direction-icon{
    height: 35px;
    width: 35px;
    color: #fff;
    background-color: #008ad8;
    border: none;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 45px;
}
.direction-icon1{
    height: 35px;
    width: 35px;
    color: #000;
    background-color: #c8db32;
    border: none;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 45px;
}
.direction-icon2{
    height: 35px;
    width: 35px;
    color: #fff;
    background-color: transparent;
    border: none;
    border: 1px solid #fff;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 45px;
}
.club--about--us--video--frames {
  margin-top: 30px;
}
.club--about--us--video--frames::-webkit-scrollbar {
    width: 1px;
}
.club--about--us--video--frames::-webkit-scrollbar-track {
    background-color: transparent;
}
.club--about--us--video--frames::-webkit-scrollbar-thumb {
    border-radius: 10px;
}
.club--about--us--video--frames {
    display: flex;
    overflow-x: auto;
    gap: 35px;
    max-width: 100%;
    transition: transform 0.3s ease;
    margin-bottom: 15px;
}
.club--about--us--video--frames--card {
    width: 210px;
    height: auto;
    flex-shrink: 0;
    border-radius: 5px;
    border: 0.5px solid transparent;
    display: flex;
    flex-direction: column;
}
.club--about--us--video--frames--card video{
    width: 210px;
    height: 300px;
    object-fit: cover;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    margin-bottom: 5px;
}
.club--about--us--video--frames--card p{
    color: #fff;
     font-size: 13px;
     font-weight: 400;
     text-align: center;
     margin: 0;padding: 0;
     font-family: "Open Sans", sans-serif;
}
@media only screen and (min-width:0) and (max-width:576px) {
    .third {
        display: grid;
        place-items: center;
    }
    .third_2 .buttons {
        width: 80%;
        margin-top: 20px;
    }
    .third_2 .buttons button {
        width: 28%;
        display: grid;
        place-items: center;
    }
    .third_2 .buttons button img {
        margin-bottom: 10px;
    }
    .SocialShareIcon{
        gap: 20px;
        top: 45px;
      right: 15px; 
      transform: translate(0, 0);
    }
    .club--about--us--video--frames {
        height: auto;
        width: 100%;
        display: flex;
        overflow-x: auto;
        gap: 30px;
        max-width: 100%;
        transition: transform 0.3s ease;
        margin-bottom: 15px;
    }
    .club--about--us--video--frames--card{
        height: auto;
        width: 150px;
    }
    .club--about--us--video--frames--card video{
        width: 150px;
        height: 230px;
        object-fit: cover;
    }
}
.fourth_desktop {
    width: 100%;
    display: grid;
    place-items: center;
    margin-bottom: 0px;
    padding-left: 130px;
    padding-right: 130px;
}
.fourth_desktop .img {
    width: 75%;
    margin-top: 80px;
    position: relative;
}
.fourth_desktop .img #bg_img {
    width: 100%;
    height: 230px;
}
.fourth_desktop .play_icons {
    width: 100%;
    height: 230px;
    display: flex;
    position: absolute;
    top: 0px;
}
.fourth_desktop .titles {
    width: 100%;
    display: flex;
}
.fourth_desktop .play_icons .icon,
.fourth_desktop .titles .icon {
    width: 25%;
    display: grid;
    place-items: center;
}
.fourth_desktop .titles .icon {
    color: #FFF;
    text-align: center;
    font-family: Helvetica, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 20px;
}
.fourth_desktop .play_icons .icon img {
    width: 30px;
    height: 30px;
    cursor: pointer;
}
.fourth_mobile {
    width: 100%;
    display: none;
}
.fourth_mobile .div {
    width: 100%;
    margin-top: 50px;
    display: grid;
    place-items: center;
    position: relative;
}
.fourth_mobile .div .mob_img {
    width: 80%;
    height: 240px;
}
.fourth_mobile .div .mob_play_icon {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 35%;
    cursor: pointer;
}
.fourth_mobile .div h3 {
    color: #FFF;
    text-align: center;
    font-family: Helvetica, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 20px;
}
.menu_div {
    width: 100%;
    margin-top: -25px;
    position: sticky;
    top: 0;
    height: 75px;
    background-color: #120829;
    display: flex;
    flex-direction: row;
    column-gap: 25px;
    z-index: 100;
    border-bottom: 1px solid #554e65;
}
.menu_nav {
    color: white;
    display: inline-block;
    color: #FFF;
    text-align: center;
    font-family: Helvetica, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 6%;
    padding-top: 30px;
    padding-bottom: 10px;
    cursor: pointer;
}
.menu_nav a {
    text-decoration: none;
    color: #FFF;
    padding-bottom: 20px;
}
.menu_nav a:hover {
    text-decoration: none;
    color: #D51361;
    padding-bottom: 20px;
}
.menu_div .active {
    border-bottom: 3px solid #D51361;
}
.favorate--club--category--10{
    padding-top: 10px;
}
.menu-padding{
    padding-top: 40px;
}
.menu_div_2 h2 {
    color: #FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Open Sans', sans-serif;
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    margin-top: 40px;
    letter-spacing: 2px;
    margin-bottom: 0px;
}
.menu_div_2 .img1 {
    float: right;
    margin-top: 100px;
    margin-right: 25px;
}
.menu_div_2 .img2 {
    margin-top: 7px;
    margin-left: -25px;
}
.menu_div_2 .img3 {
    margin-top: 96px;
    margin-left: 4px;
    height: 130px;
}
.booking {
    width: 100%;
}
.card {
    background-color: #2A2A2C;
    margin-top: 20px;
}
.card-title {
    color: #FFF;
    font-family: Helvetica;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.card-subtitle {
    color: #FFF;
    font-family: Helvetica;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    margin-top: 30px;
}
.option_1 {
    height: 60px;
    display: grid;
    place-items: center;
    width: 30%;
    border-radius: 6px;
    border: 1px solid #C42457;
    background: rgba(196, 36, 87, 0.10);
    margin-right: 3%;
    cursor: pointer;
}
.option_2,
.option_3 {
    height: 60px;
    display: grid;
    place-items: center;
    width: 30%;
    border-radius: 6px;
    background: #2A2A2C;
    border: 1px solid #413e3f;
    margin-right: 3%;
    cursor: pointer;
}
.option_1 h2,
.option_2 h2,
.option_3 h2 {
    color: white;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}
.option_1 h3,
.option_2 h3,
.option_3 h3 {
    color: white;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}
.option_1 h4,
.option_2 h4,
.option_3 h4 {
    color: #cbc0c0;
    text-align: center;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}
.option_people_1,
.option_people_2,
.option_people_3,
.option_people_4 {
    height: 60px;
    display: grid;
    place-items: center;
    width: 22%;
    border-radius: 6px;
    background: #2A2A2C;
    border: 1px solid #413e3f;
    margin-right: 3%;
    cursor: pointer;
}
.option_people_1 {
    height: 60px;
    display: grid;
    place-items: center;
    width: 22%;
    border-radius: 6px;
    border: 1px solid #C42457;
    background: rgba(196, 36, 87, 0.10);
    margin-right: 3%;
    cursor: pointer;
}
.option_people_1 h3,
.option_people_2 h3,
.option_people_3 h3,
.option_people_4 h3 {
    color: white;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}
.option_yes,
.option_no {
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-left: 5px;
}
.inp_kids2 {
    margin-left: 20%;
}
.button_reserve {
    width: 100%;
    height: 40px;
    background: none;
    border: none;
    align-items: center;
    border-radius: 6px;
    border: 2px solid #D51361;
    background: #D51361;
    color: var(--Base-On-Background, #FFF);
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.club--about--tabs--left--right{
    padding-right: 60px;
}
.menu_images_div h2 {
    color: white;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Open Sans', sans-serif;
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 25px;
    letter-spacing: 2px;
}
.menu_images_div .img_2 {
    margin-left: 30px;
}
.menu_images_div .img_1 {
    float: right;
}
.menu_images_div h3 {
    color: white;
    font-family: Helvetica;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 10px;
}
.menu_images_div h4 {
    color: #9e9292;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}
.events_div .event_img1,
.events_div .event_img2 {
    width: 100%;
    position: relative;
}
.events_div .event_img1 img,
.events_div .event_img2 img {
    width: 100%;
}
.inner_div_events {
   margin-top: 40px;
}
.event_img1 {
    float: right;
    margin-right: 30px;
}
.titles_event {
    display: flex;
    justify-content: space-between;
}
.titles_event h3 {
    color: white;
    font-family: 'Ubuntu', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    text-transform: capitalize;
    margin-top: 10px;
}
.titles_event h4 {
    color: rgb(157, 154, 154);
    font-family: 'Ubuntu', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 5px;
    text-transform: capitalize;
}
.titles_event h5 {
    color: rgb(157, 154, 154);
    font-family: Helvetica, sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 10px;
    text-transform: capitalize;
}
.titles_event span {
    color: #FFF;
    font-family: Helvetica;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.titles_event p {
    color: #FE262F;
    font-family: 'Ubuntu', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 5px;
    text-transform: capitalize;
}
.titles_event button {
    border-radius: 4px;
    border: 1px solid #FFF;
    background: #1F1F21;
    color: #FFF;
    text-align: center;
    font-family: Helvetica, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
}
.event_mask {
    display: flex;
    position: absolute;
    top: 0px;
    background: linear-gradient(90deg, #000 0%, rgba(55, 55, 55, 0.00) 105.77%);
}
.event_mask img {
    width: 70px !important;
    margin-top: -30px;
}
.event_mask h6 {
    font-family: 'Ubuntu', sans-serif;
    color: white;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 2.8px;
    margin-top: 10px;
    margin-left: 10px;
}
.review_section .first {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
}
.review_section .first h3 {
    color: #FD2F71;
    text-align: right;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 35px;
}
.review_inner_div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.review_section .first h3 img {
    margin-right: 10px;
}

.review_inner_div .food {
    display: flex;
    justify-content: space-between;
}
.review_inner_div .div_2 h4 {
    color: #FFF;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    margin-top: 20px !important;
}
.review_inner_div .div_2 .line {
    margin-top: 25px;
    width: 60%;
    height: 5px;
    border-radius: 16px;
    background: #FFB547;
}
.review_inner_div .div_2 h5 {
    color: #959393;
    text-align: right;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-style: normal;
    line-height: 16px !important;
    font-weight: 500;
    margin-top: 20px;
}
.offer--img--heading{
    padding-left: 65px;
}
.review_inner_div .div_1 .box3 {
    width: 100%;
    height: 150px;
    display: flex;
    padding: 32px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;
    border-radius: 6px;
    background: rgba(250, 250, 250, 0.10);
}
.review_inner_div .div_1 .box3 .rating {
    display: grid;
    place-items: center;
}
.review_inner_div .div_1 .box3 .rating h3 {
    color: #FFF;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-size: 56px;
    font-style: normal;
    font-weight: 500;
    line-height: 56px;
}
.review_inner_div .div_1 .box3 .rating h4 {
    color: #FFF;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    opacity: 0.3;
}
.testimonial .div_main {
    display: flex;
    padding: 24px 28px 24px 16px;
    align-items: flex-start;
    gap: 19px;
    align-self: stretch;
    border-radius: 6px;
    background: rgba(250, 250, 250, 0.10);
    margin-top: 80px;
    width: 100%;
}
.testimonial .text {
    width: 100%;
}
.testimonial .text .first {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}
.testimonial .text .first h3 {
    color: #FFF;
    font-family: 'Lato', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}
.testimonial .text .first h4 {
    color: #FFF;
    text-align: right;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    opacity: 0.2;
}
.testimonial .text .third3 p {
    color: #7E7E7E;
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-top: 20px;
    margin-left: -30px;
}
.testimonial .text .fourth img {
    margin-left: 10px;
}
#btn_review {
    margin-top: 30px;
    margin-left: 30%;
    width: 90px;
    border: none;
    border-bottom: 1px solid white;
    background: none;
    color: #FFF;
    text-align: center;
    font-family: Helvetica, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}
.gallery_section {
    margin-top: 40px;
}
.gallery_section .inner_div {
    margin-top: 60px;
}
.gallery_section .wrapper_videos {
    display: flex;
    justify-content: space-between;
}
.gallery_section .inner_div .gallery_1 {
    position: relative;
}
.gallery_section .inner_div h3 {
    color: #FFF;
    text-align: center;
    font-family: Helvetica, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 20px;
}
.gallery_section .inner_div h4 {
    color: #FFF;
    text-align: center;
    font-family: Helvetica, sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: absolute;
    top: 33%;
    left: 46%;
    cursor: pointer;
}
#explore_btn,
#explore_btn2,
#explore_btn3,
#explore_btn4 {
    width: 58px;
    height: 28px;
    border: none;
    border-radius: 4px;
    background-color: #40404A;
    padding: 5px;
    margin-top: 10px;
}
#explore_btn_img,
#explore_btn_img2,
#explore_btn_img3,
#explore_btn_img4 {
    width: 16px;
    height: 16px;
    margin-top: -5px;
    margin-right: 5px;
    color: #000;
}
.explore_img {
    position: relative;
}
#discount_img,
#discount_img2,
#discount_img3,
#discount_img4 {
    width: 80px;
    height: 30px;
    position: absolute;
    top: -5px;
    right: -10px;
}
.disc_img_text {
    color: #FFF;
    text-align: center;
    font-family: 'Ubuntu Condensed', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    position: absolute;
    top: 0;
    right: 10px;
}
.foot_img {
    width: 100%;
    display: grid;
    place-items: center;
    padding-top: 70px;
    padding-bottom: 60px;
}
.foot_img_inner {
    width: 90%;
}
.pagination {
    width: 100%;
    display: grid;
    margin-top: 30px;
    place-items: center;
    padding-bottom: 30px;
}
#img_nav {
    cursor: pointer;
}
.modal_cross_sign {
    position: absolute;
    top: -30px;
    right: 47%;
    background-color: white;
    opacity: 70%;
    border: none;
    border-radius: 50px;
}
.club--about--us--tab--view .club--about--us--page--card--3{
    width: 100%;
    height: auto;
}
.club--about--us--tab--view .div_2{
    width: 48%;
    height: auto;
}
.wrapper_videos--img img{
    width: 90%;
    height: auto;
}
.club--about--us--tab--view{
    display: flex;
    flex-direction: row;
    column-gap: 40px;
}
.wrapper_videos::-webkit-scrollbar {
    width: 1px;
}
.wrapper_videos::-webkit-scrollbar-track {
    background-color: transparent;
}
.wrapper_videos::-webkit-scrollbar-thumb {
    border-radius: 10px;
}
.wrapper_videos {
    display: flex;
    overflow-x: auto;
    column-gap: 25px;
    transition: transform 0.3s ease;
}
.wrapper_videos_gallery::-webkit-scrollbar {
    width: 1px;
}
.wrapper_videos_gallery::-webkit-scrollbar-track {
    background-color: transparent;
}
.wrapper_videos_gallery::-webkit-scrollbar-thumb {
    border-radius: 10px;
}
.wrapper_videos_gallery {
    display: flex;
    overflow-x: auto;
    column-gap: 30px;
    transition: transform 0.3s ease;
}
.package--about--us--menu--card img{
    height: 450px;
    width: 360px;
    object-fit: cover;
    border-radius: 5px;
}
.package--about--us--menu--card7 img{
    height: 450px;
    object-fit: cover;
    border-radius: 17px;
}
.modal-content {
    position: relative;
    max-width: 35% !important;
}
.modal-content_package {
    position: relative;
    max-width: 55% !important;
}
.modal-dialog-centered {
  justify-content: center !important;
}
.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 4px !important;
}
.modal-title{
    text-transform: capitalize !important;
}
.package--about--us--menu--card5 img{
    height: 450px;
    width: 320px;
   object-fit: cover;
    border-radius: 5px;
}
.img_title{
    display: flex;
    flex-direction: row;
    column-gap: 30px;
}
.img_title h3{
    text-align: center;
    text-transform: capitalize;
}
.package--about--us--menu--card{
    position: relative;
}
.package--about--us--menu--card-1{
    position: absolute;
    top: 0;
 }
.package--about--us--menu--card-1 img{
    height: 450px;
    width: 320px;
}
.package--anout--us--card--content--1 {
    width: 100%;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    padding-left: 30px;
    padding-right: 30px;
}
.package--anout--us--card--content--2 {
    width: 100%;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
}
.package--anout--us--card--content {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    max-width: 320px; 
    height: 450px; 
    overflow-y: auto; 
}
.package--anout--us--card--content--6 {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    max-width: 100%; 
    height: 450px; 
    overflow-y: auto; 
}
.package--anout--us--card--content::-webkit-scrollbar {
    width: 1px; 
}
.package--anout--us--card--content::-webkit-scrollbar-track {
    background-color: transparent; 
}
.package--anout--us--card--content--6::-webkit-scrollbar {
    width: 1px; 
}
.package--anout--us--card--content--6::-webkit-scrollbar-track {
    background-color: transparent; 
}
.package--anout--us--card--content--9{
    display: flex;
    justify-content: center;
    padding-top: 20px;
}
.package--anout--us--card--content--9 img{
   height: 40px;
   width: auto;
   border-radius: 50%;
}
.package--anout--us--card--content--9-1{
    display: flex;
    justify-content: center;
    padding-top: 20px;
}
.package--anout--us--card--content--9-1 img{
    height: 50px;
    width: auto;
    border-radius: 50%;
    margin-bottom: 10px;
 }
.package--veg--non-veg--row{
    display: flex;
    flex-direction: row;
    column-gap: 20px;
}
.package--veg--course--border--line{
   width: 1px;
   height: auto;
   background-color: #fff;
   opacity: 0.5;
}
.package--veg--course--p{
    color: #fff;
    font-family: 'Ubuntu Condensed', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    margin: 0;padding: 0;
}
.package--veg--course--p2{
    color: #fff;
    font-family: 'Ubuntu Condensed', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    margin: 0;padding: 0;
}

.para--dots{
    text-align: left;
    margin: 0;
    padding: 0px 0px 0px 20px;

}
.package--veg--course--p1{
    color: #fff;
    font-family: 'Ubuntu Condensed', sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    letter-spacing: 1px;
    margin: 0;padding: 0;
    /* padding-bottom: 10px; */
}
.package--veg--course--p1-2{
    color: #fff;
    font-family: 'Ubuntu Condensed', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    letter-spacing: 1px;
    margin: 0;padding: 0;
    padding-bottom: 10px;
}
.package--choose--veg{
    font-family: 'Ubuntu Condensed', sans-serif;
    font-size: 12px;
    color: #fff;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    opacity: 0.4;
    margin: 0;padding: 0;
    padding-bottom: 5px;
}
.package--choose--veg1{
    font-family: 'Ubuntu Condensed', sans-serif;
    font-size: 16px;
    color: #fff;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    opacity: 0.4;
    margin: 0;padding: 0;
    padding-bottom: 5px;
}
.package-food--service-card{
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 30px;
    margin-bottom: 20px;
}
.package-food--service-card1{
    display: flex;
    flex-direction: row;
    column-gap: 15px;
    align-items: center;
}
.package-food--service-card2 h5{
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    margin: 0;padding: 0;
    font-family: "Open sans",sans-serif;
}
.package-food--service-card2 h6{
    color: #008ad8;
    font-size: 12px;
    font-weight: 400;
    margin: 0;padding: 0;
    font-family: "Open sans",sans-serif;
}
.party--package--banner-1{
    width: 100%;
    height: auto;
    margin-bottom: 15px;
    margin-top: 15px
}
.party--package--banner-1 img{
    width: 100%;
    height: auto;
}
.pacakge_card_left{
    width: 70%;
    height: auto;
}
.package_card_right{
    width: 30%;
}
.package_card{
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    padding-right: 50px;
}
@media(min-width: 591px) and (max-width:1100px){
    .modal-content_package {
        position: relative;
        max-width: 90% !important;
    }
}
@media only screen and (min-width:0) and (max-width:590px) {
    .form_input{
        width: 100%;
    }
    .SocialShareIcon{
        gap: 20px;
        display: flex;
        gap: 20px;
        position: absolute;
        top: 5px;
        right: 15px; 
        transform: translate(0, 0);
    }
    .package-food--service-card1 img
    .form_navbar,
    .login_section {
        margin-top: 20px;
    }
    .package-food--service-card1 img{
        height: 30px;
        width: auto;
    }
    .package-food--service-card{
        display: flex;
        flex-direction: row;
        column-gap: 20px;
        margin-bottom: 10px;
    }
    .package--choose--veg1{
        font-family: 'Ubuntu Condensed', sans-serif;
        font-size: 14px;
        color: #fff;
        font-style: normal;
        font-weight: 400;
        text-align: center;
        opacity: 0.4;
        margin: 0;padding: 0;
        padding-bottom: 5px;
    }
   
    .pacakge_card_left{
        width: 100%;
        height: auto;
    }
    .package_card_right{
        width: 100%;
        height: auto;
        padding: 20px;
    }
    .package_card{
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        padding-right: 0px;
    }
    .third3 .buttons .btn1 {
       
        float: left;
        margin-top: 30px;
    }
    .fourth_desktop {
        display: none;
    }

    .fourth_mobile {
        display: block;
    }
    .wrapper_videos::-webkit-scrollbar {
        width: 1px;
    }
    .wrapper_videos::-webkit-scrollbar-track {
        background-color: transparent;
    }

    .wrapper_videos::-webkit-scrollbar-thumb {
        border-radius: 10px;
    }

    .wrapper_videos {
        display: flex;
        overflow-x: auto;
        max-width: 100%;
        transition: transform 0.3s ease;
    }
    .third3 h2 {
        color: #FFF;
        font-family: Helvetica, Sans-Serif;
        text-align: left;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
        margin: 0;padding: 0;
        margin-bottom: 10px;
        margin-top: 5px;
        letter-spacing: 2px;
    }
    .third3 h3 {
        color: #E4E4E4;
        text-align: left;
        font-family: Helvetica, Sans-Serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0;
        padding: 0;
    }
    .inner_div_events {
       margin-top: 20px;
    }
    .third3 p {
        color: #fff;
        text-align: left;
        font-family: Helvetica, Sans-Serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        opacity: 50%;
       margin: 0;padding: 0;
    }
    
    .videos_1 {
        flex: 0 0 80%;
        height: auto;
    }
    .package--veg--course--p2{
        color: #fff;
        font-family: 'Ubuntu Condensed', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        text-align: center;
        margin: 0;padding: 0;
    }
    .wrapper_videos_teaser::-webkit-scrollbar {
        width: 1px;
    }

    .wrapper_videos_teaser::-webkit-scrollbar-track {
        background-color: transparent;
    }
    .banner_img {
        width: 100%;
        height: 250px;
        margin-top: 60px;
    }
    .wrapper_videos_teaser::-webkit-scrollbar-thumb {
        border-radius: 10px;
    }
    .wrapper_videos_teaser {
        display: flex;
        overflow-x: auto;
        max-width: 100%;
        transition: transform 0.3s ease;
    }

    .videos_teaser {
        flex: 0 0 70%;
        height: auto;
    }
    .wrapper_videos_gallery::-webkit-scrollbar {
        width: 1px;
    }

    .wrapper_videos_gallery::-webkit-scrollbar-track {
        background-color: transparent;
    }

    .wrapper_videos_gallery::-webkit-scrollbar-thumb {
        border-radius: 10px;
    }
    .wrapper_videos {
        display: flex;
        overflow-x: auto;
        column-gap: 5px;
        transition: transform 0.3s ease;
    }
    .wrapper_videos_gallery {
        display: flex;
        overflow-x: auto;
        max-width: 100%;
        transition: transform 0.3s ease;
    }
    .banner_img img {
        width: 100%;
        height: 250px;
        object-fit: fill;
    }
    .videos_1_gallery {
        flex: 0 0 50%;
        height: auto;
    }
    .menu_div {
        width: 100%;
        margin-top: 10px;
        position: sticky;
        top: 0;
        height: 70px;
        background-color: #120829;
        display: flex;
        flex-direction: row;
        column-gap: 25px;
        z-index: 100;
        border-bottom: 1px solid #554e65;
    }
    .menu_div {
        margin-top: 30px;
    }
    .menu_nav {
        color: white;
        display: inline-block;
        color: #FFF;
        text-align: center;
        font-family: Helvetica, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-left: 6%;
        padding-top: 30px;
        padding-bottom: 10px;
        cursor: pointer;
    }
    .button_reserve {
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 1;
    }

    .menu_div_2 h2 {
        font-size: 30px;
        margin-left: 5px;
    }

    .menu_div_2 .div_1 {
        margin-bottom: 50px;
    }
    .package--veg--course--p1-2{
        color: #fff;
        font-family: 'Ubuntu Condensed', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        text-align: center;
        letter-spacing: 1px;
        margin: 0;padding: 0;
        padding-bottom: 10px;
       
    }
    .menu_div_2 .img1,
    .menu_div_2 .img2,
    .menu_div_2 .img3 {
        width: 90%;
        margin: 20px 0;
    }

    .menu_div_2 .image {
        display: grid;
        place-items: center;
    }
    .menu_div_2 h2 {
        color: #FFF;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
        margin-top: 20px;
        letter-spacing: 0px;
        margin-bottom: 0px;
    }
    .club--about--us--tab--view .club--about--us--page--card--3{
        width: 100%;
        height: auto;
    }
    .club--about--us--tab--view .div_2{
        width: 100%;
        height: auto;
    }
    .club--about--tabs--left--right{
        padding-left: 5px;
        padding-right: 10px;
    }
    .offer--img--heading {
        padding: 0px;
    }
    .modal_1 {
        width: 100vw;
        height: 80vh;
    }

    .cross_sign {
        font-size: 40px;
    }

    .event_img1 {
        float: none;
        margin-top: 50px;
    }
    .modal-content {
        max-width: 95% !important;
    }
    .modal-content_package {
        position: relative;
        max-width: 95% !important;
    }
    .event_img2 {
        margin-top: 50px;
        margin-left: 10px;
    }

    .event_img_1 ,.event_img_2 {
        height: 220px;
    }

    .review_inner_div .div_2 {
        padding-left: 30px;
    }

    .review_inner_div .div_1 .box {
        display: grid;
        place-items: center;
        width: 80%;
        height: 200px;
        margin: 30px 0px 30px 0px;
    }

    .review_inner_div .div_1 .box .rating h4 {
        margin-top: 10px;
    }

    .testimonial .div_main {
        width: 90%;
        display: grid;
    }

    .testimonial .text .first {
        display: grid;
    }

    .testimonial .text .third3 p {
        margin-left: -15px;
    }

    .gallery_section .inner_div .gallery_1 {
        margin-left: 0px;
        position: relative;
        width: 100%;
    }

    .gallery_section .inner_div .gallery_1 img {
        width: 100%;
        margin-top: 40px;
    }

    .gallery_section .inner_div h4 {
        top: 44%;
        left: 44%;
    }

    #gallery_2 {
        margin-left: 10%;
        margin-right: 10%;
    }

    #gallery_3 {
      margin-right: 10;
    }
    .package--about--us--menu--card-1 img{
        height: 300px;
        width: 220px;
    }
    .package--about--us--menu--card5{
        display: flex;
        flex-direction: row;
        column-gap: 15px;
        margin-top: 20px;
    }
    .package--about--us--menu--card5 img{
        height: 300px;
        width: 220px;
        border-radius: 5px;
    }
    .package--about--us--menu--card img{
        height: 380px;
        width: 300px;
        object-fit: cover;
        border-radius: 5px;
    }
    .package--anout--us--card--content--1 {
        width: 100%;
        position: absolute;
        top: 0;
        display: flex;
        justify-content: center;
       padding-left: 10px;
       padding-right: 10px;
    }
    .package--anout--us--card--content {
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        max-width: 300px; 
        height: 370px; 
       
        overflow-y: auto; 
    }
    .package--anout--us--card--content--6 {
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        max-width: 100%; 
        height: 450px; 
        overflow-y: auto; 
    }
    .img_title{
        display: flex;
        flex-direction: row;
        column-gap: 20px;
    }
    .package--anout--us--card--content--9{
        display: flex;
        justify-content: center;
        padding-top: 20px;
       
    }
    .menu_images_div h3 {
        color: white;
        font-family: Helvetica;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 30px;
    }
    
    .package--anout--us--card--content--9 img{
       height: 30px;
       width: auto;
       border-radius: 50%;
       margin-bottom: 5px;
    
    }
    .package--anout--us--card--content--9-1{
        display: flex;
        justify-content: center;
        padding-top: 10px;
    }
    .package--anout--us--card--content--9-1 img{
        height: 35px;
        width: auto;
        border-radius: 50%;
        margin-bottom: 10px;
     }
     .Timing_container{
        gap: 20px;
        display: flex;
        justify-content: left;
        align-items: left;
    }
  
}

@media only screen and (min-width:600px) and (max-width:980px)
{
    .club--about--us--tab--view .club--about--us--page--card--3{
        width: 100%;
        height: auto;
    }
    .SocialShareIcon{
     
        
        gap: 20px;
     
        top: 55px;
        right: 15px; 
        transform: translate(0, 0);
    }
    .club--about--us--tab--view .div_2{
        width: 100%;
        height: auto;
    }
    .club--about--tabs--left--right{
        padding-left: 20px;
        padding-right: 20px;
    }
    .offer--img--heading {
        padding-left: 20px;
    }
    .Timing_container{
        gap: 20px;
        display: flex;
        justify-content: left;
        align-items: left;
    }
    .third3 h3 {
        color: #E4E4E4;
        text-align: left;
        font-family: Helvetica, Sans-Serif;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0;padding: 0;
    }
    
}

   
