.aboutus {
    width: 100%;
    height: auto;
    padding: 10px 40px;
}

.s-page{
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}
.service-aboutusPage{
    width: 100%;
    height: auto;
    margin-bottom: 50px;
}
.about--banner {
    width: 100%;
    height: 250px;
   
    background-image: url("./image/img1.avif");
}
.title--name{
    height: 250px;
    width: 100%;
  
}
.border--line{
        border: 1px solid grey;
        border-radius: 2px; 
}
.about-banner{
    height: 600px;
    width: 100%;
    background-image: url("./image/singer.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0;
    margin-bottom: 30px;
}
.aboutbaner{
    height: 500px;
    width: 100%;
    background-image: url("./image/club.avif");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 0;
}
.form--box {
    background-color: #0B051C;
    position: relative;
    padding: 35px;
    border-radius: 5px;
    margin:40px 300px ;
}
.form--box::before {
    margin-top: 20px;
    border-color: #0B051C;
    position: absolute;
    content: "";
    left: -3.95em;
    top: -3.5em;
    right: 4em;
    bottom: 3em;
    height: auto;
    width: auto;
    border: 25px solid #0B051C;
    z-index: -1;
    border-radius: 5px;
}
.title--name h3 {
    color: white;
    font-size: 70px;
   padding-top: 80px;
    text-align: center;
}
.title{
    padding-top: 20px;
    padding-bottom: 30px;
}
.title p{
    font-size: 20px;
}
.title h1{
    font-size: 58px;
}
.c--box{
    background-color: transparent;
    border-radius: 10px;
    text-align:center ;
    padding: 15px;
    width: 100%;
    height: auto;
}
.c--box:hover{
    background-color: #211738;
}

.form-control {
   border-radius: 5px;
    height: 45px;
    margin-top: 7px;
    color: #909090;
}
.col{
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}

.row{
    padding: 20px;
}
.title-name h1 {
    color: white;
    font-size: 90px;
    padding-left: 480px;
    padding-top: 200px;
}
.content{
    padding: 20px;
}
.description--box p {
    font-size: 22px;
}
.description--box h1 {
    font-size: 60px;
}
.description-box h2{
     font-size: 50px;
     text-align: center;
}
.description-box p{
    font-size: 20px;
    text-align: center;
}
.description-box h1{
    padding-top: 40px;
    font-size: 50px;
    text-align: center;
}
.benefit-box{
    background-color: #0F0F3B;
    text-align: center;
    padding: 5px;
    border-radius: 20px;
}
.benefit-box h5{
 font-size: 28px;
 text-align: center;
}
.benefit-box p{
    font-size: 18px;
    text-align: center;
   }
.description-box{
    margin-bottom: 30px;
}
.title {
    text-align: center;
}
.title h5 {
    font-size: 45px;
    font-weight: lighter;
}
.title h2 {
    font-size: 80px;
}
.left--box h3{
    font-size: 50px;
    color: white;
}
.left--box p{
    font-size: 18px;
    color: white;
}
.right--box img{
    height: 500px;
    width: 600px;
    border-radius: 30px;
}
.wrapper--box{
    display: flex;
    flex-direction: row;
    gap: 20px;
}
.grid-box{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-top: 30px;
}
.grid--box {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
}
.package--box {
    background-color: #0F0F3B;
    border-radius: 20px;
    border: 2px solid #D71362;
}
.package--box h5 {
    padding: 20px;
    font-size: 40px;
    font-weight: lighter;
}
.btn--theame--2 {
    border: none;
    background-color: #D71664;
    color: white;
    border-radius: 20px;
    margin-top: 10px;
    text-align: end;
    padding: 10px;
}
.btn--theame--1 {
    width: 100%;
    height: 40px;
    border: none;
    background-color: #D71664;
    color: white;
    border-radius: 10px;
    margin-top: 10px;
    text-align: center;
    padding: 10px;
}
.left--box{
    padding-bottom: 20px;
}
.package--box p {
    margin: 0;
    padding: 20px;
    font-size: 20px;
}
.package--box img {
    width: 100%;
    height: 261px;
    border-radius: 20px;
}
.icon--img{
    height: 90px;
    width: 90px;
    color: white;
}
.box--a{
   display: flex;
   flex-direction: column;
   row-gap: 10px;
   justify-content: center;
   align-items: center;
   margin-bottom: 50px;
}

.artist-description{
    display: flex;
    flex-direction: row;
    gap: 20px;
}
.col--1{
    display: flex;
    flex-direction: row;
    column-gap: 20px;
}
.col--1_8{
    display: flex;
    flex-direction: row;
    column-gap: 20px;
}
.form-group1{
    width: 50%;
}
.form-group2{
    width: 50%;
}
.form-group_1{
    width: 50%;
}
.form-control1 {
    height: 120px;
    width: 100%;
    resize: vertical; 
    overflow-y: auto; 
    border-radius: 5px;
}
.footer-card2{
    display: flex;
    justify-content: space-between;
    padding: 4px 80px;
}
.form-group textarea{
    padding: 10px;
}
@media(max-width:920px) {
    .aboutusPage{
        overflow: hidden;
    }
    .aboutus{
        overflow: hidden;
        padding: 10px;
    }
    .aboutbaner{
        width: 100%;
        height: 250px;
    }
    .footer-card2{
        display: flex;
        justify-content: space-between;
        padding: 4px 10px;
    }
    .col--1{
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        margin-bottom: 0px;
    }
    .col--1_8{
        display: flex;
        flex-direction:column;
        row-gap: 2px;
        
    }
    .form-group_1{
        width: 100%;
    }
    .title--name h3 {
        color: #ffffff;
        font-size: 30px;
        padding-top: 100px;
        text-align: center;
    }
    .title-name h1 {
        color: #ffffff;
        font-size: 50px;
        padding-left: 110px;
        padding-top: 105px;
    }
    .col{
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .about--banner {
        height: 250px;
        width: 100%;
    }
    .box--a img{
        height: 50px;
        width: auto;
    }
    .box--a p{
       font-size: 12px;
       text-align: center;
    }
    
    .form--box{
        width: 100%;
        height: auto;
        margin: 0;
     }
   .main--box h5{
         font-size: 28px;
    }
    .main--box h2{
        font-size: 35px;
   }
   .description--box h1{
    font-size: 40px;
}
    .main--box p{
        font-size: 12px;
   }
   .form-control {
    border-radius: 5px;
     height: 45px;
     margin-top: 5px;
     color: #909090;
 }
   
   .grid--box {
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;
}
.grid-box{
    
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}
.right--box img{
    height: 300px;
    width: 100%;
    border-radius: 30px;
}
.wrapper--box{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
}
    .slider-img {
        width: 100%;
        height: 550px;
    }
    .slider-img img {
        width: 100%;
        height: 550px;
    }
    .about-banner{
        height: 300px;
        width: 100%;
        margin-bottom: 30px;
    }
    
} 

   

    

    