.blog-banner{
    width: 100%;
    height: 250px;
    background-image: url("./image/img1.avif");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-bottom: 30px;
}
.blog-aboutus{
    display: flex;
    flex-direction: row;
    gap: 30px;
}
.blog-banner h1{
    font-size: 90px;
    font-weight: 500;
    color: white;
}
.blog-button button{
    border: 2px solid black;
    background-color: #1D0B0B;
    border: none;
    color: white;
    height: 40px;
    width: 90px;
    border-radius: 5px;
}
.blog-button{
    display: flex;
    flex-direction: row;
    row-gap: 10px;
    column-gap: 20px;
    margin-bottom: 10px;
}
.blog-content{
    width: 100%;
    height: auto;
}
.c--box-1 {
    background-color: #211738;
    border-radius: 10px;
    text-align: center;
    padding: 15px;
}
.c--box-1 img{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    object-fit: contain;
    height: 500px;
    width: 100%;
}
.blog-h3{
    color: white;
    text-align: start;
}
.blog-p-white{
    color: white;
    text-align: start;
}
.blog-pink{
    color: #B4235A;
    text-align: start;
    font-size: 13px;
}
.blog-pink_content{
    color: #B4235A;
    text-align: right;
    font-size: 13px;
}
.blog-content-emoji{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
}
.blog-content-emoji-img{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    column-gap: 40px;
}
.blog-content-emoji-img img{
    height: 50px;
    width: auto;
}
.blog-content-emoji p{
    color: white;
    font-size: 30px;
    font-weight: "Open Sans",sans-serif;
    padding-top: 20px;
}
.blog-content-emoji-img p{
    color: white;
    font-size: 12px;
    font-weight: "Open Sans",sans-serif;
    margin: 0;padding: 0;
    letter-spacing: 0.5px;
}
.blog-content-emoji-img-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 5px;
}
@media only screen and (max-width: 640px) {
   
    .blog-accordion{
        display: none;
    }
    .aboutus{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
    }
    .blog-aboutus {
        flex-direction: column;
        align-items: center;
    }
    .blog-accordian, .blog-content {
        width: 100%;
    }
    .st-part {
        flex-direction: column;
    }
    .blog-content-emoji-img{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr ;
        gap: 40px;
    }
    .blog-content-emoji-img img{
        height: 40px;
        width: auto;
    }
    .c--box-1 img{
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        object-fit: contain;
        height: 300px;
        width: 100%;
    }
  }
.blog-grid--box-detail{
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
 }
 .blog-banner-detail{
    width: 100%;
    height: 400px;
    background-image: url("./image/img1.avif");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-bottom: 30px;
 }
 .blog-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 50px; 
  }
  .blog-content {
    flex: 1; /* Take up remaining space */
  }
  .blog-accordion {
    flex: 0 0 300px; /* Fixed width for the accordion */
  }