.gallery_section {
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 70%;
    color: #fff;
    margin-left: 20px;
}
.container{
    padding: 20px;
}
.gallery_row {
    display: flex;
    gap: 20px; /* Adjust the gap between items */
  }

  
 
.gallery_item {
    width: 200px;
    height: 200px;
    position: relative;
    overflow: hidden;
    border: 2px solid white;
    border-radius: 8px;
    margin-top: 2rem;

  }
  
  .image_container {
    width: 100%;
    height: 100%;
  }
  
  .image_container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    color: #fff;
    background-color: black; /* Dark black color */
    opacity: 0.6;
  }
  
  .overlay_text {
    position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  font-size: 22px;
  font-family: Helvetica;
  font-weight: 400;
  
  }
  
  .category_title_container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 10px;
    text-align: center;
  }
  
  .category_title {
    margin: 0;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    line-height: 23px;
    font-family: Helvetica;
    margin-top: 1rem;
  }
  
  .category_title_container h2 {
    margin: 0;
    font-size: 20px;
    font-weight: 400;
  }

  @media (max-width: 768px){
    .gallery_section {
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 95%;
    color: #fff;
    margin-left: 20px;
}
.gallery_item {
  width: 130px;
  height: 130px;
  position: relative;
  overflow: hidden;
  border: 2px solid white;
  border-radius: 8px;
  margin-top: 2rem;

}
  }

  @media (max-width: 668px){
    .gallery_section {
      padding: 0;
  }
    .gallery_item {
      width: 90px;
      height: 90px;
      position: relative;
      overflow: hidden;
      border: 1px solid white;
      border-radius: 8px;
      margin-top: 2rem;
    
    }
    .overlay_text{
      font-size: 16px;
    }
  }

  
  @media (max-width: 668px){
    .gallery_section {
      padding: 0;
  }
    .gallery_item {
      width: 80px;
      height: 80px;
      position: relative;
      overflow: hidden;
      border: 1px solid white;
      border-radius: 8px;
      margin-top: 2rem;
    
    }
    .overlay_text{
      font-size: 14px;
    }
    .gallery_row {
      display: flex;
      gap: 5px; /* Adjust the gap between items */
    }
    .category_title{
      font-size: 12px;
    }
  }
