.Big_wrapper {
  width: 100%;
  height: auto;
  background: #141420;
}

.banner_img {
  width: 100%;
  position: relative;
}

.active {
  border-bottom: 2px solid rgba(213, 19, 97, 1);
}

.ClubInfo {
  color: #ffffff;
  text-align: center;
  padding: 1rem;
  font-family: Helvetica;
  font-size: 35px;
  font-weight: 700;
  line-height: 40.25px;
  position: absolute;
  /* top: 50%; */
  left: 50%;
  transform: translate(-50%, -110%);
}

.ClubInfo h3 {
  font-size: 20px;
}
.infoSign {
  color: #d51361;
  font-size: 25px;
}

.container{
  margin: 50px;
}

.ContentContainer {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  position: sticky;
  top: 70px; 
  z-index: 99;
  margin: 40px;
  height: 0;
  margin-bottom: 550px;
/* pointer-events: none; */
}




.booking{
  display: none;
}
.Conatiner__Box{
  background-color: #d51361;
}

.contentWrapper {
  width: 92%;
  /* border-bottom: 1px solid #8383838e; */
}
.menu_div {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(20, 20, 32, 1);

}


.menu_nav a {
  color: #f0f0f0;
  text-decoration: none;
  text-transform: capitalize;
  font-family: Helvetica;
  font-size: 22px;
  margin: 20px;
  font-weight: 400;
  line-height: 27.6px;
  text-align: center;

}

.MenuContainer{
  display: flex;
  gap: 2rem;

}

.buttons {
  text-align: center;

  padding: 0.5rem;
  font-family: Helvetica;    
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align items to the top */
  flex-direction: column-reverse; /* Reverse the order of flex items */
  position: absolute;
  top: 0;
  right: 0; 
  transform: translate(0, 0); /* No additional translation needed */
}

.buttons > button {
  /* Add your button styles here */
  background-color: #141420 !important;
  opacity: 0.5;
  padding: 2px 2px;
  border-radius: 5px;
  border-color: #fff !important; /* Border color */
}
.buttons button:hover {
  background-color: #fff !important;
  opacity: 0.5; /* Change background color on hover */
}
.hover-white:hover {
  background-color: rgba(255, 255, 255, 0.5); /* White with opacity 0.5 */
}
.buttons > button .MuiButton-startIcon {
  /* Reset icon color to inherit */
  color: inherit !important;
}


.club_Card_Container_detail{
  position: relative;
  overflow: hidden;
  display: flex;
  height: auto;
  flex-direction: row;
  /* overflow-x: scroll; */
  column-gap: 25px;
}

.CardToExplore{
  position: relative;
  overflow: hidden;
  display: flex;
  height: auto;
  flex-direction: row;
  overflow-x: scroll;
  column-gap: 25px;
}

.Explore{
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0 0 3rem;
  width: 60%;
  color: #fff;
  margin-left: 20px;
}

.menucontianer{
  margin-top: -500px;
}


@media screen and (max-width: 930px){
  .ContentContainer {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    position: sticky;
    margin: 40px;

    top: 60px; /* Stick from the middle */
    background-color: rgba(20, 20, 32, 1);
    z-index: 99;
  }
  .menucontianer{
    margin-top: 0px;
  }
  .ContentContainer{
    width: 700px;
    margin: 20px;
  }
  
}



@media screen and (max-width: 768px) {
  .ClubInfo {
    font-size: 24px;
    line-height: 30px;
  }
  .menucontianer{
    margin-top: 0px;
  }
  .menu_div{
    display: none;
  }
  
  
.ContentContainer {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  position: sticky;
  margin: 40px;

  top: 60px; /* Stick from the middle */
  background-color: rgba(20, 20, 32, 1);
  z-index: 99;
}

  .ClubInfo h3 {
    font-size: 18px;
  }
  .buttons{
    transform: translate(0, 90%); /* No additional translation needed */

  }
  .ClubInfo h3 {
    font-size: 12px;
    font-weight: 400;
  }
  .menu_div{
    /* width: 100%; */
  }
  .menu_nav a {
    font-size: 16px;
  }
  
}




  
