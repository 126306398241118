body{
    background-color: #120829;
}
.happeningParty-img{
    width: 100%;
    height: 500px;
}
.happeningParty-all-elements{
    margin: 30px;
}
.happeningParty-search{
    display: flex;
    flex-direction: row-reverse;
}
.happeningParty-searchtag {
    background-color:#4A4A5F;
    color:white;
    border: none;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
}
.happeningParty-searchbutton{
    background-color:#4A4A5F;
    border: none;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
}
.happeningParty-searchbutton span{
    color: #756E6A;
}
.happeningParty-tags-card-event{
    height: 70px;
    width: 100px;
    padding-left: 25px;
    margin-bottom: 10px;
    margin-top: 10px;
}
.happeningParty-tags-allcards{
    margin-top: 30px;
    margin-left: 120px;
    display: flex;
    flex-direction: row;
    column-gap: 50px;
}
.happeningParty-tags-card{
    height: 130px;
    width: 130px;
    background-color: #0F0F3B;
}

.happeningParty-tags-card p{
    color: white;
    background-color: #FD2F71;
    padding: 8px;
    padding-left: 28px;
}
.happeningParty-tags-card-live-music{
    height: 90px;
    width: 120px;
    padding-left: 20px;
}
.happeningParty-tags-card-dancefloor{
    height: 80px;
    width: 100px;
    padding-left: 20px;
    margin-bottom: 10px;
}
 .happeningParty-allclubs-card{
    width: 100%;
    height:280px;
    background-color: #0F0F3B;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
} 
.happeningParty-allclubs{
    margin-top: 50px;
    background-color:#120829 ;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 35px;
    column-gap: 20px;
}
.lawn-img{
    width: 100%;
    height: 190px;
    border-radius: 5px;
}
.content p{
    margin: 0;padding: 0;
}
.content{
    padding: 10px;
}
@media(max-width:480px){
    .happeningParty-img{
        width: 100%;
        height: 250px;
    }
    .happeningParty-all-elements{
        margin: 20px;
    }
    .happeningParty-search{
        display: flex;
        flex-direction: row;
    }
    .happeningParty-tags-allcards{
        margin-top: 30px;
        margin-left: 0px;
        display: flex;
        flex-direction: row;
        column-gap: 15px;
        overflow: scroll;
    }
    .happeningParty-allclubs{
        margin-top: 50px;
        background-color:#120829 ;
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 35px;
        column-gap: 20px;
    }
   
}