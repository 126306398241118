.services {
  display: flex;
  flex-direction: row;
  column-gap: 35px;
  animation: scaleIn 1s ease-out forwards;
  overflow: hidden;
  overflow-x: scroll;
  scrollbar-width: none; 
}

.services-name {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  overflow: hidden;
}

.services-name img {
  width: auto;
  height: 170px;
  animation: fadeIn 1s ease-out forwards;

}

.services-name p {
  margin: 0;
  padding: 0;
  color: #fff;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 2px;
  text-align: center;
}

@media (min-width:641px) and (max-width:1040px) {

  .services-name {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    overflow: hidden;
  }

  .services-name1 {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    overflow: hidden;
  }

  .services-name img {
    width: auto;
    height: 120px;

  }

  .services-name p {
    margin: 0;
    padding: 0;
    color: #fff;
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }

  .services-name1 p {
    margin: 0;
    padding: 0;
    color: #fff;
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }
}

@media(max-width:640px) {
  .services {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 40px;
    margin-bottom: 0px;
  }

  .services-name {

    display: flex;
    flex-direction: column;
    row-gap: 5px;
    border-radius: 5px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
  }

  .services-name img {
    width: 100px;
    height: auto;

  }

  .services-name p {
    font-size: 10px;
    margin: 0;
    padding: 5px;
  }

}