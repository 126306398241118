.club-rating {
    color: white;
    width: 60px;
    background-color: #372E4C;
    border-radius: 3px;
    padding: 2px;
}

.club-rating img {
    width: 12px;
    height: auto;
}