@font-face {
  font-family: blanka;
  src: url(./Font/Blanka-Regular.otf);
}

.footer-card {
  margin-top: 30px;
  padding-top: 30px;
  width: 100%;
  height: auto;
  background-color: #0c0522;
  display: flex;
  flex-direction: column;
  row-gap: 50px;
  align-items: start;
  padding-left: 15px;
  padding-right: 15px;
  border: 1px solid #2a213e;
  padding-bottom: 40px;
}

.footer-card--1 {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.footer-content {
  display: flex;
  flex-direction: column;
  color: #fff;
  width: 18%;
  height: auto;
}

.footer-content ul li {
  list-style: none;
}

.footer-content--1 {
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 18%;
  height: auto;
  color: #fff;
}

.footer-content-ul1 li {
  list-style: none;
  color: white;
  font-size: 14px;
  margin: 0;
  padding: 0;
}

.footer-content--button button {
  height: 50px;
  width: 50px;
  border: none;
  background-color: #0c0522;
  border-radius: 50%;
  border: 1px solid #888394;
  padding-right: 10px;
}

.footer-content--button button span {
  color: white;
  font-size: 30px;
  position: relative;
  top: -8px;
}

.footer-content-ul2 li {
  list-style: none;
  color: #fff;
  font-size: 14px;
  margin: 0;
  padding: 0;
  position: relative;
  top: -12px;
}

.footer-content-ul3 li {
  color: white;
  font-size: 14px;
  margin: 0;
  padding: 0;
  position: relative;
  top: -26px;
}

.footer-content-ul3 {
  margin-left: 20px;
}

.footer-content1 {
  padding-top: 20px;
}

.footer-anchor {
  display: flex;
  flex-direction: row;
  column-gap: 15px;
}

.footer-content2 {
  padding-top: 20px;
  text-align: left;
}

.footer-content2 a {
  color: white;
}

.footer {
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin: auto;
}

.footer-card2 {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
  padding-top: 13px;
  padding-bottom: 40px;
}

.footer-card2--1 {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  margin-left: -13px;
}

.footer-card2 p {
  color: white;
  padding: 0;
  margin: 0;
  font-size: 13px;
}

.footer h1 {
  font-weight: 300;
  font-size: 22px;
  color: white;
  margin-left: 20px;
}

.footer-card2--1 select {
  background-color: #0c0522;
  color: white;
  border: none;
  outline: none;
}

.footer--as-a-buisness {
  color: blue;
}

.footer-content ul li {
  color: white;
  cursor: pointer;
  margin-bottom: 0px;
  padding-bottom: 0;
  font-size: 14px;
  margin-left: -16px;
}

.footer-card2 p {
  color: #888394;
}

.footer-content ul {
  color: white;
  cursor: pointer;
  margin-bottom: 5px;
  margin-top: 0px;

}

.menu-links {
  display: flex;
  flex-direction: column;
  align-items: left;
  padding-left: 20px;
}

.menu-links-links {
  margin-bottom: -15px;
}

.footer-mobile-view {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.social-links {
  display: flex;
  align-items: left;

}

.follow-header {
  margin: auto !important;
  text-align: center;
  color: white;
  font-family: blanka;

}

.playstore-mobile {
  width: 250px;
  margin-top: 15px;
}

.playstore {
  margin-left: 25px;
  margin-top: 20px;
}

.playstore-mobile img {
  width: 100%;
  height: 70px;
  overflow: hidden;
  border-radius: 5px;
}

.partner {
  margin-top: 10px;
  color: white;
  margin-bottom: -10px !important;
}

.footer-btm {
  margin-top: 10px;
  display: flex;
  color: white;
  font-size: 14px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo-mobile {
  width: 100px;
}

.logo-container-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-mobile-view {
  color: white;
}

.logo1 h2 {
  color: white;
  font-size: 30px;
}

.footer-content h1 {
  color: #888394;
  font-size: 20px;
  position: relative;
  left: 10px;
}

.footer-content--1 h1 {
  color: #888394;
  font-size: 20px;
  position: relative;
  left: 10px;
}

.playstore {
  width: 120px;
  height: 40px;
  border-radius: 5px;
  gap: 20px;
  display: flex;
  flex-direction: column;

}

.playstore img {
  width: 100%;
  height: 40px;
  overflow: hidden;
  background: #fff;
  border-radius: 5px;
}

.footer-card--12 {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  column-gap: 40px;
}

.footer-card--12--coupon {
  height: 40px;
  width: 250px;
  border: 1px solid #888394;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding-top: 15px;
}

.footer-card--12--1 h2 {
  color: white;
  width: 60%;
  font-size: 32px;
  font-weight: 400;
  padding-top: 20px;
}

.footer-card--12--img {
  width: 45%;
}

.footer-card--12--1 {
  width: 55%;
}

.footer-card--12--img img {
  height: 250px;
  width: 500px;
}

.footer-card--12--1--number {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
}

.footer-card--12--1--number input {
  background-color: #0c0522;
  color: white;
  border: none;
  outline: none;
}

.footer-card--12--1--number p {
  color: white;
  font-size: 14.5px;
  margin-top: 15px;
}

.footer-card--12--1--number p span {
  margin-bottom: -5px;
}

.footer-card--1--border-line {
  width: 100%;
  height: 0.3px;
  background-color: #2a213e;
}

.footer-card--1--border-line--1 {
  width: 100%;
  height: 0.3px;
  background-color: #2a213e;
}

.footer-content h3 {
  color: #fff;
  margin-left: 32px;
  font-size: 22px;

}

.footer-content--1 h3 {
  font-size: 22px;
  margin-left: 45px;
}



@media screen and (max-width: 640px) {

  .footer-card--1 {
    display: none;
  }

  .footer-card--12 {
    display: none;
  }

  .footer-content2 {
    margin: 0;
    padding: 0;

  }

  .menu-links {
    padding-top: 5px;
    display: flex;
    flex-direction: column;
    row-gap: 5px;

  }

  .footer-mobile-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
  }

  .footer-mobile-view {
    display: block !important;
    padding-top: 25px;
    justify-content: left;
  }

  .social-links {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;
    justify-content: center;
  }

  .logo1 img {
    width: 70px;
    height: 40px;
  }

  .menu-links-links {
    margin-bottom: 0px;
    font-size: 14px;
  }

  .footer-btm {
    margin-top: 10px;
    display: flex;
    color: white;
    font-size: 10px;
    flex-direction: column;
    justify-content: left;
    text-align: left;
    align-items: flex-start;
    padding-left: 20px;
    padding-right: 20px;
  }

  .footer-btm p {
    margin: 0;
    padding: 0;
  }

  .partner {
    margin-top: 1px;
    color: white;
    padding-top: 20px;
    padding-bottom: 15px;
    font-size: 14px;
    font-weight: 400;
    width: 100%;

    opacity: 30%;
  }

  .menu-links-links li {
    margin: 0;
    padding: 0;


  }

  .footer-card {

    width: 100%;
    height: auto;

    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid white;
    align-items: center;
  }

  .footer-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
  }

  .footer-content h3 {
    color: #fff;


  }

  .footer {
    width: 98%;
    height: auto;
    display: grid;
    margin-top: 20px;
    margin-bottom: 20px;
    grid-template-columns: 1fr 1fr;
  }

  .follow-header {

    margin: 0px !important;
    color: white;
    font-family: blanka;

  }

  .playstore-mobile {
    display: flex;
    justify-content: left;
    margin-top: 5px;

    padding-right: 20px;
  }

  .playstore-mobile img {
    width: 150px;
    height: 45px;
    overflow: hidden;
  }

  .footer-content ul li {
    color: white;
    font-size: 12px;
    cursor: pointer;
  }

  .footer-content ul {
    color: white;
    cursor: pointer;
  }

  .footer--content--1--2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;

  }

}

@media (min-width: 640px) {
  .footer-mobile-view {
    display: none !important;
  }

  .footer,
  .footer-card2 {
    display: visible !important;
  }
}


/**
Quick Call Footer
*/
.quick-call-footer {
  position: fixed;
  bottom: 50px;
  right: 10px;
  overflow: hidden;
  text-align: center;
  background-color: #fff;
  z-index: 999;
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  width: 50px;
  display: inline-block;
}

.f-label {
  display: none;
}

.f-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.f-col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.quick-call-footer .f-row a {
  font-size: 14px;
  padding: 8px 0;
  display: block;
  color: #000;
}

.quick-call-footer .f-row a img {
  width: 41px;
  margin-bottom: 10px;
}

.f-call {
  display: none;
}

@media (max-width: 767px) {
  .quick-call-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    text-align: center;
    background-color: #fff;
    z-index: 999;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
    width: 100%;
    display: block;
  }

  .f-label {
    display: inline-block;
  }

  .f-call {
    display: unset;
  }
}