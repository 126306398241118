@import "~react-image-gallery/styles/css/image-gallery.css";

.button-condition .addcart1 {
    width: 200px;
    height: auto;
    border: none;
    background-color: #17A2B8;
    padding: 5px 20px;
    color: white;
    border-radius: 5px;
    font-size: 20px;
    margin-left: 20px;

}

.button-condition .addcart11 {
    width: 200px;
    height: auto;
    border: none;
    background-color: #525252;
    padding: 5px 20px;
    color: white;
    border-radius: 5px;
    font-size: 20px;
    margin-left: 20px;

}

.club_category_name1 {
    width: 100%;
    height: auto;
    padding: 5px 10px;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.bid_button {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

}

@media (max-width: 640px) {
    .bid_button {
        width: 100%;
        height: auto;
        display: grid;
        grid-template-columns: 1fr 1fr;

    }

    .club_category_package_type {
        display: grid;
        grid-template-columns: 1fr !important;
    }

    .mobile_view {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;

        margin: auto;
    }

    .button-condition .addcart1 {
        width: 100%;
        height: auto;
        border: none;
        background-color: #17A2B8;
        padding: 5px 20px;
        color: white;
        border-radius: 5px;
        font-size: 20px;
        margin-left: 0px;
    }

    .club_category_package_type {
        margin-top: 10px;
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 7px;
        column-gap: 7px;
    }

    .button-condition {
        width: 100%;
        height: auto;

        display: flex;
        flex-direction: row;
        justify-content: center;
        justify-content: space-between !important;
        margin: auto;

        margin-bottom: 20px;
        gap: 20px;
    }

    .club_category_name1 {
        gap: 0px !important;
    }

    .button-condition .addcart {
        width: 100% !important;
        margin-left: 0px !important;
    }
}

.club-card-page {
    width: 100%;
    height: auto;
}

.club-page {
    width: 100%;
    height: auto;
    background-color: #0c0522;
}

.club-card {
    width: 95%;
    height: auto;
    margin: auto;
    background-color: #0c0522;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.dealstab-card {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #1F1E2E;
    padding: 10px;
}

.scondtab-part {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.club-left-page {
    width: 73%;
    height: auto;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
}

.club-aboutus-about {
    width: 100%;
    height: auto;
}

.club-aboutus-about span {
    background-color: #120829;
    border: none;
    color: #d71362;
    outline: none;
    position: relative;

}

.club-right-page {
    width: 25%;
    height: auto;
    display: flex;
    flex-direction: column;
}

.club-left-image img {
    width: 100%;
    height: 400px;
}

.club-name p img {
    width: 10px;
    height: 10px;
}

.club-name h1 {
    color: white;
}

.club-name p {
    color: white;
}

.club-tab-about-card {
    width: 100%;
    height: 400px;
    overflow: scroll;
}

.club-tab-about-card::-webkit-scrollbar {
    display: none;
}

.event-text-tablist::-webkit-scrollbar {
    width: 1px;
}

.event-text-tablist::-webkit-scrollbar-track {
    background-color: transparent;
}

.event-text-tablist::-webkit-scrollbar-thumb {
    border-radius: 10px;
}

.event-text-tablist {
    display: flex;
    overflow-x: auto;
    gap: 20px;
    padding: 10px;
    width: 100%;
    transition: transform 0.3s ease;
}

.details-page {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.select-offer {
    width: 100%;
    height: 300px;
    background-color: #1F1E2E;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    color: white;
}

.offer-text {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
}

.deals-tab-content1 {
    display: flex;
    flex-direction: row;
    column-gap: 15px;
}

.date-background {
    background-color: white;
}

.content1-tab-card {
    height: 60px;
    width: 90px;
    border-radius: 10px;
    border: 2px solid #d71362;
}

.content1-tab1-card {
    height: auto;
    width: 90px;
    border-radius: 20px;
    border: 2px solid #d71362;
}

.club-tab p {
    text-align: center;
    margin: 0;
    padding: 0;
}

.dealstab-card {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.dealstab-card img {
    width: 100%;
    height: 0px;
}

.clubmenu img {
    width: 100%;
    height: 900px;
    border-radius: 10px;
}

.dropdown-lable {
    background-color: none;
}

.package_images {
    width: 100%;
    height: auto;
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    gap: 10px;
    margin-bottom: 10px;


}



.package--web--view::-webkit-scrollbar {
    width: 1px;
}

.package--web--view::-webkit-scrollbar-track {
    background-color: transparent;
}

.package--web--view::-webkit-scrollbar-thumb {
    border-radius: 10px;
}
.package--web--view{
    width: 100%;
    height: auto;
    padding: 20px;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    gap: 10px;
    margin-bottom: 10px;
}
.package_images1 {
    width: 100%;
    height: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;



}

.pkg_img {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: row;
    overflow: scroll;
    gap: 10px;
}

.pkg_img::-webkit-scrollbar {
    display: none;
}

.pkg_img1 img {
    width: 100% !important;
    height: 100% !important;

}


.pkg_img1 p {
    width: 100%;
    display: flex;
    justify-content: center;
}

.pkg_img img {
    width: 340px !important;
    height: 250px;
    border-radius: 5px;

}

.pkg_img p {
    width: 100%;
    display: flex;
    justify-content: center;
}

.pkg_img_name {
    width: 100%;
    height: 350px;
    border-radius: 5px;
    background-color: white;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.pkg_img_name_card {
    flex: 0 0 230px;
    height: 350px;
    border-radius: 5px;
    background-color: white;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.pkg_img_name_card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
    cursor: pointer;
}
.pkg_img_name_card p {
    width: 100%;
    height: 66px;
    cursor: pointer;
    margin: 0;padding: 0;
    padding: 5px 10px;
}
.pkg_img_name_card p span {
    color: rgb(157, 157, 157);
    font-size: 12px;
    margin: 0;padding: 0;
}
.pkg_img_name img {
    width: 100%;
    height: 280px;
    object-fit: cover;
    opacity: 1;
    cursor: pointer;
}

.pkg_img_name p span {
    color: rgb(157, 157, 157);
    font-size: 12px;
}

.pkg_img_name p {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.replica-page {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    background-color: #1d1d35;

}

.button-condition {
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: row;
    justify-content: center;

    margin: auto;

    margin-bottom: 20px;
    gap: 20px;
}

.button-condition .accept {
    width: 150px;
    height: 42px;
    border: none;
    background-color: #d71362;
    padding: 5px 20px;
    color: white;
    border-radius: 5px;
    font-size: 20px;

}

.button-condition .accept1 {
    width: 150px;
    height: 42px;
    border: none;
    background-color: #525252;
    padding: 5px 20px;
    color: white;
    border-radius: 5px;
    font-size: 20px;

}

.button-condition .reject {
    width: 150px;
    height: 42px;
    border: none;
    background-color: #DC3545;
    padding: 5px 20px;
    color: white;
    border-radius: 5px;
    font-size: 20px;


}

.button-condition .reject1 {
    width: 150px;
    height: 42px;
    border: none;
    background-color: #525252;
    padding: 5px 20px;
    color: white;
    border-radius: 5px;
    font-size: 20px;


}

.button-condition .addcart {
    width: 160px;
    height: 42px;
    border: none;
    background-color: #17A2B8;
    padding: 5px 20px;
    color: white;
    border-radius: 5px;
    font-size: 20px;
    margin-left: 20px;

}

.list_views {
    width: 90%;
    height: auto;
    margin-bottom: 20px !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: auto;
    border-bottom: 1px solid gray;
    padding: 10px;
}

.ratting-profile-image img {
    width: 50px;
}

.ratting-profile-image {
    width: 10%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.ratting-profile-content h3 {
    font-size: 19px;
}

.ratting-profile-content p {
    font-size: 15px;
}

.ratting-profile-time p {
    font-size: 15px;
}

.ratting-profile-content {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.ratting-profile-time {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.ratting-profile-time p {
    width: 100%;
    text-align: end;
}

.writeareview button {
    width: 150px;
    height: 50px;
    border-radius: 5px;
    background-color: #d71362;
    border: none;
    align-items: center;
    color: white;
}

.writeareview {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.review_list .h_rating {
    width: 100%;
    border-bottom: 1px solid white;
}

.r_img_list {
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.r_img_list img {
    width: 80px;
    height: 100px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    border-radius: 5px;
}

.club_category_package_type::-webkit-scrollbar {
    width: 1px;
}

.club_category_package_type::-webkit-scrollbar-track {
    background-color: transparent;
}

.club_category_package_type::-webkit-scrollbar-thumb {
    border-radius: 10px;
}

.club_category_package_type {
    margin-top: 20px;
    display: grid;

    width: 100%;
    height: auto;
    transition: transform 0.3s ease;

    grid-template-columns: 1fr 1fr 1fr;

}

.club_category_card {
    height: auto;
    width: 200px;

    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;

}

.club_category_card:hover {
    transform: scale(1.05);
}

.club_category_card p {
    color: white;
    font-size: 14px;
    text-align: center;

    border: 1px solid #d71362;
    border-radius: 20px;
    padding: 10px;

}

.club_package_card_image {
    width: 100%;
}

.carousel11 {
    position: relative;
    width: 23rem;
    height: 23rem;
    perspective: 500px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.card-container11 {
    position: absolute;
    width: 100%;
    height: 100%;

    transition: all 0.3s ease-out;
    opacity: var(--opacity);

}

.card11 {
    width: 100%;
    height: 100%;

    overflow: hidden;

    border-radius: 5px;

    text-align: justify;

    border: 1px solid rgba(129, 129, 129, 0.508);
    margin: auto;
    margin-left: 13px;

}

.card11 h4 {
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;

    color: #d71362;
}

.card11 p {
    text-align: start;
    font-size: 1rem;

    padding-left: 10px;
    margin: 0 0 0.7em;
    color: #d71362;
}

.card11 p,
.card11 h4 {
    transition: all 0.3s ease-out;
    opacity: var(--active);
}

.nav11 {
    color: #d71362;
    font-size: 2rem;
    position: absolute;
    top: calc(50% - 1.5rem);
    z-index: 2;
    cursor: pointer;
    user-select: none;
    background: none;
    border: none;
}

.nav11 i {
    vertical-align: middle;
}

.nav11.left11 {
    left: -1rem;

}

.nav11.right11 {
    right: -2.6rem;

}

.card11 img {
    width: 100%;
    height: 200px;
}

.card11 button {
    width: 100px;
    height: 40px;
    border: none;
    border-radius: 5px;
    color: white;
    background-color: #d71362;
    float: right;
    margin-right: 20px;
}

.resion_text {
    border: none;
    border-radius: 3px;
    background-color: #d71362;
    color: white;
    float: right;
}

.club-aboutus-page--discount {
    height: 280px;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    background-color: #0c0522;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.8);
    border-radius: 5px;
    padding-left: 25px;
    padding-right: 25px;
}

.club-aboutus-page--discount1 {
    height: 280px;
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.club-aboutus-page--discount button {
    border: none;
    background-color: #0c0522;
    text-align: left;
    color: #d71362;
    position: relative;
    top: -10px;
    left: -8px;
    font-size: 15px;
}

.club-aboutus-page--discount21 {
    height: 200px;
    width: 300px;
    background-color: #1F1E2E;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    justify-content: center;
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
}

.club-aboutus-about--offers--coupon {
    height: 70px;
    width: 100%;
    background-color: #1F1E2E;
    margin-bottom: 25px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.8);
}

.club-aboutus-page--discount21 h2 {
    font-size: 18.5px;
}

.club-aboutus-page--discount21 h2 span {
    font-size: 12px;
}

.club-aboutus-page--discount1 h2 {
    font-size: 23px;
}

.club-aboutus-page--discount1 p {
    font-size: 15px;
}

.club-aboutus-page--discount21--border {
    border: 1px solid grey;
    margin-top: -15px;
}

.club-aboutus-page--discount21--bill--calc {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.club-aboutus-page--discount21--bill--calc1 {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
}

.club-aboutus-page--discount2 {
    height: 280px;
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.club-aboutus-page--discount11 {
    display: flex;
    flex-direction: column;
    column-gap: 15px;
}
.mobile--view--book--a--table--button{
    /*display: none;*/
    
}
.club-aboutus-page--discount21--bill--calc p {
    margin: 0;
    padding: 0;
    font-size: 13.5px;
}

.club-aboutus-page--discount21--border-ddashed {
    border: 1px dashed gray;
}

.club-aboutus-about--offers--coupon {
    display: none;
    flex-direction: row;
    padding-left: 15px;
    padding-right: 15px;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
}
.custom-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 560px;
    height: 560px;
    background-color: var(--background-paper);
    border: 0.5px solid #fff;
    border-radius: 20px;
    box-shadow: 24px 24px 48px rgba(0, 0, 0, 0.12);
    padding: 1rem;
    background-color: #fff;
    overflow: hidden; /* Ensure the content doesn't overflow the modal */
  }
 

  
  .custom-carousel {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
  
  .carousel-slide {
    scroll-snap-align: start;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .carousel-image {
    max-width: 100%;
    height: auto; /* Ensure images resize within their container */
    object-fit: contain; /* Fit image within container while maintaining aspect ratio */
  }
  

.club-aboutus-about--offers--coupon1 {
    width: 30%;
    margin-top: 6px;
    display: none;
}

.club-aboutus-about--offers--coupon2 {
    width: 70%;
    margin-top: 13px;
}

.club-aboutus-about--offers--coupon2 p {
    font-size: 19px;
}

.club-tab-about-card {
    margin-top: 20px;
}

.deals--card--1 {
    margin-top: 20px;
}

.club--rating--1 {
    margin-top: 20px;
}
.book--a--table--menu--page--menu--card{
    display: none;
}

.book--a--table--menu--page--menu--card--Mobile--view {
    display: none;
}

.event--mobile--view--club--about--us {
    display: none;
}

.reccomended--club--mobile--view {
    display: none;
}
/* .club--about--us--video--frames{
    display: none;
} */
.club--about--us--video--frames {
    display: flex;
    flex-direction: row;
    column-gap: 15px;
    margin-top: 30px;
}


.club--about--us--video--frames--card img {
    width: 197px;
    height: 230px;
    flex-shrink: 0;
    border-radius: 5px;
}
.club--about--us--reserve--booking--table {
    width: 28%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(129, 129, 129, 0.508);
    border-radius: 5px;
    padding: 10px;
    padding-top: 20px;
    margin-top: 35px;
 
  }
.club--about--us--video--frames--card button {
    height: 38px;
    width: 160px;
    background-color: #cc0c39;
    border: none;
    outline: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: white;
    font-size: 11px;
    position: relative;
    top: -20px;
}


.club--about--us--video--frames {
    display: flex;
    overflow-x: auto;
    gap: 12px;
   max-width: 100%;
    transition: transform 0.3s ease;
}
.club--about--us--video--frames::-webkit-scrollbar {
    width: 1px;
}

.club--about--us--video--frames::-webkit-scrollbar-track {
    background-color: transparent;
}
.club--about--us--video--frames::-webkit-scrollbar-thumb {
    border-radius: 10px;
}

.club-tab-about-card {
    width: 100%;
    height: auto;
    overflow: hidden;
}
.club-thumbnail-content11--1{
    position: relative;
    top: 50px;
}


@media (max-width: 640px) {
    .custom-box {
        position: absolute;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
         width: 85%;
         height: 450px;
         background-color: var(--background-paper);
         border: 0.5px solid #fff;
         border-radius: 20px;
         box-shadow: 24px 24px 48px rgba(0, 0, 0, 0.12);
         padding: 1rem;
         background-color: #fff;
       }
       .carousel-image{
        display: inline-block; /* Ensure images are in a row */
        object-fit: contain;
        height: 100%; /* Ensure images fill the carousel height */
        scroll-snap-align: center;
      }
      .custom-carousel {
        width: 100%;
        height: 100%;
        overflow-x: auto; /* Enable horizontal scrolling */
        white-space: nowrap; /* Ensure items stay in a single row */
        scroll-snap-type: x mandatory;
      }
    .club-aboutus-about {
        width: 100%;
        height: auto;
    }
    .club-tab-about-card {
        margin-top: 10px;
        background-color: #0c0522;
        border-radius: 5px;
    }
    .club-aboutus-about--offers--coupon {
        display: none;
    }
    .club-aboutus-page--discount {
        display: none;
    }
    .club--about--us--reserve--booking--table{
    display: none;
    }
    .deals--card--1 .club--rating--1 {
        margin-top: 10px;
    }
    .package_images {
        width: 100%;
        height: auto;
        padding: 20px;
        display: flex;
        flex-direction: row;
        column-gap: 10px;
    }
    .package_images::-webkit-scrollbar {
        width: 1px;
    }
    .package_images::-webkit-scrollbar-track {
        background-color: transparent;
    }
    .package_images::-webkit-scrollbar-thumb {
        border-radius: 10px;
    }
    .package_images {
        display: flex;
        overflow-x: auto;
        gap: 15px;
        max-width: 100%;
        transition: transform 0.3s ease;
    }

    .pkg_img_name {
        display: flex;
        flex-direction: column;

    }

    .pkg_img_name img {
        height: 160px;
        width: 150px;
    }

    .pkg_img_name {
        flex: 0 0 150px;
        height: 200px;
        border-radius: 5px;
        overflow: hidden;
        transition: transform 0.2s;
        background-color: #fff;
    }

    .club--about--us--mobile--view {
        padding-left: 15px;
        padding-right: 15px;
    }

    .club--abou--us--border--line {
        width: 100%;
        height: 1px;
        opacity: 0.3;
        background: #FFF;
        margin-top: 15px;
    }

    .club--about--us--video--frames--card {
        width: 120px;
        height: 165px;
        flex-shrink: 0;
        border-radius: 5px;
        border: 1px solid transparent;
        display: flex;
        flex-direction: column;

        justify-content: center;
        align-items: center;

    }
   

    .club--about--us--video--frames--card img {
        width: 120px;
        height: 130px;
        border-radius: 5px;
    }

    .club--about--us--video--frames--card button {
        height: 30px;
        width: 120px;
        background-color: #cc0c39;
        border: none;
        outline: none;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        color: white;
        font-size: 12px;
        position: relative;
        top: -10px;
    }
    
    
    .pkg_img_name_card {
        flex: 0 0 150px;
        height: 200px;
        border-radius: 5px;
        background-color: white;
        color: black;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }
    
    .club--about--us--video--frames {
        display: flex;
        flex-direction: row;
        column-gap: 15px;
    }

    .club--about--us--video--frames::-webkit-scrollbar {
        width: 1px;
    }

    .club--about--us--video--frames::-webkit-scrollbar-track {
        background-color: transparent;
    }
    .club-tab-about-card {
        width: 100%;
        height: auto;
        overflow: hidden;
    }
    .club-thumbnail-content11--1{
        position: relative;
        top: 50px;
    }
    .club--about--us--video--frames::-webkit-scrollbar-thumb {
        border-radius: 10px;
    }


    .club--about--us--video--frames {
        display: flex;
        overflow-x: auto;
        gap: 12px;

        max-width: 100%;
        transition: transform 0.3s ease;
    }

    .menu--mobile--select {
        height: 40px;
        width: 150px;
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        justify-content: center;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
    }
    .menu--mobile--select--1 {
        height: 40px;
        width: 150px;
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        justify-content: center;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
    }
    .pkg_img_name_card p {
        height: 55px;
        font-size: 12px;
        margin: 0;padding: 0;
        padding: 5px;
    }

    .menu--mobile--select p {
        margin: 0;
        padding: 0;
    }
    .menu--mobile--select--1 p {
        margin: 0;
        padding: 0;
        font-size: 12px;
    }
    .reccomended--club--mobile--view {
        padding-left: 15px;
        padding-right: 15px;
    }

    .recommended-club h2 {
        color: #FFF;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .h2 {
        margin-bottom: 10px;
        margin-top: 10px;
        font-size: 15px;
    }

    .event--mobile--view--club--about--us {
        display: block;
        padding-left: 20px;
        padding-right: 20px;
    }

    .details-page p {
        color: #FFF;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .rating--name--view {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
    }

    .club_category_card {
        height: auto;
        width: 150px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
        transition: transform 0.2s ease;
    }

    .club_category_card p {
        color: white;
        font-size: 12px;
        text-align: center;
        border: 1px solid #d71362;
        border-radius: 20px;
        padding: 10px;
    }

    .pkg_img {
        width: 100%;
        height: 200px;
        display: flex;
        flex-direction: row;
        overflow: scroll;
        gap: 10px;
    }

    .package_images1 {
        width: 100%;
        height: 200px;
    }

    .club-aboutus-about p {
        font-size: 11px;
    }
    /* .package--web--view{
        display: none;
    } */

    .club-tab-about-card {
        padding: 3px 10px;
    }
    
    .book--a--table--menu--page--menu--card--Mobile--view {
        display: block;
    }

    .reccomended--club--web--view {
        display: none;
    }

    .reccomended--club--mobile--view {
        display: block;
    }


    .mobile--view--book--a--table--button {
        display: block;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 0px 15px;
        height: 50px;
        background-color: #1EBE92;
        color: white;
        font-size: 15px;
        border: none;
        outline: none;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        z-index: 1000;
    }


    .mobile--view--book--a--table--button button {
     width: 100%;
        height: 50px;
        background-color: #1EBE92;
        color: white;
        font-size: 15px;
        border: none;
        outline: none;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    .reserve--table--booking--card--1{
        display: none;
    }
}

.discount-cupons {
    padding: 5px;

    border-radius: 5px;
    background-color: #0c0522;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: monospace;
}

.Addonvalue {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.addon_value h1 {
    border-bottom: 2px dotted black;
}

.input--with--calculate input {
    width: 100%;
}

.addon_value button {
    border: none;
    background-color: #d71362;
    border-radius: 5px;
    color: white;
    padding: 5px 10px;
}

.input--with--calculate img {
    cursor: pointer;
}

.accept--addon {
    width: 100%;
    border: none;
    border-radius: 2px;
    background-color: #1EBE92;
    color: white;
}

.accept--addon1 {
    width: 100%;

    border-radius: 5px;
    background-color: #ffffff;
    border: 1px solid #1976D2;
    color: #1976D2;
}

.extra--addon--border .extrapercentage {
    margin: auto;
    margin-top: 4px;
    font-size: 20px;
}

.totalpercentage {
    width: 100%;
    height: auto;
    border: 1px solid white;
    margin-top: 5px;
    border-radius: 3px;
    padding: 5px;
    color: white;
}

.addon_value label span {
    font-weight: 400;
}

.addon_value {
    padding: 20px;

    background-color: #D32A62;
    border-radius: 2px;
    color: white;
}


.nav_btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    z-index: 4;
    border: none;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background: #ffffff56;
}

.nav_btn>svg {
    fill: #000;
    width: 100%;
    height: 100%;
    position: relative;
}

.nav_btn_left {
    left: 0;
}

.nav_btn_right {
    right: 0;
}



.carousel .control-dots {
    z-index: 3;
}

.ind {
    width: 20px;
    height: 20px;
    list-style: none;
    border: 4px solid #f5f5f5;
    transform: rotate(135deg);
    display: inline-block;
    cursor: pointer;
    position: relative;
    z-index: 100;
}

.ind.active {
    background-color: #000;
    transition: 500ms 200ms;
}

.ind:not(:last-child) {
    margin-right: 1.4rem;
}

.carousel .carousel-status {
    left: 0;
    right: 0;
    text-align: center;
    color: #000;
    font-size: 1.4rem;
    text-shadow: none;

    text-transform: uppercase;
    font-weight: 700;
}

.box {
    max-width: 500px;
    margin: auto;
}

.carousel .thumbs-wrapper {
    display: none;
}

.carousel .control-dots {
    display: none;
}

.reviewformpage {
    width: 100%;
    height: auto;
}

.reviewformpage h2 {
    padding-left: 20px;
}

.replica_card_page {
    width: 300px !important;
}

@media (max-width: 640px) {
    .replica_card_page {
        width: 100% !important;
    }

    .button-condition .reject {
        width: 100%;
        height: 42px;
        border: none;
        background-color: #DC3545;
        padding: 5px 20px;
        color: white;
        border-radius: 5px;
        font-size: 20px;


    }

    .button-condition .accept {
        width: 100%;
        height: 42px;
        border: none;

        padding: 5px 20px;
        color: white;
        border-radius: 5px;
        font-size: 20px;


    }

    .bid_button {
        gap: 20px;
    }

    .addon_value {
        width: 100% !important;
        height: auto;
        display: flex;
        flex-direction: column;

    }

    .Addonvalue {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }

    .addondiscountpage {

        width: 100%;
        margin: auto;
        padding: 10px;
    }

    .resion_text {
        width: 30%;
        padding: 5px 10px;
        border: none;
        border-radius: 3px;
        background-color: #d71362;
        color: white;
        float: right;
    }

    .book--a--table--menu--page {
        margin-top: 10px;
    }

    .book--a--table--menu--page--menu--card button {

        height: 35px;
        width: 230px;
        background-color: #525252;
        color: white;
        border: none;
        outline: none;
        border-radius: 4px;
        font-size: 10px;
        margin-bottom: 12px;
    }
    .book--a--table--menu--page--menu--card-1 button {

        height: 35px;
        width: 230px;
        background-color: #525252;
        color: white;
        border: none;
        outline: none;
        border-radius: 4px;
        font-size: 10px;
        margin-bottom: 12px;
    }
    .book--a--table--menu--page--menu--card {
        background-color: #0c0522;
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .book--a--table--menu--page--menu--card--5 {
        background-color: #0c0522;
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
       
    }

    .book--a--table--menu--page--menu--card--1 {
        background-color: #0c0522;
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 10px;

    }

    .book--a--table--menu--page--dropdown span {
        font-size: 20px;
        display: flex;
        justify-content: center;
    }

    .book--a--table--menu--page--dropdown {
        position: relative;
        top: -10px;
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        justify-content: center;
        align-items: center;
    }


}

.addonheadersection {
    width: 100%;
    height: 50px;
    background-color: #000;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: white;
    padding: 0px 10px;
}

.crosspage {
    cursor: pointer;
}

.addonheader {
    padding: 16px;
}

.nodata--found {
    width: 100%;
    height: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.club--package--replica--page {
    width: 95%;

    margin-top: 40px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;

    justify-content: space-between;
}

.club--selected--package {
    width: 28%;
    height: 250px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: white;
    color: black;
    border-radius: 10px;
    border: 1px solid white;
}

.club--package--accept--reject {
    width: 55%;
    height: 215px;

    border-radius: 5px;

    display: flex;
    flex-direction: row;
    margin: auto;
    margin-left: 0px;
}

.club--selected--package img {
    width: 100%;
    height: 100%;
}

.club--selected--package p {
    font-size: 14px;
    font-weight: 550;
    height: 10px;
    display: flex;
    flex-direction: row;

    align-items: center;

}

.club--selected--package p img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    margin-left: 10px;
}

.timeprogressbaar1 {
    width: 45%;
    height: 100%;
    display: flex;
    justify-content: center;

}

.club--package--accept--reject--text {
    height: 80%;
    display: flex;
    flex-direction: column;


}

.club--package--accept--reject--text h4 {
    font-size: 20px;
    font-weight: 300;
}

.club--package--accept--reject--text h4 span {
    font-size: 20px;
    font-weight: 600;
    color: #FFAC06;
}

.club--package--accept--reject--text p span {
    font-size: 14px;
    font-weight: 500;
    color: #008AD8;
}

.club--package--accept--reject--text p {
    width: 100%;
    height: auto;

    font-size: 11px;
    color: #bababa;

    justify-content: center;


}

@media (max-width: 640px) {
    .club--package--replica--page {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
    }

    .club--selected--package {
        width: 100%;
        height: 350px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        background-color: white;
        color: black;
        border-radius: 5px;
        margin-bottom: 20px;
    }

    .club--package--accept--reject {
        width: 100%;
        height: 350px;

        border-radius: 5px;
        border: none !important;
        display: flex;
        flex-direction: column;
    }

    .club--selected--package img {
        width: 100%;
        height: 100%;
    }

    .club--selected--package p {
        height: 10px;
        display: flex;
        flex-direction: row;

        align-items: center;

    }

    .club--selected--package p img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        margin-left: 10px;
    }

    .timeprogressbaar1 {
        width: 100%;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .club--package--accept--reject--text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }

    .club--package--accept--reject--text p {
        width: 80%;
        height: auto;
        margin: auto;
        text-align: center;
        font-size: 14px;
        color: #bababa;

        justify-content: center;
        align-items: center;

    }

}