.New--thankew--page--card--start {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.New--thankew--page--card--start--1 {
    height: auto;
    width: 340px;
    border-radius: 7px;
    background: #1B1528;
    display: flex;
    flex-direction: column;
    row-gap: 13px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    margin-top: 35px;
}

.New--thankew--page--card--start--1--img {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.New--thankew--page--card--start--1--img img {
    height: 220px;
    width: 220px;
}

.New--thankew--page--card--start--order {
    color: #008AD8;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    padding: 0;
}

.New--thankew--page--card--start--order1 {
    color: #E4E4E4;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
    padding: 0;
}

.New--thankew--page--card--start--people--p--user {
    color: #FFF;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
    padding: 0;
}

.New--thankew--page--card--booking {
    color: #1EBE92;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
    padding: 0;
}

.New--thankew--page--card--booking--date {
    color: #FFF;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.New--thankew--page--card--start--view--button {
    width: 180px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid #008AD8;
    background: #1B1528;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.New--thankew--page--card--start--view--button p {
    margin: 0;
    padding: 0;
    color: #008AD8;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.28px;
}

.New--thankew--page--card--start--people {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.New--thankew--page--card--start--people--p {
    color: #FFF;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    padding: 0;
}

.New--thankew--page--card--payment--upi {
    color: #008AD8;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    padding: 0;
}

.New--thankew--page--card--payment--total {
    color: #FFF;
    text-align: justify;
    font-family: Karla;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    padding: 0;
}