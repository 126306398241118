.Container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inputContainer{
  background: #fff;
  width: 530px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-top: 30vh;
}
.inputContainer span{
  font-size: 21px;
  padding: 20px;
  color: #7D7878;
}
.inputContainer input {
  border: none;
  outline: none;
  width: inherit;
  height: inherit;
  background: #fff;
  border-radius: 10px;
  font-size: 20px;
  color: #121212;
}
.categoryContainer{
  display: flex;
  gap: 20px;
  
}
.cateBTN{
  border: none;
  outline: none;
  padding: 14px 25px;
  border: 1px solid#E5E7F3;
  background: transparent;
  border-radius: 6px;
  color: #fff;
}
.cateBTN.active{
  background: #FD2F711A;
  border: 0.3px solid #FD2F71;
}
#basetext{
  color:#FFFFFF;
  opacity: 30%;
  margin-top: 1rem;
  text-transform: capitalize;
  text-align: center;
}

.renderResults{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

@media screen and (min-width: 600px) {
  .inputContainer{
    width: 400px;
    margin-top: 10vh;
  }
}

@media screen and (max-width: 600px) {
  .inputContainer{
    width: 300px;
    margin-top: 10vh;
  }
}