.upper {
    text-transform: uppercase;
}

.my-booking-card {
    padding: 50px 150px;
}

.booking-history-card {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}

.my-booking-card h1 {
    color: #fff;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 30px;
    letter-spacing: 3px;
}

.web-view-img {
    height: 150px;
    /*width: 25%;*/
}

.web-view-img img {
    width: 145px;
    height: 145px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.web--view--Notification--bar--heading--and--cross--card1--1 {
    min-height: 150px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 30px;
}

.web--view--Notification--bar--heading--and--cross--card1 {
    width: 100%;
    min-height: 150px;
    background: #120829;
    border: 1px solid grey;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    column-gap: 30px;
}

.web--view--notification--1--p {
    font-size: 14px;
    font-family: "Open sans", sans-serif;
    color: #fff;
    opacity: 0.5;
    margin: 0;
    padding: 0;
    letter-spacing: 1px;
}

.web--view--notification--1--p1 {
    font-size: 16px;
    font-family: "Open sans", sans-serif;
    color: #fff;
    margin: 0;
    padding: 0;
    letter-spacing: 2px;
}

.transaction-history-card {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding: 10px;
    width: 60%;
}

.web--view--Notification--bar--heading--and--cross--card2 {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

@media (min-width:601px) and (max-width: 1100px) {
    .my-booking-card {
        padding: 70px 30px;
    }

    .css-a6ayad {
        padding: 0 !important;
        margin-top: 30px;
    }

    .web--view--notification--1--p {
        font-size: 13px;
        font-family: "Open sans", sans-serif;
        color: #fff;
        opacity: 0.5;
        margin: 0;
        padding: 0;
        letter-spacing: 0.5px;
    }

    .web--view--notification--1--p1 {
        font-size: 15px;
        font-family: "Open sans", sans-serif;
        color: #fff;
        margin: 0;
        padding: 0;
        letter-spacing: 1px;
    }
}

@media (max-width:600px) {
    .web--view--Notification--bar--heading--and--cross--card1 {
        display: block;
    }

    .my-booking-card {
        padding: 70px 10px;
    }

    .css-a6ayad {
        padding: 0 !important;
        margin-top: 30px;
    }

    .web--view--notification--1--p {
        font-size: 10px;
        font-family: "Open sans", sans-serif;
        color: #fff;
        font-weight: 300;
        opacity: 0.5;
        margin: 0;
        padding: 0;
        letter-spacing: 0.5px;
    }

    .web--view--notification--1--p1 {
        font-size: 13px;

        font-family: "Open sans", sans-serif;
        color: #fff;
        margin: 0;
        padding: 0;
        letter-spacing: 1px;
    }

    .my-booking-card h1 {
        color: #fff;
        text-align: center;
        font-family: "Open Sans", sans-serif;
        font-size: 20px;
        letter-spacing: 3px;
        margin-bottom: 20px;
    }

    .web-view-img {
        height: 150px;
        width: 35%;
    }

    .web-view-img img {
        height: 145px;
        width: auto;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    .web--view--Notification--bar--heading--and--cross--card1--1 {
        min-height: 150px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding-top: 8px;
    }

    .transaction-history-card {
        display: flex;
        flex-direction: column;
        row-gap: 3px;
    }

}