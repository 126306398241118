.border--box--1{
  height: auto;
  width: 700px;
  background-color: #0b051c;
  border-radius: 10px;
  padding: 20px;
}
.main--box--1{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
.form-group select {
  border-radius: 5px;
  width: 100%;
   height: 45px;
   margin-top: 7px;
   padding-left: 8px;
   color: #909090;
}
.row p{
  font-size: 14px;
  color: #909090;
}
.title--name{
  display: flex;
  justify-content: center;
  align-items: center;
}
.title--name h2{
  color: #fff;
  font-size: 25px;
  font-weight: 500;
  letter-spacing: 1px;
  font-family: "open sans",sans-serif;

}
.col--1--2 select{
  outline: none;
  width: 75px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 0.7px solid rgba(0, 0, 0, 0.30);
  background-color: #FFF;
  color: #000;
  margin-top: 7px;
  padding-left: 3px;
}
.wati--terms-and-condition{
  display: flex;
  flex-direction: row;
  column-gap: 10px;
   justify-content: center;
}
.wati--terms-and-condition p{
  margin: 0;padding: 0;
  font-size: 12px;
  color: white;
}

.wati--terms-and-condition p span{
  margin: 0;padding: 0;
  font-size: 12px;
  color: #008AD8;
}
.col--1--2-1 p{
  font-size: 15px;
  color: #909090;
}

.col--1--2 input{
  border-radius: 5px;
  width: 80%;
   height: 45px;
   margin-top: 7px;
   color: #909090;
}

.col--1--2 button{
  height: 45px;
  margin-top: 7px;
  background-color: #cdcdcd;
  width: 20%;
  color: white;
  border: none;
  border-radius: 5px;
}
.col--1--2-1{
  display: flex;
  flex-direction: row;
  column-gap: 6px;
  margin-top: 10px;
}
.col--1--2-1 p{
margin: 0;padding: 0;
} 
.col--1--2-1 input{
position: relative;
top: -10px;
}
.col--1--2{
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}
.form-control-1{
  margin-top: 10px;
  height: 30px;
  width: 150px;
  border-radius: 3px;
  border: none;
}
.form-group1{
  width: 50%;
}
.col-2-3{
  display: flex;
  flex-direction: row;
  column-gap: 20px;
}
.form-group1 select {
  border-radius: 5px;
  width: 100%;
  height: 45px;
  margin-top: 7px;
  padding-left: 8px;
  color: #909090;
}
@media(max-width:640px){
  .form-group1{
    width: 100%;
  }
  .col-2-3{
    display: flex;
    flex-direction: column;
    
  }
}
