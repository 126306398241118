.CardContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0;
    color: #fff;
    margin-left: 40px;
    /* margin-top: 60px; */
    /* width: 100%;
    padding-bottom: 20px;
    overflow-x: scroll; */

  }
  .CardContainer::-webkit-scrollbar {
  width: 6px; /* width of the scrollbar */
  height: 3px; /* height of the scrollbar */
  background-color: #F5F5F5;
}

.CardContainer::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #F5F5F5;
}

.CardContainer::-webkit-scrollbar-thumb {
  background-color: #000000;
}
  
  

.Menucontent{
    color: #fff;
    display: flex;
    gap: 20px;
}
.imageContainer{
    position: relative;
}
.MaskTag{
    background: linear-gradient(90deg, #000000 0%, rgba(55, 55, 55, 0) 105.77%);
    position: absolute;
    top:-10px;
    text-transform: capitalize;
    font-weight: 600;
}

.infoContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: .6rem;
}

.infoContainer h2{
    font-size: 18px;
    text-transform: capitalize;
}
#clubName{
    opacity: 70%;
}
#seatLeft{
    color: #FE262F;
    font-size: 14px;
}
.rightContent{
    text-align: end;
}
.rightContent button{
    border: none;
    outline: none;
    padding: .7rem .8rem;
    border-radius: 4px;
    text-transform: capitalize;
    background: #1F1F21;
    color: #fff;
    border: 1px solid #fff;
}

@media screen and (max-width: 768px) {
    .CardContainer {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 1.2rem;
        color: #fff;
        margin-left: 20px;
        margin-top: 60px;
        width: 95%;
        overflow-x: scroll;
      }
}

