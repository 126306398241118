.live-music-card-content{
    height: 250px;
    width: 350px;
    border: 1px solid #fff;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    margin-bottom: 50px;
}
.ArtistCard-image{
    background-color: #141420;
    background-image: 
        linear-gradient(rgba(20, 20, 32, 0.6), rgba(20, 20, 32, 0.6)), 
        url('./image/landing.jpg');
        background-size: cover;
        background-repeat: no-repeat;
         width: 100%;
        height: auto;
    
}
.live-music-card-content:hover .live-music-card-content-1 {
    bottom: 0;
}
.Artist-card-font h4{
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    font-family: "Open sans",sans-serif;
    letter-spacing: 3px;
    margin: 0;padding: 0;
    margin-bottom: 10px;
    margin-top: 20px;
}
.Artist-card-font-trending h4{
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    font-family: "Open sans",sans-serif;
    letter-spacing: 3px;
    margin: 0;padding: 0;
    margin-bottom: 10px;
    margin-top: 20px;
}
.live-music-card-p{
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    font-family: "Open sans",sans-serif;
    letter-spacing: 1.5px;
    margin: 0;padding: 0;
    margin-bottom: 5px;
    opacity: 0.6;
}
.live-music-card-content-1 p{
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    font-family: "Open sans",sans-serif;
    letter-spacing: 2px;
    margin: 0;padding: 0;
}
.live-music-card-content-h p{
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    font-family: "Open sans",sans-serif;
    letter-spacing: 2px;
    margin: 0;padding: 0;
}
.live-music-card-content-1 button{
    height: 30px;
    width: 80px;
    background-color: #d71362;
    background-image: linear-gradient(to right, #fe2944, #fd2d65);
    color: #fff;
    border: none;
    font-size: 10px;
    font-family: "Open Sans",sans-serif;
    border-radius: 5px;
    
}
.live-music-geners button{
    height: 30px;
    width: auto;
    background-color: #d71362;
    background-image: linear-gradient(to right, #fe2944, #fd2d65);
    color: #fff;
    font-size: 10px;
    border: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.live-music-card-content img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.Artist-card{
    padding-left: 50px;
    padding-right: 50px;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
}
.live-music-card-content-1{
    position: absolute;
    bottom: -50px;
    height: 50px;
    width: 100%;
    background: rgba(0, 0, 0, 0.582);
    transition: bottom 0.3s ease; 
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
}
.live-music-card-content-h{
    position: absolute;
    bottom: -50px;
    height: 50px;
    width: 100%;
    background: rgba(0, 0, 0, 0.582);
    transition: bottom 0.3s ease; 
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
}
.live-music-geners {
    position: absolute;
    top: 0;
    height: 170px;
    right: -130px;
    transition: right 0.3s ease; 
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-top: 30px;
}
.live-music-all-card::-webkit-scrollbar {
    width: 100%;
    height: 7px;
}
.Percussion-card-content:hover .live-music-card-content-1 {
    bottom: 0;
}
.Percussion-card-content2:hover .live-music-card-content-h {
    bottom: 0;
}
.Percussion-card-content2:hover .live-music-card-content-1 {
    bottom: 0;
}
.Percussion-card-content2:hover .live-music-geners {
    right: 0;
}
.live-music-card-content:hover .live-music-geners {
    right: 0;
}
.Percussion-card-content:hover img {
    transform: scale(1.05); 
}
.Percussion-card-content2:hover img {
    transform: scale(1.05); 
}
.live-music-all-card::-webkit-scrollbar-track {
    background: #241448;
    border-radius: 10px;
}
.live-music-all-card::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #d71362; 
   
    transition: background-color 0.3s ease;
}
.live-music-all-card {
    display: flex;
    overflow-x: auto;
    gap: 35px;
    width: 100%;
    transition: transform 0.3s ease;
    margin-bottom: 15px;
}
.percussion-card{
    display: flex;
    flex-direction: row;
    height: 380px;
    flex: 0 0 400px; 
    margin-bottom: 0px;
}
.Artist-footer{
    position: relative;
    top: -100px;
}
.num {
    transition: opacity 0.8s cubic-bezier(0, 0.02, 0, 1);
    font-size: clamp(40px, 20vw, 230px); 
    line-height: 1.2; 
    font-family: "Open Sans",sans-serif;
    padding-top: 80px;
    text-shadow: 
        1px 1px 0 #d71362, 
        -1px -1px 0 #d71362, 
        -1px 1px 0 #d71362, 
        1px -1px 0 #d71362,
        1px 0 0 #d71362,
        0 1px 0 #d71362,
        -1px 0 0 #d71362,
        0 -1px 0 #d71362;
        position: relative;
        left: 25px;
}
.special-night-card-content {
    display: flex;
    border-radius: 20px;
    border: 1px solid #fff;
    margin-bottom: 20px;
    overflow: hidden;
    flex: 0 0 280px;
    transition: flex 0.3s ease;
    margin-bottom: 50px;
}
.special-card-left {
    width: 280px;
    height: 250px;
    cursor: pointer;
}
.special-night-card-content-1 {
    width: 280px;
    height: 250px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding: 20px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, #000 100%);
    transition: transform 0.3s ease, opacity 0.3s ease;
    transform: translateX(100%); /* Initially hidden */
    opacity: 0;
}
.special-night-card-content:hover,.special-night-card-content.active {
    flex: 0 0 560px;
}
.special-night-card-content:hover .special-night-card-content-1,
.special-night-card-content.active .special-night-card-content-1 
{
    transform: translateX(0);
    opacity: 1; 
}
.arstist-club-name-1{
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    margin: 0;padding: 0;
    font-family: "Open Sans",sans-serif;
    letter-spacing: 2px;
}
.percussion-date-time{
    font-size: 12px;
    font-weight: 400;
    color: #fff;
    opacity: 0.5;
    margin: 0;padding: 0;
    font-family: "Open Sans",sans-serif;
    letter-spacing: 1px;
}
.artist--name--percussion p{
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    margin: 0;padding: 0;
    letter-spacing: 3px;
    font-family: "Open Sans",sans-serif;
    margin-top: 15px;
    margin-bottom: 10px;
}
.percussion-price{
    font-size: 18px;
    font-weight: 600;
    color: #d71362;
    margin: 0;padding: 0;
    letter-spacing: 3px;
    font-family: "Open Sans",sans-serif;
}
.special-card-left img{
    height: 250px;
    width: 280px;
    border-radius: 20px;
    object-fit: cover;
}
.special-card-left video{
    height: 250px;
    width: 280px;
    border-radius: 20px;
    object-fit: cover;
}
.Artist-card-font{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-bottom: 30px;
}
.artist-view-profile button{
    margin-top: 20px;
    height: 30px;
    width: 100px;
    border: none;
    border: 1px solid #fd2d65;
    background-color: transparent;
    color: #fff;
    font-size: 12px;
    border-radius: 20px;
}
.Artist-card-font-trending{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}
.Percussion-card-content{
    height: 330px;
    flex: 0 0 250px; 
    border-radius: 20px;
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;
}
.Percussion-card-content.active .live-music-card-content-1 {
    bottom: 0; 
}
.Percussion-card-content2.active .live-music-card-content-1 {
    bottom: 0; 
}
.Percussion-card-content2.active .live-music-card-content-h {
    bottom: 0; 
}
  .live-music-card.active .live-music-card-content-1 {
    bottom: 0; 
}
.Percussion-card-content2{
    height: 300px;
    flex: 0 0 250px; 
    border-radius: 20px;
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;
    margin-bottom:50px ;
}
.Percussion-card-content img{
   height: 100%;
   width: 100%;
   object-fit: cover;
   border-radius: 5px;
   transition: transform 0.3s ease; 
}
.Percussion-card-content2 img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
    transition: transform 0.3s ease; 
 }
.Top10-Artist-card-content{
    height: 350px;
    flex: 0 0 300px; 
    border-radius: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
}
.Top10-Artist-card-content-img{
    height: 350px;
    width: 300px;
    border-radius: 20px;
}
.Top10-Artist-card-content-img img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
}
.Top10-Artist-card-content p{
   height: 300px;
   width: auto;
   border: 1px solid #fff;
}
.Artist-geners-card{
    width: 100%;
    height: 300px;
    border-radius: 10px;
    position: relative;
}
.Artist-geners-card img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}
.Artist-geners-card-overlay{
    position: absolute;
    top: 0;
    width: 100%;
    height: 300px;
    border-radius: 10px;
    background: linear-gradient(to bottom, rgba(215, 19, 98, 0.5), #d71362);
}
.Artist-geners-card-overlay h4{
    font-size: 40px;
    font-weight: 600;
    font-family: "Open Sans",sans-serif;
    color: #fff;
    letter-spacing: 3px;
    padding-top: 30px;
    padding-left: 50px;
}
.Artist-gener-card1{
    position: relative;
    margin-left: 20px;
    top: -120px;
}
@media (min-width:600px) and (max-width:1040px){
    .num {
        padding-top: 150px; 
        padding-bottom: 10px; 
        position: relative;
        left: 5px;
    }
}
@media (max-width:599px){
    .Artist-card{
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        flex-direction: column;
        row-gap: 30px;
    }
    .Artist-geners-card{
        width: 100%;
        height: 150px;
        border-radius: 10px;
        position: relative;
    }
    .Artist-geners-card-overlay{
        position: absolute;
        top: 0;
        width: 100%;
        height: 250px;
        border-radius: 10px;
        background: linear-gradient(to bottom, rgba(215, 19, 98, 0.5), #d71362);
    }
    .Artist-gener-card1{
        position: relative;
        margin-left: 10px;
        top: -30px;
    }
    .Artist-geners-card-overlay h4{
        font-size: 22px;
        font-weight: 600;
        font-family: "Open Sans",sans-serif;
        color: #fff;
        letter-spacing: 3px;
        padding-top: 30px;
        padding-left: 25px;
    }
    .live-music-card-content{
        height: 200px;
        width: 280px;
        border: 1px solid #fff;
        border-radius: 5px;
        position: relative;
        margin-bottom: 20px;
    }
    .live-music-card-content-1 p{
        color: #fff;
        font-size: 12px;
        font-weight: 500;
        font-family: "Open sans",sans-serif;
        letter-spacing: 2px;
        margin: 0;padding: 0;
        padding: 10px;
    }
    .live-music-card-content-h p{
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        font-family: "Open sans",sans-serif;
        letter-spacing: 2px;
        margin: 0;padding: 0;
        padding: 10px;
    }
    .live-music-card-content-1 button {
        height: 30px;
        width: 80px;
        background-color: #d71362;
        background-image: linear-gradient(to right, #fe2944, #fd2d65);
        color: #fff;
        border: none;
        font-size: 8px;
        font-family: "Open Sans", sans-serif;
        border-radius: 5px;
    }
    .live-music-card-content-1{
        position: absolute;
        bottom: -50px;
        height: 50px;
        width: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, #000 100%);
    }
    .live-music-card-content-h{
        position: absolute;
        bottom: -50px;
        height: 50px;
        width: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, #000 100%);
    }
    .live-music-all-card {
        display: flex;
        overflow-x: auto;
        gap: 20px;
        max-width: 100%;
        transition: transform 0.3s ease;
        margin-bottom: 15px;
    }
    .num {
        padding-top: 180px; 
        padding-bottom: 10px; 
        position: relative;
        left: 5px;
    }
    .Percussion-card-content{
        height: 280px;
        flex: 0 0 250px; 
        border-radius: 5px;
        margin-bottom: 20px;
    }
    .percussion-card{
        height: 280px;
        flex: 0 0 285px; 
        border-radius: 20px;
        margin-bottom: 20px;
    }
    .special-night-card-content{
        flex: 0 0 250px;
        height: 250px;
        display: flex;
        flex-direction: row;
        
        border-radius: 5px;
        border: 1px solid #fff;
        margin-bottom: 20px;
    }
    .special-card-left{
        width: 250px;
        height: 250px;
    }
    .special-card-left img{
        height: 250px;
        width: 250px;
        border-radius: 5px;
        object-fit: cover;
    }
    .special-card-left video{
        height: 250px;
        width: 250px;
        border-radius: 5px;
        object-fit: cover;
    }
    .special-night-card-content:hover,.special-night-card-content.active {
        flex: 0 0 500px;
    }
    .special-night-card-content-1{
     width: 250px;
     height: 250px;
     border-top-right-radius:5px ;
     border-bottom-right-radius: 5px;
     display: flex;
     flex-direction: column;
     row-gap: 5px;
     padding: 10px 10px;
    }
    .arstist-club-name-1{
        font-size: 16px;
        font-weight: 600;
        color: #fff;
        letter-spacing: 1px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .artist--name--percussion p{
        font-size: 15px;
        font-weight: 600;
        color: #fff;
        margin: 0;padding: 0;
        letter-spacing: 2px;
        font-family: "Open Sans",sans-serif;
    }
    .artist--name--percussion{
        display:flex ;
        flex-direction: column;
        row-gap: 10px;
    }
    .percussion-date-time{
        font-size: 10px;
        font-weight: 400;
        letter-spacing: 0.5px;
    }
    .percussion-price{
        font-size: 15px;
        font-weight: 600;
        color: #d71362;
        margin: 0;padding: 0;
        letter-spacing: 3px;
        font-family: "Open Sans",sans-serif;
    }
    .Percussion-card-content2{
      margin-bottom:20px ;
    }
}

