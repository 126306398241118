.club--about--us--Gallery--section--buttons{
  display: flex;
  flex-direction: row;
  column-gap: 15px;
}
.club--about--us--Gallery--section--buttons button{
  height: 40px;
  width: 110px;
  background-color: #d71362;
  border: none;
  color: white;
  border-radius: 5px;
  outline: none;
}
.club--about--us--Gallery--section1--img1 img{
  width: 180px;
  height: 150px;
  border-radius: 10px;
}
.club--about--us--Gallery--section1--all--img 
{
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
}
.club--about--us--Gallery--section1--food--img 
{
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
}
.club--about--us--Gallery--section1--ambience--img 
{
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
}

.modal-overlay {
  position: fixed;
  top: 20px;
  left: 20px;
  width: 90%;
  height: 90%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
 
  z-index: 1000;

margin: auto;
}

.modal-content {
  background-color: white;
 
  max-width: 90%;
  max-height: 90%;
  overflow: auto;

  border-radius: 20px;
}
.modal-content img{
  width: 100%;
  height: 100%;
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
@media (max-width: 680px){
 .package_images1{
  width: 100%;
  height: auto;
 }
  .club--about--us--Gallery--section--buttons{
      display: flex;
      flex-direction: row;
      column-gap: 5px;
  }
  .club--about--us--Gallery--section--buttons button{
      height: 30px;
      width: 100px;
      background-color: #d71362;
      border: none;
      color: white;
      border-radius: 5px;
      font-size: 10px;
      outline: none;
  }
  .club--about--us--Gallery--section1--img1 img{
      width: 80px;
      height: 80px;
      border-radius: 5px;
      border: 1px solid white;
  }
  .club--about--us--Gallery--section1--all--img 
  {
      margin-top: 30px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 10px;
  }
 
  
  .modal-overlay {
      position: fixed;
      top: 20px;
      left: 20px;
      width: 90%;
      height: 90%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center; 

      z-index: 1000;

  margin: auto;
    }
    
    .modal-content {
      background-color: white;
     
      max-width: 90%;
      max-height: 90%;
      overflow: auto;
      
      border-radius: 20px;
    }
    .modal-content img{
      width: 100%;
      height: 100%;
    }
    
    .modal-close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
}