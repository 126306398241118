.Container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    /* margin-left: 55px; */
}

.content_card {
    padding: 1rem;
    color: #fff;
}

.ImageContainer {
    position: relative;
    
}
.ImageContainer img{
    height: 300px;
}
.discount_ELE {
    position: absolute;
    top: 0;
    right: 0;
    width: fit-content;
    height: fit-content;
    background: #141420;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #fff;
    padding: .2rem .6rem;
    border-radius: 0 0 0 5px;
}

.Prd_name {
    text-transform: capitalize;
}

.Offer_price {
    color: #CCF326;
    font-size: 20px;
    display: flex;
    gap: .2rem;
}

.originalPrice {
    color: #8B94B2;
    text-decoration: line-through;
    font-size: 18px;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
    .Container {
        flex-direction: column;
        align-items: flex-start;
        margin-left: 0;
    }
    
    .content_card {
        margin-bottom: 20px;
    }
    .ImageContainer img{
        height: 250px;
    }
}
