.card1 {
  width: 95%;
  height: auto;
  margin: auto;
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  row-gap: 70px;
}

.homepage--new--yaer--carnival {
  height: 250px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.homepage--new--yaer--carnival p {
  color: #fff;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 250;
  line-height: normal;
  letter-spacing: 10px;
  margin-top: 35px;
}

.dis--flat--extra {
  margin-top: -35px;
}
.carnivall---all-event--page {
  margin-top: 30px;
  padding-left: 120px;
  padding-right: 120px;
  margin-bottom: 70px;
}
.e-card-background-image {
  width: 100%;
  height: 350px;
  overflow: hidden;
  border-radius: 10px;
  background-size: cover;
}
.e-card {
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  background: rgba(83, 138, 255, 0.84);
}
.homepage--new--yaer--carnival img {
  height: 400px;
  width: 400px;
  position: relative;
  top: -250px;
}
.homepage--carnival--event--h1 {
  color: #fff;
  font-family: Roboto Slab;
  font-size: 85px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  padding: 0;
  margin-top: -10px;
}

.homepage--carnival--event--p--content {
  color: #fff;
  font-family: helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  width: 90%;
  line-height: 15px;
  margin: 0;
  padding: 0;
  letter-spacing: 0.5px;
}

.homepage--carnival--event--p {
  color: #fff;
  font-family: Roboto Slab;
  font-size: 55px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  padding: 0;
}

.allevent {
  width: 95%;
  height: 350px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;

  margin: auto;
}

.event-text {
  width: 45%;
  height: 300px;
  display: flex;
  color: white;
  justify-content: center;
  flex-direction: column;
  padding-left: 25px;
}

.event-text-list {
  width: 55%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  color: white;
}

.homepage-music-joner-button {
  height: 40px;
  width: 110px;
  background-color: #0c0522;
  border: none;
  color: white;
  border-radius: 5px;
  font-size: 20px;
}

.event-card {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: #0c0522;
  border-radius: 5px;
  overflow: hidden;
}

.crousal_event {
  width: 100%;
  height: auto;
}

.recommended-club {
  width: 97%;
  margin: auto;
}

.club_about {
  width: 100%;
  height: auto;
  padding-left: 30px;
  padding-right: 30px;
}

.event-card-image-crousal img {
  width: 100%;
  height: 250px;
}

.e-card-page {
  width: 300px;
  height: auto;
  display: flex;
  flex-direction: row;

  gap: 20px;
  border: 1px solid rgba(93, 93, 93, 0.626);
}

.e-card-page::-webkit-scrollbar {
  display: none;
}

.first-image {
  width: 100%;
  height: 500px;
  background-color: rgba(33, 4, 53, 0.641);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  border-radius: 5px;
}

.first-image p {
  font-size: 30px;
  font-weight: 400;
  color: rgb(255, 255, 255);
  position: absolute;
  padding: 15px 25px;

  z-index: 10;
}

.first-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 1s ease;
}

.first-image:hover img {
  transform: scale(1.2);
}

.third-image {
  width: 25%;
  height: 100%;
  background-color: #c00;
}

.second-image {
  width: 20%;
  height: 100%;
  display: grid;

  grid-template-rows: 1fr 1fr;
  gap: 10px;
}

.second-image1 {
  width: 245px;
  height: 245px;
  overflow: hidden;

  border: 1px solid rgb(90, 90, 90);
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.second-image1 img {
  width: 100%;
  height: auto;
}

.second-image2 img {
  width: 100%;
  height: auto;
}

.aboutusvideoplayer {
  width: 97%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 15px;
}

.v--player {
  width: 100%;
  height: 50%;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;

}

.date-selector {
  width: 95%;
  margin: auto;
}

.second-image2 {
  width: 245px;
  overflow: hidden;
  height: 245px;
  border: 1px solid rgb(90, 90, 90);
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.event-card-image-crousal p {
  color: white;
  padding-left: 10px;
  margin-bottom: 5px;
  font-size: 12px;
}

.event-card-image-crousal {
  width: 300px;
  height: auto;
  display: flex;
  flex-direction: column;
  text-align: start;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.text_crousal .booknowbtnevent {
  font-size: 14px;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  color: white;
  background-color: #d71362;
  display: flex;
  align-items: center;
}

.event-card-image-crousal .event_name {
  font-size: 18px;
  font-weight: 600;
}

.text_crousal {
  width: 100%;
  height: auto;
  background-color: #1f1e2e;
  padding: 10px;
  line-height: 20px;
}

.booknowbtn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.booknowbtn .ticket {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 400;
  color: #d71362;
}

.select-offer-deals {
  width: 100%;
  height: auto;
  border-radius: 5px;
  border: 1px solid rgba(129, 129, 129, 0.508);
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
}

.formdatainput {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 10px;
}

.formdatainput input:focus {
  background: transparent;
}

.formdatainput button {
  width: 95%;
  height: 50px;
  border-radius: 5px;
  margin: auto;
  border: none;
  background-color: #d71362;
  color: white;
}

.formdatainput input {
  width: 95%;
  height: 45px;
  margin: auto;
  border-radius: 5px;
  fill: none;
  color: white;
  background: transparent;
  border: 1px solid #d71362;
}

.date_select {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border: 1px solid rgba(129, 129, 129, 0.508);
}

.date_select p {
  display: flex;
  font-size: 22px;
  font-weight: 600;
  margin: 0px;
}

.club-location img {
  width: 100%;
  height: 250px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.club-location8 img {
  width: 100%;
  height: 250px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.date_select1 {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 10px;
  color: white;
}

.date_select1 button {
  color: white;
}

.package--namewith--icon img {
  width: 100%;
}

.text_field_add {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
}

.text_field_add p span {
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: white;
  border: 1px solid red;
  border-radius: 50%;
  cursor: pointer;
}

.text_field_add p {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px !important;
  color: white !important;
}

.date_select1 .button_reserve {
  width: 100%;
  height: 50px;
  margin-top: 10px;
  background-color: #d71362;
  border: none;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 18px;
}

.date_select1 p {
  font-size: 22px;
  font-weight: 400;
  margin: 0px;
  margin-bottom: 5px;
}

.event-card-crousal {
  width: 100%;
  height: 300px;
}

.e-image {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
}

.e-image p {
  display: flex;
  margin: auto;
  font-size: 18px;
}

.partygoer--p {
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 2px;
  font-family: "Open Sans", sans-serif;
  color: white;
  
  text-align: center;
  text-transform: uppercase;
}
.partygoer--p-1 {
  font-size: 22px;
  font-family: "Open Sans", sans-serif;
  color: white;
  text-align: center;
  margin: 0;
  padding: 0;
}

.partygoer {
  padding-left: 30px;
  padding-right: 30px;
}
.partygoer2 {
  padding-left: 30px;
  padding-right: 30px;
}
.partygoer1 {
  padding-left: 30px;
  padding-right: 30px;
}

.pickup-point1 {
  width: 100%;
  height: 100px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.events-icons {
  width: 100%;
  height: 70px;
  background-color: #251351;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  column-gap: 15px;
  align-items: center;
  color: white;
}

.events-icons p {
  margin: 0;
  padding: 0;
}

.events-icons img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-left: 20px;
}

.geners {
  color: white;
  font-size: 78px;
  font-weight: bold;
  margin-top: -35px;
}

.homepage-music-geners {
  padding-top: 22px;
  color: white;
  font-size: 30px;
  text-align: start;
}

.Homepage-music-Geners {
  padding-left: 50px;
  height: 300px;
  width: 100%;
  background-color: #d71362;
}

.Homepage-music-Geners-card1 {
  height: 300px;
  width: 500px;
  padding: 0;
 
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
}

.Homepage-music-Geners-card2 {
  height: 300px;
  width: 500px;
  padding: 0;
  
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
}

.Homepage-music-Geners-card3 {
  height: 300px;
  width: 500px;
  padding: 0;

  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
}

.Homepage-music-Geners-card4 {
  height: 300px;
  width: 500px;
  padding: 0;
  
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
}

.Homepage-music-Geners-card5 {
  height: 300px;
  width: 500px;
  padding: 0;

  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
}

.Homepage-music-Geners-card-p {
  padding: 0;
  margin: 0;
  text-align: center;
  color: white;
  font-size: 30px;
  font-weight: 400;
  padding-top: 100px;
}

.Homepage-music {
  margin-top: -25px;
}

.Homepage-music-Geners-card {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-left: 250px;
  margin-top: -98px;
}


.club-thumbnail-content-1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 5px;
}

.club-thumbnail-content {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  position: relative;
  top: -23px;
}

.bookatable-routes1 {
  text-decoration: none !important;
}

.thumbnail--rating-2 {
  height: 20px;
  width: 58px;
  background-color: #1976d2;
  border-radius: 0px, 0px, 2px, 2px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* font-size: 10px; */
}

.thumbnail--rating-1-1 {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
}

.thumbnail--rating-2 p {
  margin: 0;
  padding: 0;
  font-size: 10px !important;
  color: white !important;
}

.thumbnail--rating-1-1 p {
  margin: 0;
  padding: 0;
  font-size: 12px;
  color: white;
  padding-top: 5px;
  padding-left: 4px;
  padding-right: 2px;
}

.thumbnail--rating-1-1 span {
  color: #ffde21;
  padding-left: 4px;
}

.thumbnail--rating-1-1 {
  height: 27px;
  width: 58px;
  border-radius: 3px 3px 0px 0px;
  background-color: #cc0c39;
}

.loc-club {
  color: #61636f;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  padding: 0;
  padding-bottom: 4px;
  padding-left: 8px;
  display: flex;
  flex-direction: row;
  column-gap: 4px;
}

.offer--2 {
  font-size: 12px;
  color: black;
  margin: 0;
  padding: 0;
  margin-left: 5px;
}

.offer {
  color: #61636f;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;

  margin: 0;
  padding: 0;
  padding-left: 8px;
  padding-bottom: 3px;
  display: flex;
  flex-direction: row;
  column-gap: 4px;
}

.offer-1 {
  font-size: 12px;
  color: white;
  margin: 0;
  padding: 0;
  margin-left: 5px;
  margin-top: 2px;
}

.offer span {
  position: relative;
  top: -1px;
}

.loc-club span {
  color: #61636f;
}

.hotal-name {
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  padding: 0;
  padding-left: 8px;
  padding-bottom: 5px;
}

.offer--21-1 {
  color: #1976d2;
}

.recommand--discount--offer {
  padding-top: 7px;
  height: 30px;
  width: 100%;
  background-color: #d9d9d9;
}

.recommand--discount--offer-1 {
  padding-top: 4px;
  height: 30px;
  width: 100%;
  background-color: #1976d2;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.recommand--discount--offer-1 p {
  color: white !important;
}

.recommand--discount--offer-11 {
  padding-top: 4px;
  height: 30px;
  width: 100%;
  background-color: #054f31;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.recommand--discount--offer-11 p {
  color: white !important;
}

.club-thumbnail-content11 {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  position: relative;
  top: -43px;
}

.club-location--club-img {
  border: 1px solid #fff;
}

.offer--21 {
  color: black;
}

.thumbnail--rating-3 {
  padding-left: 10px;
  padding-top: 5px;
}

.recommand--discount--offer-1-2 {
  display: flex;
  flex-direction: column;
  row-gap: 1px;
}

@media (max-width: 925px) {
  .carousel-inner .carousel-item > div {
    display: none;
  }

  .carousel-inner .carousel-item > div:first-child {
    display: block;
  }
}

.carousel-inner .carousel-item.active,
.carousel-inner .carousel-item-next,
.carousel-inner .carousel-item-prev {
  display: flex;
}

@media (min-width: 768px) {
  .carousel-inner .carousel-item-end.active,
  .carousel-inner .carousel-item-next {
    transform: translateX(25%);
  }

  .carousel-inner .carousel-item-start.active,
  .carousel-inner .carousel-item-prev {
    transform: translateX(-25%);
  }
}

.carousel-inner .carousel-item-end,
.carousel-inner .carousel-item-start {
  transform: translateX(0);
}

.events-icons p {
  margin-left: 20px;
  margin-bottom: 0px;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
}

.happening-party-card {
  width: 100%;
  height: 450px;
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 50px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  place-items: center;
}

.h-text {
  width: 35%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  color: white;
  padding: 20px;
  padding-left: 60px;
}

.h-text1 {
  width: 65%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.happening {
  font-size: 68px;
  font-weight: 300;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  color: white;
  margin: 0;
  padding: 0;
}

.club-location {
  width: 270px;
  height: 400px;
  background-color: aliceblue;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}

.premiumtag1 img {
  width: 40px;
  height: 40px;
}

.bookatable-routes {
  text-decoration: none !important;
}

.package--namewith--icon img {
  width: 100%;
}

.pickup_pointer {
  width: 100%;
  height: auto;
  flex-direction: row;
  overflow-x: scroll;
  gap: 20px;
  margin-top: 30px;
  background-color: #c00;
}

.pick_point {
  width: 100%;
  height: auto;
  position: relative;
  overflow: scroll;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  margin-top: 30px;
}

.pick_point::-webkit-scrollbar {
  display: none;
}

 .services1 {
  height: auto;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  column-gap: 25px;
} 
.services1-1 {
  height: auto;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  column-gap: 50px;
}
.services1-1::-webkit-scrollbar {
  width: 100%;
  height: 10px;
}

.services1-1::-webkit-scrollbar-track {
  background-color: transparent;
}

.carnival--homepage--nearby {
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 10px;
}

.club_Card_Container {
  position: relative;
  overflow: hidden;
  display: flex;
  height: auto;
  flex-direction: row;
  overflow-x: scroll;
  column-gap: 25px;
}

.popular--package--9 {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
}
.popular--package--9-1 {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
}
.popular--package--9-2 {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
}
.services1::-webkit-scrollbar {
  display: none;
}
.services1-1::-webkit-scrollbar {
  display: none;
}
.loc-club img {
  width: 15px;
  height: 15px;
  margin-bottom: 0px;
}

.homepage--new--yaer--carnival--mobile--1--button {
  width: 230px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.offer-deals {
  display: flex;
  flex-direction: column;
}

.eat-out {
  width: 100%;
  height: 200px;
  background-color: #f8d687;
  margin-top: 30px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
}

.eat-out-image {
  width: 400px;
  height: 200px;
}

.eat-out-text img {
  width: 200px;
  height: 200px;
  margin-left: 30px;
}

.h-party-bg {
  width: 100%;
  height: 100%;
  background-color: #08bdbd;
 
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.partyy {
  color: white;
  font-size: 85px;
  font-family: "Franklin Gothic Medium";
  font-weight: 700px;
  padding: 0;
  margin: 0;
  margin-top: -30px;
}

.homepage-happening-party-content {
  margin-top: -10px;
}

.animate {
  height: 100px;
  width: 100px;
  background-color: #c00;
  transition: all 1s ease;
  position: absolute;
  cursor: pointer;
  font: 13px/100px sans-serif;
  color: white;
  text-align: center;
}

.animate.left {
  left: 0;
  top: 50%;
  margin-top: -100px;
}

.animate.right {
  right: 0;
  top: 50%;
}

.animate.top {
  top: 0;
  left: 50%;
}

.animate.bottom {
  bottom: 0;
  left: 50%;
  margin-left: -100px;
}

.animate.left.move {
  left: 100%;
  transform: translate(-100%, 0);
}

.animate.right.move {
  right: 100%;
  transform: translate(100%, 0);
}

.animate.top.move {
  top: 100%;
  transform: translate(0, -100%);
}

.animate.bottom.move {
  bottom: 100%;
  transform: translate(0, 100%);
}

@import url("https://fonts.googleapis.com/css2?family=DM+Mono:wght@300;400;500&display=swap");

a {
  text-decoration: none;
}

.card-list-animation {
  width: 100%;

  display: flex;
  padding: 3rem;
  overflow: hidden;
  overflow-x: scroll;
}

.card-list-animation::-webkit-scrollbar-thumb {
  width: 10px;
  height: 10px;
}

.club_Card_Container::-webkit-scrollbar{
  height: 8px;
}
.club_Card_Container::-webkit-scrollbar-thumb{
  background: #fff;
  border-radius:10px ;
}
.club_Card_Container::-webkit-scrollbar-track{
  background: #4a2f8852;
}

.card-list-animation::-webkit-scrollbar-thumb{
  background: #201c29;
  border-radius: 10px;
  box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, 0.25),
    inset -2px -2px 2px rgba(0, 0, 0, 0.25);
}

.card-list-animation::-webkit-scrollbar-track {
  background: linear-gradient(90deg, #201c29, #201c29 1px, #17141d 0, #17141d);
}

.card-animation {
  height: 350px;
  width: 400px;
  min-width: 250px;
  padding: 1.5rem;
  border-radius: 16px;
  background: #ffffff;
  box-shadow: -1rem 0 3rem #000;
  display: flex;
  flex-direction: column;
  transition: 0.2s;
  margin: 0;
  scroll-snap-align: start;
  clear: both;
  position: relative;
}

.homepage-happening-party-button {
  height: 47px;
  width: 120px;
  background-image: linear-gradient(to right, #d71362, #f26d84, #d71362);
  border: none;
  color: white;
  border-radius: 10px;
  font-size: 20px;
}

.card-animation:focus-within ~ .card,
.card:hover ~ .card {
  transform: translateY(130px);
}

.card-animation:hover {
  transform: translateY(-1rem);
}

.card-animation:not(:first-child) {
  margin-left: -130px;
}

.card-header {
  margin-bottom: auto;
}

.card-header p {
  font-size: 14px;
  margin: 0 0 1rem;
  color: #7a7a8c;
}

.event_heading {
  width: 100%;
  border-bottom: 1px solid rgb(164, 164, 164);
  color: white;
}

.card-header h2 {
  font-size: 20px;
  margin: 0.25rem 0 auto;
  text-decoration: none;
  color: inherit;
  border: 0;
  display: inline-block;
  cursor: pointer;
}

.card-header h2:hover {
  background: linear-gradient(90deg, #ff8a00, #e52e71);
  text-shadow: none;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.card-author {
  margin: 3rem 0 0;
  display: grid;
  grid-template-columns: 75px 1fr;
  align-items: center;
  position: relative;
}
.Plan--your--event--popup--webapp {
  position: relative;
}
.plan--your--event--cross--we--app p {
  font-size: 35px;
  color: #000;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
.plan--your--event--cross--we--app {
  position: absolute;
  top: 5px;
  left: 95%;
  transform: translate(-5%);
}
.club-menu {
  width: 100%;
  height: 650px;
}

.club-menu img {
  width: 100%;
  height: 630px;
  overflow: hidden;
}

.order img {
  width: 100%;
  height: 500px;
}

.author-avatar {
  grid-area: auto;
  align-self: start;
  position: relative;
  box-sizing: border-box;
}

.author-avatar img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  filter: grayscale(100%);
  display: block;
  overflow: hidden;
  margin: 16px 10px;
}

.author-name {
  grid-area: auto;
  box-sizing: border-box;
}

.author-name-prefix {
  font-style: normal;
  font-weight: 700;
  color: #7a7a8c;
}

.half-circle {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 48px;
  fill: none;
  stroke: #ff8a00;
  stroke-width: 8;
  stroke-linecap: round;
  pointer-events: none;
}

.tags {
  margin: 1rem 0 2rem;
  padding: 0.5rem 0 1rem;
  line-height: 2;
  margin-bottom: 0;
}

.tags a {
  font-style: normal;
  font-weight: 700;
  font-size: 0.5rem;
  color: #7a7a8c;
  text-transform: uppercase;
  font-size: 0.66rem;
  border: 3px solid #28242f;
  border-radius: 2rem;
  padding: 0.2rem 0.85rem 0.25rem;
  position: relative;
}

.tags a:hover {
  background: linear-gradient(90deg, #ff8a00, #e52e71);
  text-shadow: none;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-box-decoration-break: clone;
  background-clip: text;
  border-color: white;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.wrapper222 {
  background-color: #08bdbd;

  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.wrapper2223 {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.carousel222 {
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__item {
  width: 100%;
  max-width: 300px;
  height: auto;
  padding: 0 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  opacity: 0;
  -webkit-animation: carousel-animate-horizontal 20s linear forwards infinite;
  animation: carousel-animate-horizontal 20s linear forwards infinite;
}

.event-text-list::-webkit-scrollbar {
  width: 1px;
}

.event-text-list::-webkit-scrollbar-track {
  background-color: transparent;
}

.event-text-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

.homepage--new--yaer--carnival--mobile--1 {
  display: none;
}

.event-text-list {
  display: flex;
  overflow-x: auto;
  gap: 20px;
  padding: 10px;
  max-width: 100%;
  transition: transform 0.3s ease;
}

.event-link {
  text-decoration: none;
}

.event-title {
  color: #fff;

  padding: 5px;
  font-size: 17px;
  text-align: center;
}
.Favorate--hangout--all--thumbnail--card {
  flex: 0 0 350px;
  height: auto;
}
.event-card {
  margin-top: 12px;
  background: url(./image/party3.png), lightgray 50% / cover no-repeat;
  flex: 0 0 250px;
  height: 230px;
  padding: 15px;
  border-radius: 5px;
  overflow: hidden;
  transition: transform 0.2s;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

.event-card--new--year--pass--content {
  display: flex;
  flex-direction: column;
  row-gap: 100px;
  justify-content: space-between;
}

.event-card--new--year--pass--content1 {
  color: #fff;
  font-family: helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  padding: 0;
}

.event-card--new--year--pass--content--button button {
  width: 87px;
  height: 33px;
  flex-shrink: 0;
  border-radius: 4px;
  border: none;
  outline: none;
  background: linear-gradient(96deg, #fd2f71 10.71%, #fd2f71 93.51%);
  color: #fff;
  font-family: Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.483px;
  backdrop-filter: blur(15.106589317321777px);
  margin-top: 20px;
}

.event-card--new--year--pass--content2 {
  color: #fd2f71;
  font-family: helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  padding: 0;
}

.event-card--new--year--pass--content--passes {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.event-card:hover {
  transform: scale(1.05);
}

.homepage--new--yaer--carnival--web--img {
  height: 350px;
  width: 550px;
}

.homepage--new--yaer--carnival--mobile--1--time--container--counting--p1 {
  color: #fff;
  font-family: Helvetica;
  font-size: 25px;
  text-align: center;
  font-weight: 600;
  margin: 0;
  padding: 0;
  padding-top: 5px;
}

.homepage--new--yaer--carnival--web--card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 10px;
}

.homepage--new--yaer--carnival--web--img img {
  height: 350px;
  width: 550px;
}

.event-card a {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.e-image img {
  width: 100%;
  height: 245px;
  display: block;
  border-radius: 5px 5px 0 0;
  object-fit: cover;
}

.homepage--new--yaer--carnival--web {
  display: flex;
  flex-direction: row;
  column-gap: 30px;
  padding-left: 180px;
  padding-right: 180px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.e-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 5px;
  font-size: 14px;
  text-align: center;
  border-radius: 0 0 5px 5px;
}

.homepage--new--yaer--carnival--mobile {
  display: none;
}
.homepage--package--img-name--1 {
  position: relative;
}
.homepage--package--img-name--1 img {
  height: 180px;
  width: 180px;
  border-radius: 50%;
}
.homepage--package--img-name--2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.homepage--package--img-name--2 img {
  height: 160px;
  width: 160px;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
  background-color: black; 
  opacity: 0.4; 
  border-radius: 50%; 
  z-index: -1;
  animation: scaleIn 1s ease-out forwards;
}

@media screen and (min-width: 925px) {
  .carousel__item {
    max-width: 500px;
  }
}

.carousel__item:nth-child(1) {
  -webkit-animation-delay: calc(3.3333333333s * 0);
  animation-delay: calc(3.3333333333s * 0);
}

.carousel__item:nth-child(2) {
  -webkit-animation-delay: calc(3.3333333333s * 1);
  animation-delay: calc(3.3333333333s * 1);
}

.carousel__item:nth-child(3) {
  -webkit-animation-delay: calc(3.3333333333s * 2);
  animation-delay: calc(3.3333333333s * 2);
}

.carousel__item:nth-child(4) {
  -webkit-animation-delay: calc(3.3333333333s * 3);
  animation-delay: calc(3.3333333333s * 3);
}

.carousel__item:nth-child(5) {
  -webkit-animation-delay: calc(3.3333333333s * 4);
  animation-delay: calc(3.3333333333s * 4);
}

.carousel__item:nth-child(6) {
  -webkit-animation-delay: calc(3.3333333333s * 5);
  animation-delay: calc(3.3333333333s * 5);
}

.carousel__item-body {
  width: 100%;
  height: 350px;
  background-color: #fff;
  border-radius: 8px;
  padding: 50px 30px 20px 30px;
  text-align: center;
}

.carousel__item-body1 {
  width: 100%;
  height: 100px;
  background-color: #251351;
  color: white;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
}

.event-text button {
  width: 100px;
  font-size: 14px;
  font-family: helvetica;
  font-weight: 400;
  height: 35px;
  border-radius: 4px;
  border: none;
  outline: none;
  color: white;
  background: linear-gradient(95.75deg, #fd2f71 10.71%, #fd2f71 93.51%);
  margin-top: 20px;
}

.club-location {
  flex: 0 0 270px;
  height: 397px;
  border-radius: 5px;
  overflow: hidden;
  transition: transform 0.2s;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  background-color: aliceblue;
}
.club-location8 {
  flex: 0 0 270px;
  height: 340px;
  border-radius: 5px;
  overflow: hidden;
  transition: transform 0.2s;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  background-color: aliceblue;
}
.mobile--view--discount--add--on {
  display: none;
}

.web--partywitty--pay--instant-discount-card--image--1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.web--partywitty--pay--instant-discount-card--image--2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.partywitty--pay--instant-discount-card--image--1--button button {
  width: 110px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 3px;
  background: linear-gradient(96deg, #fff 10.71%, #fff 93.51%);
  backdrop-filter: blur(7.5px);
  color: #000;
  font-family: Helvetica;
  border: none;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
}

.homepage--new--yaer--carnival--mobile--p3 {
  color: #fff;
  font-variant-numeric: lining-nums tabular-nums;
  font-feature-settings: "case" on;
  font-family: Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin: 0;
  padding: 0;
}

.partywitty--pay--instant-discount-card--image--1--button {
  margin-top: 90px;
  padding-right: 60px;
}

.partygoer--p-1 span {
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  color: white;
  margin-top: 0px;
  text-align: center;
  color: #fd2f71;
}

.homepage--new--yaer--carnival--mobile--p2 {
  color: #fff;
  font-variant-numeric: lining-nums tabular-nums;
  font-feature-settings: "case" on;
  font-family: Helvetica;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  margin: 0;
  padding: 0;
}

.event-text-list--mobile--view {
  display: none;
}

.homepage--new--yaer--carnival--mobile--p img {
  height: 80px;
  width: auto;
  margin-bottom: 10px;
}

.homepage--new--yaer--carnival--mobile--1--time--container {
  display: flex;
  flex-direction: row;
  column-gap: 10px;

  margin-top: 12px;
}

.homepage--new--yaer--carnival--mobile--1--time--container--counting {
  width: 60px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #2b2b36;
  border-radius: 8px;
}

.homepage--new--yaer--carnival--mobile--1--time--container--counting--p2 {
  color: #fff;
  font-family: Helvetica;
  font-size: 10px;
  text-align: center;
  font-weight: 400;
  margin: 0;
  padding: 0;
  position: relative;
  top: -7px;
}

.homepage--new--yaer--carnival--mobile--1--button p {
  margin: 0;
  padding: 0;
  text-align: center;
  color: #141420;
  font-family: Helvetica;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.favorite--hangout--card {
  width: 276px;
  height: 376px;
  flex-shrink: 0;
  border-radius: 4px;
  position: relative;
}
.favorite--hangout--card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  
}
.favorite--hangout--place--name {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 276px;
  height: 96px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
  border-radius: 0px 0px 4px 4px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, #000 100%);
}
.favorite--hangout--place--name1 {
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  padding: 0;
}
.favorite--hangout--place--name2 {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
 }
.favorite--hangout--place--name4 {
  position: absolute;
  top: 60%;
  text-align: center;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
   opacity: 0.7;
}
.services-name--1 {
  width: 100%;
  height: 154px;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  letter-spacing: 0.5;
}
.homepage--package--img-name {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  align-items: center;
}
.homepage--package--img-name p {
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.8px;
}
.homepage--package--img-name--2 p{
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.8px;
}
.Homepage_Teaser_p{
  margin: 0;padding: 0;
  font-size: 14px;
  font-family: "Open sans",sans-serif;
  margin-top: 10px;
}
@-webkit-keyframes carousel-animate-horizontal {
  0% {
    transform: translateX(-200%);
    opacity: 0;
  }

  2%,
  16.6666666667% {
    transform: translateX(-100%);
    opacity: 0.4;
  }

  18.6666666667%,
  33.3333333333% {
    transform: translateX(0);
    opacity: 1;
  }

  35.3333333333%,
  50% {
    transform: translateX(100%);
    opacity: 0.4;
  }

  52% {
    transform: translateX(200%);
    opacity: 0;
  }

  100% {
    transform: translateX(200%);
    opacity: 0;
  }
}

@keyframes carousel-animate-horizontal {
  0% {
    transform: translateX(-200%);
    opacity: 0;
  }

  2%,
  16.6666666667% {
    transform: translateX(-100%);
    opacity: 0.4;
  }

  18.6666666667%,
  33.3333333333% {
    transform: translateX(0);
    opacity: 1;
  }

  35.3333333333%,
  50% {
    transform: translateX(100%);
    opacity: 0.4;
  }

  52% {
    transform: translateX(200%);
    opacity: 0;
  }

  100% {
    transform: translateX(200%);
    opacity: 0;
  }
}

@media screen and (max-width: 925px) {
  .hotal-name {
    color: black;
    font-size: 13px;
    margin: 0;
    padding: 0;
  }
  .club-thumbnail-content11 {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    position: relative;
    top: -43px;
  }
  .dis--flat--extra {
    margin-top: 10px;
  }
  .card {
    width: 100%;
    height: auto;
    margin: auto;
    background-color: transparent;
  }
  .homepage--new--yaer--carnival--web {
    display: none;
  }
  .event-text-list--mobile--view {
    display: block;
  }
  .homepage--new--yaer--carnival {
    display: none;
  }
  .homepage--new--yaer--carnival--mobile {
    display: block;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .homepage--new--yaer--carnival--mobile--p1 {
    color: #f4c371;
    text-align: center;
    font-family: Mr De Haviland;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1.9px;
    margin: 0;
    padding: 0;
  }

  .homepage--new--yaer--carnival--mobile--p2 {
    color: #fff;
    font-variant-numeric: lining-nums tabular-nums;
    font-feature-settings: "case" on;
    font-family: Helvetica;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    margin: 0;
    padding: 0;
  }

  .homepage--new--yaer--carnival--mobile--p3 {
    color: #fff;
    font-variant-numeric: lining-nums tabular-nums;
    font-feature-settings: "case" on;
    font-family: Helvetica;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin: 0;
    padding: 0;
  }
  .homepage--package--img-name p {
    color: #fff;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    letter-spacing: 0.8px;
    margin: 0;
    padding: 0;
  }
  .homepage--package--img-name--2 p {
    color: #fff;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.8px;
    margin: 0;
    padding: 0;
  }
  .e-card-background-image {
    width: 100%;
    height: 200px;
    margin-top: 20px;
    overflow: hidden;
    border-radius: 5px;
  }

  .homepage--new--yaer--carnival--mobile--p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 20px;
  }
  .homepage--package--img-name--1 img {
    height: 120px;
    width: 120px;
    border-radius: 50%;
  }
  .homepage--package--img-name--2 img {
    height: 100px;
    width: 100px;
    border-radius: 50%;
  }
  .mobile--view--discount--add--on1 {
    padding-top: 40px;
    color: #fff;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.6px;
  }

  .mobile--view--discount--add--on2 {
    color: #1976d2;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-top: -35px;
    text-align: center;
  }
  .favorite--hangout--card {
    width: 190px;
    height: 300px;
    flex-shrink: 0;
    border-radius: 4px;
    position: relative;
  }
  .favorite--hangout--card img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .favorite--hangout--place--name {
    position: absolute;
    bottom: 0; 
    left: 50%;
    transform: translateX(-50%);
    width: 190px;
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 10px;
    border-radius: 0px 0px 4px 4px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, #000 100%);
  }
  .favorite--hangout--place--name1 {
    color: #fff;
    font-family: Ubuntu;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
    padding: 0;
  }
  .favorite--hangout--place--name2 {
    color: #fff;
    font-family: Ubuntu;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    padding: 0;
    
  }
  .homepage--new--yaer--carnival--mobile--p img {
    height: 45px;
    width: auto;
  }

  .e-card {
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    padding-top: 15px;
  }

  .allevent {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    padding-bottom: 30px;
  }

  .thumbnail--rating-2 p {
    margin: 0;
    padding: 0;
    font-size: 10px !important;
    color: white !important;
  }

  .thumbnail--rating-2 {
    height: 15px;
    width: 55px;
    background-color: #1976d2;
    border-radius: 0px, 0px, 2px, 2px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .event-text {
    width: 30%;
    height: auto;
    display: flex;
    color: white;
    flex-direction: column;
    row-gap: 5px;
    padding-left: 10px;
  }

  .event-card--new--year--pass--content {
    display: flex;
    flex-direction: column;
    row-gap: 45px;
    justify-content: space-between;
  }

  .event-text-list--mobile--view {
    width: 70%;
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 10px;
    color: white;
    margin-top: 2px;
  }

  .event-text-list--mobile--view::-webkit-scrollbar {
    width: 1px;
  }

  .event-text-list--mobile--view::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .event-text-list--mobile--view::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }

  .homepage--new--yaer--carnival--mobile--1--time--container--counting {
    width: 60px;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #2b2b36;
    border-radius: 8px;
  }
  .Favorate--club--category--8 {
    display: none;
  }
  .homepage--new--yaer--carnival--mobile--1--time--container--counting--p2 {
    color: #fff;
    font-family: Helvetica;
    font-size: 10px;
    text-align: center;
    font-weight: 400;
    margin: 0;
    padding: 0;
    position: relative;
    top: -7px;
  }

  .homepage--new--yaer--carnival--mobile--1--time--container--counting--p1 {
    color: #fff;
    font-family: Helvetica;
    font-size: 25px;
    text-align: center;
    font-weight: 600;
    margin: 0;
    padding: 0;
    padding-top: 5px;
  }

  .homepage--new--yaer--carnival--mobile--1--time--container {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
  }

  .event-text-list--mobile--view {
    display: flex;
    overflow-x: auto;
    gap: 10px;
    padding: 10px;
    max-width: 100%;
    transition: transform 0.3s ease;
  }

  .event-text-list {
    display: none;
  }
  .partygoer2 {
    margin-top: 0px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .event-text button {
    width: 55px;
    font-size: 10px;
    font-family: helvetica;
    font-weight: 300;
    height: 23px;
    border-radius: 4px;
    border: none;
    outline: none;
    color: white;
    background: linear-gradient(95.75deg, #fd2f71 10.71%, #fd2f71 93.51%);
    margin-top: 10px;
  }

  .carousel__item .MuiTypography-body2 {
    font-size: 12px;
  }

  .carousel__item .MuiCardMedia-root {
    height: 100px;
  }

  .carnivall---all-event--page {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 0px;
  }

  .partygoer--p {
    color: #fff;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.4px;
    margin-top: 0px;
    margin: 0;
    padding: 0;
    text-align: center;
    text-transform: uppercase;
  }
  .partygoer {
    padding-right: 5px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: -35px;
    display: flex;
    flex-direction: row;
    column-gap: 6px;
  }
  .Plan--your--event--popup--webapp {
    position: relative;
  }
  .plan--your--event--cross--we--app p {
    font-size: 35px;
    color: #fff;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }
  .plan--your--event--cross--we--app {
    position: absolute;
    top: -7px;
    left: 93%;
    transform: translate(-7%);
  }
  .partygoer1 {
    margin-top: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 6px;
    margin: 0;
    padding: 0;
  }

  .carnival--homepage--nearby {
    padding-left: 0px;
    padding-right: 0px;
  }

  .event-card {
    flex: 0 0 170px;
    height: 130px;
    padding: 10px;
  }

  .services1 {
    width: 100%;
    height: auto;
    gap: 15px;
    margin-top: 10px;
    display: flex;
    overflow-x: auto;
    transition: transform 0.3s ease;
    padding-right: 7px;
  }

  .services1::-webkit-scrollbar {
    width: 1px;
  }

  .services1::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .services1::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }

  .club-location {
    width: 200px !important;
    flex: 0 0 200px;
    height: 300px;
    border-radius: 5px;
    overflow: hidden;
    transition: transform 0.2s;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
      rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    background-color: aliceblue;
  }
  .club-location8 {
    width: 200px !important;
    flex: 0 0 200px;
    height: 300px;
    border-radius: 5px;
    overflow: hidden;
    transition: transform 0.2s;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
      rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    background-color: aliceblue;
  }

  .club-location--5 {
    flex: 0 0 200px;
    height: 269px;
    border-radius: 5px;
    overflow: hidden;
    transition: transform 0.2s;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
      rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    background-color: aliceblue;
  }

  .club-location img {
    width: 100%;
    height: 170px;
  }
  .club-location8 img {
    width: 100%;
    height: 170px;
  }

  .club-location--5 img {
    width: 100%;
    height: 170px;
  }

  .loc-club img {
    width: 15px;
    height: 15px;
  }

  .offer {
    color: #616365;
    margin: 0;
    padding: 0;
  }

  .loc-club {
    margin: 0;
    padding: 0;
  }

  .eat-out-image img {
    width: 80px;
    height: 80px;

    margin-right: 20px;
    border-radius: 10px;
  }

  .eat-out-text img {
    width: 80px;
    height: 80px;
    margin-left: 10px;
  }

  .e-image {
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
  }

  .e-image img {
    width: 100%;
    height: 100px;
  }

  .e-image p {
    display: flex;
    margin: auto;
    font-size: 15px;
  }

  .pick_point {
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: row;
    overflow: scroll;
    gap: 20px;
  }

  .pick_point::-webkit-scrollbar {
    display: block;
  }

  .homepage--carnival--event--p {
    color: #fff;
    font-family: Roboto Slab;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    padding: 0;
  }

  .event-card--new--year--pass--content--button button {
    width: 50px;
    height: 25px;
    flex-shrink: 0;
    border-radius: 3px;
    border: none;
    outline: none;
    background: linear-gradient(96deg, #fd2f71 10.71%, #fd2f71 93.51%);
    color: #fff;
    font-family: Helvetica;
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 1.483px;
    backdrop-filter: blur(15.106589317321777px);
    margin-top: 10px;
  }

  .e-card-page {
    width: 231px;
    height: auto;
    display: flex;
    flex-direction: row;

    column-gap: 10px;
    border: 1px solid rgba(93, 93, 93, 0.626);
  }

  .homepage--carnival--event--h1 {
    color: #fff;
    font-family: Roboto Slab;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
    padding: 0;
    margin-top: -10px;
  }
  .card1 {
    width: 95%;
    height: auto;
    margin: auto;
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    row-gap: 25px;
  }
  .events-icons {
    height: 60px;
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    align-items: center;
  }

  .events-icons img {
    width: 40px;
    height: 40px;
    position: relative;
    left: -13px;
  }

  .events-icons p {
    font-size: 15px;
    position: relative;
    left: -10px;
  }

  .happening-party-card {
    width: 100%;
    height: 250px;
    margin-top: 30px;
  }

  .h-text {
    width: 45%;
    height: 100%;
    padding: 8px;
  }

  .h-text1 {
    width: 55%;
    height: 100%;
  }

  .happening {
    font-size: 32px;
    font-weight: 80px;
    padding-bottom: 15px;
  }

  .geners {
    font-size: 34px;
    font-weight: 400px;
    padding-bottom: 15px;
    padding-top: 5px;
  }

  .offer-deals {
    display: flex;
    flex-direction: column;
  }

  .eat-out {
    width: 100%;
    height: 90px;
    background-color: #f8d687;
    margin-top: 30px;
    border-radius: 5px;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important;
    overflow: hidden;
  }
  .services1-1 {
    height: auto;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    column-gap: 20px;
  }
  .partyy {
    font-size: 32px;
    font-weight: 80px;
  }

  .homepage-happening-party-button {
    height: 30px;
    width: 80px;
    border-radius: 5px;
    font-size: 14px;
  }

  .carousel__item {
    width: 200px;
    height: 200px;
  }

  .Homepage-music-Geners {
    padding-left: 15px;
    height: 155px;
    width: 100%;
    background-color: #d71362;
  }

  .homepage-music-joner-button {
    height: 30px;
    width: 80px;
    position: relative;
    top: -90px;
    right: -240px;
  }
  .services-name--1 {
    width: 100%;
    height: 100px;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 14px;
    letter-spacing: 0.5;
  }
  .Homepage-music-Geners-card1 {
    height: 150px;
    width: 300px;
  }
  .Homepage-music-Geners-card2 {
    height: 150px;
    width: 300px;
  }
  .Homepage-music-Geners-card3 {
    height: 150px;
    width: 300px;
  }
  .Homepage-music-Geners-card4 {
    height: 150px;
    width: 300px;
  }
  .Homepage-music-Geners-card5 {
    height: 150px;
    width: 300px;
  }
  .Homepage-music-Geners-card {
    gap: 15px;
    margin-left: 50px;
    margin-top: -48px;
    overflow: scroll;
  }
  .Homepage-music-Geners-card-p {
    padding: 0;
    margin: 0;
    text-align: center;
    color: white;
    font-size: 20px;
    font-weight: 400;
    padding: 20px;
    padding-top: 30px;
  }

  .eat-out-image {
    width: auto;
    height: auto;
  }

  .club_about {
    width: 100%;
    height: auto;
    padding-left: 5px;
    padding-right: 5px;
  }

  .second-image {
    display: none;
  }

  .first-image {
    width: 100%;
    height: 200px;
    object-fit: contain;
  }

  .club-thumbnail-content-1 {
    padding-left: 6px;
  }

  .first-image p {
    font-size: 0px;
    font-weight: 400;
    color: rgb(255, 255, 255);
    position: absolute;
    padding: 5px 10px;

    z-index: 10;
  }

  .first-image img {
    height: 100%;
    width: 100%;
  }

  .homepage--carnival--event--p--content {
    color: #fff;
    font-family: helvetica;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    width: 90%;
    line-height: 11px;
    margin: 0;
    padding: 0;
    letter-spacing: 0.5px;
  }

  .event-card--new--year--pass--content1 {
    color: #fff;
    font-family: helvetica;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    padding: 0;
  }

  .event-card--new--year--pass--content2 {
    color: #fd2f71;
    font-family: helvetica;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    padding: 0;
  }

  .carousel-item img {
    height: auto;
    width: 100%;
  }

  .mobile--view--discount--add--on {
    display: block;
  }

  .mobile--view--discount--add--on {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
  }

  .carnival--homepage--nearby {
    margin-top: 0px;
  }

  .partywitty--pay--instant-discount-card--image {
    width: 100%;
    height: 150px;
    background: url("./image/party2.jpg");
    overflow: hidden;
    border-radius: 5px;
    background-size: cover;
  }

  .partywitty--pay--instant-discount-card--image--1 {
    width: 100%;
    height: 150px;
    border-radius: 5px;
    background: rgba(253, 47, 113, 0.86);
    padding-top: 20px;
    padding-left: 20px;
  }

  .mobile--view--discount--add--on1-1 {
    color: #fff;
    font-family: Helvetica;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    padding: 0;
  }

  .mobile--view--discount--add--on1-2 {
    margin: 0;
    padding: 0;
    color: #fff;
    font-family: Helvetica;
    font-size: 27px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .partywitty--pay--instant-discount-card--image--2 {
    width: 100%;
    height: 150px;
    border-radius: 5px;
    background: rgba(80, 188, 101, 0.8);
    padding-top: 20px;
    padding-left: 20px;
  }

  .thumbnail--rating-1-1 {
    height: 20px;
    width: 55px;
    border-radius: 3px 3px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #cc0c39;
  }

  .thumbnail--rating-1-1 span {
    font-size: 10px;
    color: #ffde21;
    padding-left: 0px;
  }

  .thumbnail--rating-3 {
    padding-top: 5px;
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .homepage--new--yaer--carnival--mobile--1 {
    display: block;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
  }

  .homepage--new--yaer--carnival--mobile--1--button {
    width: 230px;
    height: 35px;
    flex-shrink: 0;
    border-radius: 4px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  .homepage--new--yaer--carnival--mobile--1--button p {
    margin: 0;
    padding: 0;
    text-align: center;
    color: #141420;
    font-family: Helvetica;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    /* 16.8px */
  }
}

.party--teaser {
  width: 97%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: auto;
}

.party--teaser .p--teaser {
  font-size: 25px;
  font-weight: 600;
  color: white;
}

.teaser--images {
  width: 100%;
  height: 230px;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  column-gap: 20px;
}

.teaser--images::-webkit-scrollbar {
  display: none;
}

.teaser--images .teaser--card {
  width: 215px;
  height: 215px;
  background-color: white;
  border-radius: 5px;
}

.box {
  width: 300px;

  h1 {
    font-size: 20px;
    margin: 1rem 0 0 0;
  }

  .profile-img {
    background: #e8e8e8;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .skeleton-placeholder {
    background: linear-gradient(90deg, #e8e8e8 0px, #f8f8f8 40px, #e8e8e8 80px);
    background-size: 350px;
    width: 100%;
    height: 1.45rem;
    border-radius: 3px;
    margin-top: 1.5rem;
    animation: animation 2.5s infinite;
  }
  .partygoer--p-1 {
    margin: 0;
    padding: 0;
  }
  .partygoer--p-1 span {
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    color: white;
    margin-top: 0px;
    margin: 0;
    padding: 0;
    text-align: center;
    color: #fd2f71;
  }
}

@keyframes animation {
  0% {
    background-position: -100px;
  }

  40%,
  100% {
    background-position: 270px;
  }
}

@keyframes animation {
  0% {
    background-position: -100px;
  }

  40%,
  100% {
    background-position: 270px;
  }
}

