.club--rating--review--page{
    width: 100%;
    height: 400px;
    padding: 20px;
    background-color: rgba(65, 64, 65, 0.491);
    display: flex;
    flex-direction: row;
    margin: auto;
    justify-content: space-between;
}
.club--review--avg{
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    border: 1px dotted rgb(185, 184, 184);
    padding: 20px;
    padding-top: 50px;
}
.club--review--namewise--avg{
    width: 55%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    padding-top: 30px;
    
}
.club--review--namewise--avg1{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
   
}
.custom-modal{
    color: white;
    background-color: #151225;
}
.club--review--avg button{
    width: 100%;
    height: 40px;
    color: white;
    background-color: #1EbE92;
    border: none;
    border-radius: 5px;
}
.club--review--names{
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
}
.club--review--names p{
    width: 20%;
}
.club--review--namewise--avg1 input{
    background-color: #151225;
    border: 1px solid rgb(90, 90, 90);
    border-radius: 3px;
    padding: 5px;
}
.club--review--namewise--avg1 textarea{
    background-color: #151225;
    border: 1px solid rgb(90, 90, 90);
    border-radius: 3px;
    padding: 5px;
}
.custom-modal Button{
    background-color: #d71362;
    color: white;
}
@media (max-width: 640px) {
    .club--rating--review--page{
        width: 100%;
        height: auto;
        padding: 20px;
        background-color: rgba(65, 64, 65, 0.491);
        display: flex;
        flex-direction: column;
        margin: auto;
        justify-content: space-between;
    }
    .club--review--avg{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        border: 1px dotted rgb(185, 184, 184);
        padding: 20px;
        padding-top: 50px;
    }
    .club--review--namewise--avg{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        padding-top: 30px;
       
    }
    .club--review--namewise--avg1{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        border-radius: 5px;

        
    }
    .custom-modal{
        color: white;
        background-color: #151225;
    }
    .club--review--avg button{
        width: 100%;
        height: 40px;
        color: white;
        background-color: #1EbE92;
        border: none;
        border-radius: 5px;
    }
    .club--review--names{
        display: flex;
        flex-direction: row;
        gap: 20px;
        align-items: center;
    }
    .club--review--names p{
        width: 20%;
    }
    .club--review--namewise--avg1 input{
        background-color: #151225;
        border: 1px solid rgb(90, 90, 90);
        border-radius: 3px;
        padding: 5px;
    }
    .club--review--namewise--avg1 textarea{
        background-color: #151225;
        border: 1px solid rgb(90, 90, 90);
        border-radius: 3px;
        padding: 5px;
    }
    .custom-modal Button{
        background-color: #d71362;
        color: white;
    }
}