.blog-banner{
    width: 100%;
    height: 250px;
    background-image: url("./image/img1.avif");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-bottom: 30px;
}
.blog_img_card{
    position: relative;
}
.blog_img_card_content{
    position: absolute;
    top: 50px;
    height: 30px;
    width: 100px;
    background-color: #B4235A;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.blog_img_card_content p{
    margin: 0;padding: 0;
    font-size: 13px;
    letter-spacing: 0.5px;
    font-family: "open sans",sans-serif;
}
.blog-aboutus{
    display: flex;
    flex-direction: row;
    gap: 30px;
}
.blog-banner h2{
    font-size: 60px;
    font-weight: 500;
    color: white;
    font-family: "Open Sans",sans-serif;
   
}
.blog-button button{
    border: 2px solid black;
    background-color: #1D0B0B;
    border: none;
    color: white;
    height: 40px;
    width: 90px;
    border-radius: 5px;
}
.blog-button{
    display: flex;
    flex-direction: row;
    row-gap: 10px;
    column-gap: 20px;
    margin-bottom: 10px;
}
.blog-content{
    width: 100%;
    height: auto;
}

.blog-grid--box{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
}
.blog-h3{
    color: white;
    text-align: start;
    font-size: 22px;
    font-weight: 500;
    font-family: "Open sans", sans-serif;
}
.blog-p-white{
    color: white;
    text-align: start;
    font-size: 15px;
    font-weight: 400;
    font-family: "Open sans", sans-serif;
}
.blog-pink{
    color: #B4235A;
    text-align: start;
    font-size: 13px;
    font-family: "Open sans", sans-serif;
}



@media (min-width:602px) and (max-width: 1040px) {
    .blog-grid--box{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
    }
  }

@media (max-width: 601px) {
    .blog-grid--box {
        display: grid;
        grid-template-columns: 1fr;
        gap: 30px;
    }
   
    .aboutus{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .blog-aboutus {
        flex-direction: column;
        align-items: center;
    }
    /* .blog-accordian, .blog-content {
        width: 100%;
    } */
    .st-part {
        flex-direction: column;
    }
    .aboutus{
        padding-left: 1px;
        padding-right: 1px;
    }
  }



  /* css for blog deatil */

