body{
    background-color: #120829;
    margin: 0;padding: 0;
}
.deals{
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
}
.routes{
    text-decoration: none !important;
}
.deals-main-heading{
    color: white;
    font-size: 45px;
}
.deals-acc{
    background-color: #D71362;
}

.deals-and-offer{
    width: 100%;
    height:auto;
    margin-top: 50px;
    display: flex;
    flex-direction: row;
}

.third{
    width: 75%;
    height: auto;
    display: flex;
}

.checkbox-with-content p span{  
    margin-right: 6px; 
}
.checkbox-with-content p span input{  
    color: #D71362;
}
.white{
    color: white;
    margin: 0;
    padding-top: 3px;
}
.h4{
    color:white ;
}
.green{
    color: green;
}
.fst-part{
    width: 33%;
    height: 400px;
}
.fst-part{
    width: 100%;
    height: 100%;
}
.fst-partt{
  
    padding-right: 20px;
}
.fst-partt img{
    width: 400px;
    height: 300px;
}


.scond-part{
    width: 33%;
    height: auto;
}
.third-part{
    width: 33%;
    height: auto;
} 
.third-partt-first{
    height: 300px;
    width: 260px;
}
.third-partt p{
    padding-left: 20px;
    color: white;
}
.third-partt h3{
    padding-left: 20px;
    color: wheat;
}

.third-partt-first span{
    color: wheat;
}
.deal-info p{
    color: white;
    margin: 0;
    padding: 0;
}
.p-fst-card{
    color: #7A7A7E;
    padding: 0;margin: 0;
}


.deals-btn{
    color: #047DC3;
    border-color: #047DC3;
   padding-right: 10px;
   padding-left: 10px;
    border-radius: 5px;
    background-color: #1F1E2E;
}
.deals-third{
    width: 100%;
    height: auto;
    display:grid;
    grid-template-columns: 1fr;
    gap: 30px;
}



.accordion-header {
    --bs-accordion-active-bg:  #D71362;
  }
.bold{
    color: white;
    font-size: 18px;
    font-weight: bold;
    padding: 0;margin: 0;
}
.accordion-body{
    background-color: #0B051C;
}
.search-deal{
    background-color:  #0F0F3B;
    border-radius: 5px;
    border-style: none;
    height: 40px;
    width: 250px;
    margin-right: 8px;
    padding-left: 5px;
}
.search-btn{
    height: 40px;
    width: 40px;
    background-color: #1F1E2E;
    border-radius: 5px;
    border-style: none;
}


.scond-part-btn p{
    color: white;
    padding: 0;
    margin: 0;
}
.happeningParty-tags-allcards{
    padding-left: 130px;
}
   
.scond-part img{
   height: 100px;
   width: 170px;
   border-radius: 50%;
}
.scond-part{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}



.comming-soon {
    background-color: #120829;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .under-construction {
    text-align: center;
  }
  .under-construction p{
    font-size: 30px;
    color: white;
    position: relative;
    top: 170px;
    padding-left: 1000px;
  }
  .h1-comming-soon {
    font-size: 80px;
    margin-bottom: 20px;
    color: white;
  }
  .h2-comming-soon{
    font-size: 60px;
    margin-bottom: 20px;
    color: white;
  }
 
  



@media(max-width:640px){
    .deals{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }
    .image-slider{
        height: 200px;
        width: 100%;
    }
    .carousel-item img{
        height: auto;
        width: 100%;
    }
    .carousel-inner{
        height: auto;
        width: 100%;
        
    }
    .deals-main-heading{
        padding: 0;
        margin: 0;
        padding-left: 15px;
        font-size: 36px;
    }
    .content1-card{
        width: 120px;
        height: 136px;
    }
    .deals-content1{
        width: 100% !important;
        height: auto;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        overflow: scroll;
        padding-top: 15px;
    }
    
    .event span{
       padding-right: 25px;
    }
    .event{
        row-gap: 1px;
    }
    .clubs span{
        padding-right: 25px;
    }
    .clubs{
        row-gap: 1px;
    }
    .restaurant span{
        padding-right: 25px;
        padding-left: 8px;
    }
    .restaurant p{
        padding-right: 20px;
        text-align: center;
    }
    .restaurant{
        row-gap: 1px;
    }
    .beer{
        padding-right: 25px;
        row-gap: 1px;
    }
    .jump{
        padding-right: 25px;
        row-gap: 0.5px;
    }
    .fish{
        padding-right: 25px;
    }
    .gaming{
        padding-right: 25px;
    }
    .deals-and-offer{
        width: 100%;
        height:auto;
        display: flex;
        flex-direction: column;
        margin: 0;
        margin-top: 20px;
    }
    .second{
        width: 100%;
        height:auto;
        margin: 0;
    }
    .third{
        width: 100%;
        height: auto;
    }
    .deals-third{
        width: 100%;
        height: auto;
        display: grid;
        grid-template-columns:  1fr;
    }
    .deals-card{
        width: 100%;
        background-color: #1F1E2E;
        padding: 5px;
        display: flex;
        justify-content: flex-end;
    }
    .bold{
        font-size: 16px;
        margin: 0;padding: 0;
    }
    .white{
        font-size: 13px;
    }
    .deals-card p{
        font-size: 12px;
    }
    .deals-card h4{
        font-size: 16px;
    }
    .accordion-header button{
        padding: 0;
        margin: 0;
        padding: 7px;
       
    }
    .search-deal{
        border-radius: 5px;
        border-style: none;
        height: 35px;
        width: 90%;
        margin: 0;padding: 0;
    }
    .accordion-body{
        margin: 0;
        padding: 0;
    }
    .search-btn{
        height: 35px;
        width: 35px;
        font-size: 12px;
    }
    .fst-part{
        width: 58%;
        height: auto;
    }
    .scond-part{
        width: 42%;
        height: auto;
    }
    .deals-card{
        width: 100%;
    }
    .deals-btn{
       padding-right: 5px;
       padding-left: 5px;
    }
    .scond-part-btn{
        
        height: 25px;
        width: 100px;
        margin-left: 20px;
    }
    .scond-part img{
        height: 70px;
        width: 140px;
     }
    .deals-and-offers-btn{
        margin-top: 10px;
        display:flex ;
        flex-direction: row;
        column-gap: 10px;
        padding-top: 20px;
    }
    .add{
       height: 40px;
       width: 60px;
       margin-right: 10px;
    }
    .scond-part-price{
        font-size: 50px;
        padding-left: 20px;
    }
  
      
      .under-construction {
        text-align: center;
      }
      .under-construction p{
        font-size: 19px;
        color: white;
        position: relative;
        top: 200px;
        padding-left: 150px;
      }
      .h1-comming-soon {
        font-size: 35px;
        margin-bottom: 20px;
        color: white;
      }
      .h2-comming-soon{
        font-size: 30px;
        margin-bottom: 20px;
        color: white;
      }
     
      
    
    
   
}