.review_section {
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 70%;
    color: #fff;
    margin-left: 50px;
    margin-top: 30px;
}

.review_inner_div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.review_section .first h3 img {
    margin-right: 10px;
}

.review_inner_div .food {
    display: flex;
    justify-content: space-between;
}
.review_inner_div .div_2 h4 {
    color: #FFF;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    margin-top: 20px !important;
}
.review_inner_div .div_2 .line {
    margin-top: 25px;
    /* width: 60%; */
    height: 5px;
    border-radius: 16px;
    background: #FFB547;
}
.review_inner_div .div_2 h5 {
    color: #959393;
    text-align: right;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-style: normal;
    line-height: 16px !important;
    font-weight: 500;
    margin-top: 20px;
}
.offer--img--heading{
    padding-left: 65px;
}
.review_inner_div .div_1 .box {
    width: 100%;
    height: 150px;
    display: flex;
    padding: 32px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;
    border-radius: 6px;
    background: rgba(250, 250, 250, 0.10);
}
.review_inner_div .div_1 .box .rating {
    display: grid;
    place-items: center;
}
.review_inner_div .div_1 .box .rating h3 {
    color: #FFF;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-size: 56px;
    font-style: normal;
    font-weight: 500;
    line-height: 56px;
}
.review_inner_div .div_1 .box .rating h4 {
    color: #FFF;
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    opacity: 0.3;
}


.progress_section {
    width: auto;
    /* max-width: 700px; */
    padding: 0rem 1rem;
    margin: 0 auto;
}

.task_progress {
    display: flex;
    align-items: center;
    gap: 10px;
}

.task_progress p,
.progress_section span {
    color: #eee;
}

.progress {
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    border-radius: 10px;
    height: 10px;
}

.progress::-webkit-progress-bar {
    background-color: #f0f0f0;
}

.progress::-webkit-progress-bar,
.progress::-webkit-progress-value {
    border-radius: 10px;
}

.progress::-moz-progress-bar {
    border-radius: 10px;
}

.progress1::-webkit-progress-value {
    background: #FFB547;
}


.testimonial{
    background: rgba(250, 250, 250, 0.10);
    width: 100%;
    height: auto;
    margin-top: 2rem;
    border-radius: 6px;

}

.testimonial_Box{
    display: flex;
}
.image_section img{
    padding: 1rem 1rem;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
}
.review_section2 {
    /* border: solid black 2px; */
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 1rem;
    margin-right: 2rem;

}

.review_name {
    display: flex;
    flex-direction: row;
    align-items: center; /* Center vertically within the container */
}

.review_name h3 {
    margin: 0;
}

.review_name p {
    margin-top: 2rem;
    margin-left: 1rem; /* Adjust as needed */
    opacity: 0.2;
    font-size: 12px;
}

.starts2 {
    margin-top: 1rem;
    /* Adjust the size of the stars container */
    width: 200px; /* Adjust width as needed */
    height: 50px; /* Adjust height as needed */
}

.testimonial_para{
    padding: 5px 20px;
    margin-left: 5.5rem;
}
.testimonial_para p{
    font-family: Lato;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
}
.testimonial_para img{
    width: 100px;
    height: 100px;
    border-radius: 6px;
}

.seemore{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}

@media screen and (max-width: 768px) {
    .review_inner_div {
        flex-direction: column;
        align-items: center;
    }

    .div_2{
        margin-top: 30px;
    }
    .review_section2{
        flex-wrap: wrap;
    }
    .testimonial_Box{
        flex-direction: column;
        margin-left: 15px;
    }
    .testimonial_para{
        padding: 5px 20px;
        margin-left: 0rem;
    }
    .review_section {
        display: flex;
        flex-direction: column;
        padding: 0;
        width: 95%;
        color: #fff;
        margin-left: 20px;
        margin-top: 30px;
    }
    
}



