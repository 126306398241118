.CardContainer {
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 70%;
  color: #fff;
  margin-left: 40px;
}

.Menucontent {
  display: flex;
  gap: 0.5rem;
  overflow-x: scroll;

}
.MenuContainer {
  cursor: pointer;
  padding: 1rem 0.5rem;
  width: 100%;
}
.MenuContainer img {
  width: 300px;
  height: 300px;
  object-fit: cover;
  /* aspect-ratio: 1/1; */
  border-radius: 5px;
}
.MenuContainer h2{
  margin-top: 10px;
  text-wrap: nowrap;
  font-size: 20px;
}
.MenuContainer p {
  opacity: 70%;
}

@media screen and (max-width: 768px){
  .CardContainer {
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 95%;
    color: #fff;
    margin-left: 20px;
  }
}