.carnival-pass-card {
    background-color: #fff;
    border: 1px solid #e7e7e7;
    border-radius: 16px;
    max-width: 400px;
    position: relative;
    cursor: pointer;
    margin-top: 30px;
    padding-bottom: 30px;
}

.carnival-pass-icon {
    width: 100%;
    height: 200px;
    border-radius: 16px;
}

.carnival-pass-tags {
    position: absolute;
    top: 20px;
    z-index: 1;
    left: -2px;
}

.carnival-pass-tag {
    background-color: grey;
    color: white;
    padding: 2px 10px;
    border-radius: 5px 5px 5px 5px;
    margin-top: 3px;
    min-width: 100px;
}

.carnival-pass-timing {
    position: absolute;
    top: 20px;
    z-index: 1;
    right: 0px;
}

.carnival-pass-sponsored {
    position: absolute;
    top: 60px;
    z-index: 1;
    right: 0px;
}

.carnival-pass-sponsored img {
    width: 150px;
    height: auto;
}

.carnival-pass-content {
    padding: 8px 16px 16px;
}

.carnival-pass-name {
    font-weight: 900;
    font-size: 18px;
    line-height: 28px;
    color: #000;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    flex: 1;
    padding-right: 10px;
}

.carnival-pass-pricing {
    display: flex;
    flex-direction: row;
    border: 1px solid;
    border-radius: 8px;
    margin: 5px;
    padding: 5px;
    background-color: #f9f9f9;
}

.carnival-pass-coupons {
    background-color: #bde9a3;
}

.carnival-pass-similar {
    font-weight: 900;
    font-size: 12px;
    line-height: 14px;
    color: #008cff;
    background: #daeeff;
    border: 1px solid #008cff;
    border-radius: 30px 50px 0 30px;
    padding: 4px 12px 4px 12px;
    position: absolute;
    z-index: 1;
    right: 21px;
    cursor: pointer;
    top: -24px;
}