.checkoutformpage{
    width: 40%;
    height: auto;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 20px;
}
.checkoutformpage button{
    width: 100%;
    height: auto;
    padding: 5px;
    margin-top: 20px;
    border: none;
    background-color: #147bf1;
    border-radius: 5px;
    margin: auto;
    color: white;
}
.payment-page{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.wrapper-1{
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    height: auto;
    width: 700px;
    border: 1px solid grey;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 40px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.wrapper-1 img{
    height: 120px;
    width: auto;
    border-radius: 50%;
}
.wrapper-2{
    text-align: center;
    color: #fff;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
.wrapper-2 button{
    height: 50px;
    width: 150px;
    background-color: #d71362;
    color: #fff;
    border-radius: 5px;
    padding: 10px;
    border: none;
    margin-bottom: 30px;
}
.thankew_page_link{
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    justify-content: center;
}
.thankew_page_link span{
    height: 50px;
    width: auto;
    color: #d71362;
}
.wrapper-2 p a{
    color: #d71362;
}
.wrapper-2 h1{
   font-size: 24px;
   font-family: "open sans",sans-serif;
   font-weight: 600;
   letter-spacing: 2px;
}
.wrapper-2 p{
    font-size: 14px;
    font-family: "open sans",sans-serif;
    font-weight: 500;
    letter-spacing: 1px;
 }
@media (max-width: 640px) {
    .checkoutformpage {
        width: 90%;
        height: auto;
        display: flex;
        flex-direction: column;
        margin: auto;
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        justify-content: center;
    }
    .wrapper-1{
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
        height: auto;
        width: 350px;
        border: 1px solid grey;
        border-radius: 5px;
        padding: 20px;
        margin-bottom: 40px;
    }
    .wrapper-1 img{
        height: 90px;
        width: auto;
        border-radius: 50%;
    }
    .wrapper-2 h1{
        font-size: 18px;
        font-family: "open sans",sans-serif;
        font-weight: 600;
        letter-spacing: 2px;
     }
     .wrapper-2 p{
         font-size: 12px;
         font-family: "open sans",sans-serif;
         font-weight: 500;
         letter-spacing: 1px;
      }
} 
