.Favorate--Hangout--page {
    margin-top: -80px;
  }
  .Group--booking--view--all--left {
    width: 100%;
    height: auto;
  }
  .Group--booking--view--all--right {
    width: 0%;
    height: auto;
  }
  .Group--booking--view--all--card {
    display: flex;
    flex-direction: column;
  }
  

  .Favorate--Hangout--page--card-banner-img img {
    width: 100%;
    height: 350px;
    margin-top: 80px;
  }
  .form-control{
    width: 300px !important;
  }
  .Favorate--Hangout--page--card {
    position: relative;
  }
  .Favorate--Hangout--page--card-banner-Search {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    justify-content: center;
    align-items: center;
  }
  .Favorate--Hangout--page--card-banner-Search img {
    height: 90px;
    width: 90px;
    position: relative;
    top: 20px;
  }
  .Favorate--Hangout--page--card-banner-Search8 {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    justify-content: center;
    align-items: center;
  }
  .Favorate--Hangout--page--card-banner-Search8 img {
    height: 40px;
    width: auto;
    position: relative;
    top: 10px;
  }
  .Favorate--Hangout--page--p {
    font-family: "Open Sans", Ubuntu, sans-serif;
    font-size: 40px;
    font-weight: 700;
    margin: 0;
    padding: 0;
    letter-spacing: 1px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
  }
  .Book--a--table--view--all--gap{
    margin-top: 50px;
    display:flex;
    flex-direction: column;
    row-gap: 70px;
    padding-left: 40px;
    padding-right: 40px;
  }
  .Favorate--Hangout--page--p1 {
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    letter-spacing: 1px;
    text-align: center;
    width: 80%;
    color: #fff;
  }
  .Favorate--Hangout--page--p2 {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    letter-spacing: 1px;
    opacity: 0.8;
    text-align: center;
    width: 80%;
    color: #fff;
  }
  .Favorate--Hangout--page--card-banner-Search--1 input {
    width: 450px;
      height: 45px;
      border-radius: 5px;
      outline: none;
      color: #fff;
      font-family: "Open Sans", Ubuntu, sans-serif;
      border: none;
      padding-left: 20px;
      background: rgb(255 255 255 / 18%);
      margin-top: 0px;
      font-size: 14px;
  }
  .Favorate--Hangout--page--card-banner-Search--1 span {
    position: relative;
    left: -40px;
    color: #fff;
  }
  .Favorate--Hangout--page--card-banner-Search--1 input::placeholder {
    color: #fff;
  }
  .Favorate--Hangout--page {
    display: flex;
    flex-direction: column;
    row-gap: 50px;
  }
  .Favorate--Hangout--page--1 {
    display: flex;
    flex-direction: column;
    row-gap: 50px;
    padding-left: 60px;
    padding-right: 60px;
    margin-top: 50px;
  }
  .Favorate--club--category--card {
    height: 240px;
    width: auto;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }
  .Favorate--club--category::-webkit-scrollbar {
    width: 1px;
  }
  .Favorate--club--category::-webkit-scrollbar-track {
    background-color: transparent;
  }
  .Favorate--club--category::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }
  .Favorate--club--category {
    display: flex;
    overflow-x: auto;
    column-gap: 30px;
    max-width: 100%;
    transition: transform 0.3s ease;
  }
  .Favorate--club--category--card img {
   height: 200px;
   width: 100%;
    border-radius: 80px 80px 4px 4px;
  }
  .Favorate--club--category--card p {
    color: #fff;
    font-family: "Open Sans",sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    margin: 0;padding: 0;
    margin-top: 7px;
  }
  .Favorate--club--category--1 button {
    height: 45px;
    width: 278px;
    border: none;
    outline: none;
    border-radius: 50px;
    background-color: #211738;
    border: 0.5px solid #fff;
    color: #fff;
    text-transform: uppercase;
    font-family: "Open Sans", Ubuntu, sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin: 0;padding: 0;
    padding: 5px 20px;
  }
  .favorate--hangout--search--result p {
    font-family: "Open Sans", Ubuntu, sans-serif;
    font-size: 17px;
    font-weight: 400;
    color: #fff;
    opacity: 0.5;
    margin: 0;
    padding: 0;
    letter-spacing: 0.8px;
  }
  .Favorate--hangout--all--thumbnail--card--img--1-2 {
    height: 25px;
    width: 70px;
    background-color: rgba(191, 186, 186, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px 0px 4px 0px;
  }
  .Favorate--hangout--all--thumbnail--card--club--logo img {
    height: 60px;
    width: 72px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px 20px 0px 20px;
  }
  .Favorate--hangout--all--thumbnail--card--img--1-2 p {
    font-size: 9px;
    font-family: "Open Sans", Ubuntu, sans-serif;
    font-weight: 400;
    margin: 0;
    padding: 0;
    color: #000;
  }
  .Favorate--hangout--all--thumbnail--card--img img {
    height: 230px;
    width: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    object-fit: cover;
  }
  .Favorate--hangout--all--thumbnail--card--img--1--img {
    height: 230px;
    width: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    object-fit: cover;
  }
  .Favorate--hangout--all--thumbnail--card--img--1--img-upcomming {
    height: 320px;
    width: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    object-fit: contain;
  }
  .Favorate--hangout--all--thumbnail--card--2--1 {
    width: 100%;
    height: 45px;
    background-color: #fd2f71;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
  }
  .favorate--add--on {
    display: flex;
    flex-direction: row;
  }
  .favorate--add--on--card {
    height: 40px;
    width: 37px;
    background: #cced2a;
    border-radius: 0px 0px 4px 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .favorate--add--on--card1 {
    height: 40px;
    width: 37px;
    background: #eece61;
    border-radius: 0px 0px 4px 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }
  .favorate--add--on--card1 p {
    margin: 0;
    padding: 0;
  }
  .Favorate--hangout--all--thumbnail--card--img {
    position: relative;
  }
  
  .Favorate--hangout--all--thumbnail--card--img--2 {
    position: relative;
  }
  .Favorate--hangout--club--all-gap {
    display: flex;
    flex-direction: column;
    row-gap: 70px;
    padding-left: 40px;
    padding-right: 40px;
  }
  .Favorate--hangout--sponsered--8{
      margin-top: 30px;
      margin-bottom: 30px;
  }
  .Favorate--hangout--all--thumbnail--card--img--1 {
    position: absolute;
    top: 0;
    left: 0;
  }
  .Favorate--hangout--all--thumbnail--card--2 {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .favorate--add--on--card p {
    margin: 0;
    padding: 0;
  }
  .favorate--add--on--card--p {
    color: #1c1819;
    font-size: 18px;
    font-family: "Open Sans", Ubuntu, sans-serif;
    font-weight: 900;
    position: relative;
    top: 3px;
  }
  .favorate--add--on--card--p--1 {
    color: #1c1819;
    font-size: 13px;
    font-family: "Open Sans", Ubuntu, sans-serif;
    font-weight: 600;
    position: relative;
    top: -5px;
  }
  .favorate--add--on--p--1 {
    font-size: 8px;
    font-weight: 300;
    color: #fff;
    font-family: "Open Sans", Ubuntu, sans-serif;
  }
  .favorate--add--on--p--2 {
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    font-family: "Open Sans", Ubuntu, sans-serif;
  }
  .favorate--add--on--p--1--1 {
    font-size: 8px;
    font-weight: 300;
    color: #fff;
    font-family: "Open Sans", Ubuntu, sans-serif;
    text-align: right;
  }
  .favorate--add--on--p--2--1 {
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    font-family: "Open Sans", Ubuntu, sans-serif;
    text-align: right;
  }
  .favorate--add--on--p p {
    margin: 0;
    padding: 0;
  }
  .favorate--add--on--p {
    padding-left: 10px;
    padding-top: 9px;
  }
  .favorate--offered--card {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .favorate--offered--card--1 {
    font-size: 10px;
    font-weight: 400;
    color: #fff;
    font-family: "Open Sans", Ubuntu, sans-serif;
    margin: 0;
    padding: 0;
    position: relative;
    top: 5px;
  }
  .favorate--offered--card--days p {
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    font-family: "Open Sans", Ubuntu, sans-serif;
    margin: 0;
    padding: 0;
    padding-top: 3px;
  }
  .Favorate--hangout--all--thumbnail--card {
    height: auto;
    width: 100%;
    padding: 15px;
    background: #211738;
    border-radius: 4px;
  }
  .Favorate--hangout--all--thumbnail--card6 {
    height: auto;
    flex: 0 0 350px;
    padding: 15px;
    background: #211738;
    border-radius: 4px;
  }
  .Favorate--hangout--all--thumbnail--card1 {
    width: 340px;
    height: auto;
    padding: 15px;
    background: transparent;
    border-radius: 4px;
  }
  .Favorate--hangout--all--thumbnail--card1-upcomming {
    width: 280px;
    height: auto;
    padding: 15px;
    background: transparent;
    border-radius: 4px;
    background-color: #211738;
  }
  .Favorate--hangout--card--content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
  }
  .Favorate--hangout--all--thumbnail {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }
  .favorate--hangout--review--card img {
    height: 13px;
    width: 13px;
  }
  .Favorate--hangout--card--content--1 p {
    margin: 0;
    padding: 0;
  }
  .Favorate--hangout--card--content--1--p1 {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    font-family: "Open Sans", Ubuntu, sans-serif;
    letter-spacing: 0.9px;
  }
  .Favorate--hangout--card--content--1--p2 {
    font-size: 14px;
    font-weight: 300;
    color: #fff;
    opacity: 0.5;
    font-family: "Open Sans", Ubuntu, sans-serif;
    letter-spacing: 1px;
  }
  .favorate--hangout--review--card {
    height: 25px;
    width: 55px;
    background-color: #372E4C;
    display: flex;
    flex-direction: row;
    column-gap: 7px;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    margin-bottom: 10px;
  }
  .favorate--hangout--review--card5 {
    height: 25px;
    width: 55px;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    column-gap: 7px;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    margin-bottom: 10px;
  }
  .favorate--hangout--review--card p {
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    font-family: "Open Sans", Ubuntu, sans-serif;
    margin: 0;
    padding: 0;
    letter-spacing: 0.6px;
  }
  .favorate--hangout--address--p2 {
    font-size: 13px;
    font-weight: 400;
    color: #fff;
    font-family: "Open Sans", Ubuntu, sans-serif;
    margin: 0;
    padding: 0;
    opacity: 0.5;
    letter-spacing: 0.6px;
    text-align: right;
  }
  .favorate--hangout--address--p5 {
    font-size: 13px;
    font-weight: 400;
    color: #fff;
    font-family: "Open Sans", Ubuntu, sans-serif;
    margin: 0;
    padding: 0;
    opacity: 0.5;
    letter-spacing: 0.6px;
   
  }
  .favorate--hangout--address--p8 {
    font-size: 13px;
    font-weight: 400;
    color: #fff;
    font-family: "Open Sans", Ubuntu, sans-serif;
    margin: 0;
    padding: 0;
    opacity: 0.5;
    letter-spacing: 0.6px;
    text-align: left;
  }
  .Favorate--hangout--all--thumbnail--card1:hover {
    background-color: #211738;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  .Favorate--hangout--all--thumbnail--card--img--2 img{
      transition: transform 0.3s ease;
  }
  
  .Favorate--hangout--all--thumbnail--card--img--2 img:hover {
      transform: scale(1.1);
  }
  .Favorate--hangout--all--thumbnail--card--img img{
      transition: transform 0.3s ease;
  }
  .Favorate--hangout--all--thumbnail--card--img img:hover {
      transform: scale(1.1);
  }
  .favorate--hangout--review--card1 {
    display: flex;
    flex-direction: row-reverse;
  }
  .favorate--hangout--address {
    display: flex;
    flex-direction: column;
    width: 40%;
  }
  .Favorate--hangout--card--content--1--direction img {
    height: 15px;
    width: 15px;
    margin-right: 6px;
  }
  .Favorate--hangout--card--content--1--direction {
    font-size: 12px;
    font-weight: 400;
    color: #fd2f71;
    font-family: "Open Sans", Ubuntu, sans-serif;
    margin: 0;
    padding: 0;
  }
  .Favorate--hangout--card--content--1 {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    width: 60%;
  }
  .Favorate--hangout--card--content--1 p {
    margin: 0;
    padding: 0;
    width: 100%;
  }
  .Favorate--Hangout--page--card-banner-Search--1 span {
    font-size: 20px;
  }
  .slick-slide > div{
      margin: 0 15px;
  }
  .slick-list{
      margin: 0 -15px;
  }
  .favorate--hangout--search--result-1--1 {
    color: #fd2f71 !important;
    text-align: center !important;
    font-size: 14px;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    margin-top: 20px;
  }
  .group--booking--thumbnail--livemusic--card1 {
    height: 30px;
    width: 100px;
    background-color: #cbec29;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 13px;
    border-radius: 0px 10px 0px 10px;
  }
  .group--booking--thumbnail--livemusic--card1 img {
    height: 18px;
    width: 18px;
  }
  .group--booking--thumbnail--livemusic--card1 p {
    font-size: 11px;
    font-weight: 400;
    color: #030201;
    font-family: "Open Sans", Ubuntu, sans-serif;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
  }
  .group--booking--thumbnail--livemusic--card--2 {
    position: absolute;
    left: 0;
    top: 90px;
  }
  .group--booking--thumbnail--livemusic--card {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }
  .group--booking--thumbnail--overlay {
    position: absolute;
    top: 0;
    left: 0;
  }
  .group--booking--thumbnail--overlay--1 img {
    height: 100px;
    width: 100px;
    object-fit: cover;
  }
  .selected2 {
      border: none;
      outline: none;
      border: 1px solid #fd2f71;
      background-color: #390a34;
    border-radius: 80px 80px 4px 4px;
  }
  .selected1 button {
    height: 45px;
    width: 275px;
    border: none;
    outline: none;
    border-radius: 50px;
    background-color: #390A34;
    color: #fff;
    text-transform: uppercase;
    font-family: "Open Sans", Ubuntu, sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin: 0;padding: 0;
    padding: 5px 20px;
    border: 0.5px solid #fd2f71;
  }
  .Favorate--hangout--all--thumbnail--card--package {
    width: 400px;
    height: auto;
    padding: 15px;
    background: #211738;
    border-radius: 4px;
  }
  .Group--booking--view--all--right--1 {
    width: 100%;
    height: 200px;
    border-radius: 4px;
    border: 1px solid #43434d;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    justify-content: center;
    align-items: center;
  }
  .Group--booking--view--all--right--1-i {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    justify-content: center;
    align-items: center;
  }
  
  .Group--booking--view--all--right--1-i--2 p {
    color: #fff;
    font-size: 12px;
    font-family: "Open Sans", Ubuntu, sans-serif;
    font-weight: 500;
    margin: 0;
    padding: 0;
    text-align: center;
  }
  .Group--booking--view--all--right--1-i--2 img {
    position: relative;
    left: 28px;
    padding-bottom: 10px;
  }
  .Group--booking--view--all--right--1 h1 {
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    letter-spacing: 1px;
    font-family: "Open Sans", Ubuntu, sans-serif;
  }
  .Group--booking--view--all--right--2 {
    height: auto;
    width: 100%;
    background-color: #0a0a10;
    padding: 22px 15px;
    display: flex;
    flex-direction: column;
    row-gap: 18px;
  }
  .Group--booking--venue--type--card {
    height: 37px;
    width: 101px;
    border-radius: 4px;
    background-color: transparent;
    border: 1px solid #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
  }
  .Group--booking--venue--type--card p {
    color: #fff;
    font-size: 12px;
    font-family: "Open Sans", Ubuntu, sans-serif;
    margin: 0;
    padding: 0;
  }
  .Group--booking--venue--type {
    display: flex;
    flex-direction: row;
    column-gap: 15px;
  }
  .Group--booking--view--all--right--2--p {
    color: #fff;
    font-size: 13px;
    font-family: "Open Sans", Ubuntu, sans-serif;
    margin: 0;
    padding: 0;
  }
  .Group--booking--view--all--right--2--card {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
  .Group--booking--venue--type--card-1 {
    height: 45px;
    width: 105px;
    background-color: transparent;
    border: 1px solid #fff;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
  }
  .Group--booking--venue--type--card-1--p {
    color: #fff;
    font-size: 12px;
    font-family: "Open Sans", Ubuntu, sans-serif;
    font-weight: 500;
    margin: 0;
    padding: 0;
    position: relative;
    top: 3px;
  }
  .Group--booking--venue--type--card-1--p1 {
    color: #fff;
    font-size: 13px;
    font-family: "Open Sans", Ubuntu, sans-serif;
    font-weight: 400;
    opacity: 0.6;
    margin: 0;
    padding: 0;
    position: relative;
    top: -3px;
  }
  .Group--booking--form--border--line {
    height: 0.5px;
    width: 100%;
    background-color: #fff;
    opacity: 0.2;
    margin-top: 3px;
    margin-bottom: 3px;
  }
  .group--booking--male--increment {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .group--booking--male--increment p {
    color: #fff;
    font-size: 16px;
    font-family: "Open Sans", Ubuntu, sans-serif;
    font-weight: 400;
    margin: 0;
    padding: 0;
  }
  .group--booking--male--increment--card {
    height: 40px;
    width: 105px;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
  .group--booking--choose--package {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
  .group--booking--choose--package p {
    color: #fff;
    font-size: 12px;
    font-family: "Open Sans", Ubuntu, sans-serif;
    font-weight: 400;
    margin: 0;
    padding: 0;
  }
  .Group--booking--all-card--1 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .group--booking--choose--package select {
    height: 45px;
    width: 152px;
    background-color: transparent;
    border: 1px solid #535358;
    border-radius: 4px;
    color: #fff;
  }
  .Group--Booking--allow--whatsapp--no {
    height: 120px;
    width: 100%;
    background-color: #282832;
    display: flex;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
  }
  
  .Group--Booking--allow--whatsapp--no--1 {
    height: 60px;
    width: 100%;
    background-color: #0a0a10;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
  }
  .Group--Booking--allow--whatsapp--no--1--card img {
    height: 30px;
    width: auto;
  }
  .Group--Booking--allow--whatsapp--no--1--card {
    display: flex;
    flex-direction: row;
    column-gap: 15px;
  }
  .Group--Booking--allow--whatsapp--no--1--card p {
    color: #fff;
    font-size: 12px;
    font-family: "Open Sans", Ubuntu, sans-serif;
    font-weight: 400;
    margin: 0;
    padding: 0;
  }
  .Group--Booking--allow--whatsapp--no--1--card p span {
    color: #fd2f71;
  }
  .Group--booking--view--all--right button {
    height: 55px;
    width: 100%;
    color: #fff;
    font-size: 15px;
    font-family: "Open Sans", Ubuntu, sans-serif;
    font-weight: 400;
    background-color: #fd2f71;
    border: none;
    outline: none;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  .favorate--hangout--city--wise--club--page {
    display: flex;
    flex-direction: column;
    row-gap: 50px;
  }
  .homepage--slider-mobile{
    display: none;
  }
  .Favorate--club--category--8--mobile{
    display: none;
  }
  .Newly_Added_Club_card{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
  }
  .package-not-available{
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    justify-content: center;
    align-items: center;
    margin: 25px 0px;
  }
  .package-not-available-h1{
    font-size: 20px;
    color: #fff;
    font-weight: 500;
    letter-spacing: 1px;
    font-family: "open sans",sans-serif;
  }
  .package-not-available-p{
    font-size: 14px;
    color: #fff;
    font-weight: 500;
    letter-spacing: 1px;
    font-family: "open sans",sans-serif;
  }
  @media(min-width:641px) and (max-width:1020px){
    .Favorate--hangout--all--thumbnail {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
    }
    .Favorate--club--category--8--mobile{
      display: block;
    }
    .Newly_Added_Club_card{
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }
    .services1 {
      width: 100%;
      height: auto;
      gap: 15px;
      margin-top: 10px;
      display: flex;
      overflow-x: auto;
      transition: transform 0.3s ease;
      padding-right: 7px;
    }
    .services1::-webkit-scrollbar {
      width: 1px;
    }
  
    .services1::-webkit-scrollbar-track {
      background-color: transparent;
    }
  
    .services1::-webkit-scrollbar-thumb {
      border-radius: 10px;
    }
    .Favorate--club--category--card {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      justify-content: center;
      align-items: center;
      flex: 0 0 160px;
      height: 200px;
    }
    .Favorate--club--category--card p {
      color: #fff;
      font-size: 12px;
      margin: 0;padding: 0;
      margin-bottom: 7px;
    }
    .Favorate--club--category--card img {
      height: 150px;
      width: 100%;
       border-radius: 80px 80px 4px 4px;
     }
     .Favorate--hangout--club--all-gap {
      display: flex;
      flex-direction: column;
      row-gap: 50px;
      padding-left: 40px;
      padding-right: 40px;
  }

  }
  @media (min-width: 0px) and (max-width: 640px) {
    .Favorate--hangout--all--thumbnail--card1 {
      width: 300px;
      height: auto;
      padding: 10px;
      background: #211738;
      border-radius: 4px;
      border: 1px solid #211738;
    }
    .Newly_Added_Club_card{
      display: grid;
      grid-template-columns: 1fr;
      gap: 10px;
    }
    
    .Favorate--hangout--all--thumbnail--card--package {
      width: 270px;
      height: auto;
      padding: 10px;
      background: #211738;
      border-radius: 4px;
    }
    .Favorate--club--category--8--mobile{
      display: block;
    }
    .homepage--slider-mobile{
      display: block;
    }
    .Favorate--club--category--card {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      justify-content: center;
      align-items: center;
      flex: 0 0 117px;
      height: 165px;
    }
    .package-not-available-p{
      font-size: 12px;
      color: #fff;
      font-weight: 500;
      letter-spacing: 1px;
      font-family: "open sans",sans-serif;
    }
    .Favorate--club--category {
      display: flex;
      overflow-x: auto;
      column-gap: 15px;
      max-width: 100%;
      transition: transform 0.3s ease;
    }
    .form-control{
      width: 250px !important;
    }
    .Favorate--hangout--all--thumbnail {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      justify-content: center;
      align-items: center;
    }
    .Favorate--hangout--club--all-gap {
      display: flex;
      flex-direction: column;
      row-gap: 40px;
      padding-left: 10px;
      padding-right: 10px;
    }
    .Book--a--table--view--all--gap{
      margin-top: 50px;
      display:flex;
      flex-direction: column;
      row-gap: 70px;
      padding-left: 10px;
      padding-right: 10px;
    }
    .Favorate--club--category--1 button {
      height: 40px;
      width: 228px;
      border: none;
      outline: none;
      border-radius: 50px;
      background-color: #211738;
      border: 1px solid #fff;
      color: #fff;
      text-transform: uppercase;
      font-family: "Open Sans", Ubuntu, sans-serif;
      font-size: 11px;
      font-weight: 400;
      padding: 10px;
    }
    .selected1 button {
      height: 40px;
      width: 238px;
      border: none;
      outline: none;
      border-radius: 50px;
      background-color: #211738;
      color: #fd2f71;
      text-transform: uppercase;
      font-family: "Open Sans", Ubuntu, sans-serif;
      font-size: 11px;
      font-weight: 300;
      border: 1px solid #fd2f71;
    }
    .Favorate--Hangout--page--1 {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      padding-left: 10px;
      padding-right: 10px;
      margin-top: 25px;
    }
    .favorate--offered--card--days p {
      font-size: 10px;
      font-weight: 500;
      color: #fff;
      font-family: "Open Sans", Ubuntu, sans-serif;
      margin: 0;
      padding: 0;
      padding-top: 7px;
    }
    .Favorate--club--category--card p {
      color: #fff;
      font-size: 12px;
      margin: 0;padding: 0;
      margin-bottom: 7px;
    }
    .Favorate--Hangout--page--card-banner-img img {
      width: 100%;
      height: 280px;
      margin-top: 80px;
      object-fit: cover;
    }
    .Favorate--Hangout--page--card-banner-Search img {
      height: 30px;
      width: 30px;
      position: relative;
      top: 10px;
    }
    .Group--booking--all-card--1 {
      padding-left: 15px;
      padding-right: 15px;
    }
    .package-not-available-h1{
      font-size: 16px;
      color: #fff;
      font-weight: 500;
      letter-spacing: 1px;
      font-family: "open sans",sans-serif;
    }
    .Favorate--Hangout--page--p {
      font-family: "Open Sans", Ubuntu, sans-serif;
      font-size: 20px;
      font-weight: 600;
      margin: 0;
      padding: 0;
      letter-spacing: 1px;
      text-align: center;
      color: #fff;
      text-transform: uppercase;
    }
    .Favorate--Hangout--page--card-banner-Search8 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      row-gap: 15px;
      margin-top: 80px;
    }
    .Favorate--Hangout--page--card-banner-Search8 img {
      height: 30px;
      width: 60px;
      position: relative;
      top: 0;
    }
    .Favorate--Hangout--page--p1 {
      font-family: "Open Sans", Ubuntu, sans-serif;
      font-size: 15px;
      font-weight: 500;
      margin: 0;
      padding: 0;
      letter-spacing: 1px;
      text-align: center;
      width: 90%;
      color: #fff;
    }
    .Favorate--Hangout--page--p2 {
      font-family: "Open Sans", sans-serif;
      font-size: 12px;
      font-weight: 200;
      margin: 0;
      padding: 0;
      letter-spacing: 1px;
      opacity: 0.8;
      text-align: center;
      width: 80%;
      color: #fff;
    }
    .Favorate--Hangout--page--card-banner-Search {
      position: absolute;
      top: 70%;
      left: 35%;
      transform: translate(-30%, -55%);
      display: flex;
      flex-direction: column;
      row-gap: 15px;
      justify-content: center;
      align-items: center;
    }
    .Favorate--hangout--all--thumbnail--card {
      height: auto;
      width: 330px;
      padding: 15px;
      background: #211738;
      border-radius: 4px;
    }
    .Favorate--hangout--all--thumbnail--card6 {
      height: auto;
      flex: 0 0 330px;
      padding: 15px;
      background: #211738;
      border-radius: 4px;
    }
    .Favorate--hangout--card--content--1--p1 {
      font-size: 14px;
      font-weight: 600;
      color: #fff;
      font-family: "Open Sans", Ubuntu, sans-serif;
      letter-spacing: 0.9px;
    }
    .Favorate--hangout--card--content--1--p2 {
      font-size: 12px;
      font-weight: 300;
      color: #fff;
      opacity: 0.5;
      font-family: "Open Sans", Ubuntu, sans-serif;
      letter-spacing: 1px;
    }
    .Favorate--Hangout--page--card-banner-Search--1{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
   
    }
    .Favorate--Hangout--page--card-banner-Search--1 input {
      width: 285px;
      height: 40px;
      border-radius: 5px;
      outline: none;
      color: #fff;
      font-family: "Open Sans", Ubuntu, sans-serif;
      border: none;
      padding-left: 10px;
      background: rgba(92, 85, 85, 0.8);
      margin-top: 0;
      font-size: 12px;
    }
    .Favorate--Hangout--page--card-banner-Search--1 span {
      position: relative;
      left: -20px;
      color: #fff;
      font-size: 15px;
    }
    .favorate--hangout--address--p2 {
      font-size: 12px;
      font-weight: 400;
      color: #fff;
      font-family: "Open Sans", Ubuntu, sans-serif;
      margin: 0;
      padding: 0;
      opacity: 0.5;
      letter-spacing: 0.6px;
      text-align: right;
    }
  }
  