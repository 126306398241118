.acordian-page{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    /* padding: 30px; */
    /* color: white; */
    background-color: #120829;
    color: white;
}
.checkout--body{
    width: 89%;
    height: auto;
    margin: auto;
}
.accordion-header {
    --bs-accordion-active-bg:  #ffffff;
  }
  .page--details{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .item--details--image{
    width: 40%;
    height: 350px;
    overflow: hidden;
  }
  .page--details--text{
    width: 55%;
    height: auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  .club--name--heading{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .club--name--heading span{
  font-size: 12px;
  color: white;
  padding: 2px 10px;
  background-color: #cc0c39;
  border-radius: 3px;
  }
  .club--name--heading h4{
    font-size: 22px;
    color: white;
    font-weight: 400;
    /* padding: 2px 10px; */
    /* background-color: #cc0c39; */
    /* border-radius: 3px; */
    }
  .item--details--image img{
    width: 100%;
    height: 100%;
  }
  .page--details--text p strong{
    font-size: 14px;
    font-weight: 400;
    color: #cc0c39;
  }
  .item--location{
    font-size: 12px;
  }
  .get--direction--on--item{
    font-size: 12px;
    color: #cc0c39;
  }
  .get--direction--on--item span{
    font-size: 14px;
    color: #7483a2;
  }
  .item--price--details .item--price--details--heading{
    width: 100%;
    height: auto;
    font-size: 14px;
    border-bottom: 1px solid;
    margin-top: 20px;
    color: #525253;
  }
  .price--and--tax--included{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    border-bottom: 1ps solid white !important;
    margin-bottom: 20px;
  }
  .leftsize--price--heading{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;

  }
  .order--summery--page{
    width: 60%;
  }
  .order--summery--page1{
    width: 70%;
  }
  .gst--calculation{
    font-size: 14px;
    color: #525253;
  }
  .leftsize--price--heading1{
    
  }
  .apply--coupons--section{
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #525253;
    border-top:1px solid #525253;
  }
  .apply--coupons--text{
    width: 100%;
    height: auto;
  display: flex;
  justify-content: space-between;
  color: #008AD8;
  }
  .button--apply--coupons{
    border: 1px dotted #525253;
    padding: 3px 5px;
    border-radius: 3px;
    color: #FD2F71;
    ;
   
  }
  .dis--amount{
    color: #FD2F71;
  }
  .net--payble--amount{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .coupons--modals{
    cursor: pointer;
  }
  .booking--date--time{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
  }
  .heading{
    color:#1EBE92;
  }
  .payment--accept--button {
    width: 100%;
    
  }
  .payment--accept--button button{
    float: inline-end;
    border: none;
    background-color: #1EBE92;
    border-radius: 3px;
    color: white;
    padding: 3px 20px;
  }
  .checkout--thank--page{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .checkout--thanku--icon{
    width: 30%;
    height: 300px;
  }
  .checkout--thanku--icon img{
    width: 100%;
    height: 100%;
  }
  .checkout--confirmation--details{
    width: 30%;
    height: 300px;
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    /* border: 1px solid rgb(161, 161, 161); */
  }
  .ordernumber{
    width: 100%;
    height: auto;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
  }
  .order--digit{
    display: flex;
    flex-direction: column;
  }
  .package--image{
    width: 30%;
  }
  .ordernumber button{
    border: none;
    background-color: #CC0C39;
    border-radius: 3px;
    padding: 5px 10px;
    color: white;
    margin-left: 20px;
  }
  .ordernumber--heading{
    color: #008AD8;
  }
  .package--image{
    width: 30%;
    height: 300px;
    display: flex;
   align-items: center; justify-content: center;
   overflow: hidden;

  }
  .package--image img{
    width: 70%;
    height: 70%;
    border-radius: 50%;
  }
  .coupons--header{
    width: 100%;
    height: 45px;
    background-color: #005295;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
    color: white;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
  .coupons--header p img{
    width: 16px;
    height: 16px;
  }
  .close--button{
    background-color: #CC0C39;
    padding: 2px 9px;
    cursor: pointer;
    border-radius: 3px;
  }
  .coupons--list{
    width: 100%;
    height: 420px;
    display: flex;
    flex-direction: column;
    /* gap: 20px; */
    /* border-top: 1px solid white; */
    background-color: #000000;
    padding: 10px 20px 20px 20px !important;
    
    margin: auto;
    overflow: scroll;
  }
  .coupons--list::-webkit-scrollbar{
    display: none;
  }
  .type--coupons--text{
    color: white;
    font-size: 12px;
    cursor: pointer;
  }
  .cross--button{
    cursor: pointer;
  }
  .best--offer{
    color: white;
    opacity: 30%;
    font-family: Poppins;
font-size: 12px;
font-weight: 400;
line-height: 21px;
letter-spacing: 0em;
text-align: left;
margin-bottom: 10px;

   
  }
  .coupons--serch input{
width: 100%;
height: 40px;
background-color: #000000;
border-radius: 5px;
border: 0.5px solid white;
margin-top: 5px;
margin-bottom: 30px;
font-size: 12px;
  }
  .menu--discount--card{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .menu---discount{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .menudiscount--name p{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
  }
  .food---discount{
    width: 50%;
    height: 201px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    padding: 15px;
    border-radius: 3px;
    font-size: 14px;
    overflow: hidden;
  }
  .food---discount---card{
    width: 100%;
    height: 100%;
    /* border: 1px solid white; */
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    background-image: url(../Club-page/image/Food.svg);
  }
  .food---discount---card1{
    width: 100%;
    height: 100%;
    /* border: 1px solid white; */
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    background-image: url(../Club-page/image/beverage.svg);
  }
  .food---discount---card p{
    /* background-color: black; */
    padding: 3px;
    margin-top: -15px;
  }
  .food---discount---card h3{
    /* background-color: black; */
    /* padding: 3px; */
    margin-top: 88px;
  }
  .food---discount---card1 h3{
    /* background-color: black; */
    /* padding: 3px; */
    margin-top: 88px;
  }
  .addon---discount{
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
    /* margin-top: 10px; */
  }
  .extra-addon{
    width: 100%;
    height: 60px;
    background-color: black;
    padding: 10px;
    border-radius: 2px;
  }
.extra--addon--border{
    width: 100%;
    height: 100%;
    border: 1px solid white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
}
.extra--addon--border p{
    /* width: 100%; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 2px;
    background-color: black;
    margin-top: -8px;
    height: 15px;
    margin-bottom: 0px;
    font-size: 8px;
}
.extra--addon--border h5{
    margin: auto;
    margin-top: 4px;
    font-size: 12px;
}
.extra--discount--card{
    width: 100%;
    height: auto;
display: grid;
grid-template-columns: 1fr 1fr;
align-items: center;
gap: 10px;

}
.addon--calc{
    width: 100%;
    height: 183px;
    background-color: #D32A62;
    margin-top: 8px;
    border-radius: 5px;
    padding: 10px;
display: flex;
flex-direction: column;
}
.calculator {
    border-radius: 3px;
    height: 35px;
    /* margin-top: 7px; */
    color: #909090;
}
.calc-heading{
    font-size: 14px;
}
.amount--card{
    width: 100%;
    height: 80px;
    border: 1px solid white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding-left: 10px;
}
.amount--card p{
    font-size: 15px;
    font-weight: 200;
}
@media (max-width: 540px) {
    .acordian-page{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        /* padding: 30px; */
        /* color: white; */
        background-color: #120829;
        color: white;
    }
    .checkout--body{
        width: 89%;
        height: auto;
        margin: auto;
    }
    .accordion-header {
        --bs-accordion-active-bg:  #ffffff;
      }
      .page--details{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .item--details--image{
        /* width: 40%;
        height: 350px;
        overflow: hidden; */
        display: none;
      }
      .page--details--text{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        margin: auto;
      }
      .club--name--heading{
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .club--name--heading span{
      font-size: 12px;
      color: white;
      padding: 2px 10px;
      background-color: #cc0c39;
      border-radius: 3px;
      }
      .club--name--heading h4{
        font-size: 22px;
        color: white;
        font-weight: 400;
        /* padding: 2px 10px; */
        /* background-color: #cc0c39; */
        /* border-radius: 3px; */
        }
      .item--details--image img{
        width: 100%;
        height: 100%;
      }
      .page--details--text p strong{
        font-size: 14px;
        font-weight: 400;
        color: #cc0c39;
      }
      .item--location{
        font-size: 12px;
      }
      .get--direction--on--item{
        font-size: 12px;
        color: #cc0c39;
      }
      .get--direction--on--item span{
        font-size: 14px;
        color: #7483a2;
      }
      .item--price--details .item--price--details--heading{
        width: 100%;
        height: auto;
        font-size: 14px;
        border-bottom: 1px solid;
        margin-top: 20px;
        color: #525253;
      }
      .price--and--tax--included{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        border-bottom: 1ps solid white !important;
        margin-bottom: 20px;
      }
      .leftsize--price--heading{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;
    
      }
      .order--summery--page{
        width: 100%;
        padding: 15px 10px;
      }
      .order--summery--page1{
        width: 100%;
        padding: 5px;
      }
      .gst--calculation{
        font-size: 14px;
        color: #525253;
      }
      .leftsize--price--heading1{
        
      }
      .apply--coupons--section{
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #525253;
        border-top:1px solid #525253;
      }
      .apply--coupons--text{
        width: 100%;
        height: auto;
      display: flex;
      justify-content: space-between;
      color: #008AD8;
      }
      .button--apply--coupons{
        border: 1px dotted #525253;
        padding: 3px 5px;
        border-radius: 3px;
        color: #FD2F71;
        ;
       
      }
      .dis--amount{
        color: #FD2F71;
      }
      .net--payble--amount{
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .coupons--modals{
        cursor: pointer;
      }
      .booking--date--time{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;
      }
      .heading{
        color:#1EBE92;
      }
      .payment--accept--button {
        width: 100%;
        
      }
      .payment--accept--button button{
        float: inline-end;
        border: none;
        background-color: #1EBE92;
        border-radius: 3px;
        color: white;
        padding: 3px 20px;
      }
      .checkout--thank--page{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .checkout--thanku--icon{
        width: 100%;
        height: 300px;
      }
      .checkout--thanku--icon img{
        width: 100%;
        height: 100%;
      }
      .checkout--confirmation--details{
        width: 90%;
        height: 300px;
        display: flex;
        flex-direction: column;
        padding-top: 30px;
        margin: auto;
        /* border: 1px solid rgb(161, 161, 161); */
        /* display: none; */
      }
      .ordernumber{
        width: 100%;
        height: auto;
        display: flex;
        /* justify-content: space-between; */
        align-items: center;
      }
      .order--digit{
        display: flex;
        flex-direction: column;
      }
      .package--image{
        /* width: 30%; */
        display: none;
      }
      .ordernumber button{
        border: none;
        background-color: #CC0C39;
        border-radius: 3px;
        padding: 5px 10px;
        color: white;
        margin-left: 20px;
      }
      .ordernumber--heading{
        color: #008AD8;
      }
      .package--image{
        /* width: 30%;
        height: 300px;
        display: flex;
       align-items: center; justify-content: center;
       overflow: hidden; */
    display: none;
      }
      .package--image img{
        width: 70%;
        height: 70%;
        border-radius: 50%;
      }
      .coupons--header{
        width: 100%;
        height: 40px;
        background-color: #120829;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px;
        align-items: center;
        color: white;
      }
      .close--button{
        background-color: #CC0C39;
        padding: 2px 9px;
        cursor: pointer;
        border-radius: 3px;
      }
      .coupons--list{
        width: 98%;
        height: 320px;
        display: flex;
        flex-direction: column;
padding: 10px 10px 20px 10px !important;
        /* gap: 20px; */
      }
      .coupons--list::-webkit-scrollbar{
        display: flex;
      }
      .menu--discount--card{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .menu---discount{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .menudiscount--name p{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px;
      }
      .food---discount{
        width: 49%;
        height: 172px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: black;
        padding: 0px;
        border-radius: 3px;
        font-size: 14px;
        overflow: hidden;
      }
      .food---discount---card{
        width: 148px;
        height: 173px;
        /* border: 1px solid white; */
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        background-image: url(../Club-page/image/Food.svg);
      }
      .food---discount---card1{
        width: 148px;
    height: 173px;
        /* border: 1px solid white; */
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        background-image: url(../Club-page/image/beverage.svg);
      }
      .food---discount---card p{
        /* background-color: black; */
        padding: 3px;
        margin-top: -15px;
      }
      .food---discount---card h3{
        /* background-color: black; */
        /* padding: 3px; */
        margin-top: 88px;
      }
      .food---discount---card1 h3{
        /* background-color: black; */
        /* padding: 3px; */
        margin-top: 88px;
      }
      .addon---discount{
        width: 100%;
        height: auto;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 5px;
        /* margin-top: 10px; */
      }
      .extra-addon{
        width: 100%;
        height: 60px;
        background-color: black;
        padding: 10px;
      }
    .extra--addon--border{
        width: 100%;
        height: 100%;
        border: 1px solid white;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .extra--addon--border p{
        /* width: 100%; */
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        padding: 2px;
        background-color: black;
        margin-top: -8px;
        height: 15px;
        margin-bottom: 0px;
        font-size: 8px;
    }
    .extra--addon--border h5{
        margin: auto;
        margin-top: 4px;
        font-size: 12px;
    }
    .extra--discount--card{
        width: 100%;
        height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 10px;
    
    }
    .addon--calc{
        width: 100%;
        height: auto;
        background-color: #D32A62;
        margin-top: 8px;
        border-radius: 5px;
        padding: 10px;
    display: flex;
    flex-direction: column;
    }
    .calculator {
        border-radius: 3px;
        height: 35px;
        /* margin-top: 7px; */
        color: #909090;
    }
    .calc-heading{
        font-size: 14px;
    }
    .amount--card{
        width: 100%;
        height: 80px;
        border: 1px solid white;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        padding-left: 10px;
    }
    .amount--card p{
        font-size: 15px;
        font-weight: 200;
    }
   
}

.custom-tabs {
    background-color: none; /* Change background color */
    /* height: 30px; Change height */
  }
  .tab-content{
    width: 100%;
    height: 380px;
    display: grid;
    grid-template-columns: 1fr;
    /* gap: 20px; */
    padding: 10px;
    background-color: #292929;
    color: white;
    overflow: scroll;
    /* margin-bottom: 10px; */
  }
  .tab-content::-webkit-scrollbar{
    display: none;
  }
.custom-tabs .nav-link {
   color: rgb(150, 148, 148);
  }
  .custom-tabs .nav-link.active {
   height: 40px;
   background-color: #292929;
   color: white;
  }
  /* .tab--content .coupons--card{
    width: 100%;
    height: 40px;
    display: flex;

   
  } */
  
  @media only screen and (max-width: 540px) {
   
    .website--view--coupons{
      display: none;
    }
    .coupons--card{
      width: 100%;
      height: auto;
      background-color: #000000;
      /* padding: 5px; */
      display: flex;
      flex-direction: column;
      border-top-right-radius: 18px;
      border-top-left-radius: 18px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      overflow: hidden;
      border: 0.7px solid #565656;
    }
    .coupons--headericon{
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      /* justify-content: center; */
      /* align-items: center; */
      padding: 5px;
      /* border-radius:  18px, 18px, 5px, 5px; */
      /* border: 0.7px solid #565656; */
     
    }
    .coupon--header--icon{
      width: 15%;
      height: 100%;
      display: flex;
      justify-content: center;
      /* font-size: 20px; */
      /* align-items: center; */
      color: #FEB92F;
      margin: auto;
    }
    .coupon--header--icon img{
  width: 38px;
  height: 38px;
    }
    .off--discount--text{
      width: 52%;
      height: auto;
      display: flex;
      flex-direction: column;
      color: white;
      /* margin-left: 10px; */
      margin-top: 4px;
    }
    .off--discount--text p{
      font-family: Poppins;
      font-size: 8px;
      font-weight: 500;
      line-height: 15px;
      letter-spacing: 0em;
      text-align: left;
      
    }
    .c--code{
      width: 100%;
      height: auto;
    }
    .c--code h3{
      padding: 5px 10px;
      border: 1px dashed rgba(255, 255, 255, 0.70);
      border-radius: 2px;
         font-size: 16px;
         text-align: left;
         font-weight: 400;
         margin-top: 5px;
         padding-left: 10px;
    }
    /* .off--discount--text h3{
     width: 100%;
   padding: 5px 10px;
   border: 1px dotted rgba(255, 255, 255, 0.30);
   border-radius: 2px;
      font-size: 14px;
      text-align: left;
      padding-left: 10px;
      font-weight: 400;
      margin-top: 5px;
      
   
    } */
    .off--discount--text h5{
     
      font-family: Poppins;
      font-size: 12px;
      font-weight: 600;
      /* line-height: 24px; */
      letter-spacing: 0.03em;
      text-align: left;
      
     }
    .coupons--card button{
      width: 100%;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #008AD8;
      color: white;
      border: none;
    }
    /* .selected {
      background-color: lightgray; 
    } */
    .coupons--card .selected-button {
      background-color: rgb(114, 114, 114); /* Change this to the desired background color */
      color: white; /* Text color */
      border: none;
      padding: 10px 20px;
      cursor: pointer;
      /* Add any other styles you need for the selected button */
    }
    .dropdown--terms{
      width: 30%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .terms--condition{
      width: 100%;
      height: auto;
      /* border-radius: 50%; */
      /* background-color: white; */
      font-size: 10px;
      font-weight: 300;
      color: #ffffff;
      cursor: pointer;
      /* margin-top: 30px; */
    }
    .terms--condition img{
      width: 10px;
      height: 10px;
    }
    .term--discription{
      width: 95%;
      height: auto;
      /* background-color: white; */
      color: white;
      font-size: 12px;
      border-top: 1px solid gray;
      /* padding: 5px; */
      margin: auto;
    }
    .chhoose--your--payment{
      width: 100%;
      height: 36px;
      background-color: white;
      border-bottom: .3px solid rgba(128, 128, 128, 0.379);
      padding-left: 7px;
      padding-right: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      
    }
    .chhoose--your--payment p{
      color: black;
    }
    .chhoose--your--payment .arrow--icon{
      font-size: 20px;
      font-weight: 100 !important;
      margin-top: 10px;
    }
    .coupons--tabs--card{
      width: 98%;
      height: 40px;
      display: flex;
      flex-direction: row;
      border-bottom: .5px solid rgb(255, 255, 255,.30);
      /* background-color: #000000; */
      /* margin: auto; */
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .partywity--coupons{
      background-color: #000000;
      color: #61636F;
      border: none;
      font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  /* line-height: 27px; */
  letter-spacing: 0em;
  text-align: left;
  margin-right: 20px;
  margin-bottom: 0px;
  padding-bottom: 5px;
  cursor: pointer;
  padding-left: 10px;
  
    }
    .active-tab {
      color: white !important;
      border-bottom: 2px solid #ffffff; /* Set your desired border-bottom style */
      /* Additional styles for the active tab */
    }
    .dis--card--coupons{
      border-top-right-radius: 15px;
      width: 85%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background-image: url(../Assets/1.png);
      /* background-repeat: repeat-y; */
      padding: 10px;
      background-repeat: no-repeat;
      background-size: 267px 95px;
    
    }
  }
  @media only screen and (min-width: 540px) {
    .mobile--view--coupons{
      display: none;
    }
    .coupons--card{
      width: 100%;
      height: auto;
      background-color: #000000;
      /* padding: 5px; */
      display: flex;
      flex-direction: column;
      border-top-right-radius: 18px;
      border-top-left-radius: 18px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      overflow: hidden;
      border: 0.7px solid #565656;
      
    }
    .coupons--headericon{
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      /* justify-content: center; */
      /* align-items: center; */
      padding: 5px;
      /* border-radius:  18px, 18px, 5px, 5px; */
      /* border: 0.7px solid #565656; */
      
     
    }
    .coupon--header--icon{
      width: 27%;
      height: 100%;
      display: flex;
      justify-content: center;
      /* font-size: 20px; */
      /* align-items: center; */
      color: #FEB92F;
      margin: auto;
    }
    .coupon--header--icon img{
  width: 55px;
  height: 55px;
    }
    .off--discount--text{
      width: 60%;
      height: auto;
      display: flex;
      flex-direction: column;
      color: white;
      margin-left: 10px;
      margin-top: 4px;
    }
    .off--discount--text p{
      font-family: Helvetica;
      font-size: 10px;
      font-weight: 100;
      line-height: 15px;
      letter-spacing: 1px;
      text-align: left;
      padding-top: 3px;
      
    }
    .off--discount--text h3{
     /* min-width: 150px; */
     min-width: 50px;
   padding: 5px 10px;
   border: 1px dashed rgba(255, 255, 255, 0.30);
   border-radius: 2px;
      font-size: 16px;
      text-align: left;
      font-weight: 400;
      margin-top: 5px;
      padding-left: 10px;
      display: inline-block;
      
      /* font-size: Poppins; */
    }
    .c--code{
      width: 100%;
      height: auto;
    }
    .c--code h3{
      padding: 5px 10px;
      border: 1px dashed rgba(255, 255, 255, 0.70);
      border-radius: 2px;
         font-size: 14px;
         text-align: left;
         font-weight: 400;
         margin-top: 5px;
         padding-left: 10px;
    }
    .off--discount--text h5{
     
      font-family: Poppins;
      font-size: 14px;
      font-weight: 600;
      /* line-height: 24px; */
      letter-spacing: 0.03em;
      text-align: left;
      
     }
    .coupons--card button{
      width: 100%;
      height: 35px;
      font-size: 14px;
      font-family: Helvetica;
      letter-spacing: 1px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #008AD8;
      color: white;
      border: none;
    }
    /* .selected {
      background-color: lightgray; 
    } */
    .coupons--card .selected-button {
      background-color: rgb(114, 114, 114); /* Change this to the desired background color */
      color: white; /* Text color */
      border: none;
      padding: 10px 20px;
      cursor: pointer;
      /* Add any other styles you need for the selected button */
    }
    .dropdown--terms{
      width: 25%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .terms--condition{
      width: 100%;
      height: auto;
      /* border-radius: 50%; */
      /* background-color: white; */
      font-size: 12px;
      font-weight: 300;
      color: #ffffff;
      cursor: pointer;
      /* margin-top: 30px; */
    }
    .terms--condition img{
      width: 13px;
      height: 13px;
    }
    .term--discription{
      width: 97.8%;
      height: auto;
      /* background-color: white; */
      color: white;
     
      border-top: 1px solid gray;
      /* padding: 5px; */
      margin: auto;
      margin-top: -10px;
    }
    .term--discription p{
      font-size: 12px !important;
    }
    .chhoose--your--payment{
      width: 100%;
      height: 60px;
      background-color: white;
      border-bottom: .3px solid rgba(128, 128, 128, 0.379);
      padding-left: 20px;
      padding-right: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      
    }
    .chhoose--your--payment p{
      color: black;
    }
    .coupons--tabs--card{
      width: 98%;
      height: 40px;
      display: flex;
      flex-direction: row;
      border-bottom: .5px solid rgb(255, 255, 255,.30);
      /* background-color: #000000; */
      /* margin: auto; */
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .partywity--coupons{
      background-color: #000000;
      color: #61636F;
      border: none;
      font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  /* line-height: 27px; */
  letter-spacing: 0em;
  text-align: left;
  margin-right: 20px;
  margin-bottom: 0px;
  padding-bottom: 5px;
  cursor: pointer;
  padding-left: 10px;
  
    }
    .active-tab {
      color: white !important;
      border-bottom: 2px solid #ffffff; /* Set your desired border-bottom style */
      /* Additional styles for the active tab */
    }
    .dis--card--coupons{
      width: 85%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background-image: url(../Assets/1.png);
      /* background-repeat: repeat-y; */
      padding: 10px;
      background-repeat: no-repeat;
      background-size: 382px 95px;
      border-top-right-radius: 15px;
    }
  }
  