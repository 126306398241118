@media (max-width: 925px) {
  .carousel-inner .carousel-item > div {
      display: none;
  }
  .carousel-inner .carousel-item > div:first-child {
      display: block;
  }
}

.carousel-inner .carousel-item.active,
.carousel-inner .carousel-item-next,
.carousel-inner .carousel-item-prev {
  display: flex;
}

.carousel-control-next,.carousel-control-prev {
  width: 3% !important;
  opacity: 0.7 !important;
}
@media (min-width: 768px) {

  .carousel-inner .carousel-item-end.active,
  .carousel-inner .carousel-item-next {
      transform: translateX(25%);
  }

  .carousel-inner .carousel-item-start.active, 
  .carousel-inner .carousel-item-prev {
      transform: translateX(-25%);
  }
}

.carousel-inner .carousel-item-end,
.carousel-inner .carousel-item-start { 
  transform: translateX(0);
}




*, *::before, *::after {
  box-sizing: border-box;
}



.wrapper222 {
  background-color: #08BDBD;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.carousel222 {
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.homepage-banner-img{
  height: 600px;
  width: 100%;
}
.carousel__item {
  width: 100%;
  width: 600px;
  padding: 0 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  opacity: 0;
  -webkit-animation: carousel-animate-horizontal 20s linear forwards infinite;
  animation: carousel-animate-horizontal 20s linear forwards infinite;
}
@media screen and (min-width: 925px) {
  .carousel__item {
    max-width: 500px;
  }
}

.carousel__item:nth-child(1) {
  -webkit-animation-delay: calc(3.3333333333s * 0);
          animation-delay: calc(3.3333333333s * 0);
}

.carousel__item:nth-child(2) {
  -webkit-animation-delay: calc(3.3333333333s * 1);
          animation-delay: calc(3.3333333333s * 1);
}

.carousel__item:nth-child(3) {
  -webkit-animation-delay: calc(3.3333333333s * 2);
          animation-delay: calc(3.3333333333s * 2);
}

.carousel__item:nth-child(4) {
  -webkit-animation-delay: calc(3.3333333333s * 3);
          animation-delay: calc(3.3333333333s * 3);
}

.carousel__item:nth-child(5) {
  -webkit-animation-delay: calc(3.3333333333s * 4);
          animation-delay: calc(3.3333333333s * 4);
}

.carousel__item:nth-child(6) {
  -webkit-animation-delay: calc(3.3333333333s * 5);
          animation-delay: calc(3.3333333333s * 5);
}

.carousel__item-head {
  border-radius: 50%;
  background-color: #dcdee1;
  width: 84px;
  height: 84px;
  margin-bottom: -42px;
  position: relative;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.carousel__item-body {
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  padding: 50px 30px 20px 30px;
  text-align: center;
}

@-webkit-keyframes carousel-animate-horizontal {
  0% {
    transform: translateX(-200%);
    opacity: 0;
  }
  2%, 16.6666666667% {
    transform: translateX(-100%);
    opacity: 0.4;
  }
  18.6666666667%, 33.3333333333% {
    transform: translateX(0);
    opacity: 1;
  }
  35.3333333333%, 50% {
    transform: translateX(100%);
    opacity: 0.4;
  }
  52% {
    transform: translateX(200%);
    opacity: 0;
  }
  100% {
    transform: translateX(200%);
    opacity: 0;
  }
}

@keyframes carousel-animate-horizontal {
  0% {
    transform: translateX(-200%);
    opacity: 0;
  }
  2%, 16.6666666667% {
    transform: translateX(-100%);
    opacity: 0.4;
  }
  18.6666666667%, 33.3333333333% {
    transform: translateX(0);
    opacity: 1;
  }
  35.3333333333%, 50% {
    transform: translateX(100%);
    opacity: 0.4;
  }
  52% {
    transform: translateX(200%);
    opacity: 0;
  }
  100% {
    transform: translateX(200%);
    opacity: 0;
  }
} 

.clubnameby_filter{
  width: 350px;
  max-height: 400px;
  background-color: white;
  padding: 20px;
  overflow: scroll;

}
.clubnameby_filter::-webkit-scrollbar{
  display: none;
}
.clubname_images{
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  gap: 10px;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.clubname_images img{
  width: 60px;
  height: 100%;
  border-radius: 3px;
}
.searchbar_field {
  width: 350px;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 0px 0px;
  border-radius: 5px;
  z-index: 100;
}

.searchbar_field .searchbar_icons input{
  width: 350px;
  height: 30px;
  background-color: white;
  border: none;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  outline: none;
}
.searchbar_field .searchbar_icons input:focus{
  border: none;
}
@media screen and (max-width: 925px) {
  .clubnameby_filter{
    width: 100%;
    max-height: 400px;
    background-color: white;
    padding: 20px;
    overflow: scroll;
  }
  .searchbar_field {
    width: 190px;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 0px 0px;
    border-radius: 5px;
    z-index: 100;
  }
  
  .searchbar_field .searchbar_icons input{
    width: 190px;
    height: 30px;
    background-color: white;
    border: none;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    outline: none;
  }
  .clubname_images{
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    gap: 10px;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  }
  .searchbar_field .searchbar_icons input:focus{
    border: none;
  }
  
  .carousel-control-next,.carousel-control-prev {
    width: 7% !important;
    opacity: 0.9 !important;
  }
  .searchbar_field--mobile {
    width: 100%;
    height: 35px;
    position: fixed;
    padding: 0px 25px;
    border-radius: 5px;
  }
  .searchbar_field--mobile .searchbar_icons input{
    width: 120px;
    height: 30px;
    background-color: white;
    border: none;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    outline: none;
  }
  .searchbar_field--mobile .searchbar_icons input:focus{
    
    border: none;
  }
 
  .searchbar_field--mobile .searchbar_icons span{
    font-weight: 400;
    font-size: 18px;
    display: flex;
    align-items: center;
    color: #d71362;
  }
  .searchbar_icons{
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  .clubname_images p{
font-size: 14px;
  }
}
@media screen and (max-width: 925px) {
  .clubname_images p{
    font-size: 10px;
        }
}