.responsive-modal-1--popup--webApp {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 550px;
    height: 300px;
    background-color: white;
    box-shadow: 24px;
    transition: all 0.3s ease;
    outline: none;
    overflow: hidden;
}
.responsive-modal-1--popup--webApp-artist {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    height: 250px;
    background-color: white;
    border: 2px solid;
   
    box-shadow: 24px;
    transition: all 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.9);
    border: 1px solid #fff;
    outline: none;
    overflow: hidden;
}

.Plan--your--event--popup--webapp {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    align-items: center;
    position: relative;
}
.modal-button_plan{
    position: absolute;
    top: 0.5%;
    left: 92%;
    border: none;
    background-color: transparent;
    font-size: 25px;
    color: #404040;
    margin-top: -5px;
}
.Plan--your--event--popup--webapp--img img {
    height: 300px;
    width: 200px;
    object-fit: contain;
}
.Plan--your--event--popup--webapp--p1 {
    color: #000;
    font-family: Poppins ;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    width: 97%;
    margin: 0;padding: 0;
}
.Plan--your--event--popup--webapp--p2 {
    color: #303030;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 96%;
    margin: 0;padding: 0;
}
.Plan--your--event--popup--webapp--download--img{
    display: flex;
    flex-direction: row;
    column-gap: 25px;
    padding-top: 5px;
}
.Plan--your--event--popup--webapp--download--img img{
    height: 30px;
    width: auto;
    box-shadow: 0 4px 8px rgba(128, 128, 128, 0.9);
}
.Plan--your--event--popup--webapp--img--6{
    display: none;
}
.plan--yor--event--mobile--view{
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    padding-top: 10px;
}
.app-popup-row{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
.app-popup-row-p{
    color: #000;
    font-family: Poppins ;
    font-size: 14px;
    font-weight: 600;
    width: 97%;
    text-transform: uppercase;
    margin: 0;padding: 0;
}
.app-popup-row-p1{
    display: none;
}
@media (max-width:640px){
.responsive-modal-1--popup--webApp {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 320px;
    height: 450px;
    background-color: white;
    outline: none;
    overflow: hidden;
}
.app-popup-row{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 10px;
}
.app-popup-row-p{
    color: #000;
    font-family: Poppins ;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    margin: 0;padding: 0;
    text-align: center;
}
.app-popup-row-p1{
    display: block;
    color: #000;
    font-family: Poppins ;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0;padding: 0;
    text-align: center;
}
.app-popup-row1{
    display: flex;
    flex-direction: column;
}
.app-popup-row-p{
    display: none;
}
.responsive-modal-1--popup--webApp-artist {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 320px;
    height: 450px;
    background-color: white;
    outline: none;
    overflow: hidden;
}
.Plan--your--event--popup--webapp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.Plan--your--event--popup--webapp--img{
    display: none;
}
.Plan--your--event--popup--webapp--img--6{
    display: block;
}
.plan--yor--event--mobile--view{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 25px;
}
.modal-button_plan{
    position: absolute;
    top: 0%;
    left: 88%;
    border: none;
    background-color: transparent;
    font-size: 20px;
    color: white;
    opacity: 80%;
}
.Plan--your--event--popup--webapp--p2{
    color: #303030;
    font-family: Poppins;
    font-size: 10px;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    margin: 0;padding: 0;
    width:90% ;
}
.Plan--your--event--popup--webapp--p1 {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}
.Plan--your--event--popup--webapp--download--img img{
    height: 30px;
    width: auto;
}
.Plan--your--event--popup--webapp--download {
    color: #1976D2;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
}
.Plan--your--event--popup--webapp--img--6 img{
    width: 320px;
    height: 180px;
    object-fit: contain;
}
.Plan--your--event--popup--webapp--download--img{
    display: flex;
    flex-direction: row;
    column-gap: 10px;
}
}