.Club-about-us {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

/* Custom Shimmer Effect */
.shimmer {
  background: linear-gradient(to right, #d5d8dc 8%, #ebedef 18%, #eaeded 33%) !important;
  background-size: 1000px 100% !important;
  animation: shimmer 2.2s linear infinite forwards !important;
} 
.package-img {
  height: 300px; 
  width: 400px;
}
.home-page-design {
  width: 100%;
  height: auto;
}
.header {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  background-color: #1b183b;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 100;
  top: 0px;
}
.header1 {
  display: flex;
  flex-direction: column;
}
.router-page {
  margin-top: 75px;
}
.logo {
  width: 60%;
  position: relative;
  left: -270px;
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.logo img {
  width: 110px;
  height: 50px;
}
.navbar {
  width: 50%;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: end;
  padding-right: 50px;
  gap: 20px;
}
.carticon {
  font-size: 24px;
}
.carticon span {
  position: relative;
  color: #d71362;
  font-size: 12px;
  font-weight: 400;
  top: -15px;
  left: -5px;
  padding: 3px 5px;
  border-radius: 50%;
}
.login-button1 {
  border: none;
  padding: 5px;
  background-color: #d71362;
  border-radius: 2px;
  color: white;
  font-weight: 500;
}
.signup-button1 {
  border: none;
  padding: 2px 5px;
  background-color: #d71362;
  border-radius: 2px;
  color: white;
  font-weight: 300;
}
.header-navbar {
  height: 64px;
  display: flex;
  flex-direction: row;
  padding-right: 10px;
  margin: auto;
  justify-content: space-between;
  align-items: center;
}
.mobile--view--search-bar {
  display: none;
}
.nav-content {
  display: flex;
  flex-direction: row;
}
.menu {
  padding-top: 10px;
}
.menu a {
  text-decoration: none;
  color: white;
  outline: none;
}
.menuu a {
  text-decoration: none;
  color: white;
  outline: none;
}

.profile_icon {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.profile_icon img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.profile_icon p {
  width: auto;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  cursor: pointer;
}

.menu a:hover {
  color: #d71362;
  cursor: pointer;
  border-bottom: 1px solid white;
}

.menu1 a:hover {
  color: #d71362;
  cursor: pointer;
  border-bottom: 1px solid white;
}
.banner-slider {
  width: 95%;
  height: 350px;
  overflow: hidden;
  margin: auto;
}
.banner-image img {
  width: 100%;
  height: 700px !important;
}
.home-page-design1 {
  width: 100%;
  height: auto;
  /* background-color: #0c0522; */
  display: flex;
  align-items: center;
}
.signup-button1 a {
  text-decoration: none;
  color: white;
}
.MuiPaper-root.MuiModal-paper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80%;
  width: 500px;
  padding: 20px;
  background-color: #d71362;
  border-radius: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
#parent-modal-title,
#child-modal-title {
  font-size: 24px;
  margin-bottom: 15px;
}
#parent-modal-description,
#child-modal-description {
  font-size: 16px;
  color: #888;
}
.planyourevent-div {
  margin-bottom: 15px;
}
.planyourevent-div label {
  display: block;
  margin-bottom: 5px;
}
.planyourevent-div input[type="text"],
.planyourevent-div input[type="email"],
.planyourevent-div input[type="tel"],
.planyourevent-div input[type="checkbox"] {
  width: 90%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: start;
  margin-top: 10px;
}
.cars2{
  color: #fff;
}
.close-btn {
  position: relative;
  top: -350px;
  right: -405px;
  color: #d71362;
}
.close-btn1 {
  position: relative;
  top: -330px;
  right: -405px;
  color: #d71362;
}
.close-btn2 {
  position: relative;
  top: -300px;
  right: -405px;
  color: #d71362;
}
.planyourevent-div input[type="checkbox"] {
  margin-right: 5px;
}
.form-buttons {
  display: flex;
  justify-content: space-between;
}
.form-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #d71362;
  color: #fff;
  cursor: pointer;
}
.plan-yourevent-div {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.form-buttons button:last-child {
  background-color: #dc3545;
}
.planyourevent-div {
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 50px;
}
.plan-your-event-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
}
.guest {
  margin-bottom: 40px;
}
.planyourevent-div {
  margin-bottom: 15px;
}
.planyourevent-div label {
  display: block;
  margin-bottom: 5px;
}
.planyourevent-div input[type="number"],
.planyourevent-div select,
.planyourevent-div input[type="radio"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.planyourevent-div select {
  height: 40px;
}
.select-city {
  margin-left: 40px;
}
.label-name {
  margin-left: 45px;
}
.label-email {
  margin-left: 9px;
}
.plan-div {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.plan-div button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #d71362;
  color: #fff;
  cursor: pointer;
  margin-top: 6px;
}
.plan-your-event-div {
  display: flex;
  justify-content: flex-end;
}
.plan-your-event-div button {
  height: 37px;
  width: 130px;
  border: none;
  border-radius: 5px;
  background-color: #1ebe92;
  color: #fff;
  cursor: pointer;
  position: relative;
  top: -30px;
}
.plan-your-event-div1 button {
  height: 34px;
  width: 130px;
  border: none;
  border-radius: 5px;
  background-color: #1ebe92;
  color: #fff;
  cursor: pointer;
  position: relative;
  top: -80px;
}
.MuiPaper-root.MuiModal-paper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80%;
  width: 500px;
  padding: 20px;
  background-color: #d71362;
  border-radius: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.plan-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
#child-modal-title {
  font-size: 24px;
  margin-bottom: 10px;
}
#child-modal-description {
  font-size: 16px;
  color: #888;
}
.slider-img {
  width: 100%;
  height: 550px;
}
.slider-img img {
  width: 100%;
  height: 550px;
}
.left-side {
  width: 70%;
  height: fit-content;
  margin-right: 1rem;
  border: 1px solid rgba(129, 129, 129, 0.508);
  border-radius: 5px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.left-side::-webkit-scrollbar {
  display: none;
}
.event_crousal_list {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  overflow: scroll;
}
.event_crousal_list::-webkit-scrollbar {
  display: none;
}
.details-text {
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
}
.details-text1 {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
}
.details-text1 p {
  margin: 0;
  padding: 0;
}
.details-text p {
  font-size: 15px;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}
.details-text1 p span {
  font-size: 16px;
}
.details-text span {
  color: white;
  align-items: center;
}
.details-text span img {
  width: 20px;
  height: 20px;
}
.details-text .rupee img {
  width: 20px;
  height: 16px;
}
.club-aboutus-img-caraousel {
  width: 100%;
  height: 500px;
  position: relative;
}
.planyourevent-card {
  padding-top: 20px;
}
.planyourevent-card1 {
  display: flex;
  flex-direction: row;
  gap: 90px;
}
.planyourevent-card2 {
  display: flex;
  flex-direction: row;
  gap: 90px;
}
.club-about-us-party-images1 img {
  width: 350px;
  height: 250px;
}
.planyourevent-card2 p {
  margin: 0;
  padding: 0;
}
.location-part p {
  font-size: 14px;
  color: white;
  margin: 0;
  padding: 0;
}
.planyourevent-image {
  height: 70px;
  width: 70px;
}
.about {
  margin-bottom: 2.5rem;
}
.arrow {
  position: absolute;
  top: 2%;
  left: 85%;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}
.arrow button {
  display: flex;
  align-items: center;
  height: 50px;
  width: 50px;
  font-size: 30px;
  border: none;
  background-color: #000;
  border: 1px solid grey;
  color: white;
  border-radius: 5px;
}
.arrow button img {
  width: 25px;
  height: 25px;
}
.deal {
  margin-top: 1rem;
  background-color: #1f1e2e;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 6.5rem;
}
.deal-img {
  width: 6.5rem;
  height: 5.6rem;
}
.deal-content {
  width: 63%;
  height: auto;
}
.deal-icon {
  width: 7%;
  height: auto;
}
.deal-img img {
  padding-top: 0.6rem;
  padding-left: 0.6rem;
  width: 80%;
  height: 5rem;
}
.deal-icon span {
  color: white;
}
.h3 {
  color: white;
}
.deal p {
  color: white;
}
.h2 {
  color: white;
  font-size: 1.8rem;
  padding-bottom: 7px;
}
.order {
  background-color: #1f1e2e;
  width: 100%;
  height: 600px;
}
.order1 {
  background-color: white;
  border-radius: 0.31rem;
}
.green-h3 {
  color: #799e2e;
  margin-top: 25px;
  margin-left: 15px;
}
.btn:hover {
  background-color: #4cb04e;
  color: white;
}
.btn {
  background-color: #4cb04e;
  color: white;
  border-radius: 0.31rem;
  border: none;
  padding: 0.2rem;
  height: 30px;
  width: 73px;
}
.order2 {
  background-color: white;
  border-radius: 0.31rem;
}
.green-h3 {
  margin-top: 1.6rem;
  margin-left: 0.9rem;
  color: #799e2e;
}
.Pottage {
  background-color: #f9fafb;
  margin-left: 0.9rem;
  margin-right: 0.9rem;
  margin-top: 0.9rem;
  margin-bottom: 1.6rem;
}
.Haute {
  background-color: #f9fafb;
  margin-left: 0.9rem;
  margin-right: 0.9rem;
  margin-top: 0.9rem;
  margin-bottom: 1.6rem;
}
.recipe1 {
  display: flex;
  justify-content: space-between;
}
.blue-h3 {
  color: #363576;
  padding-left: 0.63rem;
}
.features {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
}
.features-content1 {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 10px;
}
.p-white {
  color: white;
  padding: 0.63rem 0.63rem;
}
.features-content2 {
  width: 42%;
}
.features-content3 {
  width: 16%;
  display: flex;
  align-items: end;
}
.p-pink {
  color: #b4235a;
  padding: 0.63rem 0.63rem;
}
.rating {
  background-color: #1f1e2e;
  padding: 10px 10px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}
.star-rating p {
  margin: 0;
  padding: 0;
  padding-bottom: 12px;
  padding-left: 6px;
}
.star-rating-content {
  display: flex;
  flex-direction: row;
  gap: 9px;
}
.review p {
  margin: 0;
  padding: 0;
}
.star p {
  margin: 0;
  padding: 0;
  color: goldenrod;
}
.star {
  display: flex;
  flex-direction: row;
}
.side p {
  margin: 0;
  padding: 0;
}
.side-right p {
  margin: 0;
  padding: 0;
}
.rating h1 {
  color: white;
  padding-left: 0.4rem;
}

.row {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding-bottom: 30px;
}
.row_11{
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 20px;
}
.side {
  width: 15%;
}

.middle {
  width: 75%;
  margin-left: 10px;
}

.side-right {
  width: 10%;
}

.first-rating {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.ambience {
  width: 100%;
  height: 0.5rem;
  border-radius: 0.63rem;
  background-color: #b4235a;
}

.food {
  width: 80%;
  height: 0.5rem;
  border-radius: 0.63rem;
  background-color: #b4235a;
}

.hygiene {
  width: 100%;
  height: 0.5rem;
  border-radius: 0.63rem;
  background-color: #b4235a;
}

.music {
  width: 100%;
  height: 0.5rem;
  border-radius: 0.63rem;
  background-color: #b4235a;
}

.pricing {
  width: 100%;
  height: 0.5rem;
  border-radius: 0.63rem;
  background-color: #b4235a;
}

.servce {
  width: 100%;
  height: 0.5rem;
  border-radius: 0.63rem;
  background-color: #b4235a;
}

.bar-container {
  width: 100%;
  height: auto;
  background-color: grey;
  border-radius: 0.63rem;
}

.club-image {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  gap: 1.3rem;
}

.first-club-image {
  width: 33%;
  height: auto;
}

.party1 {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.about-party-img {
  width: 100%;
  height: 11.3rem;
  border-radius: 0.32rem;
}

.about {
  padding-left: 10px;
  background-color: #1f1e2e;
  display: flex;
  margin-bottom: 2rem;
  width: 100%;
  height: 7rem;
}

.about1 span {
  color: white;
}

.about1 {
  width: 30%;
}

.club-about-us-party-images1 {
  flex: 0 0 350px;
  height: 250px;
  border-radius: 5px;
  overflow: hidden;
  transition: transform 0.2s;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

.club-about-us-party-images1:hover {
  transform: scale(1.05);
}

.about2 {
  width: 62%;
}

.about3 {
  width: 8%;
  display: flex;
  flex-direction: row;
}

.about3 img {
  background-color: #b4235a;
  border-radius: 10px;
}

.about-img-display {
  display: flex;
  flex-direction: row;
}

.left-right-combo {
  display: flex;
  flex-direction: row;
}
.right-side {
  width: 28%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(129, 129, 129, 0.508);
  border-radius: 5px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.shimmerEffect{
  display: flex;
  flex-direction: row;
  column-gap: 20px;
}
.offers {
  width: 90%;
  height: 30rem;
  background-color: #1f1e2e;
}

.about-img1 {
  height: 1.3rem;
  width: 1.3rem;
  margin-top: -2px;
}

.content1 {
  display: flex;
  flex-direction: row;
  column-gap: 13px;
}

.content1 p {
  margin: 0;
  padding: 0;
  font-size: 14.5px;
}

.location {
  width: 100%;
  display: flex;
  position: absolute;
  flex-direction: row;
  padding-bottom: 15px;
  top: 335px;
  transform: translate(1%, 50%);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, #000 100%);
}

.calculate-review {
  display: flex;
  flex-direction: row;
  column-gap: 15px;
  border-radius: 3px;
  font-size: 16.5px;
}

.calculate-review1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.calculate-review1--second-box {
  color: white;
}
.calculate-review1--third-box {
  color: #3ea8f2;
}
.first-box1 {
  display: flex;
  flex-direction: row;
  column-gap: 3px;
}
.first-box {
  display: flex;
  flex-direction: row;
  column-gap: 6px;
}
.first-box span {
  color: #4cb04e;
  font-size: 20px;
  margin-top: -5px;
}
.first-box p {
  margin: 0;
  padding: 0;
  color: white;
}
.club-about-us-party-images::-webkit-scrollbar {
  width: 1px;
}

.club-about-us-party-images::-webkit-scrollbar-track {
  background-color: transparent;
}

.club-about-us-party-images::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

.club-about-us-party-images {
  display: flex;
  overflow-x: auto;
  gap: 20px;
  padding: 10px;
  max-width: 100%;
  transition: transform 0.3s ease;
  padding-left: 30px;
  padding-right: 30px;
}

.club--about--us--detail--review {
  display: none;
}
.event-card-design {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login-close-button Button {
  width: auto;
  height: 30px;
  font-size: 16px !important;
  color: #fff;
  position: absolute;
  top: 2%;
  left: 90%;
}
.login-close-button-update Button {
  width: auto;
  height: 30px;
  font-size: 16px !important;
  color: #000;
  opacity: 0.6;
  position: absolute;
  top: 2%;
  left: 87%;
}
.club-about-us--offers-coupan1 {
  display: none;
}
.close--signup-button Button {
  position: absolute;
  top: 0px;
  right: 0px;
}

.close--signup-button Button p {
  font-size: 20px;
  color: rgb(255, 255, 255);
  position: relative;
  cursor: pointer;
  margin: 0px;
}

.signup--submit--button {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signup--submit--button button {
  padding: 10px 130px;
  border: none;
  border-radius: 5px;
  color: #fff;
  margin-top: 0px;
}

.login-otp-close-button p {
  font-size: 15px;
  background-color: rgb(212, 60, 60);
  color: rgb(255, 255, 255);
  position: relative;
  top: -715px;
  left: 560px;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 3px;
}
.login-content11 {
  display: flex;
  flex-direction: row;
  column-gap: 2px;
}
.login-image1 img {
  height: 100%;
  width: 100%;
}

.login-image1 p {
  margin: 0;
  padding: 0;
  padding-bottom: 10px;
}

.login-image {
  width: 100%;
  height: 270px;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-image111 {
  width: 100%;
  height: 170px;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-image1 {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: white;
  z-index: 0;
}

.login-image-text h3 {
  font-size: 24px;
}

.login-otp-text {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
}

.login-content1 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login-content-update {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  justify-content: center;
  align-items: center;
}
.login-content1 h4 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.login-content1--1 {
  width: 60%;
  height: 100%;
}

.login-content--otp {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 20px; */
}

.login-content--otp h4 {
  font-size: 20px;
  color: black;
  opacity: 0.7;
  text-align: left;
  padding-top: 20px;
  padding-bottom: 5px;
}

.login-content11 button {
  border: none;
  background-color: transparent;
  color: #3ea8f2;
}

.login-content {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.login-content1 h4 {
  font-size: 20px;
  color: black;
  font-weight: 600;
  opacity: 0.7;
  text-align: left;
}
.login-content p {
  text-align: center;
  gap: 10px;
  font-weight: 400;
  font-size: 12px;
  margin: 0px;
}
.login-content1--1 h4 {
  font-size: 25px;
  color: black;
  opacity: 0.7;
  text-align: left;
  padding-top: 20px;
  padding-bottom: 5px;
}

.login-content1 h3 {
  font-size: 18px;
  color: black;
  text-align: left;
}

.login-content1 h4 {
  font-size: 15px;
  color: black;
  opacity: 0.7;
  text-align: left;
  padding-bottom: 5px;
}

.login-content1--1 h3 {
  font-size: 23px;
  color: black;
  opacity: 0.7;
  text-align: left;
  padding-top: 20px;
  padding-bottom: 5px;
}
.homepage--search--mobile--view--11{
  display: none;
}
.signup--content-dob {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}

.pink-button {
  background-color: rgb(8, 147, 87);
}

.gray-button {
  background-color: gray;
}

.login-phoneno {
  width: 50%;
  display: flex;
  flex-direction: column;
  margin: auto;
  column-gap: 15px;
}

.login-phoneno .lable {
  font-size: 14px !important;
}

.login-phoneno input {
  width: 100%;
  height: 30px;
  border-radius: 2px;
  border: 0.5px solid gray;
  font-size: 14px;
  font-weight: 300;
  padding: 10px;
  font-family: "Martel Sans", sans-serif;
}

.phone-input:focus {
  border: 2px solid #437fc0;
}

.login-content11-otp {
  color: #3ea8f2;

  margin-left: 12px !important;
  padding: 0 !important;
  cursor: pointer;
}

.login-content11-otp:hover {
  background-color: white !important;
}

.login-otp-content-timer {
  padding-top: 10px;
}

.login-submit-button button {
  padding: 10px 50px;
  border: none;
  border-radius: 5px;

  color: #fff;
  margin-top: 10px;
}

.login-submit-button {
  text-align: left;
}

.mobile-input-container {
  display: flex;
  align-items: center;
}

.login-otp-content {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-otp-content p {
  padding-top: 15px;
}

.login-otp-content button {
  margin-top: 15px;
  border: none;
  border-radius: 5px;
  color: #fff;
  padding: 10px 20px;
}

.country-code {
  font-weight: bold;
  padding: 5px;
}

.blur-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(7px);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.header {
  width: 100%;
  height: 75px;
  display: flex;
  flex-direction: row;
  background-color: #1c0e33;
  align-items: center;
  position: fixed;
  z-index: 100;
}
.header6 {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  background-color: #141420;
  border-bottom: 1px solid #585858;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 100;
}
.input-placeholder-white::placeholder {
  color: #fff !important; 
  font-size: 14px !important;
  font-family: "Open Sans",sans-serif !important;
}
.home-page-design6{
  position: relative;
  top: -75px;
}
.logo img {
  width: 110px;
  height: 50px;
}
.navbar {
  width: 30%;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: end;
  padding-right: 50px;
  gap: 20px;
}

.header-navbar {
  width: 20%;
  height: 65px;
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  align-items: center;
}

.menu {
  padding-top: 10px;
}

.menu1 {
  padding-top: 10px;
  width: 130px;
}

.menu1 a {
  text-decoration: none;
  color: white;
  outline: none;
}

.signup-button2 {
  border: none;
  width: 130px;
  padding: 2px 5px;
  background-image: linear-gradient(to right, #fe2944, #fd2d65);
  border-radius: 2px;
  color: white;
  font-weight: 300;
}

.signup-button2 a {
  text-decoration: none;
  color: white;
}

.login-button1 {
  border: none;
  padding: 5px;
  background-color: #d71362;
  border-radius: 2px;
  color: white;
  font-weight: 500;
}

.signup-button1 {
  border: none;
  padding: 2px 5px;
  background-color: #d71362;
  border-radius: 2px;
  color: white;
  font-weight: 300;
}

.nav-content {
  display: flex;
  flex-direction: row;
}

.menu a {
  text-decoration: none;
  color: white;
}

.menuu a {
  text-decoration: none;
  color: white;
}

.navbar-search {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 420px;
  margin-top: 12px;
}

.navbar-search form {
  width: 440px;
  margin-top: 5px;
  display: flex;
  flex-direction: row;
}

.navbar-searchtag {
  background-color: whitesmoke;
  color: black;
  border: 2px solid grey;
  border: none;
  height: 40px;
  width: 340px;
  padding: 10px;
  border-radius: 3px;
}

button {
  outline: none;
}

.navbar-searchbutton {
  background-color: #1f1e2e;
  border: none;
  border: 2px solid grey;
  height: 40px;
  width: 75px;
  padding-top: 2px;
  margin-left: -4px;
}

.modal {
  outline: none;
}

.navbar-searchbutton span {
  color: wheat;
}

.feedback {
  background-color: #31b0d5;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  border-color: #46b8da;
}

#mybutton {
  position: fixed;
  bottom: 20px;
  right: 0px;
  padding-right: 20px;
}

#mybutton img {
  width: 100px;
  height: 100px;
}

.signup_page_open {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.signup_page_open p span {
  color: #d71362;
  cursor: pointer;
}

.my-account--payment p {
  margin: 0;
  padding: 0;
}

.my-account--payment--wallet img {
  height: 16px;
  width: 16px;
  margin-top: 2px;
  margin-bottom: 8px;
}

.my-account--payment--wallet p {
  font-size: 16px;
}

.my-account--payment--wallet p:hover {
  color: #d71362;
}

.my-account--payment--wallet {
  display: flex;
  flex-direction: row;
  column-gap: 15px;
  row-gap: 5px;
  margin-left: -23px;
}

.my-account--payments {
  padding-top: 8px;
}

.my-account--payment--p {
  font-size: 18px;
}

.my--account--sign-out p:hover {
  color: #b4235a;
}

.my--account--sign-out {
  display: flex;
  flex-direction: row;
}

.my--account--sign-out1 {
  margin-top: 6px;
}

.no-bullets {
  list-style: none;
}

.logout_button {
  width: 100%;
  height: 30px;
  background-color: #d71362;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.carousel11 {
  position: relative;
  width: 23rem;
  height: 23rem;
  perspective: 500px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.card-container11 {
  position: absolute;
  width: 100%;
  height: 100%;

  transition: all 0.3s ease-out;
  opacity: var(--opacity);
}

.card11 {
  width: 100%;
  height: 100%;

  overflow: hidden;

  border-radius: 5px;

  text-align: justify;

  border: 1px solid rgba(129, 129, 129, 0.508);
  margin: auto;
  margin-left: 13px;
}

.card11 h4 {
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;

  color: #d71362;
}

.card11 p {
  text-align: start;
  font-size: 1rem;

  padding-left: 10px;
  margin: 0 0 0.7em;
  color: #d71362;
}

.card11 p,
.card11 h4 {
  transition: all 0.3s ease-out;
  opacity: var(--active);
}

.nav11 {
  color: #d71362;
  font-size: 2rem;
  position: absolute;
  top: calc(50% - 1.5rem);
  z-index: 2;
  cursor: pointer;
  user-select: none;
  background: none;
  border: none;
}

.nav11 i {
  vertical-align: middle;
}

.nav11.left11 {
  left: -1rem;
}

.nav11.right11 {
  right: -2.6rem;
}

.card11 img {
  width: 100%;
  height: 200px;
}

.card11 button {
  width: 100px;
  height: 40px;
  border: none;
  border-radius: 5px;
  color: white;
  background-color: #d71362;
  float: right;
  margin-right: 20px;
}

.slider_img1 {
  width: 100%;
  height: 500px !important;
  object-fit: cover !important;
}

.slider_img1 img {
  width: 100%;
  height: 100%;
}

.css-19kzrtu {
  padding: 0 !important;
}

.navbar--hamburger--sign {
  display: none;
}

.menu-toggle {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  
}

.bar {
  width: 17px;
  height: 1.5px;
  background-color: #fff;
  margin: 2px 0;
}

.navbar--ham {
  list-style: none;
  display: flex;
}

.navbar--ham li {
  margin-right: 20px;
}
.navbar--ham a {
  text-decoration: none;
  color: #fff;
  font-size: 18px;
}
.responsive-modal--log--in {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  height: 450px;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 24px;
  transition: all 0.3s ease;
}
.banner_slider_update--footer{
  margin-top: 50px;
}

.banner_slider_update {
  width: 95%;
  height: auto;
  background-color: white;
  margin: auto;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 40px;
}

.responsive-modal111 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 550px;

  height: 500px;

  background-color: white;

  border-radius: 10px;
  overflow: scroll;
  box-shadow: 24px;

  transition: all 0.3s ease;
}

.responsive-modal111::-webkit-scrollbar {
  display: none;
}

.share-menu {
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-size: 24px;
  justify-content: space-around;
}

.share-menu .facebook {
  color: rgb(10, 47, 149);
}

.share-menu .whatsapp {
  color: rgb(6, 118, 30);
}

.share-menu .twitter {
  color: rgb(16, 163, 232);
}

.share-menu .gmail {
  color: rgb(139, 20, 20);
}

.login-content1--text-field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Plan--your--event--popup--1 {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.plan-yor-event-second-page h2 {
  color: black;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.Plan--your--event--popup--1--img {
  height: 180px;
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url("./Navbar/image/planevent.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px 10px 0px 0px;
  position: relative;
  
}
.cls--btn p{
  color: #000;
  font-size: 25px;
  font-weight: 300;
  font-family: "Open Sans",sans-serif;
  margin: 0;padding: 0;
  position: relative;
  top: -15px;
  left: 5px;
  opacity: 0.3;
}
.Plan--your--event--popup--1--img--book--banner {
  height: 175px;
  width: 100%;
  background: url("./Visiting--table-venue/image/bookatable.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Plan--your--event--popup--1--img--newyear {
  height: 175px;
  width: 100%;
  background: url("./Visiting--table-venue/image/newyear.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.close-btn1 button p {
  background-color: transparent;
  position: relative;
  top: -55px;
  left: 20px;
  border: none;
  color: blue;
  font-size: 25px;
}

.responsive-modal-1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 500px;
  background-color: white;
  border: 2px solid;
  border-radius: 20px;
  box-shadow: 24px;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.9);
  outline: none;
  overflow: scroll;
}

.responsive-modal-1::-webkit-scrollbar {
  display: none;
}
.responsive-modal-1--popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 550px;
  background-color: white;
  border: 2px solid;
  border-radius: 20px;
  box-shadow: 24px;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.9);
  outline: none;
  overflow: hidden;
}

.Plan--your--event--popup--img2 {
  height: 30px;
  width: 60px;
  margin-top: -10px;
  margin-bottom: 10px;
}

.lead--genrate--thankew-page {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Plan--your--event--popup--1--img h4 {
  color: white;
  width: 60%;
  text-align: center;
}

.Plan--your--event--popup--1--img h4 b {
  color: #ffd527;
}

.Plan--your--event--popup--1--img p {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85%;
}

.plan-yor-event-second-page--1 {
  display: flex;
  flex-direction: column;
}

.Plan--your--event--popup--img1 {
  height: 80px;
  width: 200px;
}

.Plan--your--event--popup--1--img--p {
  color: white;
}

.Plan--your--event--popup--1--img--p b {
  color: #ffd527;
}

.Plan--your--event--popup--1--img--p--1 {
  color: white;
}

.Plan--your--event--popup--1--img--p--1 b {
  color: #c6f223;
}

.plan-yor-event-second-page--1--button {
  border: 1px dashed white;
  background-color: transparent;
  width: auto;
  height: 30px;
  border-radius: 4px;
  color: white;
}

.plan-yourevent-div b {
  color: #008ad8;
}

.plan-your-event-div button {
  height: 37px;
  width: 130px;
  border: none;
  border-radius: 5px;
  background-color: #1ebe92;
  color: #fff;
  cursor: pointer;
  position: relative;
  top: -60px;
}

.Plan--your--event--popup-page2 img {
  height: 350px;
  width: 350px;
  margin-top: -50px;
}

.Plan--your--event--popup-page2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 65px;
  margin-left: 20px;
}
.third3--1{
  display: flex;
  flex-direction: row;
  column-gap: 15px;
  padding-bottom: 20px;
}
.login-otp-close-button-1 button p {
  font-size: 25px;
  position: relative;
  top: -480px;
  left: 560px;
}

.login-otp-close-button-11 button p {
  font-size: 25px;
  position: relative;
  top: -480px;
  left: 560px;
  color: red;
}

.login-otp-close-button-1 button {
  background-color: transparent;
  color: black;
  opacity: 0.4;
  border: none;
}

.plan-yor-event-second-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.plan-your-event--pop-up-2--1-1 {
  display: flex;
  flex-direction: column;
  column-gap: 20px;
}

.plan-your-event--pop-up-2--1-1--2 input {
  /* position: relative;
  top: -2px; */
}

.plan-your-event--pop-up-2--1-1--2 {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
}

.plan-your-event--pop-up-2--1-1--2 p {
  margin: 0;
  padding: 0;
}

.plan-your-event--pop-up-2--1 {
  display: flex;
  flex-direction: column;
}

.plan-your-event--pop-up-2--1 label {
  margin: 0;
  padding: 0;
}

.Plan--your--event--popup--1--p p {
  text-align: center;
  font-size: 18px;
  margin: 0;
  padding: 0;
  color: black;
}

.Plan--your--event--popup-page1 {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  justify-content: center;
  align-items: center;
}

.Plan--your--event--popup-page1-1 {
  height: 200px;
  width: 200px;
  border-radius: 10px;

  background-color: #120829;
}

.thumbnail--rating-2 {
  height: 16px;
  width: 58px;
  background-color: #1976d2;
  border-radius: 0px, 0px, 2px, 2px;
}

.thumbnail--rating-2 p {
  margin: 0;
  padding: 0;
  font-size: 10px;
  color: white;
  padding-left: 5px;
  padding-right: 2px;
}

.Plan--your--event--popup-page1-1 img {
  height: 180px;
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.Plan--your--event--popup-page1-2 img {
  height: 180px;
  width: 100%;

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.plan-yourevent-div--terms {
  position: relative;
  top: -35px;
}

.plan-yourevent-div--terms1 {
  position: relative;
  top: -59px;
}

.plan-yourevent-div--terms1 p {
  font-size: 14px;
}

.plan-yourevent-div--terms1 input {
  position: relative;
  top: -8px;
}

.plan-yourevent-div--terms input {
  position: relative;
  top: -8px;
}

.plan-your-event--pop-up-2--1-11 {
  /* padding-top: 20px; */
  padding-left: 5px;
  display: flex;
  flex-direction: column;
  column-gap: 20px;
}

.plan-your-event--pop-up-2--1-11 label {
  font-size: 14px;
  color: #000000;
  opacity: 1;
  margin: 0;
  padding: 0;
}

.Plan--your--event--popup-page1-1 p {
  font-size: 15px;
  margin: 0;
  padding: 0;
  padding: 5px;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Plan--your--event--popup-page1-2 p {
  font-size: 15px;
  margin: 0;
  padding: 0;
  padding: 5px;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Plan--your--event--popup-page1-2 {
  height: 200px;
  width: 200px;

  border-radius: 10px;
  background-color: #120829;
}

.plan-your-event--pop-up-2 {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
}

.plan-your-event--pop-up-2--first-part {
  width: 50%;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.plan-your-event--pop-up-2--second-part {
  width: 50%;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.login--checkbox-condition {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  margin: 0;
  padding: 0;
}

.login--checkbox-condition span {
  margin-top: 6px;
}
/* header--search--start */
.homepage--search--caraousal-1{
  margin-top: -75px;
}
.homepage--search--content--1 {
  width: 100%;
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.homepage--search--content--1 p {
  color: #fff;
  font-family: "Open Sans";
 
  font-style: normal;
  margin: 0;
  padding: 0;
}
.homepage--search--caraousal {
  width: 100%;
  height: 500px;
  position: relative;
}
.homepage--search--caraousal img {
  height: 100%;
  width: 100%;
}
.homepage--search--content{
  width: 100%;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  row-gap: 70px;
}
.homepage--search--content--1--p1{
  text-align: left;

  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.location--wise--filter--1--1 span img{
  height: 22px;
  width: 22px;
  position: relative;
  left: 13px;
  top: 12px;
  
}

.homepage--search--line--border{
   height: 50px;
   display:flex ;
   background-color: #fff;
   justify-content: center;
}
.homepage--search--line--border p{
  font-size: 30px;
  opacity: 0.3;
  color: #000;
  margin: 0;padding: 0;
  position: relative;
  left: 3px;
}
.homepage--search--content--1--p2{
  text-align: right;
  font-size: 14px;
  letter-spacing: 2px;
  font-weight: 300;
  cursor: pointer;
}
.homepage--banner--search--mobile--view{
  display: none;
}
.homepage--search--all--logo--search{
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  justify-content: center;
  align-items: center;
  margin-top: 200px;
}
.homepage--search--all--logo--search img{
  height: 70px;
  width: 140px;
}

.homepage--search--all--logo--search--p{
  color: #fff;
text-align: center;
font-family: "Open Sans";
font-size: 30px;
font-style: normal;
font-weight: 600;
line-height: normal;
width: 40%;
}
.homepage--search--degign{
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.homepage--search--degign-1{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  left: -90px;
}
/* header--search--end */

@media (min-width: 925px) {
  .event_crousal_list1 {
    display: none;
  }
}

@media (min-width: 0px) and (max-width: 925px) {
  .responsive-modal-1 {
    width: 95%;
    height: 450px;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 24px;
    transition: all 0.3s ease;
  }
  
  .homepage--search--caraousal {
    width: 100%;
    height: 350px;
    position: relative;
    margin-bottom: 50px;
  }
  .homepage--search--caraousal img {
    height: 100%;
    width: 100%;
    margin-top: 50px;
    
  }
  .homepage--search--content--1--p1{
    text-align: left;
    letter-spacing: 0px;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
   
  }
  .homepage--search--degign-1{
   position: relative;
   left: -30px;
  }
  .homepage--search--content--1 {
    width: 100%;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .logo {
    width: 60%;
    position: relative;
    left: 25px;
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  .homepage--search--all--logo--search{
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
  }
  .homepage--search--all--logo--search img{
    height: 35px;
    width: 70px;
  }
  .homepage--search--content{
    width: 100%;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
  
  }
  .location--wise--filter--1--1 span img{
    height: 16px;
    width: 16px;
    position: relative;
    top: 7px;
    left: 5px;
  }
  
  .homepage--search--all--logo--search--p{
    color: #fff;
  text-align: center;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 80%;
  
  }
.homepage--banner--search--web--view{
  display: none;
}
.homepage--banner--search--mobile--view{
  width: 100%;
  display: block;
  margin-top: -75px;
}
  .responsive-modal-1::-webkit-scrollbar {
    display: none;
  }

  .plan-yor-event-second-page h2 {
    color: black;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .Plan--your--event--popup-page1-2 img {
    height: 120px;
    width: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .login-otp-text input {
    width: 150px;
    margin-top: 5px;
    margin-bottom: 0px;
    border-radius: 3px;
    border: 0.7px solid rgba(0, 0, 0, 0.3);
    background: #fff;
  }
  .mobile--view--search-bar {
    display: none;
    
  }

  .Plan--your--event--popup--1--img--newyear {
    height: 144px;
    width: 100%;
    background: url("./Visiting--table-venue/image/newyear.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 10px 10px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .plan-your-event--pop-up-2--1-1--2 input {
    height: 12px;
    width: 12px;
    margin-top: 4px;
  }

  .Plan--your--event--popup-page1-1 img {
    height: 120px;
    width: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .responsive-modal-1--popup {
    width: 95%;
    /* Default width */
    height: 430px;
    /* Default height */
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 24px;
    transition: all 0.3s ease;
    /* Add a transition for smooth resizing */
  }

  /* lead form pop up start */

  .login-otp-close-button-1 button p {
    font-size: 25px;
    position: relative;
    top: -470px;
    left: 350px;
  }

  .login-phoneno .lable {
    font-size: 14px !important;
    color: #000;
    font-family: Roboto;

    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.56px;
  }

  .plan-yourevent-div--terms {
    position: relative;
    top: -65px;
  }

  .login-otp-close-button-11 button p {
    font-size: 25px;
    position: relative;
    top: -80px;
    left: 60px;
    color: red;
  }

  .Plan--your--event--popup-page1-1 {
    height: 150px;
    width: 150px;
    border-radius: 10px;
    background-color: #120829;
  }

  .Plan--your--event--popup--1--img {
    height: 199px;
    width: 350px;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    background: url("./Navbar/image/loginbanner.jpg");
    background-size: cover;
    background-repeat: no-repeat;
  }
  .Plan--your--event--popup--1--img--group--booking {
    height: 160px;
    width: 330px;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    background: url("./Navbar/image/loginbanner.jpg");
    background-size: cover;
    background-repeat: no-repeat;
  }
  .Plan--your--event--popup--1--img--book--banner {
    height: 145px;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 5px;

    background-image: url("./Visiting--table-venue/image/Bookatable1.jpg");
    background-size: cover;
    background-repeat: no-repeat;
  }

  .Plan--your--event--popup--1--img h4 {
    font-size: 16px;
    margin: 0;
    padding: 0;
  }

  .Plan--your--event--popup--1--img p {
    font-size: 13px;
    text-align: center;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .Plan--your--event--popup-page1 {
    margin-bottom: 30px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .Plan--your--event--popup--img1 {
    height: 50px;
    width: 150px;
  }

  .plan-your-event--pop-up-2 {
    width: 105%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .Plan--your--event--popup-page1-2 {
    height: 150px;
    width: 150px;
    border-radius: 10px;
    background-color: #120829;
  }

  .plan-your-event--pop-up-2--first-part {
    width: 35%;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
  }

  .plan-your-event--pop-up-2--second-part {
    width: 35%;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
  }

  .plan-your-event--pop-up-2--1-1 label {
    font-size: 12px;
    font-weight: 400;
  }

  .plan-your-event--pop-up-2--1-11 {
    padding-top: 0px;

    display: flex;
    flex-direction: row;
    column-gap: 3px;
  }

  .event_crousal_list1 {
    display: flex;
    flex-direction: row;
    overflow: scroll;
  }

  .event-card-image-crousal p {
    color: white;
    margin-bottom: 5px;
    font-size: 10px;
  }

  .login-content p {
    align-items: center;
    gap: 0px;
    font-weight: 400;
    font-size: 14px;
    margin: 0px;
  }

  .event_crousal_list {
    height: auto;
    display: flex;
    flex-direction: row;
    column-gap: 15px;
  }

  .text_crousal1 button {
    border: none;
    padding: 3px;
    border-radius: 3px;
    color: white;
    background-color: #d71362;
    font-size: 10px;
  }

  .event_crousal_list1::-webkit-scrollbar {
    display: none;
  }

  .e-card-page1 {
    width: 200px;
  }

  .location {
    display: flex;
    position: absolute;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 15px;
    top: 650px;
    transform: translate(1%, 50%);
  }
  

  .event-card-image-crousal1 img {
    width: 100%;
    height: 150px;
  }

  .home-page-design1 {
    width: 98%;
    height: auto;
    margin: auto;
  }

  .right-side {
    display: none;
  }

  .my-account--payment {
    display: none;
  }

  .navbar--ham {
    flex-direction: column;
    background-color: #1f1e2e;
    height: 550px;
    width: 300px;
    position: absolute;
    top: 50px;
    right: 0;
    left: 120px;
    z-index: 1;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.9);
  }

  .navbar--ham.open {
    opacity: 1;
    pointer-events: auto;
  }

  .navbar--ham li {
    margin: 4px 0;
  }

  .login-content--1 {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  .responsive-modal--log--in {
    width: 350px;
    height: 442px;
    padding-right: 0px;
  }

  .ham--profile {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
  }

  .club-aboutus-page--discount21--border1 {
    border: 1px solid grey;
    position: relative;
    left: -32px;
  }

  .ham--menu--wallet-and-location1 {
    height: 35px;
    padding: 3px 10px;
    width: 200px;
    background-color: #1f1e2e;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.8);
  }

  .ham--profile img {
    width: 15px;
    height: 15px;
    margin-top: 4px;
  }

  .login-image1 img {
    height: 100%;
    width: 100%;
  }

  .login-image1 {
    width: 100%;
    height: 180px;
  }

  .login-image {
    width: 100%;
    height: 180px;
  }

  .login-content1 h4 {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .login-content1 h3 {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .login-content1--1 h4 {
    font-size: 20px;
    padding-top: 5px;
    padding-bottom: 0px;
  }

  .login-content1--1 p {
    margin: 0;
    padding: 0;
    font-size: 14.5px;
  }

  .login--checkbox-condition p {
    margin: 0;
    padding: 0;
  }

  .login-phoneno input {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 0px;
  }

  .login-phoneno {
    width: 65%;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    column-gap: 15px;
  }

  .plan-yourevent-div {
    display: flex;
    flex-direction: row;
    gap: 5px;
    font-size: 14.5px;
  }

  .login-submit-button button {
    width: 145px;
    height: 30px;
    flex-shrink: 0;
    border: none;
    font-size: 12px;
    font-family: Poppins;
    border-radius: 3px;
    color: #fff;
    margin-top: 0px;
    position: relative;
    top: 20px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .signup_page_open {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 0px;
  }

  .input-field {
    width: 130px;
    height: 43px;
    margin-bottom: px;
    /* Add some spacing between text fields */
  }

  .signupinput--field {
    width: 280px !important;
    height: 35px !important;
    border-radius: 3px;
    border: 0.7px solid rgba(0, 0, 0, 0.3);
    background: #fff;
    padding-left: 8px;
    font-size: 12px;
  }

  .signupinput--field1 {
    width: 135px !important;
    height: 35px !important;
    border-radius: 3px;
    border: 0.7px solid rgba(0, 0, 0, 0.3);
    background: #fff;
    padding-left: 8px;
  }

  .login-content11 h4 {
    font-size: 18px;
    color: black;
    opacity: 0.7;
    text-align: left;
    padding-top: 0px;
    padding-bottom: 5px;
    position: relative;
  }
  .login-content11 {
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .login-content1--1 h3 {
    font-size: 18px;
    color: black;
    opacity: 0.7;
    text-align: left;
    padding-top: 0px;
    padding-bottom: 5px;
    position: relative;
    top: -15px;
  }

  .login-content1--text-field {
    width: 90%;
    height: auto;
    margin: auto;
  }

  .your-custom-class .css-h4y409-MuiList-root {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .css-h4y409-MuiList-root {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .signup--submit--button button {
    padding: 6px 30px;
    border: none;
    border-radius: 5px;
    color: #fff;
    margin-top: 0px;
  }

  .navbar--ham li p {
    color: white;
    font-size: 18px;
  }

  .left-side {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .h2 {
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 25px;
  }

  .location-part p {
    font-size: 13px;
  }

  .location {
    display: flex;
    flex-direction: column;
  }

  .p-white {
    font-size: 13px;
    margin: 0;
  }

  .p-pink {
    font-size: 13px;
    margin: 0;
  }

  .club-aboutus-img-caraousel {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
  }

  .Club-about-us {
    margin: 0;
    padding: 0;
    flex-direction: column;
  }

  .h3 {
    font-size: 16px;
    font-weight: bold;
  }

  .para-white {
    font-size: 13px;
  }

  .deal-img img {
    width: 70px;
    height: 70px;
  }

  .deal-icon span {
    height: 2px;
    width: 2px;
  }

  .menuu {
    margin-top: 10px;
  }
  .arrow {
    position: absolute;
    top: 2%;
    left: 70%;
    display: flex;
    flex-direction: row;
    column-gap: 10px;
  }
  .arrow a {
    font-size: 13px;
  }

  .arrow button {
    height: 30px;
    width: 30px;
    font-size: 13px;
  }

  .about-img1 {
    height: 16px;
    width: 16px;
  }

  .about {
    margin: 0;
  }

  .deal {
    padding-top: 10px;
    width: 100%;
    height: 80px;
  }

  .calculate-review1 {
    display: flex;
    flex-direction: column;
  }

  .green-h3 {
    font-size: 16px;
    font-weight: bold;
  }

  .recipe1 p {
    margin: 0;
    font-size: 13px;
  }

  .first-box span {
    color: #4cb04e;
    font-size: 19px;
    margin-top: -3px;
  }

  .blue-h3 {
    font-size: 16px;
    font-weight: bold;
  }

  .first-box p {
    font-size: 16px;
  }

  .btn {
    height: 20px;
    width: 50px;
    margin: 0;
  }

  .btn p {
    font-size: 13px;
  }

  .order {
    margin: 5px;
    padding: 0;
  }

  .about-party-img {
    width: 100%;
    height: 100px;
  }

  h1 {
    margin: 0;
    font-size: 20px;
    font-weight: bold;
  }

  .num {
    margin: 0;
    padding: 0;
  }

  .about {
    margin: 0;
  }

  .deal {
    margin: 0;
  }

  .deal-content {
    padding-top: 13px;
  }

  .order {
    row-gap: 8px;
    display: flex;
    flex-direction: column;
  }

  .green-h3 {
    margin-top: 5px;
    margin-left: 5px;
  }

  .Pottage {
    margin: 5px;
    padding: 0;
  }

  .Haute {
    margin: 5px;
  }

  .rating {
    height: 220px;
    margin: 0;
    padding: 0;
    padding-bottom: 30px;
  }

  .star-rating-content h1 {
    font-size: 35px;
    font-weight: bold;
  }

  .calculate-review {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    border-radius: 3px;
    font-size: 15px;
  }

  .navbar-search {
    display: none;
  }

  .star p {
    margin: 0;
    padding: 0;
    font-size: 13px;
  }

  .middle {
    padding-left: 17px;
    padding-right: 11px;
  }

  .row {
    display: flex;
    flex-direction: column;
    row-gap: 0px;
  }

  .bar-container {
    height: 4px;
  }

  .pricing {
    height: 4px;
  }

  .food {
    height: 4px;
  }

  .about {
    margin: 0;
    padding: 0;
  }

  .features {
    width: 100%;
    height: auto;
  }

  .club-image {
    display: flex;
    flex-direction: column;
    gap: 1px;
  }

  .first-club-image {
    width: 100%;
    height: auto;
  }

  .party1 img {
    width: 100%;
    height: 170px;
  }

  .banner-image img {
    width: 100%;
    height: 350px !important;
  }

  .banner-slider {
    width: 100%;
    height: 200px;
  }

  .features-content1 {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 5px;
  }

  .navbar {
    padding-right: 10px !important;
  }

  .searchbar input {
    width: 80%;
    margin: auto;
    display: flex;
    height: 60px;
    border-radius: 1px;
  }

  .club-about-us--offers-coupan1 {
    display: block;
  }

  .club-aboutus-about--offers--coupon {
    display: block;
  }

  .club-aboutus-about--offers--coupon1 {
    width: 20%;
    margin-top: 6px;
  }

  .club-aboutus-about--offers--coupon2 {
    width: 80%;
    margin-top: 13px;
  }

  .club-aboutus-about--offers--coupon2 p {
    font-size: 19px;
    color: white;
  }

  .navbar-icon {
    color: white;
    padding-right: 20px;
    padding-bottom: 10px;
    font-size: 30px;
    font-weight: 500;
    cursor: pointer;
  }

  .header {
    width: 100%;
    height: 50px;
  }
  .header6 {
    width: 100%;
    height: 50px;
  }
  /* navbar responsive by srishti start */
  .header-navbar {
    margin: 0;
    padding: 0;
  }
  .navbar {
    display: none;
  }
  .menu {
    padding-top: 0px;
    display: none;
  }
  .menu1 {
    display: none;
  }
  .scrollable-tabs-container {
    overflow: hidden;
    position: relative;
  }
  .scrollable-tabs {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
     scroll-behavior: smooth;
     scrollbar-width: thin;
     scrollbar-color: rgba(255, 255, 255, 0.5) transparent;
    max-width: 100%;
  }

  .MuiTab-root {
    min-width: auto;

    padding: 10px 20px;
  }
  .router-page {
    margin-top: 0px;
  }
  .Plan--your--event--popup--1 .cls--btn {
    position: fixed !important;
    top: -10px !important;
    right: 0px !important;
    padding: 5px 10px;
    font-size: 30px;
    border-radius: 3px;
    color: white;
    cursor: pointer;
  }
}

@media (max-width: 925px) {
  .right-side {
    display: block;
    width: 100%;
  }

  .navbar--hamburger--sign {
    display: block;
  }

  .my-account--payment {
    display: none;
  }

  .navbar--ham {
    flex-direction: column;
    background-color: #1f1e2e;
    height: 550px;
    width: 300px;
    position: absolute;
    top: 50px;
    right: 0;
    left: 120px;
    z-index: 1;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.9);
  }

  .navbar--ham.open {
    opacity: 1;
    pointer-events: auto;
  }

  .navbar--ham li {
    margin: 4px 0;
  }

  .logo img {
    width: 72px;
    height: 36px;
    margin-top: 0px;
    margin-left: -15px;
  }

  .login-content--1 {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  .ham--profile {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
  }

  .login--checkbox-condition {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    margin: 0;
    padding: 0;
  }

  .club-aboutus-page--discount21--border1 {
    border: 1px solid grey;
    position: relative;
    left: -32px;
  }

  .ham--menu--wallet-and-location1 {
    height: 35px;
    padding: 3px 10px;
    width: 200px;
    background-color: #1f1e2e;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.8);
  }

  .ham--profile img {
    width: 17px;
    height: 17px;
    margin-top: 4px;
  }

  .ham--profile p {
    margin: 0;
    padding: 0;
    font-size: 13px;
    padding-top: 2px;
  }

  .login-content1 h4 {
    font-size: 14px;
    padding-top: -15px;
    padding-bottom: 0px;
  }

  .ham--profile1 {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
  }

  .ham--profile1 img {
    height: 15px;
    width: 15px;
  }

  .ham--profile1 p {
    font-size: 13px;
  }

  .login-content1--1 h4 {
    font-size: 20px;
    padding-top: 5px;
    padding-bottom: 0px;
  }

  .login-content1--1 p {
    margin: 0;
    padding: 0;
    font-size: 14.5px;
  }

  .login-phoneno input {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 0px;
    border-radius: 3px;
    border: 0.7px solid rgba(0, 0, 0, 0.3);
    background: #fff;
  }

  .plan-yourevent-div {
    display: flex;
    flex-direction: row;
    gap: 5px;
    font-size: 14.5px;
  }

  .signup_page_open {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 0px;
  }

  .input-field {
    width: 130px;
    height: 43px;
    margin-bottom: px;
  }

  .login-content1--1 h3 {
    font-size: 18px;
    color: black;
    opacity: 0.7;
    text-align: left;
    padding-top: 0px;
    padding-bottom: 5px;
    position: relative;
    top: -15px;
  }

  .navbar--ham li p {
    color: white;
    font-size: 18px;
  }

  .left-side {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .h2 {
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 25px;
  }

  .location-part p {
    font-size: 13px;
  }

  .location {
    display: flex;
    position: absolute;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 15px;
    transform: translate(1%, 50%);
    top: 20px
  }
  

  .p-white {
    font-size: 13px;
    margin: 0;
  }

  .p-pink {
    font-size: 13px;
    margin: 0;
  }

  .club-aboutus-img-caraousel {
    width: 100%;
    height: 210px;
    margin: 0;
    padding: 0;
  }

  .Club-about-us {
    margin: 0;
    padding: 0;
    margin-top: 30px;
  }

  .h3 {
    font-size: 16px;
    font-weight: bold;
  }

  .para-white {
    font-size: 13px;
  }

  .deal-img img {
    width: 70px;
    height: 70px;
  }

  .deal-icon span {
    height: 2px;
    width: 2px;
  }

  .arrow a {
    font-size: 13px;
  }

  .arrow button {
    height: 30px;
    width: 30px;
    font-size: 13px;
  }

  .about-img1 {
    height: 16px;
    width: 16px;
  }

  .about {
    margin: 0;
  }

  .deal {
    padding-top: 10px;
    width: 100%;
    height: 80px;
  }

  .calculate-review1 {
    display: flex;
    flex-direction: column;
  }

  .green-h3 {
    font-size: 16px;
    font-weight: bold;
  }

  .recipe1 p {
    margin: 0;
    font-size: 13px;
  }

  .first-box span {
    color: #4cb04e;
    font-size: 19px;
    margin-top: -3px;
  }

  .blue-h3 {
    font-size: 16px;
    font-weight: bold;
  }

  .first-box p {
    font-size: 16px;
  }

  .btn {
    height: 20px;
    width: 50px;
    margin: 0;
  }

  .btn p {
    font-size: 13px;
  }

  .order {
    margin: 5px;
    padding: 0;
  }

  .about-party-img {
    width: 100%;
    height: 100px;
  }

  h1 {
    margin: 0;
    font-size: 20px;
    font-weight: bold;
  }

  .num {
    margin: 0;
    padding: 0;
  }

  .about {
    margin: 0;
  }

  .deal {
    margin: 0;
  }

  .deal-content {
    padding-top: 13px;
  }
  .header1 {
    display: flex;
    flex-direction: column;
    margin-bottom: 17px;
  }

  .order {
    row-gap: 8px;
    display: flex;
    flex-direction: column;
  }

  .green-h3 {
    margin-top: 5px;
    margin-left: 5px;
  }

  .Pottage {
    margin: 5px;
    padding: 0;
  }

  .Haute {
    margin: 5px;
  }

  .rating {
    height: 220px;
    margin: 0;
    padding: 0;
    padding-bottom: 30px;
  }

  .star-rating-content h1 {
    font-size: 35px;
    font-weight: bold;
  }

  .calculate-review {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    border-radius: 3px;
    font-size: 15px;
  }

  .star p {
    margin: 0;
    padding: 0;
    font-size: 13px;
  }

  .middle {
    padding-left: 17px;
    padding-right: 11px;
  }

  .row {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  .bar-container {
    height: 4px;
  }

  .pricing {
    height: 4px;
  }

  .food {
    height: 4px;
  }

  .about {
    margin: 0;
    padding: 0;
  }

  .features {
    width: 100%;
    height: auto;
  }

  .club-image {
    display: flex;
    flex-direction: column;
    gap: 1px;
  }

  .first-club-image {
    width: 100%;
    height: auto;
  }

  .party1 img {
    width: 100%;
    height: 170px;
  }

  .banner-image img {
    width: 100%;
    height: 350px !important;
  }

  .banner-slider {
    width: 100%;
    height: 200px;
  }

  .features-content1 {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 5px;
  }

  .navbar {
    padding-right: 10px !important;
  }

  .searchbar input {
    width: 80%;
    margin: auto;
    display: flex;
    height: 60px;
    border-radius: 1px;
  }

  .club-about-us--offers-coupan1 {
    display: block;
  }

  .club-aboutus-about--offers--coupon {
    display: block;
  }

  .club-aboutus-about--offers--coupon1 {
    width: 20%;
    margin-top: 6px;
  }

  .club-aboutus-about--offers--coupon2 {
    width: 80%;
    margin-top: 13px;
  }

  .club-aboutus-about--offers--coupon2 p {
    font-size: 19px;
    color: white;
  }

  .navbar-icon {
    color: white;
    padding-right: 20px;
    padding-bottom: 10px;
    font-size: 30px;
    font-weight: 500;
    cursor: pointer;
  }

  .header {
    width: 100%;
    height: 62px;
  }
  .home-page-design6 {
    position: relative;
    top: -1px;
}
  .header-navbar {
    margin: 0;
    padding: 0;
  }
  .homepage--search--mobile--view--11{
    display: block;
   
  }
  .navbar {
    display: none;
  }

  .menu {
    padding-top: 0px;
    display: none;
  }

  .menu1 {
    display: none;
  }

  .scrollable-tabs-container {
    overflow: hidden;

    position: relative;
  }

  .scrollable-tabs {
    display: flex;
    flex-wrap: nowrap;

    overflow-x: auto;

    -webkit-overflow-scrolling: touch;

    scroll-behavior: smooth;

    scrollbar-width: thin;

    scrollbar-color: rgba(255, 255, 255, 0.5) transparent;

    max-width: 100%;
  }

  .MuiTab-root {
    min-width: auto;

    padding: 10px 20px;
  }
  .homepage--search--web--view--11{
    display: none;
  }
  .banner_slider_update {
    width: 98% !important;
    height: auto!important;
    margin: 0;
   
    border-radius: 5px;
    overflow: hidden;
  }

  .formdatainput1 .booktablecss {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 20px !important;
    font-weight: 700;
    border-bottom: 1px solid #585858;
  }
  .login-otp-content button {
    width: 135px;
    height: 30px;
    flex-shrink: 0;
    border: none;
    font-size: 12px;
    font-family: Poppins;
    border-radius: 3px;
    color: #fff;
    margin-top: 0px;
    position: relative;
    top: 20px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .navbar--hamburger--sign{
    color: #fff;
    font-size: 25px;
    padding-right: 20px;
    display: flex;
    column-gap: 18px;
    align-items: center;
  }
}

.input-field {
  width: 100%;
}

.bn_image img {
  width: 100%;
  height: auto;
 
}

.gender-group {
  margin-top: 5px !important;
  margin-bottom: 0px !important;
}

.gender-group--1 {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}

.gender-heading {
  font-size: 15px;
}

.margin-left {
  margin-left: 10px;
}

.location_name p {
  margin: 0px;
  padding: 0px;
  color: white;
}

.main-component {
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 10px;
  color: #fff;
  overflow: scroll;
}

.main-component::-webkit-scrollbar {
  display: none;
}

.main-component-time {
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: 10px;
  color: #fff;
  overflow: scroll;
}

.main-component-time::-webkit-scrollbar {
  display: none;
}

.chip {
  width: 120px !important;
  border-radius: 15px;
  font-size: 12px;
}
.chip1 {
  width: auto;
  height: 40px;
  border-radius: 15px;
  font-size: 12px;
}
.chip p {
  background-color: #8d29518d;
  border-radius: 15px;
  padding: 5px;
  height: 30px !important;
  margin: 0px;
  width: 100px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.chip1 p {
  background-color: #8d29518d;
  border-radius: 15px;
  padding: 5px;
  height: 30px !important;
  margin: 0px;
  width: 100px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.kids--yes--no {
  display: flex;
  justify-content: space-between;
  color: white;
}

.radio-options {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.formdatainput {
  font-family: "Poppins", sans-serif;
}

.radio-label {
  display: flex;
  color: white;
  align-items: center;
  gap: 10px;
}

.chip .no--off--pax {
  margin: 0px;
  padding: 0px;
  width: 30px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.chip1 .no--off--pax {
  margin: 0px;
  padding: 0px;
  width: auto;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.radio-options .radio-label input[type="radio"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  bottom: -13px;
  position: relative;
  background-color: #d71362;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

.formdatainput1 button {
  background-color: #1ebe92 !important;
}

.formdatainput1 .booktablecss {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 25px;
  font-weight: 700;
  border-bottom: 1px solid #585858;
}

.choose--time--date {
  width: 95%;
  margin: auto;
}

.choose--time--date p {
  color: white;
}

.club--aboutus--ads--banner {
  width: 100%;
  height: auto;
  background-color: #88769768;
  border-radius: 10px;
  border: 1px dotted white;

}
.club--aboutus--ads--banner img {
  width: 100%;
  height: 100%;
}

.clubname--dirction--icon {
  display: flex;
  gap: 20px;
}

.clubname--dirction--icon h2 {
  font-size: 25px;
}

.Discount--addon--coupons {
  width: 865px;
  height: 145px;
  display: flex;
  align-items: center;
}

.Discount--addon--coupons1 {
  width: 865px;
  height: 145px;
  display: flex;
  align-items: center;
}

.discount--circle {
  width: 145px;
  height: 100%;
  background-color: #d71362;
  border-radius: 50%;
  border: 5px solid black;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
}

.discount--circle h1 {
  font-size: 20px;
}

.discount--circle1 h1 {
  font-size: 20px;
}

.discount--rectangle {
  width: 780px;
  height: 120px;
  border: 3px solid #d71362;
  border-radius: 60px;
  position: relative;
  margin-left: -110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.my--account--Setting--dropdown {
  height: 40px;
  width: 200px;
  border-radius: 10px;
  background-color: white;
  color: black;
  border: 1px solid #e6e7ec;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 20px;
  margin-bottom: 10px;
}

.my--account--Setting--dropdown span {
  font-size: 30px;
  position: relative;
  top: -14px;
}
ul {
  list-style-type: disc;
  padding-left: 50px;
  padding-top: 16px;
}
.partner--with--us--header1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: -70px;
}

.my--profile--setting--dropdown--borderline {
  height: 30px;
  width: 0.5px;
  position: relative;
  left: 14px;
  background-color: #1d1d29;
}



ul li {
  font-size: 12px;

  align-items: center;
}

.my--account--Setting--dropdown1 {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}

.my--account--Setting--dropdown1 img {
  height: 20px;
  width: 20px;
}

.discount--circle1 {
  width: 145px;
  height: 100%;
  background-color: #1ebe92;
  border-radius: 50%;
  border: 5px solid black;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.discount--rectangle1 {
  width: 780px;
  height: 120px;
  border: 3px solid #1ebe92;
  border-radius: 60px;
  position: relative;
  margin-left: -110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.discount--rectangle1 h3 span {
  color: #1ebe92;
}

.discount--rectangle h3 span {
  color: #d71362;
}

.discount--rectangle h3 {
  font-size: 24px;
}

.discount--rectangle1 h3 {
  font-size: 24px;
}

.addverties-card {
  width: 815px;
  height: auto;
  margin-top: 10px;
  margin: 20px;

  color: white;
}

.reserve--table--booking--card {
  width: 100%;
  height: auto;
  /* background-color: rgba(20, 20, 32, 1); */
  z-index: 99;
  border-radius: 5px;
  border: 1px dotted rgba(111, 111, 111, 0.881);
  padding-bottom: 10px;
}

.review-list--onclubpage {
  width: 97%;
  height: 250px;
  background-color: white;
  margin: auto;
  border-radius: 5px;
  margin-top: 20px;
  display: flex;
  gap: 20px;
  overflow: hidden;
}

.review--card--design {
  width: 200px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #1ebe92;
}

.reviewimage {
  width: 100%;
  height: 140px;
  background-color: red;
}

.user--data--list {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
}

.plan-your-event--pop-up-2--1 select {
  width: 97%;
  height: 30px;
  font-size: 13px;
  border-radius: 4px;
  border: 1px solid #a6a6a6;
  padding-left: 5px;
  padding-right: 5px;
}

.plan-your-event--pop-up-2--1 input {
  width: 97%;
  height: 30px;
  font-size: 13px;
  border-radius: 4px;
  border: 1px solid #a6a6a6;
  padding-left: 10px;
  padding-right: 5px;
}

.plan-your-event--pop-up-2--1 label {
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: 400px;
  color: #000000;
  opacity: 1;
}


.location--wise--filter select {
  width: 160px;
  height: 50px;
  font-size: 14px;
  font-family:"Open Sans",sans-serif ;
  font-weight: 500;
  color: #1b183b;
  padding-left: 4px;
  border: 1px solid white;
  outline: none;
}
.searchbar_field span img{
 height: 20px;
 width: auto;

 position: relative;
 top: -2px;
}
.recent--search--nav {
  max-height: 100px;
  overflow-y: auto;
}

.input--search--navbar {
  background-color: white;
  color: #313144;
  height: auto;
  width: 170px;
}

.location--wise--filter1 {
  margin-left: 20px;
}

.location--wise--filter1 select {
  width: 50px;
  height: 40px;

  color: #1b183b;
  padding-left: 4px;
  border: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  outline: none;
}



.custom-dropdown-container {
  position: relative;
}

.location--wise--filter--content {
  position: absolute;
  top: 100%;

  left: 25px;
  height: auto;
  width: 250px;
  background-color: white;
  border-radius: 3px;
  color: black;
  display: none;

  padding: 15px 20px;
  margin-top: 5px;
}

.recent--search--p {
  font-size: 13px;
  color: #313144;
  font-weight: 300;
  margin: 0;
  padding: 0;
  padding-top: 15px;
  padding-bottom: 15px;
}
.searchbar_field input::placeholder {
  color: #313131; 
  font-size: 14px;
  font-weight: 500;
  font-family: "Open Sans",sans-serif;
}
.location--gps--p1 {
  font-size: 14px;
  font-weight: 400;
  color: #313144;
  margin: 0;
  padding: 0;
  position: relative;
  left: 35px;
}
.location--gps--p1-2 {
  font-size: 14px;
  font-weight: 400;
  color: #313144;
  margin: 0;
  padding: 0;
}
.location--wise--filter--content--detect--current--location p {
  font-size: 14px;
  font-weight: 400;
  line-height: 10px;
  color: #1976d2;
  margin: 0;
  padding: 0;
}

.location--gps--p {
  font-size: 13px;
  font-weight: 400;
  color: black;
  opacity: 30%;
  position: relative;
  left: 31px;
}

.location--wise--filter--content input {
  height: 35px;
  width: 200px;
  border: none;
  border-radius: 18px;
  padding-left: 10px;
  border: 1px solid #999999;
  color: black;
  margin-left: 15px;
  margin-bottom: 15px;
  position: relative;
  left: -15px;
}

.location--wise--filter--1--1 {
  display: flex;
  flex-direction: row;
}

.location--wise--filter--1--1 span {
  height: 50px;
  width: 40px;
  background-color: #fff;
  margin-left: 20px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.location--wise--filter--content--detect--current--location img {
  height: 20px;
  width: 20px;
}

.location--wise--filter--content--detect--current--location {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  position: relative;
  left: -15px;
  margin-top: 20px;
}
.location--wise--filter:hover .location--wise--filter--content {
  display: block;
}

.searchbar_field--mobile {
  display: none;
}

.plan-yourevent-div--terms {
  position: relative;
  top: -45px;
}

.plan-yourevent-div--terms1 {
  position: relative;
  top: -70px;
}

.plan-yourevent-div--terms1 p {
  font-size: 13px;
}

.my-account--profile-name--1 {
  color: #0a1b39;
  font-size: 16px;
  font-size: 900;
  margin: 0;
  padding: 0;
}

.my-account--profile-name {
  height: 55px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  border: 1px solid #e6e7ec;
  padding-left: 15px;
  padding-right: 15px;
  justify-content: center;
  align-items: center;
}

.my-account--profile-name img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
.my-account--profile-name1 {
  height: 55px;
  width: 170px;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  border: 1px solid #e6e7ec;
  padding-left: 0px;
  padding-right: 0px;
  justify-content: center;
  align-items: center;
}
.my-account--profile-name1 img {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  position: relative;
  left: -10px;
}
.my-account--profile-name--1--2 {
  color: #31b0d5;
  font-size: 13px;
  font-size: 400;
  margin: 0;
  padding: 0;
}
.custom-dropdown {
  position: relative;
  display: inline-block;
}
.dropdown-header {
  height: 40px;
  width: 100px;
  background-color: white;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  margin-left: 30px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: 0px 5px;
  justify-content: space-between;
  align-items: center;
}
.update-profile--text-field{
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  justify-content: center;
  align-items: center;
}
.update-profile--text-field input{
  height: 45px;
  width: 300px;
  border: none;
  border-radius: 5px;
  border: 1px solid #cfcfcf;
  padding-left: 5px;
  font-family: "Open Sans",sans-serif;
}
.update-profile--text-field button{
  height: 45px;
  width: 200px;
  border: none;
  border-radius: 5px;
  border: 1px solid #cfcfcf;
  color: #000;
  font-family: "Open Sans",sans-serif;
}
.dropdown-header span {
  font-size: 20px;
}
.dropdown-header.open {
  border-bottom: none;
}
.dropdown-options {
  position: absolute;
  top: 100%;
  left: 30px;
  height: auto;
  width: 100px;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 5px;
  font-size: 15px;
  overflow-y: auto;
  z-index: 1;
  margin-top: 3px;
}
.dropdown-options div {
  padding: 10px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
}
.dropdown-options div:hover {
  background-color: #f0f0f0;
}
.thankyou--page--close{
  position: relative;
  }
  .thankyou--page--close button {
    position: absolute;
    top: 2%;
    left: 93%;
    margin-right: 10px;
    margin-top: 10px;
    border: none;
    background-color: grey;
    opacity: 0.4;
    color: #fff;
    border-radius: 50%;
  }
@media (max-width: 925px) {
  .addverties-card {
   
  }
 .my-account--profile-name--1 {
    color: #0a1b39;
    font-size: 14px;
    font-size: 900;
    margin: 0;
    padding: 0;
  }

  .my-account--profile-name {
    height: 48px;
    width: 100%;
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    border: 1px solid #e6e7ec;
    padding-left: 0px;
    padding-right: 0px;
    justify-content: center;
    align-items: center;
  }
  .Plan--your--event--popup-page2 {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    justify-content: center;
    align-items: center;
    margin-top: 65px;
    margin-left: 20px;
  }
  .my-account--profile-name img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    position: relative;
    left: -10px;
  }

  .my--profile--setting--dropdown--borderline {
    height: 30px;
    width: 0.5px;
    position: relative;
    left: 20px;
    background-color: #1d1d29;
  }
  .update-profile--text-field input{
    height: 45px;
    width: 250px;
    border: none;
    border-radius: 5px;
    border: 1px solid #cfcfcf;
  }

  .my-account--profile-name1 {
    height: 48px;
    width: 100%;
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    border: 1px solid #e6e7ec;
    padding-left: 0px;
    padding-right: 0px;
    justify-content: center;
    align-items: center;
  }

  .my--account--help {
    display: flex;
    position: relative;
    top: 180px;
  }


  .my--account--Setting--dropdown1 img {
    height: 15px;
    width: 15px;
  }

  .my--account--Setting--dropdown1 p {
    margin: 0;
    padding: 0;
  }

  .my-account--profile-name1 img {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    position: relative;
    left: -10px;
  }

  .reserve--table--booking--card {
    /* width: 297px; */
    height: auto;

    border-radius: 5px;
    border: 1px dotted rgba(111, 111, 111, 0.881);
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .location--wise--filter select {
    width: 90px;
    height: 40px;
    font-size: 13px;
    background-color: #fff;
    padding-left: 5px;
    margin: 0;padding: 0;
    border: 1px solid white;
    outline: none;
  }
  .location--wise--filter--8 select {
    width: 120px;
    height: 30px;
   border-radius: 5px;
    background-color: transparent;
    padding-left: 4px;
    border: 1px solid gray;
    outline: none;
  }
  .my--account--Setting--dropdown {
    height: 40px;
    width: 130px;
    border-radius: 10px;
    background-color: white;
    color: black;
    border: 1px solid #e6e7ec;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 10px;
    margin-bottom: 10px;
  }

  .my-account--profile-name--1--2 {
    color: #0a1b39;
    font-size: 12px;
    font-size: 400;
    margin: 0;
    padding: 0;
  }

  .addverties-card h2 {
    font-size: 18px;
  }

  .plan-your-event--pop-up-2--1 input {
    width: 97%;
    height: 25px;
    font-size: 13px;
    border-radius: 4px;
    border: 1px solid #a6a6a6;
    padding-left: 4px;
    padding-right: 2px;
  }

  .searchbar_field--mobile {
    display: block;
  }

  .plan-your-event--pop-up-2--1 select {
    width: 97%;
    height: 25px;
    font-size: 13px;
    border-radius: 4px;
    border: 1px solid #a6a6a6;
    padding-left: 1px;
    padding-right: 1px;
  }
  .row_11{
    display: flex;
    flex-direction: column;
    row-gap: 0px;
    padding: 20px;
  }
  .Discount--addon--coupons {
    width: 100%;
    height: 70px;

    display: flex;
    align-items: center;
  }

  .Discount--addon--coupons1 {
    width: 100%;
    height: 70px;

    display: flex;
    align-items: center;
  }
.hm--page{
  position: relative;
  left: -10px;
}
.popular--package--9{
  margin-top: 35px;
}
.popular--package--9-1{
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-top: 20px;
}
.popular--package--9-2{
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-top: 20px;
}
  .location--wise--filter--content input {
    height: 30px;
    width: 160px;
    border: none;
    border-radius: 18px;
    padding-left: 10px;
    border: 1px solid #999999;
    color: black;
    margin-left: 15px;
    margin-bottom: 15px;
    position: relative;
    left: -20px;
  }

  .discount--circle {
    width: 70px;
    height: 100%;
    background-color: #d71362;
    border-radius: 50%;
    border: 5px solid black;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
  }

  .discount--rectangle {
    width: 100%;
    height: 60px;
    border: 3px solid #d71362;
    border-radius: 35px;
    position: relative;
    margin-left: -50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
  }

  .discount--circle1 {
    width: 70px;
    height: 100%;
    background-color: #1ebe92;
    border-radius: 50%;
    border: 5px solid black;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
  }

  .discount--rectangle1 {
    width: 100%;
    height: 60px;
    border: 3px solid #1ebe92;
    border-radius: 35px;
    position: relative;
    margin-left: -50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
  }
  .third3--1{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
  .discount--rectangle1 h3 span {
    color: #1ebe92;
  }

  .discount--rectangle h3 span {
    color: #d71362;
  }

  .discount--rectangle h3 {
    font-size: 18px;
  }

  .discount--rectangle1 h3 {
    font-size: 18px;
  }

  .discount--circle1 h1 {
    font-size: 18px;
  }

  .discount--circle1 h3 {
    font-size: 16px;
  }

  .discount--rectangle1 h1 {
    font-size: 18px;
  }

  .discount--rectangle1 h3 {
    font-size: 16px;
  }

  .discount--circle h1 {
    font-size: 18px;
  }

  .discount--circle h3 {
    font-size: 16px;
  }

  .discount--rectangle h1 {
    font-size: 18px;
  }
  .homepage--search--line--border{
    height: 40px;
    display:flex ;
    background-color: #fff;
    justify-content: center;
    border: none;
    border: 0.1px solid #fff;
 }
  .thankyou--page--close button {
    position: relative;
    float: right;
    margin-right: 10px;
    margin-top: 10px;
    border: none;
    background-color: rgba(243, 62, 62, 0.724);
    color: white;
    border-radius: 3px;
  }

  .discount--rectangle h3 {
    font-size: 16px;
  }

  .gender-group {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .responsive-modal111 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
    height: 590px;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 24px;
    transition: all 0.3s ease;
  }

  .leadform--page {
    width: 95%;
    height: auto;
    margin: auto;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    padding-bottom: 30px;
  }
  .Plan--your--event--popup-page2 img {
    height: auto;
    width: 200px;
    margin-top: -50px;
}
  .plan-your-event-div122 button {
    height: 34px;
    width: 130px;
    border: none;
    border-radius: 5px;
    background-color: #1ebe92;
    color: #fff;
    cursor: pointer;
    position: relative;
  }

  .plan-yourevent-div--terms122 {
    position: relative;
    top: 0px;
  }

  .plan-yourevent-div--terms122 p {
    margin: 0px;
    display: flex;
    align-items: center;
  }

  .plan-your-event--pop-up-2--second-part22 {
    width: 40%;
    display: flex;
    flex-direction: column;
    row-gap: 7px;
    margin-top: -28px;
  }
  .homepage--search--line--border p{
    font-size: 23px;
    opacity: 0.3;
    color: #000;
    margin: 0;padding: 0;
    position: relative;
    left: 3px;
    top: 0px;
    background-color: #fff;
  }
  .plan-your-event--pop-up-2--first-part22 {
    width: 40%;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
  }

  .category--card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  .category--card p {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #d71362;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #d71362;
    overflow: hidden;
  }

  .selecteddate {
    cursor: pointer;
    /* border: 3px solid #d71313; */
    color: white !important;
    border-radius: 8px;
  }

 
  .slider_img1 {
    width: 100%;
    height: 200px !important;
  }
  .custom-dropdown {
    display: none;
  }



  .location--wise--filter--content {
    position: absolute;
    top: 100%;

    left: 0px;
    height: auto;
    width: 230px;
    background-color: white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    color: black;
    display: none;

    padding: 15px 20px;
    margin-top: 30px;
  }

  .Discount--addon--coupons--strip2 {
    width: 100%;
    height: 33px !important;
    background-image: url(http://localhost:3000/static/media/packagestrip.dfacd26….png);
    background-repeat: no-repeat;
    background-size: 100% 33px !important;

    padding-left: 25px;
    display: flex;
    align-items: center;
  }

  .login-content .privacy-policy-aggreement {
    width: 90% !important;

    text-align: center !important;
    margin-top: 30px !important;
    font-size: 10px !important;
  }
  .login-content .privacy-policy-aggreement p {
    width: 90% !important;

    text-align: center !important;
    margin-top: 30px !important;
    font-size: 10px !important;
  }

  .login-content .privacy-policy-aggreement1 {
    width: 90% !important;

    text-align: center !important;
    margin-top: 10px !important;
    font-size: 10px !important;
  }

  .signup--submit--button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  .signup--submit--button button {
    width: 135px;
    height: 30px;
    flex-shrink: 0;
    border: none;
    font-size: 12px;
    font-family: Poppins;
    border-radius: 3px;
    color: #fff;
    margin-top: 10px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .login-otp-content-timer {
    padding-top: 10px;
    font-size: 12px;
    margin-top: 6px;
  }
  .resend--otp--after--time {
    cursor: pointer;
    color: #008ad8;
  }
  .login-content1--text-field .lable {
    font-size: 12px;
  }

  .gender--lable {
    font-size: 12px !important;
    margin-bottom: 5px !important;
  }

  .gender--lable11 {
    font-size: 14px !important;
    margin-bottom: 5px !important;
    position: relative !important;
    top: -3px !important;
    margin: 0;
    padding: 0;
    padding-right: 10px;
  }
  .gender--lable11 input {
    position: relative;
    top: 2px;
  }
  .searchbar_field span img {
    height: 13px;
    width: auto;
    position: relative;
    top: -2px;
}
.club--aboutus--ads--banner {
 margin-top: 75px;

}
}

.plan-yor-event-second-page--1 .cls--btn {
  position: fixed;
  top: 10px;
  right: 10px;
  padding: 5px 10px;
  font-size: 30px;
  border-radius: 3px;
  color: white;
  cursor: pointer;
}

.Plan--your--event--popup--1 .cls--btn {
  position: fixed;
  top: 10px;
  right: 10px;
  padding: 5px 10px;
  font-size: 30px;
  border-radius: 3px;
  color: white;
  cursor: pointer;
}

.location--wise--filter select .currentadd {
  color: rgb(65, 10, 215);
}

.no-bullets {
  margin-top: 25px;
  list-style: none;
}

.no-bullets li {
  margin-bottom: 25px;
  color: black;
}

.plan-your-event--pop-up-2--1-1 label {
  font-size: 12px;
  font-weight: 400;
}

.Discount--addon--coupons--strip {
  width: 100%;
  height: 53px;
  background-image: url(./Assets/Discount.png);
  background-repeat: no-repeat;
  background-size: 100% 53px;

  padding-left: 25px;
  display: flex;
  align-items: center;
}

.Discount--addon--coupons--strip p {
  font-size: 24px;
  margin-top: -5px !important;

  font-weight: 600;
}

.Discount--addon--coupons--strip2 {
  width: 100%;
  height: 53px;
  background-image: url(./Assets/packagestrip.png);
  background-repeat: no-repeat;
  background-size: 100% 53px;

  padding-left: 25px;
  display: flex;
  align-items: center;
}

.Discount--addon--coupons--strip2 p {
  width: 100%;
  text-align: end;
  font-size: 24px;
  padding-right: 20px;
  margin-top: -5px !important;

  font-weight: 600;
}

.Discount--addon--coupons--strip1 {
  width: 100%;
  height: 53px;
  background-image: url(./Assets/Add\ On.png);
  background-repeat: no-repeat;
  background-size: 100% 53px;

  padding-left: 25px;
  display: flex;
  align-items: center;
}

.Discount--addon--coupons--strip1 p {
  font-size: 24px;
  margin-top: -5px !important;

  font-weight: 600;
}

.per--person--rate {
  font-size: 10px;
}

@media (max-width: 925px) {
  .Discount--addon--coupons--strip2 p {
    width: 100%;
    text-align: end;
    font-size: 12px;
    padding-right: 20px;
    margin-top: 0px !important;

    font-weight: 600;
  }

  .Discount--addon--coupons--strip {
    width: 100%;
    height: 33px;
    background-image: url(./Assets/Discount.png);
    background-repeat: no-repeat;
    background-size: 100% 28px;

    padding-left: 8px;
    display: flex;
    align-items: center;
  }

  .Discount--addon--coupons--strip p {
    font-size: 12px;

    font-weight: 600;
  }

  .Discount--addon--coupons--strip1 {
    width: 100%;
    height: 33px;
    background-image: url(./Assets/Add\ On.png);
    background-repeat: no-repeat;
    background-size: 100% 28px;

    padding-left: 8px;
    display: flex;
    align-items: center;
  }

  .Discount--addon--coupons--strip1 p {
    font-size: 12px;

    font-weight: 600;
  }
  #cars1{
     color: #000;
  }
  .location--wise--filter--1--1 span {
    height: 40px;
    width: 26px;
    background-color: #fff;
    margin: 0;
    padding-left: 2px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .login-close-button p {
    width: 100%;
    font-size: 25px !important;
    position: absolute;
    top: -70px;
    right: -135px;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 3px;
  }
}

@media (max-width: 660px){
  .location {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
    transform: translate(1%, 50%);
     top: 45px;
}
}
