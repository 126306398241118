/* Base styles */
.Container,
.imageContainer {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 1;
  padding-top: 2.5rem;
  gap : 20px;
  margin-left: 20px;
  
}

.videoContainer {
  display: flex;
  width: 100%;
  z-index: 2;
  position: absolute;
}

.videocontent {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.videotag {
  width: 150px;
  height: 260px;
  aspect-ratio: 16/9;
  border: 1px solid white;
  border-radius: 5px;
}

.videocontent p {
  position: absolute;
  bottom: calc(100px - 100%);
  color: #fff;
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  text-align: center;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  
  
  .videotag {
    width: 87px;
  height: 150px;
    aspect-ratio: 16/9;
    margin-bottom: 1rem;
  }
  
  .videocontent p {
    bottom: calc(50px - 100%);
  }
}
