.Package--Price{
    display: flex;
    flex-direction: column;
    font-family: 'Open Sans', sans-serif;
}
.Package--Price--content--information{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    justify-content: center;
    align-items: center;
    padding-top: 25px;
}
.Package--Price--content--information h1{
   color: white;
   margin: 0;padding: 0;
   text-align: center;
}
.Package--Price--content--information h3{
    color: white;
   margin: 0;padding: 0;
   text-align: center;
   margin-top: 50px;
   margin-bottom: 20px;
}
.Package--Price--content--information--p{
    color: #f5f5f5;
    text-align: center;
    margin: 0;padding: 0;
 }
 .Package--Price--card--button{
    height: 40px;
    width: 280px;
    background-color: #2B2D37;
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    margin-top: 30px;
}
.Package--Price--card--button button{
    height: 30px;
    width: 100px;
    border: none;
    background-color: #2B2D37;
    border-radius: 15px;
    color: white;
}
.Package--Price--card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.Package--Price--card--button button.pink-color {
    background-color: #d71362;
    color: white; 
  }
  .Package--Price--all--cards--gold--1{
    display: none;
  }
.Package--Price--all--cards--freemium{
    height: auto;
    width: 350px;
    background-color: #D1D1F7;
    border-radius: 20px;
    padding: 35px 35px;
    margin-top: 13px;
}
.Package--Price--all--cards--silver_commision{
    height: 70px;
    width: 350px;
    background-color: #D1D1F7;
    border-radius: 20px;
    padding: 35px 35px;
    margin-top: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Package--Price--all--cards--gold_commision{
    height: 70px;
    width: 350px;
    background-color: #ffffff;
    border-radius: 20px;
    padding: 35px 35px;
    margin-top: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Package--Price--all--cards--platinum{
    height: auto;
    width: 350px;
    background-color: #D1D1F7;
    border-radius: 20px;
    padding: 35px 35px;
    margin-top: 13px;
}
.Package--Price--all--cards--silver1 h2{
    margin-bottom: 50px;
    font-size: 30px;
    font-weight: 500;
    font-family: helvetica;
}
.Package--Price--all--cards--gold{
    height: auto;
    width: 370px;
    background-color: #ffffff;
    border-radius: 20px;
    padding: 35px 35px;
    border: 2px solid #23CF91;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.9);
}
.Package--Price--all--cards--gold h2{
    position: relative;
    top: -24px;                                                                   
    margin-bottom: 20px;
}
.Package--Price--all--cards--freemium h2{
    margin-bottom: 40px;
}
.Package--Price--all--cards--platinum h2{
    margin-bottom: 40px;
}
.package--name-border--line{
    height: 0.2px;
    width: 35px;
    background-color: black;
    position: relative;
    top: -25px;
}
.Package--Price--all--cards1{
    margin-top: 35px;
    display: flex;
    flex-direction: row;
    column-gap: 10px;
}
.Package--Price--all--cards1-commision{
    margin-top: 35px;
    display: flex;
    flex-direction: row;
    column-gap: 10px;
}
.Package--Price--all--cards2{
    margin-top: 35px;
    display: flex;
    flex-direction: row;
    column-gap: 10px;
}
.Package--Price--all--cards--silver--p2--gst{
    display: flex;
    flex-direction: row;
    column-gap: 3px;
}
.Package--Price--all--cards--silver--p2--free{
    display: flex;
    flex-direction: row;
    column-gap: 20px;
}
.Package--Price--all--cards--silver--p{
    font-size: 15px;
    color: #3d2e7c;
    margin: 0;padding: 0;
}
.Package--Price--all--cards--silver--p1{
    font-size: 12px;
    font-family: helvetica;
    font-weight: 400;
    color: #3d2e7c;
}
.Package--Price--all--cards--silver--p2{
    font-size: 22px;
    font-weight: 500;
    color: #2B1C50;
}
.Package--Price--all--cards--silver--p2 span{
    font-size: 13px;
    font-weight: 500;
    color: #2B1C50;
}
.Package--Price--all--cards--silver--p2--gst p{
    font-size: 12px;
    color: #212121;
}
.Package--Price--all--cards--silver--p2--gst{
    margin-top: 13px;
}
.Package--Price--all--cards--silver button{
    height: 45px;
    width: 138px;
    background-color: #1976D2;
    border-radius: 40px;
    color: white;
    border: none;
}
.Package--Price--all--cards--gold-img{
    height: 30px;
    width: 130px;
    position: relative;
    top: -35px;
    left: 70px;
}
.Package--Price--all--cards--silver1 button{
    height: 45px;
    width: 138px;
    background-color: #1976D2;
    border-radius: 40px;
    color: white;
    border: none;
}
.Package--Price--all--cards--gold button{
    height: 45px;
    width: 138px;
    background-color: #1976D2;
    border-radius: 40px;
    color: white;
   border: none;
}
.select--the--button{
    height: 45px;
    width: 138px;
    background-color: #1976D2;
    border-radius: 40px;
    color: white;
   border: none;
   margin-top: 50px;
}
.package--price--silver--border--line{
    width: 100%;
    height: 1px;
    background-color: gray;
    opacity: 30%;
    margin-top: 20px;
    margin-bottom: 20px;
}
.package--price--silver--border--line--1{
    width: 100%;
    height: 1px;
    background-color: gray;
    opacity: 30%;
    margin-top: 60px;
    margin-bottom: 20px;
}
.Package--Price--all--cards--silver--tick--points{
    display: flex;
    flex-direction: row;
    column-gap: 15px;
}
.Package--Price--all--cards--silver--tick--points p{
    margin: 0;padding: 0;
    color: #2B1C50;
    font-size: 14px;
}
.Package--Price--all--cards--silver--tick--points span{
    color: #2B1C50;
    font-size: 14px;
}
.Package--Price--all--cards--silver--commision{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 40px;
}
.pacakage--price--question1{
    height: 250px;
    width: 100%;
    border: 1px solid #2a213e;
    border-radius: 12px;
    position: relative;
    top: -6px;
    right: 6px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    justify-content: center;
    align-items: center;
}
.pacakage--price--question{
    height: 250px;
    width: 100%;
    border-radius: 12px;
    background-color: #251c3e;
}
.pacakage--price1--question{
    margin-top: 50px;
    height: 250px;
    width: 1100px;
}
.pacakage--price--question1--button{
    height: 50px;
    width: 250px;
    background-color: white;
    border-radius: 6px;
    border: none;
}
.pacakage--price--question1--button1{
    height: 45px;
    width: 150px;
    background-color: #1976D2;
    border-radius: 6px;
    color: white;
    border: none;
}
.pacakage--price--question1--button1--1{
    height: 45px;
    width: 220px;
    background-color: #1976D2;
    border-radius: 6px;
    color: white;
    border: none;
    margin-top: 20px;
}
.pacakage--price--question1 p{
    color: white;
    margin: 0;padding: 0;
}
.pacakage--price1--question--leads{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    padding-left: 120px;
    padding-right: 120px;
    margin-top: 40px;
}
.pacakage--price1--question--leads--all-pricing{
    width: 100%;
    height: 260px;
    padding-left: 120px;
    padding-right: 120px;
    background-color: #251c3e;
    padding-top: 20px;
    color: white;
    margin-top: 35px;
}

.Package--Price--card--button button.active {
    background-color: #d71362; 
    color: #ffffff; 
}
.pacakage--price1--question--leads--all-pricing--1{
    padding-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 40px;
    row-gap: 20px;
}
.Package--Price--all--cards--silver--tick--points1{
    display: flex;
    flex-direction: row;
    column-gap: 20px;
   
}
.Package--Price--all--cards--silver--tick--points1 span{
    color: #1976D2;
    font-size: 15px;
}
.Package--Price--all--cards--silver--tick--points1 p{
    color: white;
    font-size: 15px;
    margin: 0;padding: 0;
}
.pacakage--price1--question--frequently--asked--1-1{
    display: flex;
    flex-direction: row;
    column-gap: 15px;
}
.pacakage--price1--question--frequently--asked--1-1 button{
    height: 25px;
    width: 25px;
    background-color: #120829;
    border-radius: 50%;
    border: none;
    border: 1px solid white;
    color: white;
   display: flex;
   justify-content: center;
   align-items: center;
}
.pacakage--price1--question--frequently--asked{
    margin-top: 40px;
    padding-left: 120px;
    padding-right: 120px;
    color: white;
}
.pacakage--price1--question--frequently--asked--1{
    margin-top: 20px;
}
.pacakage--price1--question--frequently--asked--border-line{
    height: 0.3px;
    width: 100%;
    background-color: #595269;
    margin: 25px 0px;
    opacity: .4;
}
.Package--Price--all--cards--mobile-view{
    display: none;
}
.package--deals--talk--to--expert{
    height: 50px;
    width: 100%;
    background-color: #120829;
}
.package--deals--talk--to--expert h2{
    font-size: 18px;
    font-weight: 400;
    color: white;
    padding-top: 15px;
    padding-left: 20px;
}
.Plan--your--event--popup--1--1 button{
    width: 400px;
    height: 40px;
    background-color: #23CF91;
    border: none;
    color: white;
    border-radius: 5px;
    margin-left: 30px;
}
.responsive-modal-1-1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 460px;
    height: 555px;
    background-color: white;
    border: 2px solid;
    border-radius: 20px;
    box-shadow: 24px;
    transition: all 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.9);
    outline: none;
    overflow: hidden;
  }
.package--deals--talk--to--expert--content--1--2{
    display: flex;
    flex-direction: column;
    margin-top: 5px;
}
.package--deals--talk--to--expert--content--1--2 input{
    height: 35px;
    width: 200px;
    border-radius: 5px;
    border: 1px solid #b3b3b3;
    padding-left: 10px;
}
.package--deals--talk--to--expert--content--1--21 input{
    height: 35px;
    width: 420px;
    border-radius: 5px;
    border: 1px solid #b3b3b3;
    padding-left: 10px;
}
.package--deals--talk--to--expert--content--1{
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    margin-bottom: 10px;
}
.package--deals--talk--to--expert--content--1--2 label{
    color: black;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    padding-bottom: 5px;
}
.package--deals--talk--to--expert--content--1--21 label{
    color: black;
    font-size: 14px;
    font-weight: 400;
    margin-top: 13px;
    margin: 0;
    padding-bottom: 5px;
}
.package--deals--talk--to--expert--content{
    padding-left: 20px;
    padding-right: 20px;
   padding-top: 20px;
    padding-bottom: 20px;
}
.package--deals--talk--to--expert--content1{
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
}
.package--deals--talk--to--expert--content h2{
    font-size: 17px;
    font-weight: 600px;
    padding-bottom: 10px;
}
.package--deals--talk--to--expert--content1 h2{
    font-size: 17px;
    font-weight: 600px;
    padding-bottom: 10px;
}
.package--deals--talk--to--expert .cls--btn{
    position: fixed;
    top: 10px;
    right: 10px;
    padding: 0px 10px;
    font-size: 20px;
    border-radius: 3px;
    color: white;
  }
  .package--deals--talk--to--expert button{
    width: 200px;
    height: 40px;
    background-color: #23CF91;
    border: none;
    border-radius: 5px;
    color: white;
  }
  .popup--thankew2{
    font-size: 20px;
    font-weight: 600;
    color: black;
  }
  .popup--thankew1{
    font-size: 16px;
    font-weight: 500;
  }
  .pacakage--price1--question--leads--all-pricing--mobile{
    display: none;
  }
  .pacakage--price1--question--buisness--h2{
    display: none;
  }
  .pacakage--price1--question--buisness1{
    display: none;
  }
  .partywiity--benifit{
    display: none;
  }
  .Package--Price--all--cards--silver--1 h2{
    font-size: 30px;
    font-weight: 500;
    font-family: helvetica;
  }
  .Package--Price--all--cards--silver--1{
    display: none;
  }
  .Package--Price--all--cards--silver1--1 h2{
    font-size: 30px;
    font-weight: 500;
    font-family: helvetica;
  }
  .Package--Price--all--cards--silver1--1{
    display: none;
  }
  .package_price_floating_button {
    position: fixed; 
    bottom: 20px;    
    left: 50%;      
    transform: translateX(-50%); 
    z-index: 1000;   /* Ensures the button is above other content */
  }
  .package_price_floating_button button{
    height: 50px;
    width: 180px;
    border-radius: 10px;
    background-color: #1976D2;
    border: none;
    color: #fff;
  }
  .pacakage--price1--question--leads--1 h2{
    font-size: 18px;
    font-weight: 700;
    text-align: left;
}
.package-price-buisness-login{
    display:flex;
    justify-content: end;
    align-items: end;
    margin-top: 30px;
    margin-right: 30px;
}
.package-price-buisness-login button{
    height: 40px;
    width: 130px;
    border: none;
    background: #1976D2;
    color: #fff;
    border-radius: 5px;
}
.businessType_data {
    padding-left: 8px;
}
@media (min-width: 641px) and (max-width: 1040px){
    .Package--Price--all--cards1{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }
    .Package--Price--all--cards2{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }
    .package_price_floating_button button{
       height: 40px;
        width: 150px;
        border-radius: 10px;
        background-color: #1976D2;
        border: none;
        color: #fff;
      }
    .pacakage--price1--question--leads--all-pricing{
        width: 100%;
        height: auto;
        padding-left: 120px;
        padding-right: 120px;
        background-color: transparent;
        padding-top: 20px;
        color: white;
        margin-top: 35px;
    }
}
@media (min-width:0px) and (max-width:640px)
{
    .Package--Price--all--cards1{
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        justify-content: center;
        align-items: center;
    }
    .Package--Price{
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: left;
        overflow: hidden;
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
         margin-top: 35px;
    }
    .Package--Price--all--cards--silver1--1{
        display: block;
    }
    .pacakage--price1--question--frequently--asked--1-1{
        display: flex;
        flex-direction: row;
        column-gap: 10px;
    }
    .Package--Price--card{
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: left;
    }
    .Package--Price--content--information{
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        justify-content: center;
        align-items: center;
        padding-top: 10px;
    }
    .Package--Price--all--cards--gold--1{
        display: block;
      }
    .Package--Price--all--cards--silver--1{
        display: block;
    }
    .pacakage--price1--question--frequently--asked--1-1 button{
        height: 20px;
        width: 20px;
        background-color: #120829;
        border-radius: 50%;
        border: none;
        border: 1px solid white;
        color: white;
        font-size: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .Package--Price--all--cards2{
        display: none;
    }
   
    .pacakage--price--question1--button1{
        height: 35px;
        width: 150px;
        background-color: #1976D2;
        border-radius: 4px;
        color: white;
        border: none;
        font-size: 12px;
    }
    .Package--Price--all--cards--silver--tick--points1 p{
        color: white;
        font-size: 13px;
        margin: 0;padding: 0;
    }
    .pacakage--price1--question--frequently--asked h2{
        font-size: 13px;
        font-weight: 700;
        text-align: center;
    }
    .Package--Price--content--information h1{
        font-size: 15px;
        display: flex;
        justify-content: center;
        align-items: center;

    }
    .Package--Price--content--information h3{
        font-size: 15px;
        display: flex;
        justify-content: center;
        align-items: center;

    }
    .pacakage--price--question1--button1--1{
        height: 35px;
        width: 180px;
        background-color: #1976D2;
        border-radius: 6px;
        color: white;
        border: none;
        font-size: 12px;
        margin-top: 20px;
    }
    .Package--Price--all--cards--silver--commision{
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .package--price--silver--border--line{
        width: 100%;
        height: 1px;
        background-color: gray;
        opacity: 30%;
        margin-top: 20px;
        margin-bottom: 5px;
    }
    .pacakage--price1--question--frequently--asked--1-1 p{
        font-size: 11px;
        line-height: 12px;
    }
    .pacakage--price1--question--frequently--asked--1-1-1{
        font-size: 10px;
        line-height: 12px;
        padding-left: 30px;
    }
   .pacakage--price1--question--leads--all-pricing--mobile h2{
    font-size: 14px;
    font-weight: 600;
    text-align: center;
   }
    .Package--Price--content--information{
        font-size: 12px;
    }
    .Package--Price--all--cards--silver--p2--gst{
        margin-top: 3px;
    }
    .Package--Price--all--cards--silver--1 button{
        height: 35px;
        width: 128px;
        font-size: 12px;
        background-color: #1976D2;
        border-radius: 40px;
        color: white;
        border: none;
    }
    .Package--Price--all--cards--gold--1 button{
        height: 35px;
        width: 128px;
        font-size: 12px;
        background-color: #1976D2;
        border-radius: 40px;
        color: white;
        border: none;
    }
    .Package--Price--all--cards--freemium,
    .Package--Price--all--cards--platinum
    {
        height: auto;
        width: 320px;
        background-color: #D1D1F7;
        border-radius: 20px;
        padding: 35px 35px;
        margin-top: 13px;
    }
    .package--name-border--line{
        height: 0.8px;
        width: 35px;
        background-color: white;
        position: relative;
        top: -25px;
    }
    .Package--Price--all--cards--silver1--1 button{
        height: 35px;
        width: 128px;
        font-size: 12px;
        background-color: #1976D2;
        border-radius: 40px;
        color: white;
        border: none;
    }
    .Package--Price--all--cards{
        margin-top: 35px;
        display: flex;
        flex-direction: column;
        row-gap: 5px;
    }
    .Package--Price--all--cards--silver--1{
        margin: 0;padding: 0;
        height: auto;
        width: 98%;
        background-color: #2B2D37;
        color: white;
        border-radius: 5px;
        padding: 5px;
        border: 1px solid #23CF91;
        padding-left: 15px;
        padding-top: 15px;
        
    }
    .Package--Price--all--cards--img--1 h2{
        margin-bottom: 10px;
        font-size: 17px;
        font-weight: 600;
    }
    .Package--Price--all--cards--silver1--1{
        margin: 0;padding: 0;
        height: auto;
        width: 98%;
        background-color: #2B2D37;
        color: white;
        border-radius: 5px;
        padding: 5px;
        padding-left: 15px;
        border: 1px solid #23CF91;
        padding-top: 15px;
    }
    .Package--Price--all--cards--silver1--1 h2{
        margin-bottom: 10px;
        font-size: 17px;
        font-weight: 600;
    }
    .Package--Price--all--cards--gold--1{
        margin: 0;padding: 0;
        height: auto;
        width: 98%;
        background-color: #2B2D37;
        color: white;
        border-radius: 5px;
        padding: 5px;
        border: 1px solid #23CF91;
        padding-left: 10px;
        padding-top: 15px;
    }
    .Package--Price--all--cards--silver--p2 span{
        font-size: 18px;
        font-weight: 600;
        color: white;
    }
    .Package--Price--all--cards--silver--p2--1 span{
        font-size: 18px;
        font-weight: 600;
        color: white;
      
    }
    .Package--Price--all--cards--img--1 h2{
        margin-bottom: 10px;
        font-size: 25px;
        font-weight: 600;
    }
    .Package--Price--all--cards--silver--p{
        color: white;
        font-size: 11px;
        margin: 0;padding: 0;
    }
    .Package--Price--all--cards--silver--p1{
        color: white;
        font-size: 12px;
        margin: 0;padding: 0;
        padding-top: 4px;
        padding-bottom: 6px;
    }
    .Package--Price--all--cards--silver--p1-1{
        color: white;
        font-size: 12px;
        margin: 0;padding: 0;
        padding-top: 4px;
        padding-bottom: 6px;
        opacity: 50%;
    }
    .pacakage--price1--question--frequently--asked{
        margin-top: 20px;
        padding-left: 10px;
        padding-right: 1px;
        color: white;
       
    }
    .Package--Price--all--cards--silver--p2{
        color: white;
        font-size: 10px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        column-gap: 4px;
    }
    .Package--Price--all--cards--silver--p2--1{
        color: white;
        font-size: 10px;
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: flex-start;
        column-gap: 4px;
    }
    .Package--Price--all--cards--silver--p2--gst p{
        color: white;
    }
    .Package--Price--all--cards--silver--tick--points p{
        color: white;
        font-size: 12px;
    }
    .Package--Price--all--cards--silver--tick--points span{
        color: white;
        font-size: 12px;
    }
    .Package--Price--all--key--feature{
        display: flex;
        flex-direction: column;
        row-gap: 10px;
    }
    .pacakage--price1--question{
        margin-top: 10px;
        height: 250px;
        width: 98%;
        border-radius: 2px;
    }
    .pacakage--price1--question--leads{
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: left;
        color: white;
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 0px;
       
        margin-top: 15px;
      
    }
    .pacakage--price1--question--leads--all-pricing--mobile{
        display: block;
        width: 100%;
        height: auto;
        padding-left: 10px;
        padding-right: 10px;
        background-color: #1d1e29;
        padding-top: 10px;
        color: white;
        margin-top: 0px;
        
        padding-top: 15px;
        padding-bottom: 15px;
        border-radius: 5px;
   
    }
    .Package--Price--all--cards1-commision{
        display: none;
    }
    .pacakage--price1--question--leads--all-pricing{
        display: none;
        
    }
    .Package--Price--all--cards--gold{
        height: auto;
        width: 320px;
        background-color: #ffffff;
        border-radius: 20px;
        padding: 35px 35px;
        border: 2px solid #23CF91;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.9);
    }
    .Package--Price--all--cards--gold h2{
        position: relative;
        top: -14px;                                                                   
        margin-bottom: 20px;
    }
    .Package--Price--all--cards--img--1 img{
        height: 30px;
        width: 120px;
        position: relative;
        top: -15px;
        left: 5.5px;
    }
    .Package--Price--all--cards--img--1{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .pacakage--price1--question--leads--all-pricing--1 img{
        height: 60px;
        width: 130px;
    }
    .pacakage--price1--question--leads--all-pricing--1{
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .Package--Price--all--cards--mobile-view{
        display: block;
        display: flex;
        flex-direction: row;
        column-gap: 5px;
    }
    .Package--Price--all--cards--mobile-view button{
        height: 30px;
        width: 72px;
        border: none;
        font-size:12px;
        background-color: #2B2D37;
        border-radius: 15px;
        color: white;
    }
    .Package--Price--card--button{
        height: 35px;
        width: 220px;
        background-color: #2B2D37;
        border-radius: 20px;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
        display: flex;
        flex-direction: row;
        column-gap: 5px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .Package--Price--card--button button{
        height: 25px;
        width: 72px;
        border: none;
        font-size: 11px;
        background-color: #2B2D37;
        border-radius: 15px;
        color: white;
    }
    .Package--Price--all--cards{
        display: none;
    }
    .Package--Price--all--cards--mobile-view button.active {
        background-color: #1976D2;
        color: white;
      }
      .pacakage--price--question1{
        height: 150px;
        width: 100%;
        border: 1px solid transparent;
        border-radius: 12px;
        position: relative;
        top: -6px;
        right: 6px;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        justify-content: center;
        align-items: center;
    }
    .pacakage--price--question1 p{
        font-size: 13px;
    }
    .pacakage--price--question{
        height: 150px;
        width: 100%;
        border-radius: 5px;
        background-color: #251c3e;
      
    }
    .Package--Price--all--cards--freemium h2,
    .Package--Price--all--cards--platinum h2{
        margin-bottom: 10px;
    }
    
    .pacakage--price1--question--leads--1 h2{
        font-size: 13px;
        font-weight: 700;
        text-align: center;
    }
    .pacakage--price1--question--buisness{
        display: block;
        height: auto;
        width: 98%;
        background-color: #1d1e29;
        color: white;
        border-radius: 5px;
        padding-top: 15px;
        padding-bottom: 0px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .pacakage--price1--question--buisness h2{
        font-size: 12px;
        font-weight: 600;
        text-align: center;
    }
    .pacakage--price1--question--buisness p{
        font-size: 10px;
         line-height: 12px;
        text-align: center;
    }
    .pacakage--price1--question--buisness--h2{
        display: block;
    }
    .pacakage--price1--question--buisness--h2 h2{
        font-size: 13px;
        font-weight: 600;
        color: white;
        text-align: center;
        margin-top: 15px;
    }
    .partywiity--benifit{
        display: block;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
       
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .partywiity--benifit p{
        color: white;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        margin: 0;padding: 0;
    }
    .pacakage--price1--question--buisness1{
        display: block;
       
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        margin-bottom: 15px;
    }
    .pacakage--price1--question--leads p{
        font-size: 10px;
        text-align: center;
        line-height: 13px;
    }
    .pacakage--price1--question--leads--button{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
      
   
}