.Payment--Thankew--Page{
    height: 700px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.Payment--Thankew--Page1{
    height: auto;
    width: 600px;
    background-color: #fbfbfbe4;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.8);
}
.Payment--Thankew--Page1--thanks--content{
    height: 250px;
    width: 100%;
    background-color: #1f1e2e;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    justify-content: center;
    align-items: center;
    color: white;
}
.Payment--Thankew--Page1--thanks--content img{
    height: 40px;
    width: 40px;
}
.Payment--Thankew--Page1--thanks--content p{
      margin: 0;padding: 0;
      font-size: 17px;
    }
    .Payment--Thankew--Page1--orderid--button{
        background-color: #d7133a;
        border-radius: 5px;
        height: auto;
        width:350px;
        display: flex;
        flex-direction: row;
        column-gap: 5px;
        text-align: center;
        position: relative;
        top: -15px;
        /* left: 230px; */
        color: white;
        padding: 5px;
        margin: auto
    }
.Payment--Thankew--Page1--orderid--button p{
    margin: 0;padding: 0;
    padding-top: 8px;
    padding-left: 10px;
    text-align: center;
}
.Payment--Thankew--Page1--thanks--Billing--content{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.Payment--Thankew--Page1--thanks--Billing--content1{
    display: flex;
    flex-direction: row;
    column-gap: 60px;
    padding-left: 43px;
}
.payment--thankew--heading{
    font-size: 17px;
    color: #848685;
    font-weight: 500;
}
.payment--thankew--heading-1{
    font-size: 16px;
    color: #848685;
    font-weight: 480;
}
.payment--thankew--content{
    font-size: 15.5px;
}
.Payment--Thankew--Page1--thanks--Billing--content-date .Payment--Thankew--Page1--thanks--Billing--content-Payment .Payment--Thankew--Page1--thanks--Billing--content-Bill{
    width: 33%;
}
.Payment--Thankew--order--summary{
    padding-left: 30px;
}
.Payment--Thankew--order--summary h3{
    font-size: 19px;
}
.Payment--Thankew--Page1--all--content{
    padding: 20px;
}
.Payment--Thankew--order--summary1{
    height: 40px;
    width: 100%;
    background-color: #fbfbfbaa;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
   padding-top: 7px;
   padding-left: 15px;
}
.Payment--Thankew--order--summary1--content{
   
 display: flex;
    flex-direction: row;
    justify-content: space-around;
   padding-top: 7px;
   padding-left: 15px;
}
.Payment--Thankew--order--summary1-product{
    width: 50%;
}
.Payment--Thankew--order--summary1-quantity{
    width: 25%;
}
.discount--calculator--page1--border1--2 {
    position: relative;
}
.discount--calculator--page1--border1--2::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px; /* Adjust the height to control the thickness */
    border-top: 1px solid #848685; /* Dashed border with white color */
}
.Payment--Thankew--order--summary1--total{
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    column-gap: 70px;
    justify-content: center;
    margin-left: 180px;
}
@media (max-width: 640px){
    .Payment--Thankew--Page{
        height: 800px;
    }
    .Payment--Thankew--Page1{
        height: auto;
        width: 90%;
    }
    .Payment--Thankew--Page1--all--content{
        padding: 10px;
    }
    .Payment--Thankew--Page1--orderid--button{
        position: relative;
        top: -15px;
        left: 115px;
    }
    .Payment--Thankew--order--summary{
        padding-left: 0px;
    }
}

.Booking--coupon--code{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.Booking--coupon--code--1{
    width: 700px;
    height: auto;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.9);
    display: flex;
    flex-direction: column;
}
.Booking--coupon--code1{
    width: 700px;
    height: 300px;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: row;
    padding: 20px;
    padding-top: 40px;
    justify-content: space-between;
}
.confirmation--page{
    width: 700px;
    height: auto;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: row;
    padding: 20px;
    padding-top: 40px;
    justify-content: space-between;
}
.Booking--coupon--code11 h1{
    color: #400245;
    font-size: 27px;
    margin-bottom: 10px;
}
.Booking--coupon--code11 p{
    /* color: white; */
    font-size: 15px;
    margin: 0;padding: 0;
}
.Booking--coupon--code11--1{
    margin-top: 10px;
}
.Booking--coupon--code11--1 h2{
    /* color: white; */
    font-size: 24px;
    margin: 0;padding: 0;
}
.Booking--coupon--code11--1 p{
    color: grey;
    font-size: 14px;
    margin: 0;padding: 0;
}
.Booking--coupon--code11--2{
    margin-top: 10px;
}
.Booking--coupon--code11--2 h3{
    font-size: 15px;
    /* color: white; */
    margin: 0;padding: 0;
    padding-top: 3px;
}
.Booking--coupon--code12--1{
    height: 180px;
    width: 140px;
    border-radius: 5px;
    background-color:white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    /* margin-top: 20px;
    margin-left: 60px; */
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    justify-content: center;
    align-items: center;
    /* color: white; */
}
.Booking--coupon--code12--1 h3{
    font-size: 15px;
    margin: 0;padding: 0;
}
.Booking--coupon--code12--1 h1{
    color: #400245;
}
.Booking--coupon--code12--1 h4{
    font-size: 14px;
    margin: 0;padding: 0;
}
.Booking--coupon--code12--1 p{
    font-size: 14px;
     margin: 0;padding: 0;
}

.backgroundcolor--for--confirm{
    width: 100%;
    height: 150px;
    /* background-color:#28ba4f; */
    background-color: #25bb12;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    
    color: white;
    padding: 20px;
}
.Booking--coupon--code2--1 h3{
    margin: 0;padding: 0;
}
.Booking--coupon--code2--1 h1{
    margin: 0;padding: 0;
}
.Booking--coupon--code2--1 p{
    margin: 0;padding: 0;
}
.Booking--coupon--code2--1--12{
    margin-top: 20px;
}
.Booking--coupon--code2--1--12 p{
    text-align: center;
}
.Booking--coupon--code2--12{
    width: 140px;
    height: 40px;
    border: 1px dashed white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}
.Booking--coupon--code2--1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.Booking--coupon--code11{
    width: 60%;
}
.Booking--coupon--code12{
    width: 40%;
    display: flex;
    align-items: center;
    place-content: flex-end;
}

.congratulation-tab{
    height: 35px;
    max-width: 300px;
    background-color: #400245;
    border-radius: 20px;
    color: white;
    position: relative;
    top:15px ;
    border: 2px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.Booking--coupon--code2--11:hover{
    background-color: #d71362;
}
.Booking--coupon--code12--1 h1{
    font-size: 50px;
}
.Booking--coupon--code2--12 p{
    color: white;
    font-size: 22px;
}
@media (max-width:640px)
{
    .Booking--coupon--code11{
        width: 60%;
    }
    .Booking--coupon--code12{
        width: 40%;
        display: flex;
        align-items: center;
        place-content: flex-end
    }
    .Booking--coupon--code{
        overflow: hidden;
        width: 99%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 30px;
    }
    .Booking--coupon--code--1{
        width: 100%;
        height: auto;
    }
    .Booking--coupon--code11 h1{
        color: #400245;
        font-size: 20px;
        margin-bottom: 10px;
    }
    .Booking--coupon--code1{
        padding: 8px;
        padding-top: 40px;
        display: flex;
    flex-direction: row;
   
    /* justify-content: space-evenly; */
       
    }
    .Booking--coupon--code11 p{
        /* color: white; */
        font-size: 13px;
        margin: 0;padding: 0;
    }
    .Booking--coupon--code12--1{
        height: 180px;
        width: 140px;
        border-radius: 5px;
        background-color:white;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
        margin-top: 0px;
        margin-left: 0px;
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        justify-content: center;
        align-items: center;
    }
    .Booking--coupon--code2--11{
        position: relative;
        top:22px ;
    }
    .Booking--coupon--code1 {
        width: 100%;
        height: auto;
    }
}
.icon-container {
    /* margin-bottom: 20px; */
    margin-top: 20px;
    padding: 7px 0;
    font-size: 34px;
    width: 40%;
    height: auto;
    display: flex;
    gap: 20px;
    background-color: rgb(255, 255, 255);
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }
  .visa{
    color: navy;
  }
  .amex{
    color: blue;
  }
  .mastercard{
    color: red;
  }
  .discover{
    color: orange;
  }