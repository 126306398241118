body{
    background-color: #120829;
}
.clubagreement{
    background-color: #120829;
    padding: 10px 100px;
}

.main--feacture{
    color: white;
}
.tc--title{
    color: #fff;
    text-align: left;
  
    margin-bottom: 25px;
}
.tc--subtitle{
    color: white;
}
.tc--para span{
    color: #fafafa;font-size:12px;font-weight:400;
}
@media(max-width:480px){
   .clubagreement{
    padding: 0px 10px;
   }
}