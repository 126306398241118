.bookatable-app {
    background-color: #120829;
    display: flex;
    flex-direction: row;
}

.bookatable-acc {
    background-color: #D71362;
}

.bookatable-checkbox-with-content p span {
    margin-right: 6px;
}

.Artist-viewall--some--filter11--dropdown--book--a--table select {
    height: 43px;
    width: 230px;
    background-color: white;
    border: none;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    outline: none;
}

.Artist-viewall--some--filter11--dropdown--book--a--table input {
    padding-left: 15px;
    height: 43px;
    width: 400px;
    background-color: white;
    border: none;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    outline: none;
}

.bookatable-white {
    width: 100%;
    height: auto;
    display: flex;
    color: black;
    margin: 0;
    padding-top: 3px;
    gap: 5px;
}

.accordion-header {
    --bs-accordion-active-bg: #D71362;
}

.bookatable-bold {
    color: white;
    font-size: 18px;
    font-weight: bold;
    padding: 0;
    margin: 0;
}

.Artist-viewall--some--filter1--book-a-table {
    position: relative;
    top: -20px;
    left: 230px;
}

.book--a--table--search--filter {
    height: 65px;
    width: 800px;
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

.bookatable-accordion-body {
    background-color: #0B051C;
    padding: 8px 8px;
}

.bookatable-search {
    width: 95%;
    background-color: #4A4A5F;
    color: white;
    border: none;
    padding: 5px;
    margin-top: 10px;
    margin-bottom: 7px;
    margin-left: 7px;
}

.bookatable-right-part {
    width: 100%;
    height: auto;
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 30px;
    margin-bottom: 20px;
}

.bookatable-right {
    width: 100%;
    height: auto;
    background-color: #120829;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 20px;
    column-gap: 20px;
}

.thumbnail--rating-1-1 span {
    color: hsl(51, 100%, 56%);
    padding-left: 4px;
}

.bookatable-loadmore {
    height: 35px;
    width: 110px;
    border: 1px solid #D71362;
    background-color: #120829;
    border-radius: 10px;
    color: white;
    display: flex;
    margin-top: 200px;
    margin-left: 100px;
}

.bookatable-filter-search {
    background-color: #4A4A5F;
    border: none;
    padding: 5px;
    margin-left: 10px;
    margin-bottom: 5px;
    margin-top: 8px;
    color: white;
}

.bookatable-card {
    width: 100%;
    height: auto;
    background-color: white;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.bookatable-card1 {
    width: 100%;
    height: auto;
    background-color: white;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.bookatable-img {
    width: 100%;
    height: 315px;
}

.bookatable-lawn-img {
    width: 100%;
    height: 315px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.bookatable-p-red {
    color: #C22032;
}

.bookatable-bold {
    color: black;
    font-size: 18px;
    font-weight: bold;
}

.book--a--table--search--filter--1 {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    padding-top: 12px;
}

.book--a--table--search--filter--2 {
    display: flex;
    flex-direction: row;
    column-gap: 40px;
    padding-top: 12px;
}

.book--a--table--search--filter--1 span {
    font-size: 25px;
    color: #C22032;
}

.book--a--table--search--filter--2 span {
    font-size: 25px;
    color: #C22032;
}

.Artist-viewall--some--filter12--border1 {
    border-left: 1px solid gray;
    height: 40px;
    margin-top: 15px;
}

.bookatable-routes {
    text-decoration: none !important
}

.Artist-viewall--some--filter12--input input {
    background-color: white;
    width: 320px;
    height: 45px;
    margin: auto;
    border-radius: 3px;
    color: black;
}

.Artist-viewall--some--filter11--dropdown--book-a-table {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Artist-viewall--some--filter12--input {
    display: flex;
    justify-content: center;
    align-items: center;
}

.book-a-table--filter-search {
    display: none;
}

.book-a-table-top-img {
    width: 100%;
    height: 300px;
    background-image: url("../Assets/book-at-table.avif");
}

.book--a--table--right--content {
    padding-left: 30px;
    padding-right: 30px;
}

.bookatable-routes {
    text-decoration: none !important
}


.premiumtag img {
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.premiumtag {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 10px;
    position: relative;
    top: -300px;
}

.packagetype--icon---with--name {
    color: white;
    display: flex;
    height: 100%;
    align-items: center;
    gap: 10px;
}



.packageicontype {
    position: relative;
}

.packageicontype img {
    display: block;

}

.packageicontype p {
    position: absolute;
    top: 5px;
    left: 0;
    opacity: 0;
    background-color: #4D4D4D;
    border-radius: 10px;
    padding: 5px 15px;
    transition: left 0.3s, opacity 0.9s;
}

.packageicontype:hover p {
    left: 40px;
    opacity: 1;

}


.premiumtag1 img {
    width: 40px;
    height: 40px;
}

.premiumtag1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 10px;
    position: relative;
    top: -250px;
}

.bookatable-content {
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.Artist--view--all--banner--filter{
    display: none;
}

/* .Artist-viewall-banner--11{
    display: none;
} */
@media (min-width:681px) and (max-width: 1100px){
    .bookatable-right{
        display: grid;
        grid-template-columns: 1fr 1fr  ;
        gap: 20px;
    }
}
@media (min-width:0px) and (max-width: 680px) {
    .book--a--table {
        overflow: hidden;
        padding-left: 10px;
        padding-right: 10px;
    }
    .Artist--view--all--banner--filter{
        display: block;
    }
    .Artist-viewall-banner--11{
        display: block;
    }
    .bookatable-app {
        padding: 0;
        margin: 0;
    }

    .book--a--table--right--content {
        display: block;
      
    }

    .book-a-table-top-img {
        width: 100%;
        height: 150px;
        background-image: url("../Assets/book-at-table.avif");
        background-size: 100% 150px !important;
    }

    .bookatable-left-part {
        width: 100%;
        height: auto;
        margin: 0;
        padding: 0;
        display: none;
    }

    .book-a-table--filter-search {
        margin-top: 15px;
        margin-bottom: 5px;
        display: block;
    }

    .book-a-table--filter-search input {
        background-color: white;
        width: 220px;
        border: none;
        padding: 5px;
        margin-left: 10px;
        margin-bottom: 5px;
        margin-top: 8px;
        color: black;
    }
    .bookatable-content--1--1{
        padding-left: 3px;
    }
    .bookatable-right-part {
        margin: 0;
        padding: 0;
        display: none;
    }

    .bookatable-loadmore {
        height: 35px;
        width: 110px;
        border: 1px solid #D71362;
        background-color: #120829;
        border-radius: 10px;
        color: white;
        display: flex;
        justify-content: center;
        padding-top: 5px;
        text-align: center;
        margin: 0;
        margin-bottom: 10px;
    }

    .bookatable-content--1 {
        position: relative;
        top: -48px;
     
    }

    .bookatable-right {
       
        width: 100%;
        display: flex;
       flex-direction: column;
     
       justify-content: center;
       align-items: center;
        row-gap: 15px;
        position: relative;
        top: 12px;
        background-color: #141420;
    }
    .bookatable-right--1{
        width: 100%;
        display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: center;
        row-gap: 25px;
        margin-top: 10px;
        padding-bottom: 10px;
        
    }

    .bookatable-card {
        width: 250px;
        height: 310px;
    }
    .bookatable-card1 {
        width: 250px;
        height: 295px;
    }
    .bookatable-card--1 {
        width: 250px;
        height: 300px;
        background-color: white;
        border-radius: 15px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }

    .bookatable-lawn-img {
        height: 200px;
    }

    .bookatable-bold {
        font-size: 15px;
        padding: 0;
        margin: 0;
    }

    .bookatable-white {
        font-size: 12px;
    }

    .bookatable-p-grey {
        font-size: 13px;
    }
    .bookatable-white span img{
        height: 12px;
        width: 12px;
    }

    .bookatable-search-deal {
        background-color: #4A4A5F;
    }

    .bookatable-checkbox-with-content p {
        margin: 0;
        padding: 0;
    }

    .bookatable-left-side {
        margin: 0;
        padding: 0;
    }

    .bookatable-content {
        padding: 0px;
        padding-left: 4px;
        padding-right: 5px;
        padding-top: 5px;
        margin-bottom: 6px;
        position: relative;
        top: -50px;
    }

    .bookatable-content p {
        margin: 0;
        padding: 0;
    }

    .bookatable-p-red {
        font-size: 13px;
    }

    .bookatable-checkbox-with-content p {
        margin: 0;
        padding: 0;
    }

    .premiumtag img {
        width: 25px;
        height: 25px;
    }

    .premiumtag {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: relative;
        top: -195px;
    }

    .Artist--view--all--banner--filter {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }

    .Artist--view--all--banner--filter--1 {
        color: #FFF;
        text-align: center;
        font-family: Helvetica;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

}




.booking--history {
    width: 95%;
    height: auto;
    margin: auto;
    display: flex;
    flex-direction: column;
}

.booking--history--heading {
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: row;

    color: white;
}

.booking--history--heading h3 {
    width: 100%;
    font-size: 30px;
}

.booking--history--heading--tabs {
    width: 100%;
    height: 100%;
    cursor: pointer;

}

.booking--history--heading--tabs .date-time-color .dt {
    color: #ffffff !important;
}



.transaction-details {
    display: flex;
    align-items: center;
    color: white;
    font-size: 32px;
    margin-bottom: 0px;
}
.clubname_viewclub {
    display: flex;
    gap: 20px;
}
.transaction-details-data {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}

.redirect-club {
    color: white;
}

.redirect-club:hover {
    color: #D71362;
}

.transaction-details img {
    width: 50px;
    height: 50px;
}

.transaction-details p span {
    font-size: 16px;
}



.transaction-details--amount--section {
    display: flex;
    align-items: center;
    color: white;

    padding: 0px;
    margin: 0px;


}

.transaction-details--amount--section p span {
    font-size: 18px;
    text-align: center;
    float: inline-end;
}

.transaction-details .visa {
    color: rgb(91, 34, 109);
    font-size: 18px;

}

.transaction-details .visa span {
    color: rgb(114, 114, 114);
    font-size: 14px;
}

.transaction-details--amount--section .process {
    width: 100%;
    text-align: end;
    color: rgb(4, 179, 54);
    font-size: 18px;
}

.transaction-details--amount--section .pending {
    width: 100%;
    text-align: end;
    color: rgb(180, 20, 8);
    font-size: 18px;
}

.transaction-details--amount--section .process span {
    color: rgb(114, 114, 114);
    font-size: 14px;
}

.transaction-details--amount--section .rupee {
    width: 100%;
    text-align: end;
    color: rgb(114, 114, 114);
    font-size: 18px;
}

.transaction-details--amount--section .rupee span {
    color: rgb(114, 114, 114);
    font-size: 14px;
}

.club--about--us--Gallery--section--buttons11 button {
    height: 40px;
    width: auto;
    background-color: #d71362;
    border: none;
    color: white;
    border-radius: 5px;
    outline: none;
    margin-right: 20px;
}

.club--about--us--Gallery--section--buttons11 .button--tab--history {
    width: 99px;
    height: 40px;
    border-radius: 20px;
    background-color: #1B183B;

}

.club--about--us--Gallery--section--buttons11 .selectedtab {
    background-color: #1976D2;
}

.invoice-box {
    position: absolute;
    left: -9999px;
    top: -9999px;
}

.partywitty--inovoice {
    width: 70%;
    height: auto;
    background-color: white;
    margin: auto;
    padding: 30px;
    position: absolute;
    left: -9999px;
    top: -9999px;
}

.partywitty--header {
    width: 100%;
    height: 60px;
    background-color: #120829;
    border-radius: 5px;
    display: flex;
    align-items: center;
    color: white;
    padding-left: 25px;
    margin-top: 30px;
}

.event_details_history {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 1px solid;
}

.partywitty--details {

    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    margin-top: 30px;

}

.details--leftside {
    width: 48%;
    height: auto;
    display: flex;
    flex-direction: column;
}

.details--rightside {
    width: 48%;
    height: auto;
    display: flex;
    flex-direction: column;
}

.detals--heading {
    width: 95%;
    height: auto;
    display: flex;
    flex-direction: row;
    margin: auto;

}

.heading--name {
    width: 40%;
    height: auto;
    font-weight: 500;
}

.heading--dynamic {
    width: 60%;
    height: auto;
    font-weight: 500;
}

.e-booking-details {
    width: 100%;
    height: 150px;

    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
    border: 1px solid;
}

.e-booking-details-image {
    width: 55%;
    height: 100%;

    border-radius: 5px;

}

.e-booking-details-image img {
    width: 100%;
    height: 100%;



}

.e-booking-details-content {
    width: 43%;
    height: 100%;

    border-radius: 5px;
    display: flex;
    flex-direction: column;


}

.e-booking-dat-time {
    font-size: 12px;
}

.e-booking-budge {
    color: #D71362;
}

.details-booking {
    display: flex;
    flex-direction: column;
    text-align: start;
}

.club--about--us--Gallery--section--buttons111 button {
    height: 30px;
    width: auto;
    background-color: #d71362;
    border: none;
    color: white;
    border-radius: 5px;
    outline: none;
    margin-right: 20px;
    margin-bottom: 10px;
    float: right;
}

.notes-tnc {

    font-size: 10px;

}

.transaction-details p {
    font-size: 12px;
}

.top_rw {
    background-color: #f4f4f4;
}

button {
    padding: 5px 10px;
    font-size: 14px;
}

.invoice-box {
    max-width: 890px;
    margin: auto;
    padding: 10px;
    border: 1px solid #eee;
    box-shadow: 0 0 10px rgba(0, 0, 0, .15);
    font-size: 14px;
    line-height: 24px;
    font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
    color: #555;
}

.invoice-box table {
    width: 100%;
    line-height: inherit;
    text-align: left;
    border-bottom: solid 1px #ccc;
}

.invoice-box table td {
    padding: 5px;
    vertical-align: middle;
}

.invoice-box table tr td:nth-child(2) {
    text-align: right;
}

.invoice-box table tr.top table td {
    padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
    font-size: 45px;
    line-height: 45px;
    color: #333;
}

.invoice-box table tr.information table td {
    padding-bottom: 40px;
}

.invoice-box table tr.heading td {
    background: #eee;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
    font-size: 12px;
}

.invoice-box table tr.details td {
    padding-bottom: 20px;
}

.invoice-box table tr.item td {
    border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
    border-bottom: none;
}

.invoice-box table tr.total td:nth-child(2) {
    border-top: 2px solid #eee;
    font-weight: bold;
}

@media only screen and (max-width: 600px) {
    .invoice-box table tr.top table td {
        width: 100%;
        display: block;
        text-align: center;
    }

    .invoice-box table tr.information table td {
        width: 100%;
        display: block;
        text-align: center;
    }
}

@media screen and (max-width: 540px) {
    .package--namewith--icon {
        width: 40% !important;
        height: 40px;
        display: flex;
        flex-direction: row;
        gap: 10px;

        align-items: center;

        color: white;
    }

    .package--namewith--icon img {
        width: 100%;
    }
}


.rtl {
    direction: rtl;
    font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
}

.rtl table {
    text-align: right;
}

.rtl table tr td:nth-child(2) {
    text-align: left;
}

.invoice-button {
    display: flex;
    align-items: center;
    margin-top: -15px;
    margin-right: 10px;
}

.invoice-button button {
    background-color: #D71362;
    border: none;
    border-radius: 3px;
    color: white;
}

.invoice-box .name-logo {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid;
    padding-bottom: 10px;
    font-size: 40px;
}

.all--club--filters--sorting {
    width: 90%;
    height: 100px;
    border: 1px solid #D71362;
    background-color: rgb(42, 35, 67);
    display: flex;
    border-radius: 5px;
    margin: auto;

    margin-top: -40px;
    overflow: hidden;
    align-items: center;
}

.all--club--filters {
    width: 90%;
    height: 50px;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    margin: auto;
    align-items: center;
}

.all--club--filters--options {
    width: 100%;
    height: 100%;
    background-color: rgb(177, 29, 29);
}

.all--club--filters--options select {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.New--year--page--design--filter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.New--year--page--design--filter--1 {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    color: white;
}

.New--year--page--design--filter p {
    color: white;
    font-size: 32px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    padding-bottom: 43px;
}

.New--year--page--design--filter--1-option select {
    height: 40px;
    width: 170px;
    border-radius: 5px;
    background-color: #120829;
    color: white;
    border: 1px solid white;
    outline: none;
}

.New--year--page--design--filter--1-option--sort {
    color: white;
    font-size: 25px;
}

.New--year--page--design--filter--1--button button {
    width: 170px;
    height: 40px;
    background-color: #1976D2;
    color: white;
    border-radius: 5px;
    border: none;
    border: 1px solid #1976D2;
    font-size: 16px;
    font-weight: 700;
}

.New--year--page--design--filter--1-option select::-webkit-scrollbar {
    display: none;
}

.minimum--garunty {
    width: 100%;
    color: #5d5d5d;

}

.minimum--garunty p {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.bid--history--style {
    width: 98%;
    height: 200px;
    border: 1px solid gray;
    border-radius: 5px;
    margin-bottom: 15px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.biddetails--clubimage {
    width: 170px;
    height: 100%;
    border-radius: 50%;
    background-color: white;
}

.biddetails--text {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    color: white;
}

.biddetails--addon {
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.bidhistory-date {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.bidhistory-date1 {

    display: flex;
    flex-direction: column;

}

.bidhistory-date1 .booking--date--text {
    color: #008AD8;

}

.bidhistory-date1 button {

    background-color: #0B051C;
    border: 1px solid #008AD8;
    color: #008AD8;
    border-radius: 3px;
    margin-top: 20px;
}

.package--namewith--icon {
    width: 45%;
    display: flex;
    flex-direction: row;
    gap: 10px;

    align-items: center;

    color: white;
}

.package--namewith--icon img {
    width: 100%;
}

.custom-table {
    background-color: #1B183B;

    border: 2px solid #ccc;
}

.btn--success {
    background-color: green !important;
}

@media screen and (max-width: 540px) {
    .booking--history {
        display: none;
    }
    .minimum--garunty p {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #000;
    }
    .mobile--view--history {
        width: 90%;
        height: 600px;
        margin: auto;
        padding: 20px;
        border-radius: 10px;
    }
    .alakart--booking--history--table {
        width: 100%;
        height: 450px;
        display: flex;
        flex-direction: row;
    }
    .t--history {
        width: 30%;
        height: 100%;
        position: relative;
        top: 0;
        left: 0;
        background-color: black;
        border: 1px solid rgba(255, 255, 255, 0.269);
    }
    .t--history1 {
        width: 75%;
        height: 100%;
        overflow: scroll;
        display: flex;
        flex-direction: row;
    }
    .t--history1::-webkit-scrollbar {
        display: none;
    }
    .t--history--data {
        width: 130px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: 1px solid rgba(255, 255, 255, 0.269);
    }
    .table--heading {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

    }
    .minimum--garunty img{
        height: 13px;
        width: 13px;
    }

    .main--heading--table {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.212);
        justify-content: center;
        align-items: center;
    }

    .t--history .main--heading--table p {
        width: 100px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        color: white;

    }

    .t--history1 .main--heading--table p {
        width: 130px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        color: white;

    }

    .club--about--us--Gallery--section--buttons11 button {
        height: 35px;
        width: auto;
        background-color: #d71362;
        border: none;
        color: white;
        border-radius: 5px;
        outline: none;
        margin-right: 10px;
        margin-bottom: 10px;
    }

    .club--about--us--Gallery--section--buttons11 .button--tab--history {
        width: 75px;
        height: 35px;
        border-radius: 20px;
        background-color: #1B183B;

    }

    .club--about--us--Gallery--section--buttons11 .selectedtab {
        background-color: #1976D2;
    }
    .book--a--table{
        background-color: #141420;
    }
}

@media screen and (min-width: 540px) {
    .mobile--view--history {
        display: none;
    }
}