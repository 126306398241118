.New--year--page--design--banner {
    background-image: url(./image/MainCarnival.png);
    height: 600px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    justify-content: center;
    align-items: center;
    margin-bottom: 54px;
}

.New--year--page--design--banner--1 {
    font-size: 18px;
    font-weight: 600;
    font-family: helvetica;
    margin: 0;
    padding: 0;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 420px;
    letter-spacing: 0.9px;
}

.New--year--page--design--banner h2 {
    color: white;
    font-size: 40px;
    font-weight: 700;
}

.New--year--page--design--filter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.New--year--page--design--filter--1 {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    color: white;
}

.New--year--page--design--filter p {
    color: white;
    font-size: 32px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    padding-bottom: 43px;
}

.New--year--page--design--filter--1-option select {
    height: 40px;
    width: 170px;
    border-radius: 5px;
    background-color: #120829;
    color: white;
    border: 1px solid white;
    outline: none;
}

.New--year--page--design--filter--1-option--sort {
    color: white;
    font-size: 25px;
}

.New--year--page--design--filter--1--button button {
    width: 170px;
    height: 40px;
    background-color: #1976D2;
    color: white;
    border-radius: 5px;
    border: none;
    border: 1px solid #1976D2;
    font-size: 16px;
    font-weight: 700;
}

/* Style the select element */
#cars {
    padding-left: 12px;
    /* Add left padding */
    padding-right: 3px;
    /* Add right padding */
    background-image: url('./image/dropdown.png');
    /* Replace 'path/to/custom-icon.png' with the path to your custom dropdown icon */
    background-repeat: no-repeat;
    background-position: calc(100% - 12px) center;
    appearance: none;
    /* Remove default styles (for some browsers) */
    -webkit-appearance: none;
    /* Remove default styles (for some browsers) */
    -moz-appearance: none;
    /* Remove default styles (for some browsers) */
    background-size: 10px 10px;
}

/* Style the dropdown arrow icon */
#cars::-ms-expand {
    display: none;
    /* Hide the default arrow icon in Internet Explorer */
}

/* Style the options in the dropdown menu */
#cars option {
    padding: 10px;
    /* Add padding to the options for better readability and clickability */
}



.New--year--page--design--filter--1--sort--result-1 select {
    background-color: #120829;
    border: 1px solid #969696;
    color: #969696;
    height: 30px;
    width: 118px;
    border-radius: 30px;
}

.New--year--page--design--filter--1--sort--result {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    padding-top: 35px;
}

.New--year--page--design--popular-place--1 {
    display: flex;
    flex-direction: row;
    column-gap: 25px;
}

.New--year--page--design--popular-place--1 img {
    height: 100px;
    width: 100px;
}

.New--year--page--design--popular-place p {
    color: white;
    font-size: 32px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    padding-bottom: 43px;
}

.New--year--page--design--filter--p {
    color: white;
    font-size: 32px;
    font-weight: 400;
    text-align: center;
    margin-top: 30px;
}

.New--year--page--design--popular-place {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* multicarousal style start */

/* multicarousal style end */
.New--year--page--design--All--passes1--card {
    height: 310px;
    width: 100%;
    background-color: #fefefe;
    border: 2px solid #cfcfcf;
    border-radius: 6px;
}

.web--new-year-package--table-booking {
    display: flex;
    flex-direction: row;
    column-gap: 100px;
    justify-content: center;
    align-items: center;
    margin-top: 85px;
}

.web--new-year--package--booking {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.web--new-year--package--booking button {

    height: 60px;
    width: 230px;
    border: none;
    outline: none;
    border-radius: 58px 58px 10px 10px;
    background: #FFF;
    box-shadow: 0px 6px 6px 0px rgba(255, 255, 255, 0.10) inset;
    background-color: #fff;
    color: #120829;
    font-family: Helvetica;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    position: relative;
    top: -30px;
}

.web--new--year--stag--couple--passes1 {
    display: flex;
    flex-direction: row;
    background: #4e475e;
    border-radius: 5px;
}

.web--new--year--stag--couple--passes1 button {
    width: 280px;
    height: 50px;
    flex-shrink: 0;
    border: none;
    outline: none;
    border-radius: 5px;
    background: #4e475e;
    color: #FFF;
    text-align: center;
    font-family: Helvetica;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.web--new-year--package--booking img {
    height: 250px;
    width: 250px;
    border-radius: 50%;
}

.New--year--page--design--All--passes1--card--content1 {
    padding: 10px;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.New--year--page--design--All--passes1--card--content p {
    margin: 0;
    padding: 0;
}

.New--year--page--design--All--passes1--card img {
    height: 200px;
    width: 100%;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.New--year--page--design--All--passes1--card1 img {
    height: 300px;
    width: 100%;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.New--year--page--design--All--passes1--card1--6 img {
    height: 200px;
    width: 100%;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}


.New--year--page--design--All--passes1--card--content--1 {
    display: flex;
    flex-direction: row;
}

.New--year--page--design--All--passes1--card--content--1-12 p {
    margin: 0;
    padding: 0;
}

.New--year--page--design--All--passes1--card--content--1--p {
    font-size: 16px;
    font-weight: 700;
    color: #212121;
    margin: 0;
    padding: 0;
}

.New--year--page--design--All--passes1--card--content--1--p1 {
    font-size: 16px;
    font-weight: 500;
    color: white;
}

.New--year--page--design--All--passes1--card--content--1-12--p span {
    color: #fdbf00;
}

.New--year--page--design--All--passes1--card--content--1-12--p1 span {
    color: white;
}

.New--year--page--design--All--passes1--card--content--1-12--p {
    color: #555555;
}

.New--year--page--design--All--passes1--card--content--1-12--p1 {
    color: #555555;
    font-size: 12px;
    font-weight: 500;
}

.New--year--page--design--All--passes1--card--content--1-12--p12 {
    color: white;
    font-size: 13px;
    font-weight: 200;
}

.New--year--page--design--All--passes1--card--content--p--blue {
    color: white;
    font-size: 15px;
    font-weight: 500;
}

.New--year--page--design--All--passes1--card--content--p--blue--1 {
    color: #1976d2;
    font-size: 18px;
    font-weight: 500;
    margin-top: -2px;
}

.New--year--page--design--All--passes {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 90px;
    padding-right: 90px;
}

.New--year--page--design--All--passes1--card--content button {
    background-color: #1976D2;
    border-radius: 5px;
    border: none;
    color: white;
    height: 40px;
    width: 100%;
}

.New--year--page--design--carousal {
    margin-top: 70px;
    padding-left: 110px;
    padding-right: 110px;
}

.New--year--page--design--All--passes1 {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    background-color: #2D2344;
    border-radius: 10px;
    padding: 25px;
    margin-top: 20px;
}

.New--year--page--design--All--passes1-2 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
}

.New--year--page--design--time {
    display: flex;
    justify-content: center;
    align-items: center;
}

.New--year--page--design--time1 {
    margin-top: 55px;
    width: 70%;
    height: 200px;
    background-color: #110827;
    border: 1px solid white;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 40px;
    padding-right: 40px;
}

.New--year--page--design--time11 p {
    margin: 0;
    padding: 0;
}

.New--year--page--design--time11-p {
    font-size: 17px;
    font-weight: 500;
    color: white;
}

.New--year--page--design--time11-p-small {
    font-size: 14px;
    font-weight: 200;
    color: white;
}

.New--year--page--design--more--party {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;
}

.New--year--page--design--more--party--card {
    width: 70%;
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    margin-top: 20px;
}

/*
.new--year--mobile--view--1 {
    display: none;
}
*/

.new--year--page--mobile-view {
    display: none;
}

.new--year--page--img--button--dj {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* position: relative;
    top: -58px; */
}

.New--year--page--design--All--passes1--card--content {
    /* position: relative;
    top: -55px; */
    padding: 13px 13px;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    width: 100%;
}



.dj--nights--img-btn1 {
    width: 96px;
    height: 24px;
    flex-shrink: 0;
    border-radius: 0px 6px 0px 0px;
    border: none;
    background: #1976D2;
    color: #FFF;
    font-family: Helvetica;
    font-size: 11px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.dj--nights--img {
    display: flex;
    flex-direction: column;
}

.dj--nights--img-btn2 {
    width: 89px;
    height: 24px;
    flex-shrink: 0;
    border: none;
    border-radius: 6px 0px 0px 0px;
    background: #CC0C39;
    color: #FFF;
    font-family: Helvetica;
    font-size: 11px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    position: relative;
    top: 34px;
}

.New--year--page--design--All--passes1--card--content--1--p--1-minimum {
    color: #000;
    font-family: Helvetica;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.3;
}

.new--year--page--table6 {
    color: #1976D2;
    font-family: Helvetica;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.new--year--page--border--line {
    width: 100%;
    height: 1px;
    background: rgba(0, 0, 0, 0.20);
    margin-top: 10px;
    margin-bottom: 12px;
    opacity: 0.5;
}

.new--year--page--border--line1-1 {
    width: 100%;
    height: 1px;
    background: #fff;
    opacity: 30%;
    margin-top: 15px;
    margin-bottom: 12px;
}

.new--year--page--more--detail {
    color: #1976D2;
    font-family: Helvetica;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
}

.new--year--mobile--view--1-1-stag-couple {
    display: flex;
    flex-direction: row;
    column-gap: 15px;
    margin-top: 30px;
}

.new--year--mobile--view--1-1-stag-couple p {
    color: var(--text-40, #898DA3);
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
}

.new--year--mobile--view--1-1-stag-couple img {
    width: 16px;
    height: 14px;
    flex-shrink: 0;
}

.new--year-filter-clear1 {
    color: black;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.new--year-filter-clear2 {
    color: #1976D2;
    text-align: right;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.new--year-filter-clear--content1 {
    width: 25%;
    height: auto;
    padding: 31px 0px 87px 0px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    flex-shrink: 0;
    background: var(--bg-grayish-white, #F1F1F1);
}

.new--year-filter-clear--content2-gender1 {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
}

.number--male--input {
    height: auto;
    width: 100px;
    font-size: 12px;
    background-color: #ffffff;
    border: none;
    outline: none;
}

.new--year--amenties--search img {
    position: relative;
    left: -30px;
}

.new--year--near--by {
    display: flex;
    flex-direction: row;
    column-gap: 10px;

}

.new--year--near--by img {
    height: 20px;
    width: 20px;
}

.New--year--page--design--All--passes1--card--content--p--blue {
    color: #1976d2;
    font-size: 15px;
    font-weight: 500;

}

.new--year--near--by p {
    color: #1976D2;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.location--gps--p-near-by {
    color: #000;
    font-family: Poppins;
    font-size: 8px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    opacity: 0.3;
    margin: 0;
    padding: 0;
    position: relative;
    top: -10px;
    padding-left: 30px;

}

.number--male--checkbox {
    width: 15px;
    height: 15px;
    border-radius: 2px;
    border: none;
    outline: none;
    border: 1px solid #b3b3b3;

}

.new--year-filter-clear--content2-gender1 p {
    margin: 0;
    padding: 0;
    color: #000;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.new--year-filter-clear--content2-gender {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    margin-top: 15px;
}

.new--year-filter-clear--content1 button {
    width: 100%;
    height: 32px;
    flex-shrink: 0;
    border: none;
    text-align: start;
    color: var(--text-60, #666A80);

    /* Regular / Small */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.new--year--amenties--search {
    display: flex;
    flex-direction: row;
}

.new--year--amenties--search input {
    padding-left: 10px;
    width: 197px;
    height: 37px;
    flex-shrink: 0;
    border-radius: 18.5px;
    border: 0.5px solid #999999;
    opacity: 0.4;
}

.new--year-filter-clear--1 {
    margin-top: 15px;
    padding-right: 15px;
    display: flex;
    flex-direction: row-reverse;
    column-gap: 10px;
}

.new--year-filter-clear--btn1 {

    height: 40px;
    width: 120px;
    border-radius: 5px;
    background: #1976D2;
    color: var(--white, #FFF);
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    border: none;
}

.new--year-filter-clear--btn2 {
    height: 40px;
    width: 120px;
    border-radius: 5px;
    background: white;
    color: #1976D2;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    border: none;
    border: 1px solid #1976D2;
}


.new--year-filter-clear--content {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
}

.new--year-filter-clear--content2--slider {
    display: flex;
    flex-direction: row;
}

.new--year-filter-clear--content2--price {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    margin-left: 15px;
    margin-bottom: 50px;
}

.new--year-filter-clear--content2--price input {
    height: auto;
    width: 80px;
    color: #242426;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 11.537px;
    background-color: transparent;
    outline: none;
    border: 1px solid transparent;
}

.new--year-filter-clear--content2 {
    margin-top: 15px;
}

.new--year-filter-clear--content2--slider--p {
    margin: 0;
    padding: 0;
    color: #242426;
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 11.537px;
    position: relative;
    top: 20px;
    left: 16px;
}

.new--year--input--content--border--line {
    height: 0.5px;
    width: 80px;
    background: #E2E4E5;

}

.New--year--page--design--All--passes1--card--content--1--p--price--12 {
    color: #1976D2;
    font-family: Helvetica;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    padding: 0;
}

.New--year--page--design--All--passes1--card--content--1-12 {
    text-align: right;
}

.new--year-filter-clear--content2--slider--p1 {
    margin: 0;
    padding: 0;
    color: #242426;
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 11.537px;
    position: relative;
    top: 20px;
    left: -16px;
}

.new--year--page--table6--package {
    color: #CC0C39;
    font-family: Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.new--year-filter-clear {
    margin-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.new--year--stag-male {
    display: flex;
    flex-direction: row;
    column-gap: 4px;
    justify-content: end;
    align-items: end;
}

.new--year--filter--popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 481px;
    height: 479px;
    background-color: white;
    border: 1px solid white;
    outline: none;
    border-radius: 10px;
    box-shadow: 24px;
    transition: all 0.3s ease;
    /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.9); */
    outline: none;
    overflow: hidden;
}

.new--year--stag-male img {
    height: 12px;
    width: 12px;
    position: relative;
    top: -2px;
}

.New--year--page--design--All--passes1--card--content--1--p--price {
    font-size: 17px;
    font-weight: 700;
    color: #212121;
    margin: 0;
    padding: 0;
    position: relative;
    top: -3px;
}

.new--year--stag-female {
    display: flex;
    flex-direction: row;
    column-gap: 4px;
    justify-content: end;
    align-items: end;
}

.New--year--page--design--All--passes1--card--content--1--p--1-minimum {
    color: #000;
    font-family: Helvetica;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.3;
}

.New--year--page--design--All--passes1--card--book-a-table img {
    height: 200px;
    width: 100%;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.new--year--filter--border--line {
    width: 100%;
    height: 1px;
    background: #000000;
    opacity: 30%;
}

.New--year--page--design--All--passes1--card--book-a-table {
    width: 100%;
    height: 381px;
    border-radius: 5px;
    background-color: #fefefe;
    border: 2px solid #cfcfcf;
}

.new--year--stag-female p {
    color: #FD2F71;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
    padding: 0;

}

.new--year--card--content2--1-2 {
    /* margin-top: -50px; */
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.new--year--couple {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    justify-content: end;
    align-items: end;
}

.new--year--stag-female img {
    height: 12px;
    width: 12px;
    position: relative;
    top: -2px;
}

.new--year--stag-male p {
    color: #3569DC;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
    padding: 0;
}

.dj--nights--img img {
    width: 46.69px;
    height: 33.458px;
    flex-shrink: 0;
    margin-left: 22px;
    position: relative;
    top: -15px;
}

.new--year--card--content2 {
    /* margin-top: -35px; */
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

/**
@media (min-width:0px) and (max-width:540px) {
*/
.New--year--page--design--All--passes1--card--content--1--homepage {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.New--year--page--design--All--passes1--card1 img {
    height: 300px;
    width: 100%;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.New--year--page--design--1 {
    margin-top: -100px;
    overflow: hidden;
}

.new--year--banner--5 {
    margin-top: 30px;
    margin-bottom: 25px;
}

.new--year--mobile--view--1 {
    overflow: none;
    /* margin-top: 35px; */
}

.ticket--type--name {
    font-size: 8px !important;
}

.New--year--page--design--All--passes1--card--content--p--blue {
    color: #1976d2;
    font-size: 15px;
    font-weight: 500;

}

.carret--use {
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(51, 51, 51);
    border-radius: 3px;
    padding: 10px;
}

.carret--checkbox {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    font-weight: 500;
    color: white;
}

.club--detail--and--pass--details {

    width: 90%;
    margin: auto;
}

.club--detail--and--pass--details h4 {
    margin-top: 15px;
    font-size: 14px;
    font-weight: 600;
}

.club--detail--and--pass--details h5 {
    font-size: 12px;
    font-weight: 500;
    border-bottom: 1px solid rgb(104, 103, 103);
}

.New--year--page--add--more span {
    color: rgb(248, 87, 87);
}

.carret--use p {
    color: white;
    padding-left: 20px;
    font-size: 12px;
}

.New--year--page--design--banner {
    display: none;
}

.new--year--page--mobile-view {
    display: block;
}

.New--year--page--design {
    display: none;
}


.new--year--mobile--view--1-1--button {
    height: 40px;
    width: 100%;
    background-color: #22222E;
    color: white;
    border: none;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.new--year--mobile--view--1-1--button1 {
    height: 40px;
    width: 50%;
    background-color: #22222E;
    color: white;
    border: none;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.new--year--mobile--view--1-1 {
    width: 100%;
    height: 40px;
    background-color: #22222E;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.new--year--special--package1 {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    width: 100%;
    height: auto;
    flex-shrink: 0;
    background-color: #1F1F2C;
    border: 1px solid #353535;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.New--year--page--design--All--passes1--card1--12 img {
    height: 200px;
    width: 100%;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.new--year--stag-female {
    display: flex;
    flex-direction: row;
    column-gap: 4px;
    justify-content: end;
    align-items: end;
}

.new--year--stag-male {
    display: flex;
    flex-direction: row;
    column-gap: 4px;
    justify-content: end;
    align-items: end;
}


.new--year--couple {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    justify-content: end;
    align-items: end;
}


.new--year--stag-male img {
    height: 12px;
    width: 12px;
    position: relative;
    top: -2px;
}

.new--year--stag-female p {
    color: #FD2F71;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
    padding: 0;

}

.New--year--page--design--All--passes1--card--book-a-table img {
    height: 200px;
    width: 100%;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.New--year--page--design--All--passes1--card--content--1-12 {
    width: 38%;
    text-align: right;
}

.New--year--page--design--All--passes1--card--content--1-12--p1 {
    color: black;
    font-size: 13px;
    font-weight: 400;
    opacity: 30%;
    margin: 0;
    padding: 0;
}

.New--year--page--design--All--passes1--card--content--1-12--p1--2 {
    color: #fff;
    font-size: 13px;
    font-weight: 400;
    opacity: 30%;
}

.summury--stag--male--1-3 {
    width: 100%;
    height: auto;
    flex-shrink: 0;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.05);
    display: flex;
    flex-direction: column;
}

.summury--stag--male {
    width: 100%;
    height: auto;
    flex-shrink: 0;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: white;
    padding: 0px 15px;
}

.summury--stag--male--1--content {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 8pxpx;
}

.summury--stag--male--1 {
    display: flex;
    flex-direction: row;
    column-gap: 8px;

}

.summury--stag--male--1 p {
    color: white;
    font-size: 10px;
}

.summury--stag--male--1 img {
    height: 15px;
    width: 15px;
}

.New--year--page--design--All--passes1--card--content--1--p--price--12 {
    color: #1976D2;
    font-family: Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 23px;
}

.New--year--page--design--All--passes1--card--content--1--p--price--12--homepage {
    color: #1976D2;
    font-family: Helvetica;
    width: 100%;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    position: relative;
    left: 22px;
    top: 2px;
}

.new--year--bottom--popup Button {
    outline: none;
}

.new--year-filter-clear1 {
    color: var(--text-40, #898DA3);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.New--year--page--design--All--passes1--card1--1 img {
    height: 200px;
    width: 100%;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.New--year--page--design--All--passes1--card1--row--gap {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.New--year--page--design--All--passes1--card1--1--2 img {
    height: 200px;
    width: 100%;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.New--year--page--design--All--passes1--card1--1--2-1 img {
    height: 300px;
    width: 100%;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.new--year-filter-clear2 {
    color: #1976D2;
    text-align: right;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.new--year-filter-clear--content1 {
    width: 25%;
    height: auto;
    padding: 31px 0px 87px 0px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    flex-shrink: 0;
    background: var(--bg-grayish-white, #F1F1F1);
}

.new--year-filter-clear--content2-gender1 {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
}

.number--male--input {
    height: auto;
    width: 100px;
    font-size: 12px;
    background-color: #ffffff;
    border: none;
    outline: none;
}

.new--year--amenties--search img {
    position: relative;
    left: -30px;
}

.new--year--near--by {
    display: flex;
    flex-direction: row;
    column-gap: 10px;

}

.new--year--near--by img {
    height: 20px;
    width: 20px;
}

.new--year--near--by p {
    color: #1976D2;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.summury--stag--male--1--2 {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    justify-content: center;
    align-items: center;
}

.summury--stag--male--1--2--1 {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.new--year--order--cart1--1 button {
    width: 103px;
    height: 33.023px;
    border: 1px dashed #353535;
    color: #1976d2;
    border-radius: 5px;
    background-color: transparent;
}

.summury--stag--male p {
    margin: 0;
    padding: 0;
    color: white;
    font-size: 12px;
}

.summury--stag--male img {
    height: 20px;
    width: 20px;
}

.location--gps--p-near-by {
    color: #000;
    font-family: Poppins;
    font-size: 8px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    opacity: 0.3;
    margin: 0;
    padding: 0;
    position: relative;
    top: -10px;
    padding-left: 30px;

}

.number--male--checkbox {
    width: 15px;
    height: 15px;
    border-radius: 2px;
    border: none;
    outline: none;
    border: 1px solid #b3b3b3;

}

.new--year-filter-clear--content2-gender1 p {
    margin: 0;
    padding: 0;
    color: #000;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.new--year-filter-clear--content2-gender {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}

.new--year-filter-clear--content1 button {
    width: 100%;
    height: 32px;
    flex-shrink: 0;
    border: none;
    text-align: start;
    color: var(--text-60, #666A80);

    /* Regular / Small */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 13px !important;
}

.new--year--amenties--search {
    display: flex;
    flex-direction: row;
}


.new--year--amenties--search input {
    padding-left: 10px !important;
    width: 197px;
    height: 37px;
    flex-shrink: 0;
    border-radius: 18.5px;
    border: 0.5px solid #999999;
    opacity: 0.8;
    outline: none;
}

.new--year--stag-female img {
    height: 12px;
    width: 12px;
    position: relative;
    top: -2px;
}

.new--year-filter-clear button {
    display: flex;
    width: 320px;
    padding: 10px 24px !important;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #1976D2;
    color: var(--white, #FFF);
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    border: none;
}

.new--year-filter-clear--content {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    margin-bottom: 30px;
}

.new--year-filter-clear--content2--slider {
    display: flex;
    flex-direction: row;
}

.new--year-filter-clear--content2--price {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    margin-left: 15px;
    margin-bottom: 50px;
}

.new--year-filter-clear--content2--price input {
    height: auto;
    width: 80px;
    color: #242426;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 11.537px;
    background-color: transparent;
    outline: none;
    border: 1px solid transparent;
}

.new--year-filter-clear--content2--slider--p {
    margin: 0;
    padding: 0;
    color: #242426;
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 11.537px;
    position: relative;
    top: 20px;
    left: 16px;
}

.new--year--input--content--border--line {
    height: 0.5px;
    width: 80px;
    background: #E2E4E5;
    ;
}

.new--year-filter-clear--content2--slider--p1 {
    margin: 0;
    padding: 0;
    color: #242426;
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 11.537px;
    position: relative;
    top: 20px;
    left: -16px;
}

.new--year-filter-clear {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 15px 20px;
}

.new--year--special--package1-couple {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    width: 100%;
    height: auto;
    flex-shrink: 0;
    background-color: #1F1F2C;
    border: 1px solid #353535;
    justify-content: center;
    align-items: center;
    padding-bottom: 23px;
}

.New--year--page--design--All--passes1--card--content--1--p--1-minimum {
    color: #000;
    font-family: Helvetica;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.3;
}

.new--year--special--package1-couple--6 {
    padding-left: 20px;
    padding-right: 20px;

}

.new--year--page--table6 {
    color: #1976D2;
    font-family: Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.new--year--order--cart {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
}

.new--year--order--cart1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0px;
}

.New--year--thankew--page {
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    top: -100px;
}

.new--year--order--cart1--5 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0px;
    margin-top: 10px;
}

.new--year--order--cart1--1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0px;
}

.new--year--page--table6--package {
    color: #CC0C39;
    font-family: Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.New--year--page--design--All--passes1--card--stag--p {
    margin-top: 5px;
    color: #000;
    font-family: Helvetica;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

}

.new--year--special--package1--stag--pass {
    color: #FFF;
    text-align: center;
    font-family: Helvetica;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 30px;
    padding-bottom: 10px;
}

.new--year--mobile--view--1 {
    display: block;
}

.New--year--page--design--more--party {
    display: block;
}

.new--year--mobile--view--1--h2 {
    font-size: 17px;
    color: white;
    text-align: center;
    margin-top: 18px;
    font-weight: 600;
}

.new--year--mobile--view--1--h1 {
    margin-top: 20px;
    font-size: 17px;
    color: white;
    text-align: center;
    margin-top: 12px;
    font-weight: 600;
    margin-bottom: 13px;
}

.new--year--mobile--view--1--p {
    font-size: 13px;
    color: white;
    text-align: center;
}

.new--year--special--package::-webkit-scrollbar {
    width: 1px;
}

.new--year--special--package::-webkit-scrollbar-track {
    background-color: transparent;
}

.new--year--special--package::-webkit-scrollbar-thumb {
    border-radius: 10px;
}

.new--year--special--package {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    gap: 20px;
    padding: 0px 5px;
    transition: transform 0.3s ease;
    margin: auto;
    justify-content: center;
    align-items: stretch;
}

.new--year--page--menu--details--card {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.new--year-menu--details--popup--1 {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    margin-top: 15px;
}

.new--year-menu--details--popup--1-2 {
    background-color: white;
    width: 80%;

    position: fixed;
    margin-top: -4px;
    z-index: 20;
}

.new-year-Artist-viewall-banner {
    margin-bottom: 30px;
}

.new--year-menu--details--popup--1 button {
    height: 37px;
    width: 110px;
    color: #808080;
    border: none;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    background-color: white;
    border-radius: 3px;
}


.new--year--border--line {
    margin-top: 10px;
    height: 1px;
    width: 100%;
    background: #F1F1F1;
    margin-bottom: 10px;
}

.new--year--menu--content--p {
    color: #09090D;
    font-family: Helvetica;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin: 0;
    padding: 0;
}

.new--year--menu--content--p--13 {
    color: #09090D;
    font-family: Helvetica;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    padding: 0;
}

.new--year--your--order--book-a-table--card--1 {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    justify-content: center;
    align-items: center;
}

.new--year--menu--content--p--12 {
    color: #09090D;
    font-family: Helvetica;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin: 0;
    padding: 0;
}

.new--year--menu--content--p1 {
    display: flex;
    flex-direction: row;
    column-gap: 8px;
}

.new--year--menu--content {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding-top: 80px;
}

.new--year--book--a--table--book--now--1 {
    position: relative;
    top: -45px;
    padding: 0px 20px;
}

.new--year--name {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    justify-content: center;
    align-items: center;
}

.New--year--page--design--All--passes1--card--content--1 p {
    margin: 0;
    padding: 0;
}

.new--year--menu--content1 {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding-top: 80px;
}

.new--year--menu--content--1 ul {
    margin: 0;
    padding: 0;
}

.new--year--menu--content--1 ul li::marker {
    margin: 0;
    padding: 0;
    color: black;
}

.new--year--menu--content--p--marker {
    padding-left: 20px;
}

.new--year--menu--p {
    color: #09090D;
    font-family: Helvetica;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
}

.new--year--page--img--button--dj {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    top: -58px;
}

.new--year--page--img--button--dj--homepage {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    top: -53px;
}


.New--year--page--design--All--passes1--card {
    flex: 0 0 95%;
    height: auto;
    border-radius: 5px;
    overflow: hidden;
    transition: transform 0.2s;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

.New--year--page--design--All--passes1--card--homepage {
    flex: 0 0 80%;
    height: 155px;
    border-radius: 5px;
    overflow: hidden;
    transition: transform 0.2s;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

.New--year--page--design--All--passes1--card1 {
    width: 305px;
    height: auto;
    display: block;
    margin: auto;

    border-radius: 5px;
    background-color: #fefefe;
    border: 2px solid #cfcfcf;
    padding-bottom: 10px;
}

.New--year--page--design--All--passes1--card1--img {
    width: 300px;
    height: 300px;
}

.New--year--page--design--All--passes1--card1--6 {
    width: 100%;
    height: 421px;
    border-radius: 5px;
    background-color: #fefefe;
    border: 2px solid #cfcfcf;
}

.New--year--page--all--passes1--card-p--9 {
    width: 70%;
}

.New--year--page--design--All--passes1--card--book-a-table {
    width: 100%;
    height: 390px;
    border-radius: 5px;
    background-color: #fefefe;
    border: 2px solid #cfcfcf;
}

.New--year--page--design--All--passes1--card1--12 {
    width: 100%;
    height: 415px;
    border-radius: 5px;
    background-color: #fefefe;
    border: 2px solid #cfcfcf;
}

.new--year--cart--stag--couple {
    color: black;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}

.New--year--page--design--All--passes1--card1--1 {
    width: 100%;
    height: 415px;
    border-radius: 5px;
    background-color: #fefefe;
    border: 2px solid #cfcfcf;
}

.New--year--page--design--All--passes1--card1--1--2 {
    width: 100%;
    height: auto;
    border-radius: 5px;
    background-color: #F2F2F2;
}

.New--year--page--design--All--passes1--card1--1--2-1 {
    width: 100%;
    height: auto;
    border-radius: 5px;
    background-color: #120829;

}

.new--year--name1-2 {
    display: flex;
    flex-direction: column;
}

.new--year--name1-2 input {
    width: 188px;
    height: 30px;
    flex-shrink: 0;
    border-radius: 2px;
    border: 0.7px solid rgba(0, 0, 0, 0.30);
    background: #FFF;
    padding-left: 5px;
    font-size: 13px;
    outline: none;
}

.new--year--name--1 input {
    width: 14px;
    height: 14px;
    flex-shrink: 0;
    border-radius: 2px;
    border: 0.7px solid rgba(0, 0, 0, 0.30);
    background: #FFF;
    outline: none;
}

.new--year--name--1 {
    display: flex;
    flex-direction: row;
    column-gap: 7px;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
}

.new--year--name1-2 label {
    margin: 0;
    padding: 0;
    font-size: 12px;
}

.new--year--name1 {
    display: flex;
    flex-direction: row;
    column-gap: 3px;
}

.new--year--name1 p {
    margin: 0;
    padding: 0;
}

.new--year--page--border--line {
    width: 100%;
    height: 1px;
    background: rgba(0, 0, 0, 0.20);
    /* margin-bottom: 10px; */
}

.new--year--page--border--line--homepage {
    width: 100%;
    height: 1px;
    background: rgba(0, 0, 0, 0.20);
    position: relative;
    top: -14px;
}

.new--year--menu--details {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    justify-content: space-around;
    column-gap: 10px;
}

.new--year--menu--details--9 {
    display: flex;
    flex-direction: row;
    margin-top: 6px;
    justify-content: center;
    column-gap: 10px;
}

.New--year--page--design--All--passes1--card--content--1--p {
    line-height: 18px;
    font-size: 16px;
    font-weight: 600;
    color: #212121;
    margin: 0;
    padding: 0;
}

.New--year--page--design--All--passes1--card--content--1--p--6 {
    line-height: 18px;
    font-size: 16px;
    font-weight: 600;
    width: 70%;
    color: #212121;
    margin: 0;
    padding: 0;
    position: relative;
    top: 3px;
}

.New--year--page--design--All--passes1--card--content--1--p--5 {
    line-height: 18px;
    font-size: 16px;
    font-weight: 600;
    width: 70%;
    color: #212121;
    margin: 0;
    padding: 0;
    position: relative;
    top: 3px;
}

.New--year--page--design--All--passes1--card--content--1--p-1 {
    line-height: 18px;
    font-size: 16px;
    font-weight: 600;
    width: 70%;
    color: white;
    margin: 0;
    padding: 0;
}

.New--year--page--design--All--passes1--card--content--1--p--homepage {

    font-size: 12px;
    font-weight: 600;
    width: 70%;
    color: #212121;
    margin: 0;
    padding: 0;
}

.New--year--page--design--All--passes1--card--content--1--p--price {
    font-size: 15px;
    font-weight: 600;
    color: #212121;
    margin: 0;
    padding: 0;
    position: relative;
    top: -3px;
}

.New--year--page--design--All--passes1--card--content--1--p--price span {
    font-size: 11px;
    font-weight: 400;
    color: #212121;
    margin: 0;
    padding: 0;
    /* position: relative;
        top: -3px; */
}

.new--year--stag-male p {
    color: #3569DC;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
    padding: 0;
}

.new--year--mobile--view--1--p--1 {
    font-size: 15px;
    color: #1976d2;
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
}

.dj--nights--img {
    display: flex;
    flex-direction: column;
}

.new-year--border--line {
    height: 0.2px;
    width: 100%;
    background-color: #555555;
    margin-top: 10px;
    margin-bottom: 20px;
}

.new--year--filter--mobile--view {
    margin-top: 20px;
    margin-bottom: 20px;
}

.new--year--your--order {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    margin-bottom: 10px;
    justify-content: center;
    align-items: center;
}

.new--year--your--order--1 {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    margin-bottom: 10px;

    align-items: center;
}

.new--year--your--order--1--2 {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    margin-bottom: 10px;
    justify-content: space-between;
}

.new--year--your--order--1 {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    margin-bottom: 10px;
    justify-content: center;
    align-items: center;
}

.New--year--summury--page {
    position: relative;
    top: -40px;
}

.new--year--your--order--1 p {
    margin: 0;
    padding: 0;
}

.New--year--page--design--All--passes1--card--img--homepage img {
    height: 102px;
    width: 100%;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.new--year--your--order1 {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    margin-bottom: 10px;
    justify-content: center;
    align-items: center;
}

.New--year--page--design--All--passes1--card--img {
    height: 300px;
    width: 300px;
}

.new--year--stag-male--icon {
    font-size: 15px !important;
    color: #3569DC !important;
}

.new--year--stag-female--icon {
    font-size: 15px !important;
    color: #FD2F71 !important;
}

.new--year--stag-couple--icon {
    font-size: 15px !important;
    color: #a8395c !important;
}

.new--year--stag-couple--icon img {
    width: 18px !important;
    height: 18px !important;
}

.New--year--page--add--more {
    color: #fff;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.New--year--page--add--more--5 {
    color: #fff;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.New--year--page--add--more--1 {
    color: #1976D2;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


.new--year--your--order p {
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: 600;
    color: white;
    margin-top: -4px;
}

.new--year--your--order--1--2 p {
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: 600;
    color: white;

}

.new--year--your--order1 p {
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: 600;
    color: white;
    margin-top: -4px;
}

.new--year--your--order1 img {
    position: relative;
    top: -3px;
}

.new--year--name--1--1--2 {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
}

.dj--nights--img img {
    width: 46.69px;
    height: 33.458px;
    flex-shrink: 0;
    margin-left: 22px;
    position: relative;
    top: -15px;
}

.dj--nights--img-btn1 {
    width: 96px;
    height: 24.5px;
    flex-shrink: 0;
    border-radius: 0px 6px 0px 0px;
    border: none;
    background: #1976D2;
    color: #FFF;
    font-family: Helvetica;
    font-size: 11px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.dj--nights--img-btn1--homepage {
    width: 56px;
    height: 19px;
    flex-shrink: 0;
    border-radius: 0px 6px 0px 0px;
    border: none;
    background: #1976D2;
    color: #FFF;
    font-family: Helvetica;
    font-size: 8px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    position: relative;
    top: 34px;
}

.new--year--your--order--book-a-table--card {
    height: auto;
    width: 100%;
    padding: 0px;
    padding-bottom: 15px;
    padding-top: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    overflow-y: scroll;
    background: #f2f2f2;
}

.new--year--your--order--book-a-table--card1 {
    height: auto;
    width: 100%;
    padding: 15px;
    border-radius: 5px;
    overflow-y: scroll;
    background: #120829;
}

.New--year--page--design--All--passes1--card--content button {
    background-color: white;
    height: auto;
    width: auto;
    border: none;
    outline: none;
}

.dj--nights--img-btn2 {
    width: 89px;
    height: 24px;
    flex-shrink: 0;
    border: none;
    border-radius: 6px 0px 0px 0px;
    background: #FD2F71;
    ;
    color: #FFF;
    font-family: Helvetica;
    font-size: 11px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    position: relative;
    top: -14px;
}

.dj--nights--img-btn3 {
    width: 89px;
    height: 24px;
    flex-shrink: 0;
    border: none;
    border-radius: 0px 0px 0px 0px;
    background: #CC0C39;
    color: #FFF;
    font-family: Helvetica;
    font-size: 11px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    position: relative;
    top: 34px;
}

.dj--nights--img-btn2--homepage {
    width: 70px;
    height: 19px;
    flex-shrink: 0;
    border: none;
    border-radius: 6px 0px 0px 0px;
    background: #CC0C39;
    color: #FFF;
    font-family: Helvetica;
    font-size: 8px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    position: relative;
    top: 34px;
}

.New--year--page--design--All--passes1--card--content--homepage {
    position: relative;
    top: -27px;
    padding: 13px 7px;
    display: flex;
    flex-direction: column;

    row-gap: 5px;
}

.new--year--page--more--detail {
    color: #1976D2;
    font-family: Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    letter-spacing: 5px;
    margin: 0;
    padding: 0;

}

.new--year--page--more--detail--9 {
    color: #1976D2;
    font-family: Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    letter-spacing: 1px;
    margin: 0;
    padding: 0;
}

.book--now--new--year--button {
    height: auto;
    width: auto;
    border: none;
    outline: none;
    background-color: transparent;
}

.new--year--page--more--detail--6 {
    color: #1976D2;
    font-family: Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    position: relative;
    letter-spacing: 5px;
    top: -7px;
}

.new--year--page--more--detail--homepage {
    color: #1976D2;
    font-family: Helvetica;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    position: relative;
    top: -14px;
}

.new--year--mobile--view--1-1--card {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;


}

.new--year--couple--stag {
    padding-top: 30px;
    width: 100%;
    height: 90px;
    flex-shrink: 0;
    background-color: #1F1F2C;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    justify-content: center;
    align-items: center;
    border: 1px solid #353535;
}

.new--year--mobile--view--1-1-stag-couple {
    display: flex;
    flex-direction: row;
    column-gap: 15px;
    margin-top: 0px;
}

.new--year--mobile--view--1-1-stag-couple--6 {
    display: flex;
    flex-direction: row;
    column-gap: 15px;
    margin-top: 0px;
    position: relative;
    top: -20px;
}

.new--year--add--number--page {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: -45px;
    margin-bottom: 15px;

}

.new--year--couple1 {
    display: flex;
    flex-direction: column;
    row-gap: 3px;

}

.new--year--couple1 label {
    margin: 0;
    padding: 0;
    font-size: 12px;
}

.new--year--name--couple {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.new--year--couple1 input {
    height: 32px;
    width: 135px;
    border-radius: 2px;
    border: 0.7px solid rgba(0, 0, 0, 0.30);
    background: #FFF;
    padding-left: 5px;
    font-size: 12px;
    outline: none;
}

.new--year--add--number--page1 {
    height: 35px;
    width: 120px;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    background: #FFF;
    justify-content: center;
    justify-content: space-around;
    align-items: center;
    padding-top: 17px;
    border: 1px solid gray;
}

.new--year--add--number--page1--p1 {
    color: var(--text-100, #090226);
    text-align: center;

    /* body/medium */
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    opacity: 30%;
}

.new--year--add--number--page1--p2 {
    color: var(--text-100, #090226);
    text-align: center;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.new--year--card--content2 {

    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.new--year--card--content2--1 {

    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.new--year--card--content2--1-2 {
    margin-top: 7px;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.new--year--mobile--view--1-1-stag-couple button {
    display: inline-flex;
    padding: 9px 21px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 20px;
    background: rgba(244, 244, 244, 0.30);
    border: none;
    color: white;
}

.new--year--mobile--view--1-1-stag-couple--6 button {
    display: inline-flex;
    padding: 9px 21px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 20px;
    background: rgba(244, 244, 244, 0.30);
    border: none;
    color: white;
}

.new--year--mobile--view--1-1-stag-couple p {
    color: var(--text-40, #898DA3);
    font-family: Poppins;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
}

.new--year--mobile--view--1-1-stag-couple--6 p {
    color: var(--text-40, #898DA3);
    font-family: Poppins;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
}

.new--year--table--booking--tab {
    padding-bottom: 20px;
}

.new--year--mobile--view--1-1-stag-couple img {
    width: 14.667px;
    height: 12px;
    flex-shrink: 0;
}

.new--year--mobile--view--1-1-stag-couple--6 img {
    width: 14.667px;
    height: 12px;
    flex-shrink: 0;
}

.new--year--menu--details button {
    width: 48%;
    height: 32px;
    flex-shrink: 0;
    border: none;
    border-radius: 4px;
    border: 1px solid #1976D2;
    background: #Fff;
    color: #1976D2;
    text-align: right;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.new--year--menu--details--9 button {
    width: 46%;
    height: 35px;
    flex-shrink: 0;
    border: none;
    border-radius: 4px;
    border: 1px solid #1976D2;
    background: #Fff;
    color: #1976D2;
    text-align: right;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;


}

.new--year--name--1 p {
    margin: 0;
    padding: 0;
}

.ticket--type--data--set {
    width: 90%;
    height: auto;
    display: flex;
    justify-content: space-between;
    margin: auto;
    margin-bottom: 10px;
}

.price--total--card {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0px 15px;
    height: 50px;
    background-color: #1EBE92;
    color: white;
    font-size: 15px;
    border: none;
    outline: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    z-index: 1000;
}

.new--year--view--cart--p {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
}

.passes--and--price {
    width: 90%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
}

.mobile--view--book--a--table--button {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background-color: #1EBE92;
    color: white;
    font-size: 15px;
    border: none;
    outline: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    z-index: 1000;
}

.mobile--view--book--a--table--button button {
    width: 100%;
    height: 50px;
    background-color: #1EBE92;
    color: white;
    font-size: 15px;
    border: none;
    outline: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.couple--list-name {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.couple--male--list {
    display: flex;
    flex-direction: column;
}

.menu--card--images {
    width: 100%;
    height: 300px;
    /* background-color: #000; */
}

.menu--card--images img {
    width: 100%;
    height: 100%;
}

.summery--page--pay--now .summery--page--submit {
    background-color: #1EBE92;
    border: none;
    color: white;
    font-weight: 500;
}

.p--button .summery--page--submit--pay--now {
    width: 100%;
    background-color: #1EBE92 !important;
    border: none;
    color: white;
    font-weight: 500;
    border-radius: 2px;
}

.Artist-viewall-banner--img--slider {
    width: 100%;
    height: 170px;
}

.Artist-viewall-banner--img--slider img {
    height: 100;
    width: auto;
}

.table--booking--button button {
    width: 100%;
    height: 40px;
    background-color: #1EBE92;
    border-radius: 3px;
    border: none;
    color: white;
}

.pricing--strip {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.newyear--menu--images {
    width: 95%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;

}

.newyear--menu--images1 {
    width: 100%;
    height: 350px;
    overflow: hidden;
    margin-top: 100px;
}

.newyear--menu--images1 img {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    padding-bottom: 10px;
    background-color: #090226;
    z-index: 1000;
}

.search--bar--stiky-newyear11 {
    width: 100%;
}


@media (max-width:541px) {

    .New--year--page--design--All--passes1--card1 {
        width: calc(100% - 20px);
        height: 305px;
        display: flex;
        margin-bottom: 10px;
    }

    .new--year--special--package {
        display: block;
    }

}