.mobile--view--hamburger--my--booking--border--line--fav {
    width: 100%;
    height: 0.5px;
    background-color: #66666E;
    margin-top: 10px;
    margin-bottom: 20px;
}
.mobile--view--hamburger--my--booking--border--line--fav1{
    width: 100%;
    height: 0.5px;
    background: rgba(255, 255, 255, 0.00);

    box-shadow: 0px -1px 0px 0px #E4E7EB inset;
    margin-top: 10px;
    margin-bottom: 20px;
}
.mobile--view--hamburger--my--booking--border--line--fav1-2{
    width: 100%;
    height: 0.5px;
    background: rgba(255, 255, 255, 0.00);

    box-shadow: 0px -1px 0px 0px #E4E7EB inset;
    margin-top: 10px;
    margin-bottom: 10px;
}

@media(max-width:640px) {
    .mobile--view--hamburger--my--booking {
        padding-left: 25px;
        padding-right: 25px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .mobile--view--hamburger--my--booking--1 {

        position: relative;
        top: -60px;
        display: flex;
        flex-direction: row;
        column-gap: 20px;
    }
.mobile--view--hamburger--my--booking--1 img{
    cursor: pointer;
}
    .mobile--view--hamburger--my--booking--1--fav {


        display: flex;
        flex-direction: row;
        column-gap: 20px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .mobile--view--hamburger--my--booking--search {
        position: relative;
        top: -30px;
    }


    .mobile--view--hamburger--my--booking--search input {
        border: none;
        outline: none;
        border-radius: 18.5px;
        border: 0.5px solid #66666E;
        width: 100%;
        height: 37px;
        margin-top: 20px;
        padding-left: 20px;
        display: flex;
        background-color: #120829;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

        color: white;
    }
    .mobile--view--hamburger--my--booking--search--fav{
        padding-left: 10px;
        padding-right: 10px;
    }

    .mobile--view--hamburger--my--booking--search--fav input {
        border: none;
        outline: none;
        border-radius: 18.5px;
        border: 0.5px solid #66666E;
        width: 100%;
        height: 37px;
        margin-top: 20px;
        padding-left: 20px;
        display: flex;
        background-color: #120829;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

        color: white;
    }



    .mobile--view--hamburger--my--booking--search span {
        color: white;
    }

    .mobile--view--hamburger--my--booking--border--line {
        width: 100%;
        height: 0.5px;
        background-color: #66666E;
        margin-top: 10px;
        margin-bottom: 20px;
        position: relative;
        top: -30px;
    }

    .mobile--view--hamburger--my--booking--border--line--fav {
        width: 100%;
        height: 0.5px;
        background-color: #66666E;
        margin-top: 10px;
        margin-bottom: 20px;
      

    }

    .mobile--view--hamburger--my--booking--card {
        height: 200px;
        width: 100%;
        display: flex;
        flex-direction: row;
    }

    .mobile--view--hamburger--my--booking--card1 {
        width: 70%;
        height: 200px;
    }

    .mobile--view--hamburger--my--booking--card1--party--name {
        height: 30px;
        width: 100%;
        border-radius: 3px 0px 0px 3px;
        color: #F8F4F4;
        font-family: Helvetica;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 21px;
        background: #CC0C39;
        padding-top: 5px;
        padding-left: 10px;
    }

    .mobile--view--hamburger--my--booking--card1--content--review {
        width: 80px;
        height: 15px;
        flex-shrink: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        column-gap: 3px;
        border-radius: 2px;
        background-color: #666247;
    }

    .mobile--view--hamburger--my--booking--card1--content--review span {
        color: goldenrod;
    }

    .mobile--view--hamburger--my--booking--card1--content--review p {
        color: white;
        font-size: 12px;
        margin: 0;
        padding: 0;
    }

    .mobile--view--hamburger--my--booking--card1--content--p {
        color: #FFF;
        font-family: Segoe UI;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;

        /* 150% */
    }

    .mobile--view--hamburger--my--booking--card1--content--p--1--2 {
        color: #FFF;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        /* 150% */
    }

    .mobile--view--hamburger--my--booking--card1--content--p--1--2--1 {
        color: #FFF;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        /* 150% */
    }


    .mobile--view--hamburger--my--booking--card1--content--p1 {
        color: #FFF;
        font-family: Segoe UI;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.5px;

    }

    .mobile--view--hamburger--my--booking--card1--content--p1 span {
        color: #FFF;
        font-family: Segoe UI;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        opacity: 0.3;
        letter-spacing: 0.5px;
    }

    .mobile--view--hamburger--my--booking--card1--content {
        margin-top: 8px;
        display: flex;
        flex-direction: column;
        padding-left: 3px;
    }

    .mobile--view--hamburger--my--booking--card2 button {
        width: 91px;
        height: 26px;
        flex-shrink: 0;
        color: #FFF;
        text-align: center;
        font-family: Helvetica;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        border-radius: 14px;
        border: none;
        outline: none;
        background: #1976D2;
        margin-top: 25px;
    }

    .mobile--view--hamburger--my--booking--card1--content p {
        margin: 0;
        padding: 0;
    }

    .mobile--view--hamburger--my--booking--card2 img {
        width: 100%;
        height: 85px;
        border-radius: 10px;
    }

    .mobile--view--hamburger--my--booking--card--card2 {
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        position: relative;
        top: -30px;
    }

    .mobile--view--hamburger--my--booking--history--card--card2 {
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        position: relative;
        top: -30px;
    }

    .mobile--view--hamburger--my--booking--card2 {
        width: 30%;
        height: 200px;
        display: flex;
        flex-direction: column;
        row-gap: 15px;
    }

    .mobile--view--hamburger--my--booking--1 p {
        color: #FFF;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: DM Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 19px;
        margin: 0;
        padding: 0;
        padding-right: 20px;
    }

    .mobile--view--hamburger--my--booking--1--fav p {
        color: #FFF;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: DM Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 19px;
        margin: 0;
        padding: 0;
        padding-right: 15px;
    }

    .mobile--view--hamburger--my--booking--tab {
        width: 100%;
        height: 45px;
        flex-shrink: 0;
        border-radius: 4px;
        border: 0.2px solid #3C3636;
        background: #F8F4F4;
        padding-left: 15px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        position: relative;
        top: -40px;
    }

    .mobile--view--hamburger--my--booking--tab button {
        height: 40px;
        width: auto;
        border: none;
        outline: none;
        font-size: 15px;
        font-weight: 600;
    }

    .My--Favorite--hamburger {
        position: relative;
        top: -40px;

    }

    .My--Favorite--hamburger--card {
        width: 100%;
        height: 500px;
        background: #1D1D29;
        margin-top: 15px;
        padding-top: 10px;
        padding-left: 15px;
        padding-right: 15px;

    }

    .My--Favorite--hamburger--card--12--1 {
        height: 130px;
        width: 25%;
    }

    .My--Favorite--hamburger--card--12--2 {
        height: 230px;
        width: 65%;

    }

    .My--Favorite--hamburger--card--12--2--1--12 {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        align-items: center;

    }

    .My--Favorite--hamburger--card--12--2--1--12 p {
        margin: 0;
        padding: 0;
    }

    .My--Favorite--hamburger--card--12--2--1--1 {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
    }

    .My--Favorite--hamburger--card--12--1 img {
        width: 100%;
        height: 80px;
        border-radius: 10px;
    }

    .hamburger--review--card span {
        font-size: 12px;
        color: goldenrod;
    }

    .hamburger--review--card1 span {

        color: goldenrod;
    }

    .hamburger--review--card1 {
        margin-top: 3px;
    }

    .hamburger--review--card p {
        font-size: 10px;
        color: white;
        margin: 0;
        padding: 0;
    }

    .hamburger--review--card {
        width: 35px;
        height: 16px;
        flex-shrink: 0;
        border-radius: 2px;
        background: rgba(255, 255, 255, 0.30);
        display: flex;
        flex-direction: row;
        column-gap: 3px;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .My--Favorite--hamburger--card--12--3 {
        height: 230px;
        width: 10%;
    }

    .My--Favorite--hamburger--card--12 {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        width: 100%;
        height: 100px;
    }

    .My--Favorite--hamburger--card--p {
        color: #FFF;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;

    }

    .hamburger--my--profile {
        padding-left: 20px;
        padding-right: 20px;
    }

    .hamburger--my--profile--content--1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }

    .hamburger--my--profile--content--12 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        column-gap: 10px;
    }

    .hamburger--my--profile--content--1 p {
        margin: 0;
        padding: 0;
    }

    .hamburger--my--profile--content--1--p {
        color: #FFF;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Helvetica;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
      
    }

    .hamburger--my--profile--content--1--p1 {
        color: #66666E;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Helvetica;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 19.5px;
    }

    .hamburger--my--profile--content--12--1 input {
        width: 100%;
        height: 30px;
        background-color: #120829;
        border: none;
        outline: none;
        color: white;
    }

    .hamburger--my--profile--content--12--1 select {
        width: 100%;
        height: 30px;
        background-color: #120829;
        border: none;
        outline: none;
        color: white;
    }

    .hamburger--calander--mobile {
        display: flex;
        flex-direction: row;
    }

    .hamburger--calander--mobile span {
        font-size: 20px;
        color: white;

    }

    .hamburger--my--profile--content--12--1 {
        margin-top: 30px;
    }

    .mobile--view--profile--number {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        justify-content: center;
        align-items: center;
    }

    .mobile--view--profile--number img {
        width: 30px;
        height: 20px;
    }

    .mobile--view--profile--number--border--line {
        height: 30px;
        width: 1px;
        background-color: #66666E;
    }

    .hamburger--my--profile--content--12--1 input::placeholder {
        color: white;
    }

    .hamburger--my--profile--content--12 button {
        width: 259.821px;
        height: 40px;
        flex-shrink: 0;
        border-radius: 6px;
        background: #1976D2;
        border: none;
        outline: none;
        color: #FFF;
        text-align: center;
        font-family: Helvetica;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        opacity: 0.909;
        margin-bottom: 100px;
    }
    .mobile--view--hamburger--my--booking--1 {
        display: flex;
        align-items: center;
      }
      
      .left-aligned-img {
        margin-right: 10px; 
      }
      
      .centered-text {
        margin: 0; 
        text-align: center;
        flex-grow: 1; 
      }
      .mobile-view-ham--notification{
        padding-left: 15px;
        padding-right: 15px;
        margin-top: 15px;
      }
      .web--view--notification--1--p{
        color: white;
      font-family: Inter;
      font-size: 17px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; 
      letter-spacing: 0.5px;
      }
      .web--view--notification--1--p1{
        color: white;
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0.5px;
      }
      .web--view--notification--1--p2{
        color: white;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; 
        letter-spacing: 0.5px;
      }
      .mobile--view--hamburger--my--notification--card{
        display: flex;
        flex-direction: column;
        row-gap: 15px;
      }


}