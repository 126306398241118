  .useind--homepage--caraousal--card1{
    display: flex;
    flex-direction: row;
    column-gap: 30px;
    margin-top: 40px;
 }
  .useind--homepage--caraousal--card{
    margin-bottom: 40px;
  }
  main {
    width: 100%;
    height: 400px;
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
  }
  .Artist-caraousal-left img{
   height: 100%;
   width: 100%;
   object-fit: contain;
   padding-left: 50px;
  }
  .Artist-caraousal-left video{
    height: 100%;
    width: 100%;
    object-fit: cover;
    padding-left: 50px;
   }
  .slide5  {
    width: 100%;
    height: 100%;
    position: absolute;
    transition: 1s;
  }
  .artist-time-date{
    font-size: 12px;
    color: #fff;
    opacity: 0.5;
    font-family: "Open Sans",sans-serif;
    font-weight: 400;
    letter-spacing: 1px;
  }
  .useind--homepage--caraousal--card--p--2 p{
    font-size: 45px;
    font-weight: 600;
    letter-spacing: 5px;
    font-family: "Open Sans",sans-serif;
    margin: 0;padding: 0;
    color: #fff;
    text-align: left;
  }
  .useind--homepage--caraousal--card--p--23{
    font-size: 15px;
    font-weight: 500;
    font-family: "Open Sans",sans-serif;
    text-align: left;
    color: #fff;
    letter-spacing: 1px;
    margin: 0;padding: 0;
  }
  .useind--homepage--caraousal--card--p--24{
    font-size: 14px;
    font-weight: 400;
    font-family: "Open Sans",sans-serif;
    text-align: left;
    color: #fff;
    letter-spacing: 1px;
    opacity: 0.5;
    margin: 0;padding: 0;
  }
  .artist-content-para button{
    width: auto;
    height: 35px;
    color: #fff;
    background-color: #d71362;
    background-image: linear-gradient(to right, #fe2944, #fd2d65);
    border-radius: 10px;
    font-family: "Open Sans",sans-serif;
    letter-spacing: 1px;
    border: none;
    padding: 5px 10px;
  }
  .artist-content-para1 button{
    width: auto;
    height: 30px;
    color: #fff;
    background-color: #d71362;
    background-image: linear-gradient(to right, #fe2944, #fd2d65);
    border-radius: 10px;
    font-family: "Open Sans",sans-serif;
    font-size: 10px;
    border: none;
}
  .Artist-book-now{
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
  }
  .Artist-book-now button{
    height: 50px;
    width: 150px;
    background-color:  transparent;
    border-radius: 20px;
    letter-spacing: 2px;
    font-size: 16px;
    border: none;
    font-family: "Open Sans",sans-serif;
    border: 2px solid #fd2d65;
    color: #fff;
  }
 .artist-content-para{
  display: flex;
  flex-direction: row;
  column-gap: 15px;
 }
 .artist-content-para1{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
 }
  .useind--homepage--caraousal--card--p{
    width: 25%;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
  }
  .useind--homepage--caraousal--card--p1{
    display: flex;
    flex-direction: column;
    row-gap: 40px;
  }
  .useind--homepage--caraousal--card--p p{
    margin: 0;padding: 0;
  }
  .useind--right--left--click{
    display: flex;
    flex-direction: row-reverse;
    column-gap: 15px;
    margin-top: 20px;
  }
  .useind--right--left--click button{
   background-color: black;
   color: #fff;
   border: none;
  }
  .useind--right--left--click button:hover{
    background-color: #d71362;
    color: #fff;
  }
  .Artist-caraousal-left{
  width: 75%;
  height: 400px;
  }
  .carousel .carousel-status {
    display: none;
}
  @media (min-width: 600px) and (max-width: 1100px) {
    
    .useind--homepage--caraousal--card--p {
      width: 40%;
      display: flex;
      flex-direction: column;
      row-gap: 15px;
      margin-top: 40px;
    }
    .useind--homepage--caraousal--card--p--2 p {
      font-size: 25px;
      font-weight: 600;
      letter-spacing: 4px;
      font-family: "Open Sans", sans-serif;
      margin: 0;
      padding: 0;
      color: #fff;
    }
    .useind--homepage--caraousal--card1{
      display: flex;
      flex-direction: row;
      column-gap: 40px;
    }
    main {
      width: 100%;
      height: 400px;
      padding-top: 100px;
      position: relative;
      overflow: hidden;
    }
    .Artist-caraousal-left img
    {
     height: 100%;
     width: 100%;
     object-fit: contain;
     padding-left: 50px;
    }
    .Artist-caraousal-left video{
      height: 100%;
      width: 100%;
      object-fit: cover;
      padding-left: 50px;
     }
  }
  @media (max-width:599px){
    .useind--homepage--caraousal--card{
      margin-top: 10px;
      margin-bottom: 30px;
      margin-right: 5px;
      margin-left: 6px;
    }
    .useind--homepage--caraousal--card1{
      display: flex;
      flex-direction: column;
      row-gap: 20px;
    }
    .Artist-caraousal-left{
      width: 100%;
      height: 180px;
      }
      .Artist-caraousal-left img{
        height: 100%;
        width: 100%;
        object-fit: contain;
        padding-left: 0;
       }
       .Artist-caraousal-left video{
        height: 100%;
        width: 100%;
        object-fit: cover;
        padding-left: 0;
       }
      .useind--homepage--caraousal--card--p{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 10px;
      }
      .useind--homepage--caraousal--card--p1{
        
        display: flex;
        flex-direction: column;
        row-gap: 10px;
      }
      .useind--homepage--caraousal--card--p--2{
        display: flex;
        flex-direction: row;
        column-gap: 10px;
      }
      .useind--homepage--caraousal--card--p--2 p {
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 3px;
        font-family: "Open Sans", sans-serif;
        margin: 0;
        padding: 0;
        color: #fff;
      }
      .useind--homepage--caraousal--card--p--23{
        font-size: 13px;
        font-weight: 500;
        font-family: "Open Sans",sans-serif;
        text-align: left;
        color: #fff;
        letter-spacing: 1px;
        margin: 0;padding: 0;
      }
      .useind--homepage--caraousal--card--p--24{
        font-size: 12px;
        font-weight: 400;
        font-family: "Open Sans",sans-serif;
        text-align: left;
        color: #fff;
        letter-spacing: 1px;
        opacity: 0.5;
        margin: 0;padding: 0;
      }
      .artist-time-date{
        font-size: 10px;
        color: #fff;
        opacity: 0.5;
        font-family: "Open Sans",sans-serif;
        font-weight: 400;
        letter-spacing: 1px;
      }
      .useind--homepage--caraousal--card--p--24{
        font-size: 10px;
        font-weight: 400;
        font-family: "Open Sans",sans-serif;
        text-align: left;
        color: #fff;
        letter-spacing: 1px;
        opacity: 0.5;
        margin: 0;padding: 0;
      }
      .useind--right--left--click{
        display: flex;
        flex-direction: row-reverse;
        column-gap: 15px;
        margin-top: 10px;
      }
      .artist-content-para{
        display: flex;
        flex-direction: row;
        column-gap: 8px;
       }
      .artist-content-para button{
        width: auto;
        height: 30px;
        color: #fff;
        background-color: #d71362;
        background-image: linear-gradient(to right, #fe2944, #fd2d65);
        border-radius: 10px;
        font-family: "Open Sans",sans-serif;
        letter-spacing: 0.5px;
        font-size: 10px;
        border: none;
        padding: 5px 10px;
      }
      .Artist-book-now button{
        height: 35px;
        width: auto;
        background-color:  transparent;
        border-radius: 15px;
        letter-spacing: 0.5px;
        font-size: 12px;
        border: none;
        font-family: "Open Sans",sans-serif;
        border: 2px solid #fd2d65;
        color: #fff;
      }
      .Artist-book-now{
        margin-top: 10px;
        display: flex;
        justify-content: flex-start;
      }
  }
  